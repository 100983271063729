import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// COMPS
import { CircularProgress, Grid } from "@material-ui/core";
import { InputWithLabel, ProfileImagePicker } from "Components/Commons";

// FORM VALIDATION
import { Formik } from "formik";

// STYLES
import { PageWrapper, FormWrapper, SignUpWrapper, TextFieldWrapper } from "./Signup.style";

// CONST
import { Api, URL_LOGIN, generateValidationSchema, INPUT_TYPE, AGE_RANGE, URL_GLOBAL_LANDING_PAGE, IMAGES } from "Helpers";

import { InputAdornment, FormControl, Typography, Button, MenuItem } from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

import EmailSuccessModal from "Components/Commons/EmailSuccessModal";

const initialValues = { firstName: "", lastName: "", email: "", phoneNumber: "", ageRange: "", school: "", password: "", confirmPassword: "" };

function Signup() {
	const [isLoading, setIsLoading] = useState(false),
		[imageSrc, setImageSrc] = useState(),
		[image, setImage] = useState(null),
		[customImage, setStatusOfCustomImage] = useState(false),
		[profileModal, setProfileModal] = useState(false),
		[visiblePassword, setVisiblePassword] = useState(false),
		[visibleConfirmPassword, setVisibleConfirmPassword] = useState(false),
		[isSignUpSuccess, setIsSignUpSuccess] = useState(false);

	// ROUTER
	const navigate = useNavigate();

	function onImageChange(data) {
		setStatusOfCustomImage(data.customUpload);
		setImage(data.image);
		setImageSrc(data.imageSrc);
		toggleProfileModal();
	}

	function toggleProfileModal() {
		setProfileModal(!profileModal);
	}

	function handleSubmit(values) {
		setIsLoading(true);
		let data = new FormData();
		values.isActive = true;
		values.isEmailVerify = false;
		let isAvatarSelect = false;

		if (image) {
			data.append("multipartFile", image);
			isAvatarSelect = true;
		}

		values["isAvatarSelect"] = !customImage ? isAvatarSelect : false;

		data.append(
			"userReqDto",
			new Blob([JSON.stringify(values)], {
				type: "application/json",
			})
		);

		new Api()
			.post("user/sign-up", { data, returnError: true })
			.then((response) => {
				setIsSignUpSuccess(true);
				setIsLoading(false);
			})
			.catch((error) => setIsLoading(false));
	}

	function checkPasswordVisibility(input) {
		if (input.name === "password" && visiblePassword) {
			return true;
		}

		if (input.name === "confirmPassword" && visibleConfirmPassword) {
			return true;
		}

		return false;
	}

	return (
		<PageWrapper className="flex f-column">
			<div className="header-container">
				<div className="top-bar">
					<div
						className="subject-logo"
						onClick={() => {
							navigate(-1);
						}}
					></div>
					<div className="btn-container">
						<div
							className="teacher-btn"
							onClick={() => {
								navigate(URL_GLOBAL_LANDING_PAGE);
							}}
						>
							<Typography className="login-font">Home</Typography>
						</div>
						<div
							className="login"
							onClick={() => {
								navigate(URL_LOGIN);
							}}
						>
							<Typography className="login-font">Login</Typography>
						</div>
					</div>
				</div>
			</div>
			<SignUpWrapper className="flex f-column f-h-center">
				<div className="box">
					<div className="title">
						<h1 className="text">SIGN UP</h1>
					</div>

					<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
						{({ values, touched, errors, handleChange, setFieldValue }) => (
							<FormWrapper>
								<label htmlFor="image-picker">
									<div className="image-box">
										{imageSrc ? (
											<div className="image-wrapper" style={{ backgroundImage: `url(${imageSrc || IMAGES.PROFILE.BOY})` }} onClick={toggleProfileModal} />
										) : (
											<img src={IMAGES.LANDING_PAGE.AVATAR} className="avatar" alt="avatar" onClick={toggleProfileModal} />
										)}
									</div>
								</label>

								<Grid container spacing={2} className="input-wrapper">
									{inputs.map((input) => (
										<Grid item xs={6} key={input.name}>
											{input.type === INPUT_TYPE.SELECT ? (
												<FormControl variant="outlined" className="form-select-age">
													<label className="label-age-range">{input.label}</label>
													<TextFieldWrapper
														id="outlined-select-age"
														select
														label={values[input.name] ? "" : input.label}
														className="select-age"
														value={values[input.name]}
														onChange={(value) => setFieldValue(input.name, value.target.value)}
														margin="normal"
														variant="outlined"
														InputProps={{ classes: { root: "text-root", notchedOutline: "outline" } }}
														InputLabelProps={{ shrink: false, classes: { root: "label" } }}
														validated={touched[input.name] && errors[input.name]}
														style={{ border: 0, height: "45px" }}
													>
														{input.values &&
															input.values.map((option) => (
																<MenuItem key={option.value} value={option.value} className="gutters">
																	{option.display}
																</MenuItem>
															))}
													</TextFieldWrapper>
													{touched[input.name] && errors[input.name] && <div className="error ellipses">{errors[input.name]}</div>}
												</FormControl>
											) : (
												<InputWithLabel
													className="full-width"
													name={input.name}
													label={input.label}
													placeholder={input.label}
													type={input.type === "password" ? (checkPasswordVisibility(input) ? "text" : "password") : input.type}
													value={values[input.name]}
													onChange={handleChange}
													signupform={true}
													validated={touched[input.name] && errors[input.name]}
													endAdornment={
														input.type === "password" && (
															<InputAdornment
																onClick={() => (input.name === "password" ? setVisiblePassword(!visiblePassword) : setVisibleConfirmPassword(!visibleConfirmPassword))}
																position="end"
																style={{ marginLeft: "0", cursor: "pointer" }}
															>
																{checkPasswordVisibility(input) ? <Visibility className="password-icon" /> : <VisibilityOff className="password-icon" />}
															</InputAdornment>
														)
													}
												/>
											)}
										</Grid>
									))}
								</Grid>

								<div className="button-parent">
									<Button className="button" type="submit" endIcon={isLoading ? <CircularProgress className="loader" /> : ""}>
										<Typography className="next-font">Sign Up</Typography>
									</Button>
								</div>
							</FormWrapper>
						)}
					</Formik>
				</div>
			</SignUpWrapper>

			{/* </DesignedBox> */}

			<EmailSuccessModal
				open={isSignUpSuccess}
				handleClose={() => {
					setIsSignUpSuccess(!isSignUpSuccess);
					navigate(URL_LOGIN);
				}}
			/>

			<ProfileImagePicker open={profileModal} onSuccess={onImageChange} handleClose={toggleProfileModal} />
		</PageWrapper>
	);
}

export default Signup;

const inputs = [
	{
		label: "First Name",
		name: "firstName",
		validation: { required: true },
	},
	{
		label: "Last Name",
		name: "lastName",
		validation: { required: true },
	},
	{
		label: "Email",
		name: "email",
		type: "email",
		validation: { required: true, email: true },
	},
	{
		label: "Phone Number",
		name: "phoneNumber",
		type: "number",
		validation: { number: true, positive: true, integer: true },
	},
	{
		label: "Age Range",
		name: "ageRange",
		type: INPUT_TYPE.SELECT,
		values: AGE_RANGE,
		validation: { required: true },
	},
	{
		label: "School",
		name: "school",
	},
	{
		label: "Password",
		name: "password",
		type: "password",
		validation: { required: true, min: 6 },
	},
	{
		label: "Confirm Password",
		name: "confirmPassword",
		type: "password",
		validation: { required: true, equals: "password" },
	},
];

const validationSchema = generateValidationSchema(inputs);

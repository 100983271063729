import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// ICONS
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
// STYLE
import { DialogWrapper } from "../../RapidFire/RapidFire.style";
// HELPERS
import { Api, URL_FOREIGN_LANGUAGE_DASHBOARD } from "Helpers";
// REDUX
import { quizSound } from "Redux/General/Actions";

const TYPE = { HOW_TO_PLAY: "howToPlay", DID_YOU_KNOW: "didYouKnow" };

const DEFAULT_TYPE = TYPE.DID_YOU_KNOW;
const NEXT_TYPE = TYPE.HOW_TO_PLAY;

const HowToPlay = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const profileInfo = useSelector((state) => state.Auth.profileInfo);

	const [content, setContent] = useState("");
	const [type, setType] = useState(DEFAULT_TYPE);
	const [isLoading, setLoading] = useState(false);
	const [pdfLoader, setPdfLoader] = useState(false);

	useEffect(() => {
		fetchData();
	}, []); // eslint-disable-line

	const fetchData = async () => {
		setLoading(true);

		let data = new FormData();

		data.append("moduleId", props.data.id);
		data.append("subjectId", props.subjectId);

		const response = await new Api().post("staticPage/game", { data });

		setContent(response.data);
		setLoading(false);
	};

	const proceed = () => {
		if (type === DEFAULT_TYPE) setType(NEXT_TYPE);
		else {
			if (props.data.id === "Quiz") dispatch(quizSound(true));
			closeModal();
		}
	};

	const closeModal = async () => {
		if (props.data.url) {
			const dateTime = moment().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
			if (props?.schoolId && props.schoolId.length) {
				const data = {
					type: "LOGIN",
					studentId: profileInfo?.id,
					schoolClassTestId: props.schoolId,
					moduleName: "RAPIDFIREFOREIGNLANGUAGE",
					dateTime: dateTime,
				};
				new Api().post("student/clock-in-clock-out", { data });
			}

			handleClose();
			navigate(URL_FOREIGN_LANGUAGE_DASHBOARD + "/" + props.data.url, { state: { schoolClassTestId: props?.schoolId } });
		} else {
			handleClose();
		}
	};

	const handleClose = () => props.handleClose({ url: props.data.url });

	const handlePdf = () => {
		setPdfLoader(true);

		let pdfUrl,
			attachedText = "",
			moduleName = props?.data?.id.replace(/ /g, "_").toUpperCase();

		if (type === TYPE.DID_YOU_KNOW) {
			pdfUrl = content?.didYouKnowFile;
			attachedText = `${moduleName}_DID_YOU_KNOW`;
		} else {
			pdfUrl = content?.howToPlayFile;
			attachedText = `${moduleName}_HOW_TO_PLAY`;
		}

		fetch(pdfUrl).then((response) => {
			response.blob().then((blob) => {
				// Creating new object of PDF file
				const fileURL = window.URL.createObjectURL(blob);
				// Setting various property values
				let alink = document.createElement("a");
				alink.href = fileURL;
				alink.download = `${attachedText}.pdf`;
				alink.click();

				setPdfLoader(false);
			});
		});
	};

	const ThumbVertical = (props) => <div {...props} className="thumb-vertical-foreign" />;

	return (
		<DialogWrapper onClose={handleClose} open={props.open} PaperProps={{ elevation: 0 }} classes={{ root: "background-root", paper: "paper", paperWidthSm: "paper-width" }}>
			<div className="box-modal text-center">
				<div className="title-container">
					<p className="sub-title">{type === TYPE.HOW_TO_PLAY ? "How to play ?" : "Did you know ?"}</p>
				</div>

				<div className="content-wrapper">
					{isLoading && (
						<div className="progress">
							<CircularProgress color="secondary" size={25} />
						</div>
					)}

					<Scrollbars className={"scroll-bar"} renderThumbVertical={ThumbVertical}>
						<div className="content" dangerouslySetInnerHTML={{ __html: content[type] }} />

						{content?.didYouKnowFile && type === TYPE.DID_YOU_KNOW && (
							<div className="attach-file">
								<PictureAsPdfIcon className="icon" />

								<div className="file" onClick={handlePdf}>
									Attachment
								</div>

								{pdfLoader && (
									<div>
										<CircularProgress className="pdf-loader" />
									</div>
								)}
							</div>
						)}

						{content?.howToPlayFile && type === TYPE.HOW_TO_PLAY && (
							<div className="attach-file">
								<PictureAsPdfIcon className="icon" />

								<div className="file" onClick={handlePdf}>
									Attachment
								</div>

								{pdfLoader && (
									<div>
										<CircularProgress className="pdf-loader" />
									</div>
								)}
							</div>
						)}
					</Scrollbars>

					<div className={`type-switcher ${type === DEFAULT_TYPE ? "visibility-none" : ""}`}>
						<div className="divider" />

						{type === TYPE.HOW_TO_PLAY && (
							<p className="text" onClick={() => setType(TYPE.DID_YOU_KNOW)}>
								Back to Review
							</p>
						)}
					</div>
				</div>

				<div className="play-btn-container" onClick={proceed}>
					<div className="play-btn">{type === DEFAULT_TYPE ? "HOW TO PLAY" : "LETS PLAY"}</div>
				</div>
			</div>
		</DialogWrapper>
	);
};

export default HowToPlay;

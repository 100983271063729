import styled from "styled-components";

import { PageWrapperBase } from "Components/Website.style";

// CONST
import { COLORS, FONTS, responsive } from "Styles/Constants";

import { Dialog } from "@material-ui/core";
import { IMAGES } from "Helpers";

export const PageWrapper = styled(PageWrapperBase)`
	color: #fff;
	position: relative;
	margin-left: -2px;
	min-height: 100vh !important;

	.background-image {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: -1;
		overflow: hidden;
	}

	.content {
		width: 70%;
		margin: 3% auto 0;
		${responsive.LAPTOP` margin: 3% 13% 0;`}
		${responsive.TABLET`margin: 3% 8% 0;  width: 78%;`}
        .overlay-stackrank {
			position: relative;
			.overlay-effect {
				opacity: 0.7;
			}
			.display-circular-progress {
				position: absolute;
				left: 50%;
				bottom: 50%;
			}
			.MuiCircularProgress-colorPrimary {
				color: #000;
			}
		}
	}
	.title-history {
		font-family: ${FONTS.TERTIARY_BOLD};
		font-size: 36px;
		color: #000;
		width: 100%;
		text-align: center;
		padding-top: 5%;
		padding-bottom: 5%;
		${responsive.LAPTOP` padding-top: 10%; margin: 0 auto;  width: 60%;`}
		${responsive.TABLET`padding-top: 13%;`}
	}
`;

export const GridContainer = styled.div`
	flex-wrap: wrap;
	.box {
		width: 25%;
		${responsive.LAPTOP`width:33.33%`}
		${responsive.TABLET`width:35%`}
        .disabled {
			pointer-events: none;
			filter: grayscale(80%);
			cursor: not-allowed;
		}

		.box-content {
			cursor: pointer;
			margin: 0 auto;
			max-width: 300px;
			position: relative;
			transition: 0.3s;
			width: 90%;
			position: relative;
			&:hover {
				transform: scale(1.05);
			}

			${responsive.DISPLAY` max-width : 225px;`}

			.img-container {
				width: 250px;
				height: 300px;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				position: relative;
				margin: 0 auto;

				${responsive.DISPLAY`height: 275px; width: 220px;`}
				${responsive.LAPTOP`height: 250px; width: 190px;`}
			}
			.title {
				font-size: 24px;
				padding-top: 17px;
				${responsive.DISPLAY`
                    font-size: 22px;
                    padding-top: 12px;
                `}
				${responsive.LAPTOP`
                    font-size: 18px;
                    padding-top: 12px;
                `}
			}

			.progress-container {
				bottom: 27px;
				padding: 0 10%;
				position: absolute;

				/* ${responsive.DISPLAY` bottom : 16px;`} */
			}
		}
	}
`;

export const ProgressWrapper = styled.div`
	text-align: left;

	.outer {
		background-color: #001d46;
		border: 1px solid rgba(59, 219, 255, 0.3);
		border-radius: 50px;
		width: 75%;
		margin: 0px auto;

		.active {
			border-radius: 50px;
			height: 7px;
			background: #da2323;
			width: ${(props) => props.value}%;
			transition: 0.5s ease;

			${responsive.DISPLAY`height: 6px;`}
		}
	}

	.text {
		margin: 3px auto 0px;
		width: 76%;
	}
`;

export const ScoreboardWrapper = styled.div`
	position: absolute;
	right: 17px;
	top: 150px; /* 100 header, 50 spacing */
	width: 220px;
	height: 690px;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url(${IMAGES.SCIENCE.LEADERBOARD});

	${responsive.DISPLAY` width : 170px; height:552px`}
	/* ${responsive.LAPTOP` width : 150px;`} */

    .scoreboard-title {
		font-size: 20px;
		text-align: center;
		padding-top: 5px;
		${responsive.DISPLAY`font-size: 18px; `}
	}
	.title {
		text-align: center;
		position: relative;
		z-index: 4;
		color: #fff;

		.image {
			width: 85%;
		}
		.text-container {
			margin-top: -39px;
			${responsive.DISPLAY`margin-top: -34px;`}

			.text {
				font-size: 18px;
				${responsive.DISPLAY`font-size: 14px; `}
			}
		}
	}
	.people-cards {
		margin-top: 40px;

		.person {
			width: 100%;
			height: 206px;

			&:nth-child(1) {
				z-index: 3;
			}
			&:nth-child(2) {
				z-index: 2;
			}
			&:nth-child(3) {
				z-index: 1;
			}

			${responsive.DISPLAY`height : 158px;`}

			.content {
				.image {
					border: 3px solid ${COLORS.SECONDARY};
					border-radius: 10px;
					margin: 0 auto;

					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;

					height: 90px;
					width: 90px;

					${responsive.DISPLAY`height: 70px; width: 70px;`}
					${responsive.LAPTOP`height: 50px; width: 50px;`}
				}
				.name {
					font-size: 20px;
					margin-top: 5px;
					color: #fff;
					${responsive.DISPLAY`font-size: 18px;`}
				}

				.score {
					background-color: #0c0d47;
					border-radius: 5px;
					display: inline-block;
					padding: 2px 12px;
					color: #fff;
				}
			}
		}
	}
`;

export const DialogWrapper = styled(Dialog)`
	.MuiBackdrop-root {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.MuiDialog-paper {
		background: transparent;
		color: #fff;
		overflow: visible;
	}

	.box {
		min-width: 650px;

		.title-container {
			position: relative;
			.title {
				margin-bottom: 10px;
			}

			.sub-title {
				font-size: 22px;
				margin-top: 10px;
			}

			.icon-container {
				cursor: pointer;
				position: absolute;
				right: 23px;
				top: -4px;

				.icon {
					color: ${COLORS.SECONDARY};
					font-size: 35px;
				}
			}
		}

		.content-wrapper {
			padding: 10px 10px 35px;
			position: relative;

			.content {
				padding-right: 10px;
			}

			.frames {
				display: flex;
				justify-content: center;

				.frame {
					margin: 20px;
					width: 30%;
					height: 150px;
					background-image: url(${IMAGES.SCIENCE.RAPID_FIRE_CARD});
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
					cursor: pointer;

					.image {
						height: 100%;
					}
				}
			}
		}
	}
	.button-wrapper {
		margin-top: -25px;
		text-align: center;

		.styled {
			min-width: 185px;
		}
	}
`;

import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";

// COMPS
import { AnimatedButton, DesignedBox } from "Components/Commons";
import { CircularProgress } from "@material-ui/core";

// STYLE
import { DialogWrapper } from "./HowToPlay.style";
import CancelIcon from "@material-ui/icons/Cancel";

import { Api, URL_CIVICS_DASHBOARD } from "Helpers";
// SOUND
// import LetsPlay from "Assets/Sounds/Jeopardy/lets-play.mp3";
// import LetsPlay from "Assets/Sounds/Jeopardy/Jeopardy-theme-song.mp3";
import { useDispatch, useSelector } from "react-redux";
import { quizSound } from "Redux/General/Actions";
import moment from "moment";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

const TYPE = {
	HOW_TO_PLAY: "howToPlay",
	DID_YOU_KNOW: "didYouKnow",
};

const DEFAULT_TYPE = TYPE.DID_YOU_KNOW;
const NEXT_TYPE = TYPE.HOW_TO_PLAY;

export default function HowToPlay(props) {
	const profileInfo = useSelector((state) => state.Auth.profileInfo);
	const [content, setContent] = useState({}),
		[type, setType] = useState(DEFAULT_TYPE),
		[isLoading, setLoading] = useState(false),
		[pdfLoader, setPdfLoader] = useState(false);

	// const [letsPlay, { stop: stopLetsPlay }] = useSound(LetsPlay, { volume: .2 });
	const dispatch = useDispatch();

	// const history = useHistory()
	const navigate = useNavigate();
	// eslint-disable-next-line
	useEffect(() => {
		fetchData();
	}, []);

	async function fetchData() {
		setLoading(true);

		let data = new FormData();
		data.append("moduleId", props.data.id);
		data.append("subjectId", props.subjectId);
		let response = await new Api().post("staticPage/game", { data });
		setContent(response.data);
		setLoading(false);
	}

	function proceed() {
		if (type === DEFAULT_TYPE) setType(NEXT_TYPE);
		else {
			if (props.data.id === "Quiz") dispatch(quizSound(true));
			closeModal();
		}
	}

	function closeModal() {
		if (props.data.url) {
			const dateTime = moment().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
			if (props?.schoolId && props.schoolId.length) {
				const data = {
					type: "LOGIN",
					studentId: profileInfo?.id,
					schoolClassTestId: props.schoolId,
					dateTime: dateTime,
				};
				if (props?.data?.subTitle === "Constitution") {
					data.moduleName = "CONSTITUTION";
				}
				if (props?.data?.subTitle === "Quizardy") {
					data.moduleName = "QUIZ";
				}
				if (props?.data?.subTitle === "CivBlitz") {
					data.moduleName = "FINAL";
				}
				if (props?.data?.name === "Bill Or No Bill") {
					data.moduleName = "LEGISLATIVE_BILLNOBILL";
				}
				if (props?.data?.name === "Bill Become Law") {
					data.moduleName = "LEGISLATIVE_BILLBECOMESLAW";
				}
				if (props?.data?.subTitle === "Judicial Spin") {
					data.moduleName = "JUDICIAL_SPIN";
				}
				if (props?.data?.name === "executive-branch") {
					data.moduleName = "EXECUTIVE";
				}
				if (data && data.moduleName) {
					new Api().post("student/clock-in-clock-out", { data });
				}
			}
			navigate(URL_CIVICS_DASHBOARD + "/" + props.data.url, {
				state: { schoolClassTestId: props?.schoolId },
			});
			props.handleClose();
		} else props.handleClose();
	}

	const ThumbVertical = (props) => <div {...props} className="thumb-vertical" />;

	const handlePdf = () => {
		setPdfLoader(true);
		let pdfUrl,
			attachedText = "",
			moduleName = props?.data?.id.replace(/ /g, "_").toUpperCase();
		if (type === TYPE.DID_YOU_KNOW) {
			pdfUrl = content?.didYouKnowFile;
			attachedText = `${moduleName}_DID_YOU_KNOW`;
		} else {
			pdfUrl = content?.howToPlayFile;
			attachedText = `${moduleName}_HOW_TO_PLAY`;
		}

		fetch(pdfUrl).then((response) => {
			response.blob().then((blob) => {
				// Creating new object of PDF file
				const fileURL = window.URL.createObjectURL(blob);
				// Setting various property values
				let alink = document.createElement("a");
				alink.href = fileURL;
				alink.download = `${attachedText}.pdf`;
				alink.click();
				setPdfLoader(false);
			});
		});
	};

	return (
		<DialogWrapper onClose={props.handleClose} open={props.open}>
			<DesignedBox>
				<div className="title-container">
					<h1 className="title"> {type === TYPE.HOW_TO_PLAY ? "How To Play" : "Did you know"}</h1>
					<p className="sub-title">{type === TYPE.HOW_TO_PLAY ? props.data.subTitle : ""}</p>

					<div className="icon-container" onClick={props.handleClose}>
						<CancelIcon className="icon" />
					</div>
				</div>

				<div className="content-wrapper">
					{isLoading && (
						<div className="progress">
							<CircularProgress color="secondary" size={25} />
						</div>
					)}
					<Scrollbars
						className={"scroll-bar"}
						style={{ height: 300 }}
						renderThumbVertical={ThumbVertical}
						// autoHide
					>
						<div className="content" dangerouslySetInnerHTML={{ __html: content[type] }} />
						{content?.didYouKnowFile && type === TYPE.DID_YOU_KNOW && (
							<div className="attach-file">
								<PictureAsPdfIcon className="icon" />
								<div className="file" onClick={handlePdf}>
									Attachment
								</div>
								{pdfLoader && (
									<div>
										<CircularProgress className="pdf-loader" />
									</div>
								)}
							</div>
						)}

						{content?.howToPlayFile && type === TYPE.HOW_TO_PLAY && (
							<div className="attach-file">
								<PictureAsPdfIcon className="icon" />
								<div className="file" onClick={handlePdf}>
									Attachment
								</div>
								{pdfLoader && (
									<div>
										<CircularProgress className="pdf-loader" />
									</div>
								)}
							</div>
						)}
					</Scrollbars>

					{
						<div className={`type-switcher ${type === DEFAULT_TYPE ? "visibility-none" : ""}`}>
							<div className="divider" />
							{type === TYPE.HOW_TO_PLAY && (
								<p
									className="text"
									onClick={() => {
										setType(TYPE.DID_YOU_KNOW);
									}}
								>
									Back to Review
								</p>
							)}
							{type === TYPE.DID_YOU_KNOW && (
								<p
									className="text"
									onClick={() => {
										setType(TYPE.HOW_TO_PLAY);
									}}
								>
									How to play
								</p>
							)}
						</div>
					}
				</div>
			</DesignedBox>
			<div className="button-wrapper">
				<AnimatedButton primaryFonts onClick={proceed}>
					{type === DEFAULT_TYPE ? "How to Play" : "Lets Play"}
				</AnimatedButton>
			</div>
		</DialogWrapper>
	);
}

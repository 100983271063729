import styled from "styled-components";
import { Dialog } from "@material-ui/core";
import { COLORS } from "Styles/Constants";

export const DialogWrapper = styled(Dialog)`
	.MuiBackdrop-root {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.MuiDialog-paper {
		background: transparent;
		color: #fff;
		overflow: visible;
	}

	.box {
		min-width: 600px;

		.title-container {
			position: relative;
			.title {
				margin-bottom: 10px;
			}

			.sub-title {
				font-size: 22px;
				margin-top: 10px;
			}

			.icon-container {
				cursor: pointer;
				position: absolute;
				right: 23px;
				top: 0px;

				.icon {
					color: ${COLORS.SECONDARY};
					font-size: 35px;
				}
			}
		}

		.content-wrapper {
			padding: 10px 10px 35px;
			position: relative;

			.content {
				padding-right: 10px;
			}

			.attach-file {
				display: flex;
				justify-content: center;

				.icon {
					color: #fff;
				}

				.file {
					margin: 0px 10px;
					font-size: 18px;
					cursor: pointer;
					text-decoration: underline;
					color: #fff;
				}

				.pdf-loader {
					width: 20px !important;
					height: 20px !important;
					color: #fff;
					margin-top: 1px;
				}
			}

			.progress {
				position: absolute;
				top: 36%;
				left: 0;
				right: 0;
			}

			.type-switcher {
				.divider {
					background-color: ${COLORS.SECONDARY};
					height: 2px;
					width: 50%;
					margin: 5px auto;
					opacity: 0.5;
				}

				.text {
					color: ${COLORS.SECONDARY};
					cursor: pointer;
					transition: 0.3s;
					&:hover {
						color: #fff;
					}
				}
			}
		}
	}
	.button-wrapper {
		margin-top: -25px;
		text-align: center;

		.styled {
			min-width: 185px;
		}
	}
`;

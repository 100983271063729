import React from "react";
import styled, { keyframes } from "styled-components";
import { COLORS } from "Styles/Constants";

const BORDER_CUT_WIDTH = 50,
	BORDER_WIDTH = 3,
	ALLOWED_OVERFLOW_AREA = 150;

const BoxBase = styled.div`
	min-width: 350px;
	min-height: 250px;
	position: relative;
`;

export const BoxWrapper = styled(BoxBase)`
	margin: 0 auto;
	max-width: 40%;
`;

const DiagBorder = (color) => `
    background-color : ${color};
    box-shadow  : 0 1px 21px ${color};
    content     : '';
    height      : ${BORDER_WIDTH + 1}px;
    width       : 73px;
    position    : absolute;
    transform   : rotate(-45deg);
`;

export const Box = styled(({ disableInnerShadow, borderColor, ...rest }) => <BoxBase {...rest} />)`
	background-color: ${COLORS.BLUE_BACKGROUND};
	border: ${BORDER_WIDTH}px solid ${({ borderColor }) => borderColor};
	box-shadow: ${({ disableInnerShadow, borderColor }) => (!disableInnerShadow ? `0 0 20px -4px ${borderColor} inset` : "")};
	color: #fff;
	padding: 10px;
	position: relative;
	height: 100%;
	width: 100%;

	clip-path: polygon(
		0% ${BORDER_CUT_WIDTH}px,
		/* top left */ ${BORDER_CUT_WIDTH}px 0%,
		/* top left */ ${BORDER_CUT_WIDTH}px -${ALLOWED_OVERFLOW_AREA}px,
		/* top left overflow */ 100% -${ALLOWED_OVERFLOW_AREA}px,
		/* top right overflow */ 100% 0%,
		/* top right */ 100% calc(100% - ${BORDER_CUT_WIDTH}px),
		/* bottom right */ calc(100% - ${BORDER_CUT_WIDTH}px) 100%,
		/* bottom right */ calc(100% - ${BORDER_CUT_WIDTH}px) calc(100% + ${ALLOWED_OVERFLOW_AREA}px),
		/* bottom right overflow */ 0px calc(100% + ${ALLOWED_OVERFLOW_AREA}px),
		/* bottom left overflow */ 0px 100%,
		/* bottom left */ 0 100% /* bottom left */
	);

	&:before {
		${({ borderColor }) => DiagBorder(borderColor)}
		top     : 22px;
		left: -12.5px;
	}
	&:after {
		${({ borderColor }) => DiagBorder(borderColor)}
		bottom  : 22px;
		right: -13.5px;
	}
`;

import styled from "styled-components";
import { Dialog } from "@material-ui/core";
import { InputBase } from "@material-ui/core";
// CONSTANTS
import { COLORS, responsive } from "Styles/Constants";
// IMAGES
import { IMAGES } from "Helpers";

export const PursuitWrapper = styled.div`
	display: flex;
	min-height: 100vh;
	align-items: center;
	justify-content: center;

	.background-image {
		z-index: -1;
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: absolute;
	}

	.main-container {
		display: flex;
		width: 100%;
		justify-content: center;

		.left-container {
			width: 900px;
			${responsive.DESKTOP`
               width: 680px;
            `}
			display: flex;
			flex-direction: column;
			align-items: center;
			.progress {
				color: #fff;
			}
			.pursuit-logo {
				background-image: url(${IMAGES.ECONOMICS.PURSUIT.LOGO_TWO});
				background-repeat: no-repeat;
				background-size: contain;
				width: 100%;
				height: 255px;
				${responsive.DESKTOP`
                    width: 700px;
                    height:200px;
              `}
				${responsive.LAPTOP`
                    width: 650px;
                    height:200px;
              `}

				${responsive.TABLET`
                    width: 600px;
                    height:200px;
              	`}
			}
			.form-select-gender {
				margin-top: 30px;
				width: 100%;
				.select-label {
					margin-left: 14%;
					color: rgba(255, 255, 255, 0.3);
					font-size: 20px;
					padding-top: 2px;
				}
				.MuiSelect-iconOutlined {
					color: rgba(255, 255, 255, 0.3);
				}
				.MuiOutlinedInput-notchedOutline {
					border: none;
				}
				.MuiInputLabel-outlined {
					color: #fff;
				}
				.MuiOutlinedInput-root {
					color: #fff;
				}
				.select-gender {
					margin: 0px auto;
					width: 75%;
					background: linear-gradient(to top, #252424, #000, #252424);
					border-radius: 10px;
					box-shadow: inset 0 0 9.8px 0.2px #000, inset 0px 3px 0 0 rgb(255 255 255 / 41%);
					border: solid 3px #000;
					color: #fff;
				}
			}
			.btn-container {
				background: linear-gradient(to top, #ffe6ed, #875262, #fddbdb);
				border-radius: 50px;
				margin: 10% auto 0px;
				padding: 4px;
				animation: rounded 20s linear infinite;
				transition: opacity 0.3s ease-in-out;
				.done-btn {
					&.enable {
						cursor: pointer;
						filter: blur(0px);
					}
					background: linear-gradient(to top, #a0b016, #a0b016, #a0b016);
					border-radius: 35px;
					background-size: 400%;
					box-shadow: inset 0 0 9.8px 0.2px #000, inset 0px 3px 0 0 rgba(255, 255, 255, 0.41);
					font-size: 28px;
					padding: 5px 40px;
					transition: opacity 0.3s ease-in-out;
					width: auto;
					z-index: 1;
					border: 1px solid #000;
					color: #fff;
					filter: blur(2px);
				}
				@keyframes rounded {
					0% {
						background-position: 0 0;
					}
					50% {
						background-position: 400% 0;
					}
					100% {
						background-position: 0 0;
					}
				}
			}
			.green {
				background: linear-gradient(45deg, #4caf50, rgb(255, 255, 255), #4caf50, rgb(255, 255, 255)) 0% 0% / 400%;
				box-shadow: 0px 0px 11px 4px #378b06;
			}
			.red {
				background: linear-gradient(45deg, rgb(255, 0, 0), rgb(255, 255, 255), rgb(255, 0, 0), rgb(255, 255, 255)) 0% 0% / 400%;
				box-shadow: 0px 0px 11px 4px #ff000094;
				cursor: not-allowed;
			}
		}
		.right-container {
			background-image: url(${IMAGES.ECONOMICS.PURSUIT.GIRL_TWO});
			background-repeat: no-repeat;
			background-size: cover;
			position: absolute;
			width: 270px;
			height: 820px;
			right: 200px;
			bottom: 0px;
			${responsive.DESKTOP`
                width: 200px;
                height: 620px;
                right: 80px;
          `}
			${responsive.LAPTOP`
                right: 25px;
        `}
		}
	}
`;

export const PursuitRoundOneWrapper = styled.div`
	min-height: 100vh;
	display: flex;
	align-items: center;

	.background-image {
		z-index: -1;
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: absolute;
	}

	.main-outer-container {
		display: flex;
		justify-content: center;
		/* justify-content: space-between; */
		width: 100%;
		margin: 60px auto 0px;
		.left-image {
			background-image: ${(props) => (props.selectedGender === "female" ? `url(${IMAGES.ECONOMICS.PURSUIT.GIRL})` : `url(${IMAGES.ECONOMICS.PURSUIT.BOY})`)};
			background-repeat: no-repeat;
			background-size: 100% 100%;
			width: 270px;
			height: 680px;
			background-position: center;
			${responsive.DESKTOP`
                width: 200px;
                height: 600px;
            `}
		}
		.right-image {
			background-image: url(${IMAGES.ECONOMICS.PURSUIT.GIRL_THREE});
			background-repeat: no-repeat;
			background-size: 100% 100%;
			width: 350px;
			height: 680px;
			background-position: center;
			${responsive.DESKTOP`
                width: 260px;
                height: 600px;
            `}
		}
		.question-container {
			width: 50%;
			margin: 0px 20px;
			border-radius: 20px;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;

			${responsive.DESKTOP`
               width: 60%;
            `}

			${responsive.LAPTOP`
               width: 65%;
            `}

			${responsive.TABLET`
               width: 70%;
            `}

			.timer-image {
				background-image: url(${IMAGES.ECONOMICS.PURSUIT.TIMER});
				background-repeat: no-repeat;
				background-size: 100% 100%;
				height: 60px;
				background-position: center;
				margin: 0px auto;
				z-index: 1;
				margin-bottom: -25px;
				display: flex;
				align-items: center;
				color: #fff;
				justify-content: center;
				font-size: 35px;
				padding: 0px 25px;
			}
			.outer-main-container {
				background: linear-gradient(to top, #ffe6ed, #875262, #fddbdb);
				border-radius: 20px;
				border: 1px solid #fff;
				.inner-question-container {
					border: 1px solid #fff;
					border-radius: 20px;
					border-bottom: 2px solid #fff;
					.question-box {
						margin: 5px;
						.question-wrapper {
							text-align: center;
							.question-data {
								padding: 30px 20px 0px;
								border: 2px solid #000;
								border-radius: 20px;
								background: linear-gradient(to top, #240c0c, #5c3434, #240c0c);
								box-shadow: inset 0 0 9.8px 0.2px #000, inset 0px 3px 0 0 rgba(255, 255, 255, 0.41);
								.question {
									font-size: 30px;
									width: 90%;
									margin: 0px auto;
									color: #fff;
								}
								.option-hint {
									color: #fff;
									margin-top: 15px;
									margin-bottom: 5px;
									font-size: 18px;
								}
							}
						}
					}
				}
				.calculating {
					color: black;
					.text {
						font-size: 28px;
					}
					.sub-text {
						margin: 0;
						font-size: 25px;
					}
				}
				.result {
					color: black;
					.result-heading {
						padding-top: 3%;
						font-size: 36px;
					}
					.summary {
						color: black;
						.summary-heading {
							font-size: 28px;
						}
					}
					.score-wrapper {
						.score-item {
							padding: 0 15px;
							.score {
								font-size: 46px;
							}

							&.correct {
								font-size: 17px;
								.score {
									color: ${COLORS.GREEN};
								}
							}
							&.incorrect {
								font-size: 17px;
								.score {
									color: ${COLORS.DANGER};
								}
							}
						}
					}
					.not-attempted {
						margin-top: 30px;
						font-size: 16px;
						color: black;
					}
					.action-container {
						margin: 18px 0 35px;
						color: black;

						.round-two {
							font-size: 30px;
							cursor: pointer;
							color: #7e4e16;
							font-weight: bold;
						}
						.round-two-text {
							font-size: 18px;
							margin: 0px 10px;
						}
						.text {
							cursor: pointer;
							transition: 0.3s;
							font-size: 20px;
							margin-top: 10px;
							&.try-again {
								color: #005ca8;
								&:hover {
									color: black;
								}
							}

							&.all-done {
								/* color: ${COLORS.GREEN}; */
							}

							&.dash {
								opacity: 0.7;
								font-size: 25px;
								font-weight: bold;
								margin-top: 5px;
								color: green;
								&:hover {
									opacity: 0.9;
								}
							}
						}
					}
				}
				.progress {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 100px;
				}
				.option-container {
					border-bottom: 1px solid #fff;
					border-left: 1px solid #fff;
					border-right: 1px solid #fff;
					border-bottom-left-radius: 20px;
					border-bottom-right-radius: 20px;
					.checkbox-grid-container {
						display: grid;
						grid-template-columns: auto auto;
						grid-gap: 5px;
						margin: 5px;

						.checkbox {
							background: linear-gradient(to top, #7e4e16, #813e10, #7e4e16);
							box-shadow: inset 0 0 9.8px 0.2px #000, inset 0px 3px 0 0 rgb(255 255 255 / 41%);
							color: #fff;
							font-size: 30px;
							padding: 15px 10px;
							cursor: pointer;
							border: 1px solid #7e4e16;
							display: flex;
							align-items: center;
							justify-content: center;
						}
						.left-box {
							border-bottom-left-radius: 10px;
						}
						.right-box {
							border-bottom-right-radius: 10px;
						}
						.selected {
							background: linear-gradient(to top, #275e00, #275e00, #275e00);
							border: 1px solid #275e00;
						}
					}
				}
			}
			.button-outer-container {
				display: flex;
				.btn-container {
					/* background: linear-gradient(to top, #ffe6ed, #875262, #fddbdb); */
					border-radius: 50px;
					margin: 30px auto 0px;
					padding: 4px;
					animation: rounded 20s linear infinite;
					transition: opacity 0.3s ease-in-out;
					.done-btn {
						&.enable {
							cursor: pointer;
							filter: blur(0px);
						}
						background: linear-gradient(to top, #a0b016, #a0b016, #a0b016);
						border-radius: 35px;
						background-size: 400%;
						box-shadow: inset 0 0 9.8px 0.2px #000, inset 0px 3px 0 0 rgba(255, 255, 255, 0.41);
						font-size: 28px;
						padding: 5px 40px;
						transition: opacity 0.3s ease-in-out;
						width: auto;
						z-index: 1;
						border: 1px solid #000;
						color: #fff;
						filter: blur(2px);
					}
					.skip-btn {
						cursor: pointer;
						background: linear-gradient(to top, #a0b016, #a0b016, #a0b016);
						border-radius: 35px;
						background-size: 400%;
						box-shadow: inset 0 0 9.8px 0.2px #000, inset 0px 3px 0 0 rgba(255, 255, 255, 0.41);
						font-size: 28px;
						padding: 5px 40px;
						transition: opacity 0.3s ease-in-out;
						width: auto;
						z-index: 1;
						border: 1px solid #000;
						color: #fff;
					}
					@keyframes rounded {
						0% {
							background-position: 0 0;
						}
						50% {
							background-position: 400% 0;
						}
						100% {
							background-position: 0 0;
						}
					}
				}
			}
			.green {
				background: linear-gradient(45deg, #4caf50, rgb(255, 255, 255), #4caf50, rgb(255, 255, 255)) 0% 0% / 400%;
				box-shadow: 0px 0px 11px 4px #378b06;
			}
			.red {
				background: linear-gradient(45deg, rgb(255, 0, 0), rgb(255, 255, 255), rgb(255, 0, 0), rgb(255, 255, 255)) 0% 0% / 400%;
				box-shadow: 0px 0px 11px 4px #ff000094;
				cursor: not-allowed;
			}
		}
	}
`;

export const DarkInputBase = styled(InputBase)`
	background: linear-gradient(to top, #252424, #000, #252424);
	border-radius: 5px;
	color: #fff;
	padding: 15px 21px 15px;
	margin: 10px 5px;
	width: 98%;
	box-shadow: inset 0 0 9.8px 0.2px #000, inset 0px 3px 0 0 rgb(255 255 255 / 41%);
	input::placeholder {
		color: #fff;
	}
`;

export const Input = styled(DarkInputBase)``;

export const DraggingOption = styled.div`
	border-radius: 5px;
	background: linear-gradient(to top, #7e4e16, #813e10, #7e4e16);
	cursor: pointer;
	padding: 10px;
	color: #fff;
	margin: 10px;
	user-select: none;
	border: 1px solid #000;
	box-shadow: inset 0 0 9.8px 0.2px #000, inset 0px 3px 0 0 rgb(255 255 255 / 41%);
	font-size: 25px;
	.is-dragging & {
		opacity: 0.5;
		border-color: #000;
	}
`;

export const PursuitRoundTwoWrapper = styled.div`
	min-height: 100vh;
	display: flex;
	align-items: center;

	.background-image {
		z-index: -1;
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: absolute;
	}

	.main-outer-container {
		display: flex;
		justify-content: space-between;
		/* width: 90%;
        margin: 60px auto 0px; */
		justify-content: center;
		/* justify-content: space-between; */
		width: 100%;
		margin: 60px auto 0px;
		${responsive.DESKTOP`
                margin: 15px auto 0px;
        `}
		.left-image {
			background-image: ${(props) => (props.selectedGender === "female" ? `url(${IMAGES.ECONOMICS.PURSUIT.GIRL})` : `url(${IMAGES.ECONOMICS.PURSUIT.BOY})`)};
			background-repeat: no-repeat;
			background-size: 100% 100%;
			width: 270px;
			height: 680px;
			background-position: center;
			${responsive.DESKTOP`
                width: 200px;
                height: 580px;
            `}
		}
		.right-image {
			background-image: url(${IMAGES.ECONOMICS.PURSUIT.GIRL_THREE});
			background-repeat: no-repeat;
			background-size: 100% 100%;
			width: 350px;
			height: 680px;
			background-position: center;
			${responsive.DESKTOP`
                width: 260px;
                height: 580px;
            `}
		}
		.main-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: -5%;
			color: #fff;
			font-size: 35px;
			${responsive.DESKTOP`
                 margin-top: -3%;
            `}
			.progress {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.select-text {
				margin: 5px;
			}

			.overlay-table {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				opacity: 0.7;
				.display-circular-progress {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					margin: 0;
					.progress {
						width: 50px;
						height: 50px;
					}
				}
			}
			.top-girl {
				/* background-image: url(${IMAGES.ECONOMICS.PURSUIT.AVATAR});
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-position: center;
                background-repeat: no-repeat;
                width: 130px;
                height: 150px;
                ${responsive.DESKTOP`
                    width: 90px;
                    height: 110px;
                `} */
				padding-top: 30px;
			}

			.selected {
				cursor: pointer;
				background-image: ${(props) =>
					props.selectedAmount == 1
						? "linear-gradient(to top, #3CB371, #228B22, #3CB371)"
						: props.selectedAmount == 2
						? "linear-gradient(to top,#FF00FF,#9400D3,#FF00FF)"
						: props.selectedAmount == 3 && "linear-gradient(to top, #00FFFF, #40E0D0, #00FFFF)"} !important;
			}

			.tile-one {
				width: 350px;
				height: 50px;
				margin-top: 3px;
				${responsive.DESKTOP`
                    height: 40px;
                    width: 340px;
                `}
				clip-path: polygon(0% 0%,100% 0%,96% 100%,4% 100%);
				&.chaser {
					background-image: url(${IMAGES.ECONOMICS.PURSUIT.CHASER});
					background-size: 100% 100%;
					background-position: center;
					background-repeat: no-repeat;
				}
			}
			.tile-two {
				width: 320px;
				height: 50px;
				margin-top: 3px;
				${responsive.DESKTOP`
                    height: 40px;
                    width: 310px;
                `}
				display: flex;
				align-items: center;
				justify-content: center;
				clip-path: polygon(0% 0%, 100% 0%, 96% 100%, 4% 100%);
			}
			.trapezoid {
				box-shadow: inset 0 0 9.8px 0.2px #000, inset 0px 3px 0 0 rgba(255, 255, 255, 0.41);
				background-blend-mode: soft-light;
				background-image: linear-gradient(to top, #1e90ff, #0000ff, #1e90ff);
				border-top: 3px solid #000;
				border-bottom: 3px solid #000;
			}
			.chaser {
				background-image: url(${IMAGES.ECONOMICS.PURSUIT.CHASER});
				background-size: 100% 100%;
				background-position: center;
				background-repeat: no-repeat;
				box-shadow: none;
				border: none;
			}
			.tile-three {
				width: 290px;
				height: 50px;
				margin-top: 3px;
				display: flex;
				align-items: center;
				justify-content: center;
				${responsive.DESKTOP`
                    height: 40px;
                    width: 285px;
                `}
				clip-path: polygon(0% 0%,100% 0%,96% 100%,4% 100%);
				background-image: ${(props) => !props.selectedAmount && "linear-gradient(to top, #3CB371, #228B22, #3CB371)"};
			}
			.tile-four {
				width: 265px;
				height: 50px;
				margin-top: 3px;
				display: flex;
				align-items: center;
				justify-content: center;
				${responsive.DESKTOP`
                    height: 40px;
                    width: 260px;
                `}
				clip-path: polygon(0% 0%,100% 0%,96% 100%,4% 100%);
				background-image: ${(props) => !props.selectedAmount && "linear-gradient(to top,#FF00FF,#9400D3,#FF00FF)"};
			}
			.tile-five {
				width: 240px;
				height: 50px;
				margin-top: 3px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 33px;
				${responsive.DESKTOP`
                    height: 40px;
                    width: 238px;
                `}
				clip-path: polygon(0% 0%,100% 0%,96% 100%,4% 100%);
				background-image: ${(props) => !props.selectedAmount && "linear-gradient(to top, #00FFFF, #40E0D0, #00FFFF)"};
			}
			.tile-six {
				width: 215px;
				height: 50px;
				margin-top: 3px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 32px;
				${responsive.DESKTOP`
                    height: 40px;
                    width: 213px;
                `}
				clip-path: polygon(0% 0%,100% 0%,95% 100%,5% 100%);
			}
			.tile-seven {
				width: 190px;
				height: 50px;
				margin-top: 3px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 31px;
				${responsive.DESKTOP`
                    height: 40px;
                    width: 192px;
                `}
				clip-path: polygon(0% 0%,100% 0%,95% 100%,5% 100%);
			}
			.tile-eight {
				width: 165px;
				height: 50px;
				display: flex;
				align-items: center;
				margin-top: 3px;
				justify-content: center;
				font-size: 30px;
				${responsive.DESKTOP`
                    height: 40px;
                    width: 168px;
                `}
				clip-path: polygon(0% 0%,100% 0%,95% 100%,5% 100%);
			}
			.tile-nine {
				width: 145px;
				height: 50px;
				display: flex;
				margin-top: 3px;
				align-items: center;
				justify-content: center;
				font-size: 28px;
				${responsive.DESKTOP`
                    height: 40px;
                    width: 148px;
                `}
				clip-path: polygon(0% 0%,100% 0%,92% 100%,8% 100%);
			}
			.tile-ten {
				width: 120px;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 25px;
				margin-top: 3px;
				${responsive.DESKTOP`
                    height: 40px;
                    width: 122px;
                `}
				clip-path: polygon(0% 0%,100% 0%,91% 100%,9% 100%);
			}
			.tile-eleven {
				width: 95px;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 22px;
				margin-top: 3px;
				${responsive.DESKTOP`
                    height: 40px;
                    width: 99px;
                `}
				clip-path: polygon(0% 0%,100% 0%,90% 100%,10% 100%);
				background-image: linear-gradient(to top, #3cb371, #008000, #3cb371);
			}
		}
	}
`;

export const PursuitRoundThreeWrapper = styled.div`
	background-image: url(${IMAGES.ECONOMICS.PURSUIT.GAME_BACKGROUND});
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center;
	background-repeat: no-repeat;
	min-height: 100vh;
	display: flex;
	align-items: center;
	.main-outer-container {
		display: flex;
		justify-content: space-between;
		/* width: 90%;
        margin: 60px auto 0px; */
		justify-content: center;
		/* justify-content: space-between; */
		width: 100%;
		margin: 60px auto 0px;
		.left-image {
			background-image: ${(props) => (props.selectedGender === "female" ? `url(${IMAGES.ECONOMICS.PURSUIT.GIRL})` : `url(${IMAGES.ECONOMICS.PURSUIT.BOY})`)};
			background-repeat: no-repeat;
			background-size: 100% 100%;
			width: 270px;
			height: 680px;
			background-position: center;
			${responsive.DESKTOP`
                width: 200px;
                height: 600px;
            `}
		}
		.right-image {
			background-image: url(${IMAGES.ECONOMICS.PURSUIT.GIRL_THREE});
			background-repeat: no-repeat;
			background-size: 100% 100%;
			width: 350px;
			height: 680px;
			background-position: center;
			${responsive.DESKTOP`
                width: 260px;
                height: 600px;
            `}
		}
		.question-container {
			width: 50%;
			margin: 0px 20px;
			border-radius: 20px;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.timer-image {
				background-image: url(${IMAGES.ECONOMICS.PURSUIT.TIMER});
				background-repeat: no-repeat;
				background-size: 100% 100%;
				height: 60px;
				background-position: center;
				margin: 0px auto;
				z-index: 1;
				margin-bottom: -25px;
				display: flex;
				align-items: center;
				color: #fff;
				justify-content: center;
				font-size: 35px;
				padding: 0px 25px;
			}
			.outer-main-container {
				background: linear-gradient(to top, #ffe6ed, #875262, #fddbdb);
				border-radius: 20px;
				border: 1px solid #fff;
				.inner-question-container {
					/* border: 1px solid #fff;
                    border-radius: 20px;
                    border-bottom: 2px solid #fff; */
					border: ${(props) => (props.quizStatus === "WRAPPED_UP" ? "" : "1px solid #fff")};
					border-radius: ${(props) => (props.quizStatus === "WRAPPED_UP" ? "" : "20px")};
					border-bottom: ${(props) => (props.quizStatus === "WRAPPED_UP" ? "" : "2px solid #fff")};

					.question-box {
						margin: 5px;
						.question-wrapper {
							text-align: center;
							.question-data {
								padding: 30px 20px 0px;
								border: 2px solid #000;
								border-radius: 20px;
								background: linear-gradient(to top, #240c0c, #5c3434, #240c0c);
								box-shadow: inset 0 0 9.8px 0.2px #000, inset 0px 3px 0 0 rgba(255, 255, 255, 0.41);
								.question {
									font-size: 30px;
									width: 90%;
									margin: 0px auto;
									color: #fff;
								}
								.option-hint {
									color: #fff;
									margin-top: 15px;
									margin-bottom: 5px;
									font-size: 18px;
								}
							}
						}
					}
				}
				.calculating {
					color: black;
					.text {
						font-size: 28px;
					}
					.sub-text {
						margin: 0;
						font-size: 25px;
					}
				}
				.result {
					color: black;
					.result-heading {
						padding-top: 3%;
						font-size: 36px;
					}
					.summary {
						color: black;
						.summary-heading {
							font-size: 28px;
						}
					}
					.score-wrapper {
						.score-item {
							padding: 0 15px;
							.score {
								font-size: 46px;
							}

							&.correct {
								font-size: 17px;
								.score {
									color: ${COLORS.GREEN};
								}
							}
							&.incorrect {
								font-size: 17px;
								.score {
									color: ${COLORS.DANGER};
								}
							}
						}
					}
					.not-attempted {
						margin-top: 30px;
						font-size: 16px;
						color: black;
					}
					.action-container {
						margin: 18px 0 35px;
						color: black;

						.round-two {
							font-size: 30px;
							cursor: pointer;
							color: #7e4e16;
							font-weight: bold;
						}
						.round-two-text {
							font-size: 18px;
							margin: 0px 10px;
						}
						.text {
							cursor: pointer;
							transition: 0.3s;
							font-size: 20px;
							margin-top: 10px;
							&.try-again {
								color: #005ca8;
								&:hover {
									color: black;
								}
							}

							&.all-done {
								/* color: ${COLORS.GREEN}; */
							}

							&.dash {
								opacity: 0.7;
								margin-top: 5px;
								font-size: 25px;
								font-weight: bold;
								color: green;
								&:hover {
									opacity: 0.9;
								}
							}
						}
					}
				}
				.progress {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 100px;
				}
				.option-container {
					border-bottom: 1px solid #fff;
					border-left: 1px solid #fff;
					border-right: 1px solid #fff;
					border-bottom-left-radius: 20px;
					border-bottom-right-radius: 20px;
					.checkbox-grid-container {
						display: grid;
						grid-template-columns: auto auto;
						grid-gap: 5px;
						margin: 5px;

						.checkbox {
							background: linear-gradient(to top, #7e4e16, #813e10, #7e4e16);
							box-shadow: inset 0 0 9.8px 0.2px #000, inset 0px 3px 0 0 rgb(255 255 255 / 41%);
							color: #fff;
							font-size: 30px;
							padding: 15px 10px;
							cursor: pointer;
							border: 1px solid #7e4e16;
						}
						.left-box {
							border-bottom-left-radius: 10px;
						}
						.right-box {
							border-bottom-right-radius: 10px;
						}
						.selected {
							background: linear-gradient(to top, #275e00, #275e00, #275e00);
							border: 1px solid #275e00;
						}
					}
				}
			}
			.button-outer-container {
				display: flex;
				.btn-container {
					/* background: linear-gradient(to top, #ffe6ed, #875262, #fddbdb); */
					border-radius: 50px;
					margin: 30px auto 0px;
					padding: 4px;
					animation: rounded 20s linear infinite;
					transition: opacity 0.3s ease-in-out;
					.done-btn {
						&.enable {
							cursor: pointer;
							filter: blur(0px);
						}
						background: linear-gradient(to top, #a0b016, #a0b016, #a0b016);
						border-radius: 35px;
						background-size: 400%;
						box-shadow: inset 0 0 9.8px 0.2px #000, inset 0px 3px 0 0 rgba(255, 255, 255, 0.41);
						font-size: 28px;
						padding: 5px 40px;
						transition: opacity 0.3s ease-in-out;
						width: auto;
						z-index: 1;
						border: 1px solid #000;
						color: #fff;
						filter: blur(2px);
					}
					.skip-btn {
						cursor: pointer;
						background: linear-gradient(to top, #a0b016, #a0b016, #a0b016);
						border-radius: 35px;
						background-size: 400%;
						box-shadow: inset 0 0 9.8px 0.2px #000, inset 0px 3px 0 0 rgba(255, 255, 255, 0.41);
						font-size: 28px;
						padding: 5px 40px;
						transition: opacity 0.3s ease-in-out;
						width: auto;
						z-index: 1;
						border: 1px solid #000;
						color: #fff;
					}
					@keyframes rounded {
						0% {
							background-position: 0 0;
						}
						50% {
							background-position: 400% 0;
						}
						100% {
							background-position: 0 0;
						}
					}
				}
			}
			.green {
				background: linear-gradient(45deg, #4caf50, rgb(255, 255, 255), #4caf50, rgb(255, 255, 255)) 0% 0% / 400%;
				box-shadow: 0px 0px 11px 4px #378b06;
			}
			.red {
				background: linear-gradient(45deg, rgb(255, 0, 0), rgb(255, 255, 255), rgb(255, 0, 0), rgb(255, 255, 255)) 0% 0% / 400%;
				box-shadow: 0px 0px 11px 4px #ff000094;
				cursor: not-allowed;
			}
		}
	}
`;

export const PursuitDialogWrapper = styled(Dialog)`
	.backdrop {
		background-color: rgba(0, 0, 0, 0.9) !important;
	}
	.dialog-paper {
		background: transparent;
		color: #000;
		overflow: visible;
	}
	.paper {
		max-width: 900px;
	}

	.box-modal {
		width: 880px;
		height: 620px;
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.ECONOMICS.PURSUIT.HOW_TO_PLAY});

		${responsive.DISPLAY`
			width: 680px;
    		height: 476px;
		`}

		${responsive.DESKTOP`
			width: 630px;
    		height: 446px;
		`}

		.title-container {
			.sub-title {
				color: #fff;
				font-size: 26px;
				margin-top: 15px;
				margin-left: -10px;

				${responsive.DISPLAY`
					font-size: 22px;
				`}

				${responsive.DESKTOP`
					font-size: 18px;
				`}
			}
		}

		.content-wrapper {
			height: 405px;
			margin-top: 40px;
			position: relative;
			padding: 15px 60px;

			${responsive.DISPLAY`
				height: 296px;
				font-size: 14px;
			`}

			${responsive.DESKTOP`
				height: 270px;
				font-size: 12px;
			
			`}

			.scroll-bar {
				.thumb-vertical {
					background-color: #4c2b25;
				}
			}

			.content {
				width: 90%;
				color: #fff;
				margin: 0px auto;
			}

			.attach-file {
				display: flex;
				justify-content: center;

				.icon {
					color: #fff;
				}

				.file {
					margin: 0px 10px;
					font-size: 18px;
					cursor: pointer;
					text-decoration: underline;
					color: #fff;
				}

				.pdf-loader {
					width: 20px !important;
					height: 20px !important;
					color: #fff;
					margin-top: 1px;
				}
			}

			.progress {
				position: absolute;
				top: 36%;
				left: 0;
				right: 0;
				color: #000;
			}

			.type-switcher {
				.divider {
					background-color: #fff;
					height: 2px;
					width: 50%;
					margin: 5px auto;
					opacity: 0.5;
				}

				.text {
					color: #fff;
					cursor: pointer;
				}
			}
		}
		.play {
			margin-top: 70px;
			color: #fff;
			font-size: 26px;
			cursor: pointer;

			${responsive.DISPLAY`
			margin-top: 58px;
			font-size: 20px;

			`}

			${responsive.DESKTOP`
			font-size: 18px;
			`}
		

		&.how-to-play {
			}

			&.lets-play {
				margin-top: 40px;

				${responsive.DISPLAY`
				margin-top: 26px;
			`}

				${responsive.DESKTOP`
				margin-top: 30px;
			`}
			}
		}
	}
`;

//CORE
import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress, Divider } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Scrollbars from "react-custom-scrollbars";

//ICONS
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

//CUSTOM
import { HowToPlayModalWrapper } from "./Modal.style";
import { Api, CODES, URL_FOREIGN_LANGUAGE_DASHBOARD } from "Helpers";
import { ForeignLangQuizBtn } from "Components/Commons";

const MODAL_TYPE = { HOW_TO_PLAY: "howToPlay", DID_YOU_KNOW: "didYouKnow" };

export const HowToPlayModal = (props) => {
	// const dispatch = useDispatch();
	const navigate = useNavigate();
	const profileInfo = useSelector((state) => state.Auth.profileInfo);

	const [type, setType] = useState(MODAL_TYPE.DID_YOU_KNOW);
	const [isLoading, setLoading] = useState(false);
	const [pdfLoader, setPdfLoader] = useState(false);
	const [content, setContent] = useState("");

	const handleClose = () => props.handleClose({ url: props.data.url });

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			let data = new FormData();
			data.append("moduleId", props.data.id);
			data.append("subjectId", props.subjectId);

			const response = await new Api().post("staticPage/game", { data });

			if (response.status === CODES.SUCCESS) setContent(response.data);

			setLoading(false);
		} catch (error) {
			console.log("🚀 ~ file: HowToPlayModal.jsx:30 ~ fetchData ~ error:", error);
			setLoading(false);
		}
	}, [props.data.id, props.subjectId]);

	const proceed = () => {
		if (type === MODAL_TYPE.DID_YOU_KNOW) setType(MODAL_TYPE.HOW_TO_PLAY);
		else {
			//dispatch(quizSound(true)) //add game sound if you want to
			closeModal();
			return;
		}
	};

	const closeModal = () => {
		if (props.data.url) {
			const dateTime = moment().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
			if (props?.schoolId && props.schoolId.length) {
				const data = {
					type: "LOGIN",
					studentId: profileInfo?.id,
					schoolClassTestId: props.schoolId,
					moduleName: "RAPIDFIREFOREIGNLANGUAGE",
					dateTime: dateTime,
				};
				new Api().post("student/clock-in-clock-out", { data });
			}
			handleClose();
			navigate(URL_FOREIGN_LANGUAGE_DASHBOARD + "/" + props.data.url, {
				state: { schoolClassTestId: props?.schoolId },
			});
		} else {
			handleClose();
		}
	};

	const handlePdf = () => {
		setPdfLoader(true);

		let pdfUrl,
			attachedText = "",
			moduleName = props?.data?.id.replace(/ /g, "_").toUpperCase();

		if (type === MODAL_TYPE.DID_YOU_KNOW) {
			pdfUrl = content?.didYouKnowFile;
			attachedText = `${moduleName}_DID_YOU_KNOW`;
		} else {
			pdfUrl = content?.howToPlayFile;
			attachedText = `${moduleName}_HOW_TO_PLAY`;
		}

		fetch(pdfUrl).then((response) => {
			response.blob().then((blob) => {
				// Creating new object of PDF file
				const fileURL = window.URL.createObjectURL(blob);
				// Setting various property values
				let alink = document.createElement("a");
				alink.href = fileURL;
				alink.download = `${attachedText}.pdf`;
				alink.click();

				setPdfLoader(false);
			});
		});
	};

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<HowToPlayModalWrapper
			open
			onClose={handleClose}
			classes={{ root: "dialog-root", paper: "dialog-paper", paperWidthSm: "dialog-paper-width-sm" }}
			BackdropProps={{ classes: { root: "backdrop" } }}
			modalType={type}
		>
			<div className="box-modal">
				<div className="content-wrapper flex f-v-center f-column">
					{isLoading ? (
						<div className="progress flex f-v-center f-column f-h-center">
							<CircularProgress className="progress-bar" />
							<span className="wait-text">Please Wait</span>
						</div>
					) : (
						<>
							<Scrollbars className="scrollbar" style={{ width: "80%" }}>
								<div className="content">
									<p dangerouslySetInnerHTML={{ __html: content[type] }} className="text"></p>
									{((content?.didYouKnowFile && type === MODAL_TYPE.DID_YOU_KNOW) || (content?.howToPlayFile && type === MODAL_TYPE.HOW_TO_PLAY)) && (
										<div className="attach-file">
											<PictureAsPdfIcon className="icon" />
											{pdfLoader ? (
												<div>
													<CircularProgress className="pdf-loader" />
												</div>
											) : (
												<div className="file" onClick={handlePdf}>
													Attachment
												</div>
											)}
										</div>
									)}
								</div>
							</Scrollbars>
							<div className={`type-switcher flex f-v-center f-h-center f-column ${type === MODAL_TYPE.DID_YOU_KNOW ? "visibility-none" : ""}`}>
								<Divider className="divider" />
								{type === MODAL_TYPE.HOW_TO_PLAY && (
									<p className="text" onClick={() => setType(MODAL_TYPE.DID_YOU_KNOW)}>
										Back to Review
									</p>
								)}
							</div>
							<ForeignLangQuizBtn
								className="submit-btn"
								clickHandler={() => proceed()}
								btnText={type === MODAL_TYPE.DID_YOU_KNOW ? "HOW TO PLAY" : "LET'S PLAY"}
								width={"200px"}
								height={"65px"}
								fontSize={"21px"}
							/>
						</>
					)}
				</div>
			</div>
		</HowToPlayModalWrapper>
	);
};

export default HowToPlayModal;

import { ACTION_TYPES } from "./Actions";

const initState = {
	isLoggedIn: localStorage.getItem("isLoggedIn") ? localStorage.getItem("isLoggedIn") === "true" : false,
	accessToken: localStorage.getItem("accessToken") || "",
	role: localStorage.getItem("role") || "",
	profileInfo: localStorage.getItem("profileInfo") ? JSON.parse(localStorage.getItem("profileInfo")) : {},
	subjectAccessList: localStorage.getItem("subjectAccessList") ? JSON.parse(localStorage.getItem("subjectAccessList")) : {},
	selectedSubjectDataValue: localStorage.getItem("selectedSubjectDataValue") ? JSON.parse(localStorage.getItem("selectedSubjectDataValue")) : {},
	totalScore: 0,
	gameScore: 0,
	isTimerCompletePursuit: localStorage.getItem("isTimerCompletePursuit") ? localStorage.getItem("isTimerCompletePursuit") : false,
	selectedGenderPursuit: localStorage.getItem("selectedGenderPursuit") ? localStorage.getItem("selectedGenderPursuit") : {},
	schoolClassTestID: localStorage.getItem("schoolClassTestID") ? localStorage.getItem("schoolClassTestID") : "",
	showTopBar: true,
};

const Reducer = (state = initState, action) => {
	switch (action.type) {
		case ACTION_TYPES.LOGIN_USER:
			return {
				...state,
				isLoggedIn: true,
				accessToken: action.accessToken,
			};

		case ACTION_TYPES.LOGOUT_USER:
			return {
				...state,
				isLoggedIn: false,
				selectedSubjectDataValue: {},
			};

		case ACTION_TYPES.UPDATE_PROFILE:
			return {
				...state,
				profileInfo: action.profileInfo,
			};

		case ACTION_TYPES.UPDATE_TOTAL_SCORE:
			return {
				...state,
				totalScore: action.score,
			};

		case ACTION_TYPES.UPDATE_GAME_SCORE:
			return {
				...state,
				gameScore: action.score,
			};

		case ACTION_TYPES.SUBJECT_ACCESS_LIST:
			return {
				...state,
				subjectAccessList: action.subjectAccessList,
			};

		case ACTION_TYPES.SELECTED_SUBJECT_DATA_VALUE:
			return {
				...state,
				selectedSubjectDataValue: action.selectedSubjectDataValue,
			};

		case ACTION_TYPES.IS_TIMER_COMPLETE_PURSUIT:
			return {
				...state,
				isTimerCompletePursuit: false,
			};

		case ACTION_TYPES.SELECTED_GENDER_PURSUIT:
			return {
				...state,
				selectedGenderPursuit: action.selectedGenderPursuit,
			};

		case ACTION_TYPES.SCHOOL_CLASS_ID:
			return {
				...state,
				schoolClassTestID: action.schoolClassTestID,
			};

		case ACTION_TYPES.SHOW_TOP_BAR:
			return {
				...state,
				showTopBar: action.showTopBar,
			};

		default:
			return state;
	}
};

export default Reducer;

import styled from "styled-components";
import { Dialog, Box } from "@material-ui/core";
import { responsive } from "Styles/Constants";

export const DialogWrapper = styled(Dialog)`
	position: relative;
	.paper {
		margin: 10px;
	}
	.paper-width {
		max-width: 600px;
		width: 600px;
		height: 700px;

		${responsive.LAPTOP`
			width: 500px;
			height: 600px;
		`}
	}

	.close-button-image {
		width: 30px;
		height: 30px;
		position: absolute;
		right: 0;
		cursor: pointer;
	}

	.container {
		width: 100%;
		height: 100%;
	}

	.slider {
		width: 600px;
		height: 700px;

		${responsive.LAPTOP`
			width: 500px;
			height: 600px;
		`}
	}

	.background-image {
		width: 100%;
		height: 100%;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}

	.slick-dots {
		bottom: 20px;
	}

	.slick-list {
		height: 700px;

		${responsive.LAPTOP`
			height: 600px;
		`}
	}

	.slick-dots li {
		margin: 0;
	}

	.slick-dots li button:before {
		opacity: 0.5;
		color: #ffffff;
		font-size: 8px;
	}

	.slick-dots li.slick-active button:before {
		opacity: 1;
		color: #ffffff;
		font-size: 10px;
	}
`;

export const TitleBox = styled(Box)`
	.subject-text {
		position: absolute;
		color: ${(props) => (props?.textColor ? props.textColor : "#ffffff")};
		bottom: 30%;
		font-size: 18px;
		font-weight: bold;
		max-width: 500px;
		text-align: center;

		${responsive.LAPTOP`
			bottom: 20%;
			max-width: 450px;
		`}
	}

	.subject-description {
		position: absolute;
		color: #ffffff;
		bottom: 40px;
		font-size: 18px;
		font-weight: bold;
		max-width: 500px;
		text-align: center;

		${responsive.LAPTOP`
			max-width: 450px;
		`}
	}
`;

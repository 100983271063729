export const Messages = {
	validation: {
		email: {
			valid: "Ex user@mail.com",
			required: "Please enter your email address.",
		},

		firstName: {
			required: "Please enter your first name.",
		},

		lastName: {
			required: "Please enter your last name.",
		},

		phoneNumber: {
			required: "Please enter your phone number.",
			number: "Must be numbers only",
			positive: "Should be in Positive",
			integer: "Type without . (dots)",
		},

		age: {
			required: "Please enter your age.",
			number: "Must be numbers only",
			positive: "Looks like you are ageing in reverse",
		},

		ageRange: {
			required: "Please enter your age.",
		},

		school: {
			required: "Please enter your School.",
		},

		password: {
			required: "Please enter your password.",
			min: "Password must be atleast ${min} length", // eslint-disable-line
		},

		currentPassword: {
			required: "Please enter your password",
			min: "Password must be atleast ${min} length", // eslint-disable-line
		},

		confirmPassword: {
			required: "Please re-enter your password",
			equals: "Password and Confirm password must be same",
		},

		name: {
			required: "Please enter your name.",
		},

		message: {
			required: "Please enter your message.",
		},
	},
};

import styled, { keyframes } from "styled-components";
import { Dialog } from "@material-ui/core";
// CONSTANTS
import { responsive } from "Styles/Constants";
import { IMAGES } from "Helpers";

const INDICATOR_BORDER_WIDTH = "2px";

const borderAnimation = keyframes`
    0% { opacity : 1 }
    50% { opacity : 0.5 }
    100% { opacity : 1 }
`;

const indicatorAnimation = keyframes`
    0% { width: calc(100% + 2px); height: calc(100% + 2px); visibility : visible; }
    75% { width: 0%; height: calc(100% + 2px); visibility : visible; }
    99.9% { width: 0%; height: 0%; visibility : visible; }
    100% { width: 0%; height: 0%; visibility : hidden; }
`;

export const GamePlayWrapper = styled.div`
	width: 100%;
	color: #fff;
	display: flex;
	min-height: 100vh;
	position: relative;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background-size: 100% 100%;
	background-attachment: fixed;

	.background-image {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: -1;
		overflow: hidden;
	}

	.board-container {
		width: 1200px;
		height: 750px;
		display: flex;
		position: relative;
		align-items: center;
		flex-direction: column;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.FOREIGN.RAPID_FIRE.QUESTION_MODAL});

		${responsive.DISPLAY`
			width: 1000px;
    		height: 625px;
		`}

		${responsive.DESKTOP`
			width: 860px;
    		height: 544px;
		`}

		${responsive.LAPTOP`
			width: 700px;
			height: 500px;
		`}

		.progress {
			width: 100%;
			height: 100%;
			display: flex;
			margin-top: 40px;
			position: absolute;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			.progress-bar {
				color: #fff;
			}

			.wait-text {
				color: #fff;
				margin-top: 10px;
			}
		}

		.timer {
			color: black;
			font-size: 28px;
			margin-top: 30px;
			position: relative;

			${responsive.DISPLAY`
				font-size: 20px;
				margin-top: 22px;
			`}

			${responsive.DESKTOP`
				font-size: 18px;
				margin-top: 23px;
			`}

			${responsive.LAPTOP`
				margin-top: 48px;
			`}
		}

		.initial-load-container {
			color: black;
			display: flex;
			margin-top: 330px;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			${responsive.DISPLAY`
				margin-top: 270px;
			`}

			${responsive.DESKTOP`
				margin-top: 255px;
			`}

			.text {
				margin: 0px;
				font-size: 24px;
				text-align: center;
				margin-bottom: 10px;

				${responsive.DESKTOP`
					font-size: 18px;
					margin-bottom: 5px;
				`}
			}

			.sub-text {
				margin: 0px;
				font-size: 18px;
				text-align: center;

				${responsive.DESKTOP`
					font-size: 16px;
				`}
			}
		}

		.image {
			height: 130px;
			margin-top: 95px;
			object-fit: contain;

			${responsive.DISPLAY`
				height: 125px;
    			margin-top: 55px;
			`}

			${responsive.DESKTOP`
				height: 85px;
    			margin-top: 60px;
			`}
		}

		.question {
			width: 65%;
			color: #000;
			font-size: 30px;
			margin-top: 30px;
			font-weight: bold;
			text-align: center;

			${responsive.DISPLAY`
				font-size: 24px;
    			margin-top: 20px;
			`}

			${responsive.DESKTOP`
				font-size: 18px;
    			margin-top: 20px;
			`}
		}

		.option-container {
			gap: 40px;
			width: 75%;
			display: flex;
			flex-wrap: wrap;
			margin-top: 35px;
			padding: 10px 70px;
			margin-bottom: 20px;
			align-items: center;
			justify-content: space-between;

			${responsive.DISPLAY`
				gap: 20px;
				width: 82%;
				margin-top: 20px;
				margin-bottom: 10px;
			`}

			${responsive.DESKTOP`
				gap: 20px;
				width: 85%;
				margin-top: 12px;
				margin-bottom: 10px;
			`}

			.option {
				z-index: 0;
				padding: 4px;
				cursor: pointer;
				min-height: 50px;
				position: relative;
				border-radius: 25px;
				transition: all 0.3s;
				width: calc(50% - 20px);
				border: 3px solid black;

				${responsive.DISPLAY`
					min-height: 45px;
					width: calc(50% - 10px);
				`}

				${responsive.DESKTOP`
					min-height: 40px;
					width: calc(50% - 10px);
				`}

				&.green {
					border: 3px solid #378b06;
					box-shadow: 0px 0px 11px 4px #378b06;
				}

				&.red {
					border: 3px solid red;
					box-shadow: 0px 0px 11px 4px #ff000094;
				}

				&.blinking {
					animation-duration: 2s;
					animation-name: ${borderAnimation};
					animation-iteration-count: infinite;
					animation-timing-function: ease-in-out;
				}

				&.disabled {
					cursor: default;
				}

				.option-inner {
					color: black;
					display: flex;
					padding: 2px 5px;
					text-align: center;
					align-items: center;
					border-radius: 18px;
					justify-content: center;
					border: 3px solid black;

					&.green {
						border: 3px solid #378b06;
						box-shadow: 0px 0px 11px 4px #378b06;
					}

					&.red {
						border: 3px solid red;
						box-shadow: 0px 0px 11px 4px #ff000094;
					}
				}

				.option-text {
					width: 80%;
					font-size: 24px;

					${responsive.DISPLAY`
						font-size: 20px;
					`}

					${responsive.DESKTOP`
						font-size: 18px;
					`}
				}
			}
		}
	}

	.confetti {
		width: 100%;
		height: 100%;
	}

	.wrapped-up-container {
		width: 890px;
		height: 755px;
		display: flex;
		position: relative;
		align-items: center;
		flex-direction: column;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.FOREIGN.RAPID_FIRE.CONGRATULATIONS_BACKGROUND});

		&.lose {
			background-image: url(${IMAGES.FOREIGN.RAPID_FIRE.BETTER_LUCK_BACKGROUND});
		}

		${responsive.DISPLAY`
			width: 800px;
			height: 670px;
		`}

		${responsive.DESKTOP`
			width: 570px;
    		height: 490px;
		`}

		.title {
			color: black;
			font-size: 40px;
			margin-top: 170px;
			font-weight: bold;

			${responsive.DISPLAY`
				font-size: 34px;
    			margin-top: 175px;
			`}

			${responsive.DESKTOP`
				font-size: 24px;
				margin-top: 105px;
			`}
		}

		.score-container {
			gap: 50px;
			display: flex;
			margin-top: 70px;
			flex-wrap: nowrap;
			align-items: center;

			${responsive.DISPLAY`
				gap: 40px;
				margin-top: 55px;
			`}

			${responsive.DESKTOP`
				gap: 30px;
				margin-top: 30px;
			`}

			.score {
				width: 120px;
				height: 120px;
				display: flex;
				align-items: center;
				border-radius: 15px;
				flex-direction: column;
				background-color: #fff;
				justify-content: center;
				border: solid 2px #14353e;
				box-shadow: 0px 1px 6px 0 rgba(0, 0, 0, 0.85), inset -2.3px -3.3px 1px 0 rgba(133, 14, 14, 0.4);

				${responsive.DISPLAY`
					width: 105px;
    				height: 105px;
				`}

				${responsive.DESKTOP`
					width: 80px;
					height: 80px;
				`}

				.score-text {
					font-size: 40px;

					${responsive.DISPLAY`
						font-size: 34px;
					`}

					${responsive.DESKTOP`
						font-size: 22px;
					`}

					&.correct {
						color: #06c91d;
					}

					&.incorrect {
						color: #ff0e02;
					}
				}

				.status-text {
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0.64px;
					color: rgba(0, 0, 0, 0.5);

					${responsive.DISPLAY`
						font-size: 10px;
					`}

					${responsive.DESKTOP`
						font-size: 7px;
					`}
				}
			}

			.vs-text {
				font-size: 22px;

				${responsive.DISPLAY`
					font-size: 20px;
				`}

				${responsive.DESKTOP`
					font-size: 14px;
				`}
			}
		}

		.attempt-container {
			width: 380px;
			height: 122px;
			display: flex;
			margin-top: 65px;
			margin-bottom: 25px;
			align-items: center;
			border-radius: 15px;
			flex-direction: column;
			background-color: #fff;
			justify-content: center;
			border: solid 2px #14353e;
			box-shadow: 0px 1px 6px 0 rgba(0, 0, 0, 0.85), inset -2.3px -3.3px 1px 0 rgba(133, 14, 14, 0.4);

			${responsive.DISPLAY`
				width: 315px;
				height: 100px;
				font-size: 16px;
				margin-bottom: 10px;
			`}

			${responsive.DESKTOP`
				width: 240px;
				height: 80px;
				font-size: 12px;
				margin-bottom: 18px;
			`}

			.attempt-text {
				color: #ff0600;
			}

			.wrapped-up-text {
				color: black;
			}
		}

		.home-btn-image {
			left: 105px;
			width: 60px;
			bottom: 60px;
			cursor: pointer;
			position: absolute;

			${responsive.DISPLAY`
    			left: 102.5px;
				width: 50px;
				bottom: 62.5px;
			`}

			${responsive.DESKTOP`
				left: 70px;
				width: 40px;
				bottom: 40px;
			`}
		}

		.replay-btn-image {
			width: 220px;
			cursor: pointer;
			margin-top: 50px;
			object-fit: contain;
			margin-bottom: -22px;

			${responsive.DISPLAY`
				width: 195px;
				margin-top: 40px;
			`}

			${responsive.DESKTOP`
				width: 145px;
    			margin-top: 22px;
			`}
		}
	}
`;

export const DialogWrapper = styled(Dialog)`
	.background-root {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.paper {
		background: transparent;
		color: black;
		overflow: visible;
	}

	.paper-width {
		max-width: 900px;
	}

	.box-modal {
		width: 950px;
		height: 620px;
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.FOREIGN.HOW_TO_PLAY});

		${responsive.DISPLAY`
            width: 830px;
            height: 540px;
		`}

		${responsive.DESKTOP`
			width: 730px;
            height: 490px;
		`}

		.title-container {
			.sub-title {
				color: #000;
				font-size: 26px;
				margin-top: 24px;
				font-weight: bold;

				${responsive.DISPLAY`
					font-size: 24px;
                    margin-top: 20px;
				`}

				${responsive.DESKTOP`
					font-size: 24px;
                    margin-top: 18px;
				`}
			}
		}

		.content-wrapper {
			height: 385px;
			padding: 0px 100px;
			position: relative;

			${responsive.DISPLAY`
                height: 330px;
				font-size: 14px;
			`}

			${responsive.DESKTOP`
                height: 290px;
				font-size: 14px;
			`}

			.scroll-bar {
				.thumb-vertical {
					background-color: #96624c;
				}
			}

			.content {
				width: 90%;
				color: #000;
				margin: 2% auto 0px;
			}

			.attach-file {
				display: flex;
				justify-content: center;

				.icon {
					color: #fff;
				}

				.file {
					margin: 0px 10px;
					font-size: 18px;
					cursor: pointer;
					text-decoration: underline;
					color: #fff;
				}

				.pdf-loader {
					width: 20px !important;
					height: 20px !important;
					color: #fff;
					margin-top: 1px;
				}
			}

			.progress {
				position: absolute;
				top: 36%;
				left: 0;
				right: 0;
				color: #000;
			}

			.type-switcher {
				.divider {
					background-color: #000;
					height: 2px;
					width: 50%;
					margin: 5px auto;
					opacity: 0.5;
				}

				.text {
					color: #000;
					cursor: pointer;
					transition: 0.3s;
					margin: 0;
				}
			}
		}

		.play-btn-container {
			display: flex;
			margin-top: 72px;
			align-items: center;
			justify-content: center;

			${responsive.DISPLAY`
                margin-top: 65px;
            `}

			${responsive.DESKTOP`
                margin-top: 55px;
            `}

			.play-btn {
				color: #000;
				height: 68px;
				display: flex;
				font-size: 22px;
				cursor: pointer;
				font-weight: bold;
				align-items: center;
				justify-content: center;

				${responsive.DISPLAY`
                    height: 60px;
                    font-size: 20px;
                `}

				${responsive.DESKTOP`
                    height: 50px;
                    font-size: 18px;
                `}
			}
		}
	}
`;

export const Indicators = styled.div`
	width: 282px;
	height: 44px;
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	border: ${INDICATOR_BORDER_WIDTH} solid black;

	${responsive.DISPLAY`
		width: 213px;
    	height: 40px;
	`}

	${responsive.DESKTOP`
		width: 182px;
    	height: 34px;
	`}

	${responsive.LAPTOP`
		width: 150px;
		height: 26px;
	`}

	&.animated {
		&:before,
		&:after {
			content: "";
			transition: 0s;
			position: absolute;
			visibility: visible;
			width: calc(100% + 2px);
			height: calc(100% + 2px);
		}

		&:before {
			right: -${INDICATOR_BORDER_WIDTH};
			bottom: -${INDICATOR_BORDER_WIDTH};
			border-top: ${INDICATOR_BORDER_WIDTH} solid white;
			border-right: ${INDICATOR_BORDER_WIDTH} solid white;
		}

		&:after {
			top: -${INDICATOR_BORDER_WIDTH};
			left: -${INDICATOR_BORDER_WIDTH};
			border-left: ${INDICATOR_BORDER_WIDTH} solid white;
			border-bottom: ${INDICATOR_BORDER_WIDTH} solid white;
		}

		&.running {
			&:before,
			&:after {
				animation-duration: 7.5s;
				animation-fill-mode: forwards;
				animation-timing-function: linear;
				animation-name: ${indicatorAnimation};
			}
			&:after {
				animation-delay: 7.5s;
			}
		}
	}
`;

import styled from "styled-components";
import { FONTS, responsive } from "Styles/Constants";
import { IMAGES } from "Helpers";

export const DashboardWrapper = styled.div`
	min-height: 100vh;
	clip-path: ${(props) => (props.loading ? "inset(0)" : " ")};
	transition: ${(props) => (props.loading ? "" : "filter 0.5s linear")};

	.background-image {
		width: 100%;
		z-index: -1;
		height: 100%;
		overflow: hidden;
		position: absolute;
	}

	.audio {
		display: none;
	}

	.header-container {
		background-color: ${(props) => (props?.bgImg?.backgroundImg ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.9)")};
		padding: 1%;
		.inner-header {
			display: flex;
			width: 90%;
			margin: 0px auto;
			justify-content: space-between;
			align-items: center;

			.logo-img {
				background-image: ${(props) => (props?.bgImg?.backgroundImg ? `url(${IMAGES.DASHBOARD.SUBJECT_MASTER_LOGO_WHITE})` : `url(${IMAGES.DASHBOARD.SUBJECT_MASTER_LOGO_BLACK})`)};
				width: 110px;
				height: 40px;
				background-repeat: no-repeat;
				background-size: contain;
			}

			.profile-container {
				display: flex;
				align-items: center;
				.profile-picture {
					background-image: ${(props) => props?.profileInfo && props.profileInfo?.profilePicUrl && `url(${props.profileInfo.profilePicUrl})`};
					width: 40px;
					height: 40px;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					border-radius: 50px;
					margin-left: 10px;
				}
				.text {
					color: ${(props) => (props?.bgImg?.backgroundImg ? `#fff` : `#000`)};
					font-size: 20px;
					width: 200px;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					text-align: end;
				}
				.arrow {
					background-image: ${(props) => (props?.bgImg?.backgroundImg ? `url(${IMAGES.COMMONS.WHITE_ARROW})` : `url(${IMAGES.COMMONS.BLACK_ARROW})`)};
					width: 15px;
					height: 15px;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					rotate: 90deg;
					margin-left: 10px;
					cursor: pointer;
				}
			}
			.logout-btn {
				color: white;
				cursor: pointer;
				height: 25px;
				padding: 3px 15px;
			}
		}
	}

	.text-container {
		width: 80%;
		/* margin: 5% auto 0px; */
		text-align: center;

		.header-text {
			font-family: ${FONTS.TERTIARY_BOLD};
			font-size: 32px;
			letter-spacing: 2px;
		}
		.text {
			font-family: ${FONTS.TERTIARY_SEMI_BOLD};
			margin-top: 15px;
			letter-spacing: 2px;
			font-size: 16px;
		}
	}

	.progress {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 350px;
		width: 100%;
	}

	.subject-text-container {
		width: 90%;
		margin: 1% auto 0px;

		.text-logo-container {
			display: flex;
			align-items: center;
			.text-cont {
				width: 60%;
				.header-text {
					font-family: ${FONTS.TERTIARY_BOLD};
					font-size: 34px;
					letter-spacing: 2px;
					color: #fff;
					word-spacing: 4px;
					text-align: center;
					/* ${responsive.LAPTOP`
                      font-size: 28px;
                    //   margin-top: 4%;
                    `} */
				}
				.name {
					text-align: center;
					width: ${(props) => (props.bgImg && props.bgImg.id === 1 ? `80%` : `100%`)};
					margin-top: 5px;
					font-size: 28px;
				}
				.text {
					color: #fff;
					font-family: ${FONTS.TERTIARY_SEMI_BOLD};
					margin-top: 15px;
					letter-spacing: 2px;
					font-size: 16px;
				}
			}

			.play {
				color: #fff;
				font-family: ${FONTS.TERTIARY_SEMI_BOLD};
				padding: 10px 20px;
				border-radius: 25px;
				width: 136px;
				text-align: center;
				background-color: black;
				margin-top: 5%;
				cursor: pointer;
			}
			.side-logo {
				width: 380px;
				height: 340px;
				background-size: contain;
				background-repeat: no-repeat;
				margin-left: 5%;
				${responsive.DESKTOP`
                     width: 350px;
                     height:325px;
                `}
				${responsive.LAPTOP`
                     width: 345px;
                     height:310px;
                `}
                ${responsive.TABLET`
                     width: 345px;
                     height:310px;
                `}
			}
		}
	}

	.progress-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 200px;
	}

	.welcome-container {
		margin: 50px auto;

		${responsive.LAPTOP`
            margin: 25px auto;
        `}

		.welcome-image {
			width: 600px;
			height: 200px;

			${responsive.LAPTOP`
				width: 450px;
            	height: 150px;
        	`}
		}
	}

	.subject-container {
		width: 95%;
		margin: 2% auto 0px;
		padding-bottom: 20px;
		.prev-arrow {
			background-image: url(${IMAGES.DASHBOARD.LEFT_SIDE_ARROW});
			background-repeat: no-repeat;
			background-size: contain;
			width: 30px;
			height: 30px;
			position: absolute;
			bottom: 50%;
			left: -20px;
			${responsive.TABLET`
                left:-10px
            `}
		}
		.next-arrow {
			background-image: url(${IMAGES.DASHBOARD.RIGHT_SIDE_ARROW});
			position: absolute;
			background-repeat: no-repeat;
			background-size: contain;
			width: 30px;
			height: 30px;
			bottom: 50%;
			right: -20px;
			${responsive.TABLET`
                     right: -10px;
                    `}
		}

		.subject-img {
			width: 190px;
			height: 268px;

			cursor: pointer;
			background-repeat: no-repeat;
			background-size: contain;
			margin: 16px auto 0px;

			transition: 0.3s;

			&:hover {
				transition: all 0.7s ease-in-out;
			}
		}

		.selected-subject {
			border: 3px solid red;
			border-radius: 40px;
			height: 300px;
			width: 217px;
			margin: 0px auto;
		}
	}
`;

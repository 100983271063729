import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// COMPS
import { InputWithLabel } from "Components/Commons";

// FORM VALIDATION
import { Formik } from "formik";

// STYLES
import { FormWrapper, ForgotPasswordWrapper } from "./ForgotPassword.style";

// CONST
import { Api, generateValidationSchema, URL_LOGIN, CODES, URL_GLOBAL_LANDING_PAGE, URL_ABOUT_US, URL_PRIVACY_POLICY, URL_TERMS_AND_CONDITIONS } from "Helpers";

import { showToast } from "Redux/General/Actions";
import { Button, CircularProgress, Typography } from "@material-ui/core";

const initialValues = { email: "" };

function ForgotPassword() {
	const [isLoading, setIsLoading] = useState(false);

	// REDUX
	const dispatch = useDispatch();

	// ROUTER
	// let history = useHistory();
	const navigate = useNavigate();

	function sendEmail(values) {
		setIsLoading(true);
		let data = new FormData();
		data.append("email", values.email);
		new Api()
			.post("user/forgotPassword", { data, returnError: true })
			.then((response) => {
				setIsLoading(false);
				dispatch(showToast("Email Sent !! \n Please check your email"));
				navigate(URL_LOGIN);
			})
			.catch((error) => {
				if (error.response.status === CODES.UNAUTHORIZED) dispatch(showToast(error.response.data.message));

				setIsLoading(false);
			});
	}

	return (
		<ForgotPasswordWrapper>
			<div className="header-container">
				<div className="top-bar">
					<div
						className="subject-logo"
						onClick={() => {
							navigate(URL_GLOBAL_LANDING_PAGE);
						}}
					></div>
					<div className="btn-container">
						<div
							className="teacher-btn"
							onClick={() => {
								navigate(URL_GLOBAL_LANDING_PAGE);
							}}
						>
							HOME
						</div>
						<div
							className="login"
							onClick={() => {
								navigate(URL_LOGIN);
							}}
						>
							LOGIN
						</div>
					</div>
				</div>
			</div>

			<div className="main-container">
				<div className="modal">
					{/* <DesignedBox> */}
					<div className="box">
						<div className="title">
							<h1 className="text">Forgot Password</h1>
						</div>

						<Formik initialValues={initialValues} onSubmit={sendEmail} validationSchema={validationSchema}>
							{({ values, touched, errors, handleChange }) => (
								<FormWrapper>
									<div className="input-wrapper hello">
										{inputs.map((input) => (
											<InputWithLabel
												key={input.name}
												className="full-width"
												name={input.name}
												placeholder={input.label}
												label={input.label}
												type={input.type}
												value={values[input.name]}
												onChange={handleChange}
												validated={touched[input.name] && errors[input.name]}
												signupform={true}
											/>
										))}
									</div>

									<div className="send-button-parent">
										<Button className="button" type="submit" endIcon={isLoading ? <CircularProgress className="loader" /> : ""}>
											<Typography className="send-font">Send</Typography>
										</Button>
									</div>
								</FormWrapper>
							)}
						</Formik>
					</div>
				</div>
			</div>

			<div className="footer-container">
				<div className="inner-container">
					<div className="footer-text">© 2022 Subjects Master. All rights reserved.</div>
					<div className="page-container">
						<div className="about-us" onClick={() => navigate("/" + URL_ABOUT_US)}>
							ABOUT US
						</div>
						<div className="partition">|</div>
						<div className="terms" onClick={() => navigate("/" + URL_TERMS_AND_CONDITIONS)}>
							TERMS & CONDITIONS
						</div>
						<div className="partition">|</div>
						<div className="privacy" onClick={() => navigate("/" + URL_PRIVACY_POLICY)}>
							PRIVACY POLICY
						</div>
					</div>
				</div>
			</div>
		</ForgotPasswordWrapper>
	);
}

export default ForgotPassword;

const inputs = [
	{
		label: "Email",
		name: "email",
		validation: { required: true, email: true },
	},
];

const validationSchema = generateValidationSchema(inputs);

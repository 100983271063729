import React from "react";
// ROUTES
import MainRoutes from "Routes/Route";
// COMPONENTS
import Topbar from "Components/Commons/Topbar/TopBar";
import EventManager from "Components/EventManager/EventManager";
// STYLES
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ContentWrapper } from "./Website.style";

const Website = () => (
	<>
		<Topbar />

		<ContentWrapper>
			<MainRoutes />
		</ContentWrapper>

		<EventManager />
	</>
);

export default Website;

import styled from "styled-components";
import { Dialog } from "@material-ui/core";

//CUSTOM
import { COLORS, responsive } from "Styles/Constants";
import { IMAGES } from "Helpers";

//HOW TO PALY MODAL
export const HowToPlayModalWrapper = styled(Dialog)`
	&.dialog-root {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.backdrop {
		background-color: unset;
	}

	.dialog-paper {
		background: transparent;
		color: ${COLORS.WHITE};
		overflow: visible;
		box-shadow: none;
	}

	.dialog-paper-width-sm {
		max-width: 900px;
	}

	.box-modal {
		background-image: ${(props) => (props.modalType === "didYouKnow" ? `url(${IMAGES.FOREIGN.QUIZ.DID_YOU_KNOW})` : `url(${IMAGES.FOREIGN.QUIZ.HOW_TO_PLAY})`)};
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;
		width: 800px;
		height: 580px;
		gap: 35px;
		padding: 130px 0 0 0;
		position: relative;

		${responsive.DESKTOP`
			width: 600px;
			height: 480px;
		`}

		.content-wrapper {
			height: 370px;

			${responsive.DESKTOP`
				height: 280px;
			`}

			.progress {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;

				.progress-bar {
					color: ${COLORS.WHITE};
				}

				.wait-text {
					color: ${COLORS.WHITE};
					margin-top: 10px;
				}
			}

			.scrollbar {
				width: 80%;

				.content {
					/* overflow-y: auto; */
					/* height: 350px; */
					/* width: 80%; */

					/* ::-webkit-scrollbar {
						width: 6px;
						height: 6px;
					}
	
					::-webkit-scrollbar-track {
						border-radius: 10px;
					}
	
					::-webkit-scrollbar-thumb {
						background-color: rgba(162, 162, 162, 0.5);
						border-radius: 4px;
					} */

					.text {
						text-align: center;
					}

					.attach-file {
						display: flex;
						justify-content: center;

						.icon {
							color: ${COLORS.WHITE};
							margin: 0px 10px;
						}

						.file {
							margin: 0px 10px;
							font-size: 18px;
							cursor: pointer;
							text-decoration: underline;
							color: ${COLORS.WHITE};
						}

						.pdf-loader {
							width: 20px !important;
							height: 20px !important;
							color: ${COLORS.WHITE};
							margin-top: 1px;
						}
					}
				}
			}

			.type-switcher {
				width: 100%;

				.divider {
					width: 50%;
					background-color: ${COLORS.GREY};
					height: 2px;
					margin-top: 5px;
				}

				.text {
					cursor: pointer;
					transition: 0.3s;
				}
			}

			.submit-btn {
				position: absolute;
				bottom: -26px;
			}
		}
	}
`;

//WAGER MODAL
export const WagerModalWrapper = styled(Dialog)`
	&.dialog-root {
		background-color: rgba(0, 0, 0, 0.4);
	}

	.backdrop {
		background-color: unset;
	}

	.dialog-paper {
		background: transparent;
		color: ${COLORS.WHITE};
		overflow: visible;
		box-shadow: none;
	}

	.box-modal {
		background-image: url(${IMAGES.FOREIGN.QUIZ.WAGER_NOW});
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		width: 600px;
		height: 700px;
		position: relative;

		.content {
			position: absolute;
			top: 50%;

			.sub-title {
				font-size: 26px;
				text-align: center;
				width: 80%;
			}

			.score {
				font-size: 48px;
				text-align: center;
				color: ${COLORS.WHITE};
			}

			.input-field-wrapper {
				width: 100%;

				.input-field {
					height: 50px;
					width: 80%;
					background-color: ${COLORS.TRANSPARENT_GREY};
					border-radius: 10px;
					border: solid 1px ${COLORS.PURPLE_BORDER};
					color: ${COLORS.WHITE};
					padding: 0 10px;
				}

				.error-msg {
					font-size: 18px;
					width: 80%;
					text-align: center;
				}
			}
		}

		.submit-btn {
			position: absolute;
			bottom: 2%;
			left: 50%;
			transform: translate(-50%, 0);
		}
	}
`;

//DAILY DOUBLE MODAL
export const DailyDoubleModalWrapper = styled(Dialog)`
	&.dialog-root {
		background-color: rgba(0, 0, 0, 0.4);
	}

	.backdrop {
		background-color: unset;
	}

	.dialog-paper {
		background: transparent;
		color: #fff;
		overflow: visible;
		box-shadow: none;
	}

	.box-modal {
		background-image: url(${IMAGES.FOREIGN.QUIZ.DAILY_DOUBLE_BACKGROUND});
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		width: 600px;
		height: 470px;
		gap: 35px;
		position: relative;

		.wager-btn {
			position: absolute;
			bottom: -2%;
		}
	}
`;

//CONGRATULATIONS MODAL
export const CongratulationsModalWrapper = styled(Dialog)`
	&.dialog-root {
		background-color: rgba(0, 0, 0, 0.4);
	}

	.backdrop {
		background-color: unset;
	}

	.dialog-paper {
		background: transparent;
		color: #fff;
		overflow: visible;
		box-shadow: none;
	}

	.dialog-paper-width-sm {
		max-width: 900px;
	}

	.confetti {
		position: fixed !important;
		width: 100%;
		height: 100%;
	}

	.box-modal {
		background-image: ${(props) => (props.isCongrats ? `url(${IMAGES.FOREIGN.QUIZ.CONGRATULATIONS_BACKGROUND})` : `url(${IMAGES.FOREIGN.QUIZ.BETTER_LUCK_BACKGROUND})`)};
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		width: 617px;
		height: 712px;
		position: relative;

		.title {
			bottom: ${(props) => (props.isCongrats ? "35%" : "40%")};
			font-size: 32px;
		}

		.description {
			bottom: ${(props) => (props.isCongrats ? "25%" : "30%")};
			font-size: 42px;
		}

		.points {
			bottom: 20%;
			font-size: 32px;
		}

		.text {
			width: 70%;
			font-weight: 900;
			position: absolute;
			text-align: center;
		}

		.dashboard-btn {
			position: absolute;
			bottom: ${(props) => (props.isCongrats ? "2%" : "8%")};
		}
	}
`;

import styled, { keyframes } from "styled-components";
import { Dialog } from "@material-ui/core";
// STYLES
import { COLORS, responsive } from "Styles/Constants";
import { IMAGES } from "Helpers";

const INDICATOR_BORDER_WIDTH = "2px";

const borderAnimation = keyframes`
    0% { opacity : 1 }
    50% { opacity : 0.5 }
    100% { opacity : 1 }
`;

const indicatorAnimation = keyframes`
    0% { width: calc(100% + 2px); height: calc(100% + 2px); visibility : visible; }
    75% { width: 0%; height: calc(100% + 2px); visibility : visible; }
    99.9% { width: 0%; height: 0%; visibility : visible; }
    100% { width: 0%; height: 0%; visibility : hidden; }
`;

export const GamePlayWrapper = styled.div`
	width: 100%;
	color: #fff;
	display: flex;
	overflow: hidden;
	min-height: 100vh;
	position: relative;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	.background-image {
		width: 100%;
		height: 100vh;
		position: absolute;
	}

	.board-container {
		width: 1000px;
		height: 800px;
		display: flex;
		position: relative;
		align-items: center;
		flex-direction: column;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.ECONOMICS.RAPID_FIRE.QUESTION_MODAL});

		${responsive.DISPLAY`
			width: 780px;
    		height: 660px;
		`}

		${responsive.DESKTOP`
			width: 670px;
			height: 570px;
		`}

		.progress {
			width: 100%;
			height: 100%;
			display: flex;
			margin-top: 40px;
			position: absolute;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			.progress-bar {
				color: #fff;
			}

			.wait-text {
				color: #fff;
				margin-top: 10px;
			}
		}

		.timer {
			color: #e9cf00;
			font-size: 28px;
			margin-top: 118px;
			position: relative;
			margin-left: -272px;

			${responsive.DISPLAY`
				font-size: 20px;
				margin-top: 96px;
				margin-left: -226px;
			`}

			${responsive.DESKTOP`
				font-size: 18px;
				margin-top: 82px;
				margin-left: -194px;
			`}
		}

		.initial-load-container {
			display: flex;
			margin-top: 430px;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			${responsive.DISPLAY`
				margin-top: 350px;
			`}

			${responsive.DESKTOP`
				margin-top: 305px;
			`}

			.text {
				margin: 0px;
				font-size: 24px;
				text-align: center;
				margin-bottom: 10px;

				${responsive.DESKTOP`
					font-size: 18px;
					margin-bottom: 5px;
				`}
			}

			.sub-text {
				margin: 0px;
				font-size: 18px;
				text-align: center;

				${responsive.DESKTOP`
					font-size: 16px;
				`}
			}
		}

		.image {
			height: 110px;
			margin-top: 135px;
			object-fit: contain;

			${responsive.DISPLAY`
				height: 95px;
				margin-top: 115px;
			`}

			${responsive.DESKTOP`
				height: 85px;
				margin-top: 100px;
			`}
		}

		.question {
			width: 65%;
			color: #fff;
			font-size: 30px;
			margin-top: 20px;
			font-weight: bold;
			text-align: center;

			${responsive.DISPLAY`
				font-size: 24px;
				margin-top: 10px;
			`}

			${responsive.DESKTOP`
				font-size: 18px;
				margin-top: 10px;
			`}
		}

		.option-container {
			gap: 40px;
			width: 75%;
			display: flex;
			flex-wrap: wrap;
			margin-top: 35px;
			padding: 10px 70px;
			margin-bottom: 20px;
			align-items: center;
			justify-content: space-between;

			${responsive.DISPLAY`
				gap: 20px;
				width: 82%;
				margin-top: 20px;
				margin-bottom: 10px;
			`}

			${responsive.DESKTOP`
				gap: 20px;
				width: 85%;
				margin-top: 12px;
				margin-bottom: 10px;
			`}

			.option {
				color: black;
				display: flex;
				cursor: pointer;
				min-height: 60px;
				font-weight: bold;
				text-align: center;
				border-radius: 5px;
				position: relative;
				align-items: center;
				transition: all 0.3s;
				width: calc(50% - 20px);
				background-size: 100% 100%;
				background-position: center;
				background-repeat: no-repeat;
				box-shadow: 0px 0px 5px 0px #8a8a8a;
				background-image: url(${IMAGES.ECONOMICS.RAPID_FIRE.OPTION});

				${responsive.DISPLAY`
					min-height: 45px;
					width: calc(50% - 10px);
				`}

				${responsive.DESKTOP`
					min-height: 40px;
					width: calc(50% - 10px);
				`}

				&.disabled {
					cursor: default;
				}

				&.green {
					background-size: 100% 105%;
					background-image: url(${IMAGES.ECONOMICS.RAPID_FIRE.CORRECT_OPTION});
				}

				&.red {
					background-size: 100% 105%;
					background-image: url(${IMAGES.ECONOMICS.RAPID_FIRE.INCORRECT_OPTION});
				}

				&.blinking {
					animation-duration: 2s;
					animation-name: ${borderAnimation};
					animation-iteration-count: infinite;
					animation-timing-function: ease-in-out;
				}

				.option-text {
					width: 80%;
					font-size: 22px;
					margin-left: 9%;
					overflow: hidden;
					margin-bottom: 6px;
					white-space: nowrap;
					text-overflow: ellipsis;

					${responsive.DISPLAY`
						font-size: 18px;
					`}

					${responsive.DESKTOP`
						font-size: 16px;
					`}
				}
			}
		}
	}

	.confetti {
		width: 100%;
		height: 100%;
	}

	.wrapped-up-container {
		width: 550px;
		height: 700px;
		display: flex;
		position: relative;
		align-items: center;
		flex-direction: column;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.ECONOMICS.RAPID_FIRE.CONGRATULATIONS_BACKGROUND});

		&.lose {
			width: 670px;
			background-image: url(${IMAGES.ECONOMICS.RAPID_FIRE.BETTER_LUCK_BACKGROUND});

			.home-btn-image {
				left: 35px;
				width: 50px;
				bottom: 50px;
				cursor: pointer;
				position: absolute;

				${responsive.DISPLAY`
					left: 35px;
					width: 45px;
					bottom: 30px;
				`}

				${responsive.DESKTOP`
					left: 30px;
					width: 35px;
					bottom: 25px;
				`}
			}
		}

		${responsive.DISPLAY`
			width: 430px;
    		height: 550px;
			
			&.lose {
				width: 550px;
				background-image: url(${IMAGES.ECONOMICS.RAPID_FIRE.BETTER_LUCK_BACKGROUND});
			}
		`}

		${responsive.DESKTOP`
			width: 355px;
    		height: 450px;
			
			&.lose {
				width: 450px;
				background-image: url(${IMAGES.ECONOMICS.RAPID_FIRE.BETTER_LUCK_BACKGROUND});
			}
		`}

		.title {
			font-size: 30px;
			margin-top: 340px;

			${responsive.DISPLAY`
				font-size: 24px;
    			margin-top: 266px;
			`}

			${responsive.DESKTOP`
				font-size: 18px;
   				margin-top: 215px;
			`}
		}

		.score-container {
			gap: 50px;
			display: flex;
			margin-top: 20px;
			flex-wrap: nowrap;
			align-items: center;

			${responsive.DISPLAY`
				gap: 40px;
				margin-top: 15px;
			`}

			${responsive.DESKTOP`
				gap: 30px;
    			margin-top: 12px;
			`}

			.score {
				width: 100px;
				height: 100px;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url(${IMAGES.ECONOMICS.RAPID_FIRE.SCORE});

				${responsive.DISPLAY`
					width: 85px;
					height: 85px;
				`}

				${responsive.DESKTOP`
					width: 67px;
    				height: 67px;
				`}

				.score-text {
					font-size: 36px;

					${responsive.DISPLAY`
						font-size: 30px;
					`}

					${responsive.DESKTOP`
						font-size: 22px;
					`}

					&.correct {
						color: #06c91d;
					}

					&.incorrect {
						color: #ff0e02;
					}
				}

				.status-text {
					font-size: 12px;
					font-weight: bold;
					letter-spacing: 0.64px;

					${responsive.DISPLAY`
						font-size: 10px;
					`}

					${responsive.DESKTOP`
						font-size: 7px;
					`}
				}
			}

			.vs-text {
				font-size: 22px;

				${responsive.DISPLAY`
					font-size: 18px;
				`}

				${responsive.DESKTOP`
					font-size: 14px;
				`}
			}
		}

		.attempt-container {
			width: 330px;
			height: 100px;
			display: flex;
			margin-top: 15px;
			margin-bottom: 25px;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(${IMAGES.ECONOMICS.RAPID_FIRE.WRAPPED_UP});

			${responsive.DISPLAY`
				width: 265px;
				height: 100px;
				font-size: 14px;
				margin-bottom: 10px;
			`}

			${responsive.DESKTOP`
				width: 210px;
				height: 70px;
				font-size: 10px;
				margin-bottom: 18px;
			`}

			.attempt-text {
				color: #ff0600;
			}

			.wrapped-up-text {
				color: #fbc300;
			}
		}

		.home-btn-image {
			left: 35px;
			width: 50px;
			bottom: 30px;
			cursor: pointer;
			position: absolute;

			${responsive.DISPLAY`
    			left: 22.5px;
				width: 40px;
				bottom: 22.5px;
			`}

			${responsive.DESKTOP`
				left: 20px;
				width: 30px;
				bottom: 20px;
			`}
		}

		.replay-btn-image {
			width: 220px;
			cursor: pointer;
			object-fit: contain;
			margin-bottom: -22px;

			${responsive.DISPLAY`
				width: 165px;
			`}

			${responsive.DESKTOP`
				width: 135px;
			`}
		}
	}
`;

export const RapidFireDialogWrapper = styled(Dialog)`
	.MuiBackdrop-root {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.MuiDialog-paper {
		background: transparent;
		color: black;
		overflow: visible;
	}

	.MuiDialog-paperWidthSm {
		max-width: 900px;
	}

	.box-modal {
		width: 880px;
		height: 750px;
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.ECONOMICS.RAPID_FIRE.HOW_TO_PLAY});

		${responsive.DISPLAY`
			width: 680px;
    		height: 580px;
		`}

		${responsive.DESKTOP`
			width: 630px;
    		height: 540px;
		`}

		.title-container {
			.sub-title {
				color: #e9cf00;
				font-size: 26px;
				margin-top: 120px;
				margin-left: -10px;

				${responsive.DISPLAY`
					font-size: 22px;
					margin-top: 92px;
				`}

				${responsive.DESKTOP`
					font-size: 18px;
					margin-top: 88px;
				`}
			}
		}

		.content-wrapper {
			height: 405px;
			margin-top: 70px;
			position: relative;
			padding: 15px 140px;

			${responsive.DISPLAY`
				height: 312px;
				font-size: 14px;
				margin-top: 50px;
				padding: 15px 110px;
			`}

			${responsive.DESKTOP`
				height: 290px;
				font-size: 12px;
				padding: 15px 100px;
			`}

			.scroll-bar {
				.thumb-vertical {
					background-color: #4c2b25;
				}
			}

			.content {
				width: 90%;
				color: #fff;
				margin: 0px auto;
			}

			.attach-file {
				display: flex;
				justify-content: center;

				.icon {
					color: #fff;
				}

				.file {
					margin: 0px 10px;
					font-size: 18px;
					cursor: pointer;
					text-decoration: underline;
					color: #fff;
				}

				.pdf-loader {
					width: 20px !important;
					height: 20px !important;
					color: #fff;
					margin-top: 1px;
				}
			}

			.progress {
				position: absolute;
				top: 36%;
				left: 0;
				right: 0;
				color: #000;
			}

			.type-switcher {
				.divider {
					background-color: #fff;
					height: 2px;
					width: 50%;
					margin: 5px auto;
					opacity: 0.5;
				}

				.text {
					color: #fff;
					cursor: pointer;
					transition: 0.3s;
					margin: 0px 0px 0px 10px;
				}
			}
		}

		.play {
			height: 80px;
			cursor: pointer;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;

			${responsive.DISPLAY`
				height: 70px;
			`}

			${responsive.DESKTOP`
				height: 60px;
			`}

			&.how-to-play {
				margin-top: 40px;

				${responsive.DISPLAY`
					margin-top: 26px;
				`}

				${responsive.DESKTOP`
					margin-top: 30px;
				`}
			}

			&.lets-play {
				margin-top: 40px;

				${responsive.DISPLAY`
					margin-top: 26px;
				`}

				${responsive.DESKTOP`
					margin-top: 30px;
				`}
			}
		}
	}
`;

export const Indicators = styled.div`
	width: 259px;
	height: 59px;
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	border: ${INDICATOR_BORDER_WIDTH} solid #b8cb42;

	${responsive.DISPLAY`
		width: 213px;
    	height: 50px;
	`}

	${responsive.DESKTOP`
		width: 182px;
    	height: 48px;
	`}

	&.animated {
		&:before,
		&:after {
			content: "";
			transition: 0s;
			position: absolute;
			visibility: visible;
			width: calc(100% + 2px);
			height: calc(100% + 2px);
		}

		&:before {
			right: -${INDICATOR_BORDER_WIDTH};
			bottom: -${INDICATOR_BORDER_WIDTH};
			border-top: ${INDICATOR_BORDER_WIDTH} solid ${COLORS.DANGER};
			border-right: ${INDICATOR_BORDER_WIDTH} solid ${COLORS.DANGER};
		}

		&:after {
			top: -${INDICATOR_BORDER_WIDTH};
			left: -${INDICATOR_BORDER_WIDTH};
			border-left: ${INDICATOR_BORDER_WIDTH} solid ${COLORS.DANGER};
			border-bottom: ${INDICATOR_BORDER_WIDTH} solid ${COLORS.DANGER};
		}

		&.running {
			&:before,
			&:after {
				animation-duration: 7.5s;
				animation-fill-mode: forwards;
				animation-timing-function: linear;
				animation-name: ${indicatorAnimation};
			}
			&:after {
				animation-delay: 7.5s;
			}
		}
	}
`;

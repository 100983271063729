import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const withRouterWrapper = (Component) => {
	function ComponentWithRouterProp(props) {
		const params = useParams();
		const location = useLocation();
		const navigate = useNavigate();

		return <Component {...props} router={{ location, navigate, params }} />;
	}

	return ComponentWithRouterProp;
};

export default withRouterWrapper;

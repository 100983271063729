import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Drawer, List, ListItem, Avatar, Typography, ListItemText, Collapse } from "@material-ui/core";
import useSound from "use-sound";
// STYLES
import { SidebarWrapper } from "./Sidebar.style";
// ROUTES
import { civicsSidebarRoutes } from "Routes/RoutesList";
// COMPONENTS
import HowToPlay from "Components/Commons/HowToPlay/HowToPlay";
// HELPERS
import { Api, commonSidebarListItems, IMAGES } from "Helpers";
import {
	URL_PROFILE,
	URL_CHANGE_PWD,
	URL_ABOUT_US,
	URL_PRIVACY_POLICY,
	URL_TERMS_AND_CONDITIONS,
	URL_CIVICS_DASHBOARD,
	URL_BILL_OR_NO_BILL,
	URL_BILL_BECOME_LAW,
	URL_ESCAPE_ROOM,
	URL_JUDICIAL_DASHBOARD,
	URL_JUDICIAL_SPIN,
	URL_CONSTITUTION,
	URL_JEO_PARDY,
	URL_JEO_PARDY_ANSWER,
	URL_FINAL_MODULE,
	URL_CONTACT_US,
	URL_DATA_SECURITY,
} from "Helpers/Paths";
// SOUNDS
import DrawerSound from "Assets/Sounds/switch-off.mp3";
// REDUX
import { logOutUser } from "Redux/Auth/Actions";

const getMenuItemSelected = (currentLocation, route) => {
	switch (currentLocation) {
		case URL_CIVICS_DASHBOARD:
			return route === currentLocation;

		case `${URL_CIVICS_DASHBOARD}/${URL_BILL_OR_NO_BILL}`:
			return URL_BILL_OR_NO_BILL.includes(route);

		case `${URL_CIVICS_DASHBOARD}/${URL_BILL_BECOME_LAW}`:
			return URL_BILL_BECOME_LAW.includes(route);

		case `${URL_CIVICS_DASHBOARD}/${URL_ESCAPE_ROOM}`:
			return URL_ESCAPE_ROOM.includes(route);

		case `${URL_CIVICS_DASHBOARD}/${URL_JUDICIAL_DASHBOARD}`:
			return URL_JUDICIAL_DASHBOARD.includes(route);

		case `${URL_CIVICS_DASHBOARD}/${URL_JUDICIAL_SPIN}`:
			return URL_JUDICIAL_SPIN.includes(route);

		case `${URL_CIVICS_DASHBOARD}/${URL_CONSTITUTION}`:
			return URL_CONSTITUTION.includes(route);

		case `${URL_CIVICS_DASHBOARD}/${URL_JEO_PARDY}`:
			return URL_JEO_PARDY.includes(route);

		case `${URL_CIVICS_DASHBOARD}/${URL_JEO_PARDY_ANSWER}`:
			return URL_JEO_PARDY_ANSWER.includes(route);

		case `${URL_CIVICS_DASHBOARD}/${URL_FINAL_MODULE}`:
			return URL_FINAL_MODULE.includes(route);

		case `${URL_PROFILE}`:
			return `${URL_PROFILE}` === route;

		case `${URL_ABOUT_US}`:
			return `${URL_ABOUT_US}` === route;

		case `${URL_PRIVACY_POLICY}`:
			return `${URL_PRIVACY_POLICY}` === route;

		case `${URL_TERMS_AND_CONDITIONS}`:
			return `${URL_TERMS_AND_CONDITIONS}` === route;

		case `${URL_DATA_SECURITY}`:
			return `${URL_DATA_SECURITY}` === route;

		case `${URL_CHANGE_PWD}`:
			return `${URL_CHANGE_PWD}` === route;

		case `${URL_CONTACT_US}`:
			return `${URL_CONTACT_US}` === route;

		default:
			return false;
	}
};

const CivicsSideBar = (props) => {
	const { open } = props;

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const { totalScore, profileInfo, subjectAccessList: subjects, schoolClassTestID } = useSelector((state) => state.Auth);
	const { gamesStatus } = useSelector((state) => state.GamesData);
	const sidebarListItems = profileInfo?.isFromClassLink ? commonSidebarListItems.filter((list) => list.text !== "Change Password") : commonSidebarListItems;

	const [play] = useSound(DrawerSound, { volume: 0.2 });

	const subjectId = subjects?.filter((item) => item.subject === "Civics")[0].subjectId;
	const sidebarRoutes = civicsSidebarRoutes.filter((route) => route.order).sort((a, b) => a.order - b.order);
	const routes = location.pathname.includes("civic") && sidebarRoutes;

	const [settingsOpen, setSettingsOpen] = useState(false);
	const [openHowToPlayDrawer, setOpenHowToPlayDrawer] = useState(false);
	const [howToPlayData, setHowToPlayData] = useState({ data: {}, open: true, schoolId: schoolClassTestID, subjectId: subjectId, openHowToPlay: false });

	useEffect(() => {
		if (open) props.toggleDrawer();
	}, [location.pathname]); // eslint-disable-line

	const redirectAndClose = (path) => {
		path = path === "/" + URL_CIVICS_DASHBOARD ? URL_CIVICS_DASHBOARD : path;

		let newHowToPlayData = { ...howToPlayData };

		switch (path) {
			case "/constitution":
				newHowToPlayData.openHowToPlay = true;
				newHowToPlayData.data = { id: "Constitution", open: true, subTitle: "Constitution", url: "constitution" };

				break;

			case "/bill-or-no-bill":
				newHowToPlayData.openHowToPlay = true;
				newHowToPlayData.data = { id: "Legislative Branch(Bill No Bill)", open: true, subTitle: "Bill or No Bill", url: "bill-or-no-bill" };

				break;

			case "/bill-become-law":
				newHowToPlayData.openHowToPlay = true;
				newHowToPlayData.data = { id: "Legislative Branch(How Bill Becomes Law)", open: true, subTitle: "Bill or No Bill", url: "bill-become-law" };

				break;

			case "/escape-room":
				newHowToPlayData.openHowToPlay = true;
				newHowToPlayData.data = { id: "Executive Branch", open: true, subTitle: "ESCAPE THE OVAL OFFICE", url: "escape-room" };

				break;

			case "/jeo-pardy":
				newHowToPlayData.openHowToPlay = true;
				newHowToPlayData.data = { id: "Quiz", open: true, subTitle: "Quizardy", url: "jeo-pardy" };

				break;

			case "/final-module":
				newHowToPlayData.openHowToPlay = true;
				newHowToPlayData.data = { id: "Final Game", open: true, subTitle: "CivBlitz", url: "final-module" };

				break;

			case "/judicial/dashboard":
				newHowToPlayData.openHowToPlay = true;
				newHowToPlayData.data = { id: "Judicial Branch", open: true, subTitle: "Judicial Spin", url: "judicial/dashboard" };

				break;

			default:
				break;
		}

		props.toggleDrawer();

		if (newHowToPlayData.openHowToPlay) {
			newHowToPlayData.openHowToPlay = false;

			setOpenHowToPlayDrawer(true);
			setHowToPlayData(newHowToPlayData);
		} else navigate(path);
	};

	const handleLogOut = () => {
		const data = new FormData();

		data.append("token", localStorage.getItem("accessToken"));

		new Api().post("user/logout", { data });

		dispatch(logOutUser());
	};

	const handleClose = () => setOpenHowToPlayDrawer(false);

	return (
		<>
			<SidebarWrapper
				open={open}
				anchor="left"
				civics={true}
				location={location}
				onClose={props.toggleDrawer}
				BackdropProps={{ className: "backdrop-root" }}
				classes={{ root: "root", paper: "drawer-paper" }}
			>
				<Drawer className="drawer" variant="persistent" anchor="left" open={open} classes={{ paper: "drawer-paper" }} onClose={props.toggleDrawer}>
					<List>
						<ListItem className="menu-items profile-details">
							<Avatar variant="square" className="img-profile" src={profileInfo.profilePicUrl || IMAGES.PROFILE.BOY} />

							<span className="text">
								{profileInfo.firstName} {profileInfo.lastName}
							</span>

							<Typography variant="body1" className="score">
								{totalScore}
							</Typography>
						</ListItem>

						{routes.map((route, index) => (
							<Fragment key={index}>
								{!route.expand ? (
									<ListItem
										button
										onClick={() => redirectAndClose("/" + route.path)}
										disabled={route?.activeKey ? !gamesStatus?.[route?.activeKey] : false}
										className={`menu-items ${getMenuItemSelected(location.pathname, route.path) ? "active" : ""}`}
									>
										{route.icon ? <img className="icon" src={route.icon} alt={route.name} /> : <img className="icon" src={IMAGES.COMMONS.HOME} alt={route.name} />}
										<ListItemText className="menu-text" primary={route.name} onMouseOver={play} />
									</ListItem>
								) : (
									<>
										<ListItem button className="menu-items" onClick={() => setSettingsOpen(!settingsOpen)}>
											<img className="icon" src={IMAGES.CIVICS.SETTINGS_ICON} alt={route.name} />
											<ListItemText className="menu-text" primary={route.name} onMouseOver={play} />
											{settingsOpen ? (
												<img className="arrow" alt="less-arrow" src={IMAGES.SIDEBAR.LESS_ARROW} height="30px" width="30px" />
											) : (
												<img className="arrow" alt="more-arrow" src={IMAGES.SIDEBAR.MORE_ARROW} height="30px" width="30px" />
											)}
										</ListItem>

										<Collapse in={settingsOpen} timeout="auto" unmountOnExit>
											<List component="div" disablePadding>
												{sidebarListItems.map((listItem) => (
													<ListItem
														button
														key={listItem.text}
														onClick={() => redirectAndClose(listItem.clickParams)}
														className={`menu-items settings ${getMenuItemSelected(location.pathname, listItem.clickParams) ? "active" : ""}`}
													>
														<ListItemText className="menu-text" primary={listItem.text} />
													</ListItem>
												))}

												<ListItem button className="menu-items settings" onClick={handleLogOut}>
													<ListItemText className="menu-text" primary={"Logout"} />
												</ListItem>
											</List>
										</Collapse>
									</>
								)}
							</Fragment>
						))}
					</List>
				</Drawer>
			</SidebarWrapper>

			{openHowToPlayDrawer && <HowToPlay open={howToPlayData.open} handleClose={handleClose} data={howToPlayData.data} subjectId={subjectId} schoolId={schoolClassTestID} />}
		</>
	);
};

export default CivicsSideBar;

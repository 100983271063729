import styled from "styled-components";
// COMPONENTS
import { PageWrapperBase } from "Components/Website.style";
// CONSTANTS
import { COLORS, FONTS, responsive } from "Styles/Constants";
import { IMAGES } from "Helpers";

export const PageWrapper = styled(PageWrapperBase)`
	color: #fff;
	overflow: hidden;
	min-height: 100vh;
	position: relative;

	.background-image {
		width: 100%;
		height: 100%;
		position: absolute;
	}

	.overlay-stackrank {
		width: 100%;
		height: 100%;
		z-index: 101;
		display: flex;
		position: absolute;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.3);

		.progress {
			color: #fff;
		}
	}

	.content {
		width: 70%;
		margin: 3% auto 0;
		position: relative;

		${responsive.LAPTOP` margin: 3% 13% 0;`}
		${responsive.TABLET`margin: 3% 8% 0;  width: 78%;`}
	}

	.slider {
		margin: 3% 0 0;
		width: calc(100% - 238px);
	}

	.title-history {
		font-family: ${FONTS.TERTIARY_BOLD};
		font-size: 36px;
		color: #fff;
		width: calc(100% - 238px);
		text-align: center;
		padding-top: 8%;
		padding-bottom: 2%;
		${responsive.LAPTOP` padding-top: 10%; margin: 0;  width: calc(90% - 140px);`}
		${responsive.TABLET`padding-top: 13%;`}
	}
`;

export const GridContainer = styled.div`
	flex-wrap: wrap;

	${responsive.TABLET`
		gap: 20px;
	`}

	.box {
		width: 25%;
		${responsive.LAPTOP`
			width:33.33%
		`}
		${responsive.TABLET`
			width:35%
		`}

        .disabled {
			pointer-events: none;
			filter: grayscale(80%);
			cursor: not-allowed;
		}

		.box-content {
			cursor: pointer;
			margin: 0 auto;
			max-width: 300px;
			position: relative;
			transition: 0.3s;
			width: 90%;
			position: relative;

			&:hover {
				transform: scale(1.05);
			}

			${responsive.DISPLAY`
				max-width : 225px;
			`}

			.img-container {
				width: 270px;
				height: 300px;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				background-position: center;
				position: relative;
				margin: 0 auto;
				background-image: url(${IMAGES.MATH.BOARD});

				${responsive.DISPLAY`
					height: 275px;
					width: 220px;
				`}

				${responsive.LAPTOP`
					height: 275px;
					width: 190px;
				`}
			}

			.title {
				top: 4.5px;
				width: 100%;
				font-size: 18px;
				text-align: center;
				position: absolute;
			}

			.game-logo {
				width: 80%;
				height: 100%;
				margin: auto;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}

			.progress-container {
				bottom: 18px;
				padding: 0 8%;
				position: absolute;
			}
		}
	}
`;

export const ProgressWrapper = styled.div`
	text-align: left;

	.outer {
		background-color: #001d46;
		border: 1px solid rgba(59, 219, 255, 0.3);
		border-radius: 50px;
		width: 100%;

		.active {
			border-radius: 50px;
			height: 7px;
			background: #ff0000;
			width: ${(props) => props.value}%;
			transition: 0.5s ease;

			${responsive.DISPLAY`
				height: 6px;
			`}
		}
	}

	.text {
		margin-top: 3px;

		${responsive.DISPLAY`
			font-size : 13px;
		`}
	}
`;

export const ScoreboardWrapper = styled.div`
	right: 6px;
	top: 150px;
	width: 232px;
	height: 700px;
	position: absolute;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url(${IMAGES.MATH.LEADERBOARD});

	${responsive.DISPLAY`
		width: 195px;
    	height: 550px;
	`}

	${responsive.DESKTOP`
		width: 155px;
    	height: 438px;
	`}

	.scoreboard-title {
		font-size: 20px;
		text-align: center;
		padding-top: 5px;
		${responsive.DISPLAY`font-size: 18px; `}
	}

	.title {
		text-align: center;
		position: relative;
		z-index: 4;
		color: #fff;

		.image {
			width: 85%;
		}

		.text-container {
			margin-top: -39px;

			${responsive.DISPLAY`
				margin-top: -34px;
			`}

			.text {
				font-size: 18px;

				${responsive.DISPLAY`
					font-size: 14px;
				`}
			}
		}
	}
	.scoreboard-text {
		margin-top: 20px;
		text-align: center;
		font-size: 18px;
		${responsive.DISPLAY` margin-top: 14px;`}
	}
	.people-cards {
		margin-left: 2px;
		margin-top: 36px;

		.person {
			width: 100%;
			height: 195px;

			&:nth-child(1) {
				z-index: 3;
			}

			&:nth-child(2) {
				z-index: 2;
			}

			&:nth-child(3) {
				z-index: 1;
			}

			${responsive.DISPLAY`
				height: 162px;
			`}

			${responsive.DESKTOP`
				height: 126px;
			`}

			.content {
				.image {
					border: 3px solid ${COLORS.SECONDARY};
					border-radius: 10px;
					margin: 0 auto;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					height: 70px;
					width: 70px;

					${responsive.DESKTOP`
						height: 55px;
    					width: 55px;
					`}
				}

				.name {
					font-size: 20px;
					margin-top: 5px;
					color: #fff;

					${responsive.DISPLAY`
						font-size: 18px;
					`}

					${responsive.DESKTOP`
						font-size: 16px;
					`}
				}

				.score {
					display: inline-block;
					padding: 2px 12px;
					color: #fff;

					${responsive.DISPLAY`
						font-size: 18px;
					`}

					${responsive.DESKTOP`
						font-size: 16px;
					`}
				}
			}
		}
	}
`;

import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useSound from "use-sound";
import { LoginWrapper, EmailField, PasswordField, LoginParentWrapper } from "./Login.style";

import { InputAdornment, IconButton, Typography, Button, InputLabel, Box, Checkbox, CircularProgress } from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

// FORM VALIDATION
import { useFormik } from "formik";
import * as Yup from "yup";

// STYLES
// import { PageWrapper, LoginBox, EmailField, PasswordField, SignInButton } from "./Login.style";

// CONST
import {
	Api,
	CODES,
	URL_REGISTER,
	URL_FRGT_PWD,
	generateValidationSchema,
	URL_GENERAL_DASHBOARD,
	URL_GLOBAL_LANDING_PAGE,
	URL_ABOUT_US,
	URL_TERMS_AND_CONDITIONS,
	URL_PRIVACY_POLICY,
	URL_DATA_SECURITY,
} from "Helpers";
import { loginUser, updateProfileInfo, updateStorage, subjectAccessData } from "Redux/Auth/Actions";
import { showToast } from "Redux/General/Actions";

// SOUNDS
import LoginSound from "Assets/Sounds/login-sound.mp3";

function Login() {
	const [isLoading, setIsLoading] = useState(false);
	const [values, setValues] = useState({
		password: "",
		showPassword: false,
	});

	const [play] = useSound(LoginSound, { volume: 0.2 });

	// REDUX
	const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
	const dispatch = useDispatch();

	// ROUTER
	const navigate = useNavigate();

	useEffect(() => {
		checkIfAlreadyLoggedIn();
		// eslint-disable-next-line
	}, []);

	//form
	const formik = useFormik({
		initialValues: {
			password: "",
			email: "",
		},
		validationSchema: Yup.object({
			email: Yup.string().required("*Please enter your email address.").email("Ex user@mail.com"),
			password: Yup.string().trim().required("*Please enter your password ").min(6, "Password must be atleast 6 length"),
		}),
		onSubmit: (values) => {
			handleLogin(values);
		},
	});

	function checkIfAlreadyLoggedIn() {
		if (isLoggedIn) navigate(URL_GENERAL_DASHBOARD);
	}

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	function handleLogin(values) {
		setIsLoading(true);

		new Api()
			.post("user/login", { data: values, returnError: true })
			.then(async (response) => {
				if (response.status === 200 || response.status === 201) {
					let data = {
						accessToken: response.data.accessToken,
						role: response?.data?.role ? response.data.role : "",
					};
					let subject = { data: response?.data?.subjectAccessData && response.data.subjectAccessData.length ? response.data.subjectAccessData : [] };

					// UPDATE LOCAL STORAGE
					updateStorage(data);

					// GET PROFILE INFO
					let profileInfo = await new Api().get("user/profile");

					dispatch(loginUser(data));
					dispatch(updateProfileInfo(profileInfo.data));
					dispatch(subjectAccessData(subject));
					setIsLoading(false);
					play();
					navigate(URL_GENERAL_DASHBOARD, { replace: true });
				} else {
					dispatch(showToast(response.data.message || "invalid email / password"));
					setIsLoading(false);
				}
			})
			.catch((error) => {
				if (error.status === CODES.UNAUTHORIZED) dispatch(showToast(error.data.message || "invalid email / password"));
				setIsLoading(false);
			});
	}

	return (
		<LoginParentWrapper>
			<Box className="header-container">
				<Box className="top-bar">
					<Box
						className="subject-logo"
						onClick={() => {
							navigate(URL_GLOBAL_LANDING_PAGE);
						}}
					></Box>
					<Box className="btn-container">
						<Box
							className="teacher-btn"
							onClick={() => {
								navigate(URL_GLOBAL_LANDING_PAGE);
							}}
						>
							HOME
						</Box>
						<Box
							className="login"
							onClick={() => {
								navigate(URL_REGISTER);
							}}
						>
							SIGNUP
						</Box>
					</Box>
				</Box>
			</Box>

			<Box className="main-container">
				<Box className="modal">
					<LoginWrapper>
						<form onSubmit={formik.handleSubmit} validationSchema={validationSchema}>
							<Box className="content">
								<Box className="header">
									<InputLabel className="header">Login</InputLabel>
								</Box>
								<Box>
									<Box className="flex-column-parent">
										<Box className="flex-column">
											<Box component="Box" sx={{ display: "inline" }} className="text">
												<InputLabel className="text">Email address</InputLabel>
											</Box>

											<EmailField
												id="email"
												name="email"
												className="login"
												autoComplete="false"
												onBlur={formik.handleBlur}
												onChange={formik.handleChange}
												defaultValue={formik.values.email}
												placeholder="user@subjectsmaster.com"
												helpertext={formik.touched.email && formik.errors.email}
											/>
											<Box component="div" sx={{ display: "inline", height: "10px" }} className="validation-error text">
												{formik.touched.email && formik.errors.email ? formik.errors.email : ""}
											</Box>
										</Box>

										<Box className="flex-column">
											<Box component="div" sx={{ display: "inline" }} className="text">
												<InputLabel className="text">Password</InputLabel>
											</Box>

											<PasswordField
												placeholder="Enter 6 character or more"
												name="password"
												key="password"
												className="login"
												value={formik.values.password}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												type={values.showPassword ? "text" : "password"}
												endAdornment={
													<InputAdornment position="end" name="password" key="password">
														<IconButton
															aria-label="toggle password visibility"
															onClick={handleClickShowPassword}
															onMouseDown={handleMouseDownPassword}
															className="paswword-icon"
														>
															{values.showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												}
											/>
											<Box component="div" sx={{ display: "inline", height: "10px" }} className="validation-error text">
												{formik.touched.password && formik.errors.password ? formik.errors.password : ""}
											</Box>
										</Box>
									</Box>

									<Box className="remember-me-parent">
										{/* <Box className="flex-center">
											<Checkbox className="checkbox" />
											<Typography className="remember-me-font">Remember me</Typography>
										</Box> */}

										<Box className="forgot-password-parent">
											<Typography className="forgot-password">
												<Link to={URL_FRGT_PWD}>Forgot Password?</Link>
											</Typography>
										</Box>
									</Box>
								</Box>

								<Box className="login-button-parent">
									<Button className="button" type="submit" endIcon={isLoading ? <CircularProgress className="loader" /> : ""}>
										<Typography className="login-font">Login</Typography>
									</Button>
								</Box>

								<Box className="model-footer">
									<Typography className="font dose-not-have-account-font">Don't have an account yet?</Typography>
									<Typography className="font register-as-school-font">
										<Link to={URL_REGISTER}>Register Now</Link>
									</Typography>
								</Box>
							</Box>
						</form>
					</LoginWrapper>
				</Box>
			</Box>

			<Box className="footer-container">
				<Box className="inner-container">
					<Box className="footer-text">© 2022 Subjects Master. All rights reserved.</Box>
					<Box className="page-container">
						<Box className="about-us" onClick={() => navigate("/" + URL_ABOUT_US)}>
							ABOUT US
						</Box>
						<Box className="partition">|</Box>
						<Box className="terms" onClick={() => navigate("/" + URL_TERMS_AND_CONDITIONS)}>
							TERMS & CONDITIONS
						</Box>
						<Box className="partition">|</Box>
						<Box className="privacy" onClick={() => navigate("/" + URL_PRIVACY_POLICY)}>
							PRIVACY POLICY
						</Box>
						<Box className="partition">|</Box>
						<Box className="privacy" onClick={() => navigate("/" + URL_DATA_SECURITY)}>
							DATA SECURITY
						</Box>
					</Box>
				</Box>
			</Box>
		</LoginParentWrapper>
	);
}

export default Login;

const inputs = [
	{
		label: "Email",
		name: "email",
		validation: { required: true, email: true },
	},
	{
		label: "Password",
		name: "password",
		type: "password",
		validation: { required: true, min: 6 },
	},
];

const validationSchema = generateValidationSchema(inputs);

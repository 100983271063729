import styled from "styled-components";
import { Dialog } from "@material-ui/core";
import { FONTS } from "Styles/Constants";

export const DialogWrapper = styled(Dialog)`
	.MuiBackdrop-root {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.MuiDialog-paper {
		background: transparent;
		color: #fff;
		overflow: visible;
	}

	.box {
		width: 560px;
		margin: 0px auto;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background: linear-gradient(to bottom, #fff600, #fffcb3, #fffede, #f7f7eb, #fff);
		border-radius: 10px;
		box-shadow: 0 0 20.9px 3.1px rgba(4, 23, 39, 0.5);
		padding: 20px 30px;
		color: #000;

		.content {
			padding: 25px;
			width: 100%;
			.heading {
				font-size: 32px;
				font-family: ${FONTS.TERTIARY_BOLD};

				/* margin      : 10px 0 20px; */
				text-align: center;
			}

			.title {
				/* color       : rgba(255, 255, 255, .5); */
				font-size: 20px;
				text-align: center;
				font-family: ${FONTS.TERTIARY_SEMI_BOLD};
			}

			.avatar {
				width: 85px;
				height: 85px;

				border: 2px dashed #000;
				border-radius: 20px;
				cursor: pointer;
				margin: 10px;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;

				&.selected {
					border-style: solid;
					border-color: #000;
				}
			}

			.pre-defined {
				.pre-defined-images {
					flex-wrap: wrap;
					justify-content: space-evenly;
					margin: 10px auto;
					width: 80%;
				}
			}

			.separator {
				margin: 20px 0;
				position: relative;
				display: flex;
				.text {
					background: #000;
					display: inline;
					font-size: 20px;
					padding: 0 10px;
					/* position    : relative; */
					position: absolute;
					left: 45%;
					bottom: -13px;
					color: #fff;
					font-family: ${FONTS.TERTIARY_SEMI_BOLD};
				}

				.line {
					width: 60%;
					height: 2px;
					background: #000;

					position: absolute;
					top: 50%;
					left: 20%;
					right: 0;
				}
			}

			.user-section {
				margin-bottom: 15px;
			}
		}
		.btn-container {
			.button {
				background-color: black;
				color: white;
				width: 100%;
				padding: 0.7rem 2rem;
				border-radius: 10px;
				margin-top: 30px;
				border-color: #000;
				cursor: pointer;

				.login-font {
					font-family: ${FONTS.TERTIARY_SEMI_BOLD};
					font-size: 18px;
					font-weight: 600;
					text-align: center;
					margin: 0px;
					padding: 0px;
					text-transform: capitalize;
				}
			}
		}
	}

	.close-icon {
		cursor: pointer;
		position: absolute;
		/* right       : -20px;
        top         : -20px; */
		right: 20px;
		top: 20px;
		z-index: 1;

		img {
			width: 30px;
		}
	}

	.button-wrapper {
		position: absolute;
		bottom: -22px;
		text-align: center;

		.btn {
			min-width: 175px;
		}
	}
`;

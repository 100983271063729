import { ACTION_TYPES } from "./Actions";

const initState = {
	toast: {
		enable: false,
		message: "",
		QuizSound: true,
	},
};

const Reducer = (state = initState, action) => {
	switch (action.type) {
		case ACTION_TYPES.TOAST_MESSAGE_ENABLE:
			return {
				...state,
				toast: {
					enable: true,
					message: action.message,
				},
			};

		case ACTION_TYPES.TOAST_MESSAGE_DISABLE:
			return {
				...state,
				toast: {
					enable: false,
					message: "",
				},
			};

		case ACTION_TYPES.QUIZ_SOUND:
			return {
				...state,
				QuizSound: action.QuizSound,
			};

		default:
			return state;
	}
};

export default Reducer;

import React from "react";
import styled from "styled-components";
import { Drawer } from "@material-ui/core";
// CONSTANTS
import { COLORS, DRAWER_WIDTH } from "Styles/Constants";
// IMAGES
import { IMAGES } from "Helpers";

export const SidebarWrapper = styled(({ drawerOpen, science, maths, civics, english, history, economics, foreign, ...rest }) => <Drawer {...rest} />)`
	width: ${DRAWER_WIDTH}px;

	.backdrop-root {
		background-color: unset;
	}

	.drawer-paper {
		border-right: none;
		width: ${DRAWER_WIDTH}px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		box-shadow: -3px 0px 31.8px 11.2px #000;

		${(props) => (props.science ? `background-image: url(${IMAGES.SIDEBAR.SCIENCE_SIDEBAR_BACKGROUND});` : "")}
		${(props) => (props.maths ? `background-image: url(${IMAGES.SIDEBAR.MATH_SIDEBAR_BACKGROUND});` : "")}
		${(props) => (props.civics ? `background-image: url(${IMAGES.SIDEBAR.CIVIC_SIDEBAR_BACKGROUND});` : "")}
		${(props) => (props.english ? `background-image: url(${IMAGES.SIDEBAR.ENGLISH_SIDEBAR_BACKGROUND});` : "")}
		${(props) => (props.history ? `background-image: url(${IMAGES.SIDEBAR.HISTORY_SIDEBAR_BACKGROUND});` : "")}
		${(props) => (props.economics ? `background-image: url(${IMAGES.SIDEBAR.ECONOMICS_SIDEBAR_BACKGROUND});` : "")}
		${(props) => (props.foreign ? `background-image: url(${IMAGES.SIDEBAR.FOREIGN_SIDEBAR_BACKGROUND});` : "")}

		.arrow {
			filter: invert(100%) sepia(59%) saturate(783%) hue-rotate(196deg) brightness(126%) contrast(108%);
		}

		.menu-items {
			width: 86%;
			z-index: 1;
			margin-left: 7%;
			transition: 0.3s;
			padding-left: 20px;
			padding-right: 20px;

			&.active {
				border-radius: 10px;
				background-size: 100% 100%;
				background-repeat: no-repeat;

				${(props) => (props.science ? `background-image: url(${IMAGES.SIDEBAR.SCIENCE_OPTION_BACKGROUND});` : "")}
				${(props) => (props.maths ? `background-image: url(${IMAGES.SIDEBAR.MATH_OPTION_BACKGROUND});` : "")}
				${(props) => (props.civics ? `background-image: url(${IMAGES.SIDEBAR.CIVIC_OPTION_BACKGROUND});` : "")}
				${(props) => (props.english ? `background-image: url(${IMAGES.SIDEBAR.ENGLISH_OPTION_BACKGROUND});` : "")}
				${(props) => (props.history ? `background-image: url(${IMAGES.SIDEBAR.HISTORY_OPTION_BACKGROUND});` : "")}
				${(props) => (props.economics ? `background-image: url(${IMAGES.SIDEBAR.ECONOMICS_OPTION_BACKGROUND});` : "")}

				${(props) =>
					props.foreign
						? `
							background-image: url(${IMAGES.SIDEBAR.FOREIGN_OPTION_BACKGROUND});

							.menu-text {
								color: #fff;
							}
						`
						: ""}
			}

			&.profile-details {
				z-index: 1;
				padding: 25px;
				margin-top: 12px;
				overflow: hidden;
				text-align: center;
				position: relative;
				margin-bottom: 40px;
				flex-direction: column;
				justify-content: center;
				background-size: 100% 100%;
				background-repeat: no-repeat;

				${(props) => (props.science ? `background-image: url(${IMAGES.SIDEBAR.SCIENCE_PROFILE_BACKGROUND});` : "")}
				${(props) => (props.maths ? `background-image: url(${IMAGES.SIDEBAR.MATH_PROFILE_BACKGROUND});` : "")}
				${(props) => (props.civics ? `background-image: url(${IMAGES.SIDEBAR.CIVIC_PROFILE_BACKGROUND});` : "")}
				${(props) => (props.english ? `background-image: url(${IMAGES.SIDEBAR.ENGLISH_PROFILE_BACKGROUND});` : "")}
				${(props) => (props.history ? `background-image: url(${IMAGES.SIDEBAR.HISTORY_PROFILE_BACKGROUND});` : "")}
				${(props) => (props.economics ? `background-image: url(${IMAGES.SIDEBAR.ECONOMICS_PROFILE_BACKGROUND});` : "")}
				${(props) => (props.foreign ? `background-image: url(${IMAGES.SIDEBAR.FOREIGN_PROFILE_BACKGROUND});` : "")}

				.img-profile {
					width: 70px;
					height: 70px;
					margin: 0 auto;
					border-radius: 20px;
					background-size: cover;
					background-color: #0a173a;
					background-position: center;
					background-repeat: no-repeat;

					${(props) => (props.science ? `border: 3px solid ${COLORS.SECONDARY};` : "")}
					${(props) => (props.maths ? `border: 3px solid ${COLORS.SECONDARY};` : "")}
					${(props) => (props.civics ? `border: 3px solid ${COLORS.SECONDARY};` : "")}
					${(props) => (props.foreign ? `border: 3px solid #BDBDBD;` : "")}
				}

				.text {
					color: ${(props) => (props.foreign ? "#171717" : "#fff")};
					font-size: 20px;
					margin-top: 15px;
				}

				.score {
					color: #fff;
					margin-top: 15px;
					padding: 0px 12px;
					border-radius: 6px;
					background-color: ${(props) => (props.foreign ? "#373335" : "#fa7f0b")};
					box-shadow: ${(props) => (props.foreign ? "0px 3px 0 0 #000" : "0.3px 3px 0 0 #670101")};
				}
			}

			.icon {
				width: 24px;
				height: 24px;
				margin-right: 10px;
			}

			.menu-text {
				color: ${(props) => (props.foreign ? "color: rgba(2, 43, 49, 0.8)" : "#fff")};
			}

			&.settings {
				padding-left: 54px;
			}
		}
	}
`;

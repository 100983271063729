import styled, { keyframes } from "styled-components";
import { Dialog } from "@material-ui/core";
// CONSTANTS
import { QUESTION_TYPES } from "Helpers";
import { COLORS, responsive } from "Styles/Constants";
import { IMAGES } from "Helpers";

const loaderAnimation = keyframes`
    to {
		--border-angle: 1.36turn;
    }
`;

export const GamePageWrapper = styled.div`
	width: 100%;
	color: #fff;
	display: flex;
	overflow: hidden;
	min-height: 100vh;
	position: relative;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	.background-image {
		width: 100%;
		z-index: -1;
		height: 100%;
		position: absolute;
	}

	.play-logo-img {
		width: 500px;
	}

	.btn-container {
		padding: 4px;
		background: #a66f30;
		border-radius: 50px;
		margin: 30px auto 0px;
		transition: opacity 0.3s ease-in-out;
		animation: rounded 20s linear infinite;

		.done-btn {
			width: auto;
			z-index: 1;
			color: #3c270f;
			font-size: 28px;
			filter: blur(2px);
			padding: 5px 40px;
			border-radius: 35px;
			background-size: 400%;
			border: 1px solid #000;
			transition: opacity 0.3s ease-in-out;
			background: linear-gradient(to top, #edb779, #edb779, #cb8e48, #edb779);
			box-shadow: inset 0 0 9.8px 0.2px #000, inset 0px 3px 0 0 rgba(255, 255, 255, 0.41);

			&.enable {
				cursor: pointer;
				filter: blur(0px);
			}
		}
	}
`;

export const GamePlayWrapper = styled.div`
	width: 100%;
	color: #fff;
	display: flex;
	overflow: hidden;
	min-height: 100vh;
	position: relative;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	.background-image {
		width: 100%;
		z-index: -1;
		height: 100%;
		position: absolute;
	}

	.menu-container {
		width: 720px;
		height: 476px;
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.MATH.CONNECT4.LETS_PLAY});

		.title {
			top: 0;
			margin: 0px;
			margin-top: 2px;
			position: absolute;
			left: calc(50% - 21px);
		}

		.options-container {
			height: 400px;
			padding: 20px;
			margin: 20px 20px 0px 20px;

			.play-with-container {
				display: flex;
				margin-bottom: 15px;
				align-items: center;
				height: calc(100% - 150px);
				justify-content: space-between;

				.play-with-friends-container {
					width: 99%;
					height: 100%;
					display: flex;
					cursor: pointer;
					margin-right: 2%;
					border-radius: 10px;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					border: 1px solid #a2c90d;
					background-color: rgba(0, 0, 0, 0.25);

					&.active {
						background-color: rgba(115, 201, 58, 0.3);
						box-shadow: 0px 0px 10px 2px rgba(163, 201, 13, 1);
					}

					.image {
						width: 100px;
						height: 100px;
						border-radius: 50%;
						margin-bottom: 15px;
						background-size: 100% 100%;
						background-image: url(${IMAGES.MATH.CONNECT4.PLAY_WITH_FRIEND});

						&.active {
							box-shadow: 0px 0px 10px 2px rgba(163, 201, 13, 1);
						}
					}
				}

				.play-with-computer-container {
					width: 99%;
					height: 100%;
					display: flex;
					cursor: pointer;
					border-radius: 10px;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					border: 1px solid #a2c90d;
					background-color: rgba(0, 0, 0, 0.25);

					&.active {
						background-color: rgba(115, 201, 58, 0.3);
						box-shadow: 0px 0px 10px 2px rgba(163, 201, 13, 1);
					}

					.image {
						width: 100px;
						height: 100px;
						border-radius: 50%;
						margin-bottom: 15px;
						background-size: 100% 100%;
						background-image: url(${IMAGES.MATH.CONNECT4.PLAY_WITH_COMPUTER});

						&.active {
							box-shadow: 0px 0px 10px 2px rgba(163, 201, 13, 1);
						}
					}
				}
			}

			.color-selector-container {
				height: 60px;
				display: flex;
				padding: 0px 20px;
				border-radius: 10px;
				margin-bottom: 15px;
				align-items: center;
				border: 1px solid #a2c90d;
				justify-content: space-between;
				background-color: rgba(0, 0, 0, 0.25);

				.color-selector-buttons {
					display: flex;
					align-items: center;

					.red-btn {
						width: 40px;
						height: 40px;
						cursor: pointer;
						border-radius: 50%;
						margin-right: 10px;
						background-size: 90%;
						transition: all 0.2s;
						background-repeat: no-repeat;
						background-position: 50% 40%;
						border: 1.5px solid transparent;
						background-image: url(${IMAGES.MATH.CONNECT4.RED_PLAYER});

						&.active {
							border: 1.5px solid #97121c;
							box-shadow: 0px 0px 10px 2px rgba(153, 25, 33, 1), 0px 0px 10px 2px rgba(153, 25, 33, 1), 0px 0px 10px 2px rgba(153, 25, 33, 1);
						}
					}

					.yellow-btn {
						width: 40px;
						height: 40px;
						cursor: pointer;
						border-radius: 50%;
						margin-right: 10px;
						background-size: 90%;
						transition: all 0.2s;
						background-repeat: no-repeat;
						border: 1px solid transparent;
						background-position: 47.5% 35%;
						background-image: url(${IMAGES.MATH.CONNECT4.YELLOW_PLAYER});

						&.active {
							border: 1px solid #fff392;
							box-shadow: 0px 0px 10px 2px rgba(252, 224, 0, 1), 0px 0px 10px 2px rgba(252, 224, 0, 1), 0px 0px 10px 2px rgba(252, 224, 0, 1);
						}
					}

					.blue-btn {
						width: 40px;
						height: 40px;
						cursor: pointer;
						border-radius: 50%;
						background-size: 90%;
						transition: all 0.2s;
						background-repeat: no-repeat;
						border: 1px solid transparent;
						background-position: 47.5% 35%;
						background-image: url(${IMAGES.MATH.CONNECT4.BLUE_PLAYER});

						&.active {
							border: 1px solid #15adbb;
							box-shadow: 0px 0px 10px 2px rgba(21, 173, 187, 1), 0px 0px 10px 2px rgba(21, 173, 187, 1), 0px 0px 10px 2px rgba(21, 173, 187, 1);
						}
					}
				}
			}

			.sound-container {
				height: 60px;
				display: flex;
				padding: 0px 20px;
				border-radius: 10px;
				align-items: center;
				border: 1px solid #a2c90d;
				justify-content: space-between;
				background-color: rgba(0, 0, 0, 0.25);

				.switch {
					width: 70px;
					height: 35px;
					padding: 4px;
					cursor: pointer;
					transition: all 0.5s;
					background-size: 45%;
					border-radius: 28.9px;
					border: solid 1px #a2c90d;
					background-color: #1d2614;
					background-repeat: no-repeat;
					background-position: 8% 30%;
					background-image: url(${IMAGES.MATH.VOLUME});

					&.active {
						background-position: 95% 30%;
					}
				}
			}
		}

		.start-game-btn {
			bottom: 5px;
			height: 50px;
			display: flex;
			color: #3c270f;
			font-size: 20px;
			cursor: pointer;
			position: absolute;
			align-items: center;
			left: calc(50% - 58px);

			&.center {
				left: calc(50% - 17px);
			}

			.progress {
				margin-right: 5px;
				width: 30px !important;
				height: 30px !important;
				color: #3c270f !important;
			}
		}
	}

	.pairing-container {
		width: 100%;
		height: 100vh;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;

		.loader {
			color: #19270e;
		}

		.pairing-text {
			font-size: 20px;
		}
	}

	.main-game-play-container {
		width: 100%;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;

		.left {
			left: 15px;
		}

		.right {
			right: 15px;
		}

		.red-btn-container {
			bottom: 15px;
			padding: 20px;
			border-radius: 35%;
			position: absolute;
			transition: all 0.2s;
			border: 1px solid transparent;
			background-color: rgba(153, 25, 33, 0.4);

			&.active {
				box-shadow: 0px 0px 10px 2px rgba(153, 25, 33, 1), 0px 0px 10px 2px rgba(153, 25, 33, 1), 0px 0px 10px 2px rgba(153, 25, 33, 1);

				.red-btn {
					border: 1.5px solid #97121c;
					box-shadow: 0px 0px 10px 2px rgba(153, 25, 33, 1), 0px 0px 10px 2px rgba(153, 25, 33, 1), 0px 0px 10px 2px rgba(153, 25, 33, 1);
				}
			}

			.red-btn {
				width: 60px;
				height: 60px;
				border-radius: 50%;
				background-size: 90%;
				background-repeat: no-repeat;
				background-position: 45% 39%;
				border: 1.5px solid transparent;
				background-image: url(${IMAGES.MATH.CONNECT4.RED_PLAYER});
			}
		}

		.yellow-btn-container {
			bottom: 15px;
			padding: 20px;
			position: absolute;
			border-radius: 35%;
			transition: all 0.2s;
			border: 1px solid white;
			background-color: rgba(252, 224, 0, 0.4);

			&.active {
				box-shadow: 0px 0px 10px 2px rgba(252, 224, 0, 1), 0px 0px 10px 2px rgba(252, 224, 0, 1), 0px 0px 10px 2px rgba(252, 224, 0, 1);

				.yellow-btn {
					border: 1px solid #fff392;
					box-shadow: 0px 0px 10px 2px rgba(252, 224, 0, 1), 0px 0px 10px 2px rgba(252, 224, 0, 1), 0px 0px 10px 2px rgba(252, 224, 0, 1);
				}
			}

			.yellow-btn {
				width: 60px;
				height: 60px;
				border-radius: 50%;
				background-size: 90%;
				background-repeat: no-repeat;
				border: 1px solid transparent;
				background-position: 34.5% 40%;
				background-image: url(${IMAGES.MATH.CONNECT4.YELLOW_PLAYER});
			}
		}

		.blue-btn-container {
			bottom: 15px;
			padding: 20px;
			position: absolute;
			border-radius: 35%;
			transition: all 0.2s;
			border: 1px solid white;
			background-color: rgba(21, 173, 187, 0.4);

			&.active {
				box-shadow: 0px 0px 10px 2px rgba(21, 173, 187, 1), 0px 0px 10px 2px rgba(21, 173, 187, 1), 0px 0px 10px 2px rgba(21, 173, 187, 1);

				.blue-btn {
					border: 1px solid #fff392;
					box-shadow: 0px 0px 10px 2px rgba(21, 173, 187, 1), 0px 0px 10px 2px rgba(21, 173, 187, 1), 0px 0px 10px 2px rgba(21, 173, 187, 1);
				}
			}

			.blue-btn {
				width: 60px;
				height: 60px;
				border-radius: 50%;
				background-size: 90%;
				background-repeat: no-repeat;
				border: 1px solid transparent;
				background-position: 34.5% 40%;
				background-image: url(${IMAGES.MATH.CONNECT4.BLUE_PLAYER});
			}
		}

		.player-container {
			--border-angle: 0.64turn;

			@property --border-angle {
				syntax: "<angle>";
				inherits: true;
				initial-value: 0.64turn;
			}

			width: 30%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			.avatar-image {
				width: 70px;
				height: 70px;
				position: relative;
				border-radius: 12px;
				margin-bottom: -10px;
				background-size: 100%;
				background-position: center;
				background-repeat: no-repeat;
				animation: ${loaderAnimation} 180s linear infinite;
				/* background-image: conic-gradient(from var(--border-angle), transparent 20%, #d6b730); */

				&::before {
					content: "";
					width: 100%;
					height: 100%;
					position: absolute;
					background-size: 95%;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url(${IMAGES.MATH.CONNECT4.AVATAR});
				}
			}

			.avatar-profile-image {
				width: 70px;
				height: 70px;
				position: relative;
				border-radius: 12px;
				margin-bottom: -10px;
				background-size: 100%;
				background-position: center;
				background-repeat: no-repeat;
				animation: ${loaderAnimation} 180s linear infinite;
				border: 3px solid #d6b730;
				/* background-image: conic-gradient(from var(--border-angle), transparent 20%, #d6b730); */

				&::before {
					content: "";
					width: 100%;
					height: 100%;
					position: absolute;
					background-size: 95%;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url(${IMAGES.MATH.CONNECT4.AVATAR});
				}
			}

			.board-image {
				width: 150px;
				height: 150px;
				display: flex;
				position: relative;
				align-items: center;
				justify-content: center;
				background-size: 100% 100%;
				background-repeat: no-repeat;
				background-image: url(${IMAGES.MATH.CONNECT4.SMALL_BOARD});

				.name {
					top: 2px;
					position: absolute;
				}

				.score {
					font-size: 40px;
				}
			}
		}

		.board-main-container {
			display: flex;
			flex-direction: column;

			.board-container {
				width: 40%;
				height: 500px;
				display: flex;
				min-width: 540px;
				position: relative;
				justify-content: center;
				background-size: 100% 100%;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url(${IMAGES.MATH.CONNECT4.BOARD});

				.dashboard-points {
					top: 4px;
					font-size: 20px;
					position: absolute;
				}

				.column-container {
					gap: 20px;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;

					.row-container {
						gap: 20px;
						display: flex;
						align-items: center;
						justify-content: center;

						.box {
							width: 50px;
							height: 50px;
							cursor: pointer;
							border-radius: 50%;
							background-color: #1c3503;
							border: solid 1px rgba(57, 143, 15, 0.21);
							box-shadow: 0px 1px 0 0 rgba(255, 255, 255, 0.21), inset 0px 17px 7px 0 rgba(0, 0, 0, 0.35), inset 0px 3px 7px 0 rgba(0, 0, 0, 0.35);

							&.disabled {
								cursor: default;
							}

							.red {
								width: 50px;
								height: 50px;
								cursor: default;
								box-shadow: unset;
								border-radius: 50%;
								transition: fade 2s;
								background-size: 90%;
								animation-name: fade;
								animation-duration: 2s;
								background-repeat: no-repeat;
								background-position: 40% 40%;
								border: 1px solid rgba(153, 25, 33, 1);
								background-image: url(${IMAGES.MATH.CONNECT4.RED_PLAYER});
								box-shadow: 0px 0px 10px 2px rgba(153, 25, 33, 1), 0px 0px 10px 2px rgba(153, 25, 33, 1), 0px 0px 10px 2px rgba(153, 25, 33, 1);
							}

							.yellow {
								width: 50px;
								height: 50px;
								cursor: default;
								box-shadow: unset;
								border-radius: 50%;
								background-size: 90%;
								animation-name: fade;
								animation-duration: 2s;
								background-repeat: no-repeat;
								background-position: 30% 50%;
								border: 1px solid rgba(252, 224, 0, 1);
								background-image: url(${IMAGES.MATH.CONNECT4.YELLOW_PLAYER});
								box-shadow: 0px 0px 10px 2px rgba(252, 224, 0, 1), 0px 0px 10px 2px rgba(252, 224, 0, 1), 0px 0px 10px 2px rgba(252, 224, 0, 1);
							}

							.blue {
								width: 50px;
								height: 50px;
								cursor: default;
								box-shadow: unset;
								border-radius: 50%;
								background-size: 90%;
								animation-name: fade;
								animation-duration: 2s;
								background-repeat: no-repeat;
								background-position: 30% 50%;
								border: 1px solid rgba(21, 173, 187, 1);
								background-image: url(${IMAGES.MATH.CONNECT4.BLUE_PLAYER});
								box-shadow: 0px 0px 10px 2px rgba(21, 173, 187, 1), 0px 0px 10px 2px rgba(21, 173, 187, 1), 0px 0px 10px 2px rgba(21, 173, 187, 1);
							}

							@keyframes fade {
								0% {
									opacity: 0;
									position: absolute;
									margin-top: -520px;
								}
								100% {
									opacity: 1;
									margin-top: 0px;
								}
							}
						}
					}
				}
			}

			.computer-details {
				padding: 8px;
				margin-top: 2%;
				border-radius: 3px;
				border: solid 1px #000;
				background-color: #a67030;

				.computer-container {
					border-radius: 3px;
					background-color: #edb779;

					.text {
						color: #5b370c;
						font-size: 18px;
						text-align: center;
					}
				}
			}
		}
	}
`;

export const TimerModalWrapper = styled(Dialog)`
	.backdrop {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.dialog-paper {
		color: black;
		overflow: visible;
		background: transparent;
	}

	.paper-width {
		max-width: 900px;
	}

	.box-modal {
		width: 860px;
		height: 650px;
		display: flex;
		padding-top: 60px;
		position: relative;
		align-items: center;
		padding-bottom: 100px;
		flex-direction: column;
		justify-content: center;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.MATH.CONNECT4.BOARD});

		${responsive.LAPTOP`
			width: 680px;
            height: 480px;
			padding: 50px 0;
		`}

		.timer-text {
			top: 7px;
			color: white;
			font-size: 20px;
			position: absolute;
			left: calc(50% - 45px);

			${responsive.LAPTOP`
				top: 5px;
				font-size: 18px;
			`}
		}

		.top-box-image {
			width: 80px;
			height: 80px;
			border-radius: 50%;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(${IMAGES.MATH.CONNECT4.TOP_BOX});
			box-shadow: 0px 3px 5px 3px rgb(0 0 0 / 50%);

			${responsive.LAPTOP`
				width: 70px;
            	height: 70px;
			`}
		}

		.question-text {
			color: #fff;
			font-size: 30px;
			text-align: center;
			margin: ${(props) => (props.type === QUESTION_TYPES.TEXT_FIELD ? "30px 30px 0px" : props.type === QUESTION_TYPES.RANKING_CHOICE ? "20px 30px 20px" : "30px 30px 0px")};
		}

		.helper-text {
			color: #fff;
			font-size: 18px;
			text-align: center;
		}

		.text-field-container {
			width: 80%;
			margin-bottom: 20px;

			.text-field {
				border: solid 1px #fff;

				.MuiInputBase-root {
					color: #fff;
					background-color: #142404;
				}

				fieldset {
					border: none;
				}
			}
		}

		.option-container {
			gap: 40px;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			padding: 10px 70px;
			margin-bottom: 20px;
			align-items: center;
			justify-content: space-between;

			.option {
				width: 45%;
				color: white;
				display: flex;
				cursor: pointer;
				padding: 15px 20px;
				text-align: center;
				border: solid 1px #fff;
				background-color: #142404;

				&.green {
					background-color: #428008;
				}

				&.red {
					background-color: #8f2c0d;
				}

				span {
					width: 100%;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}

		.ranking-container {
			gap: 10px;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			padding: 10px 70px;
			margin-bottom: 10px;
			align-items: center;
			flex-direction: column;

			div {
				color: white;
				min-width: 45%;
				cursor: pointer;
				padding: 8px 15px;
				user-select: none;
				text-align: center;
				border: solid 1px #fff;
				background-color: #142404;
			}

			.is-dragging {
				opacity: 0.5;
				border-color: ${COLORS.SECONDARY};
			}
		}

		.done-btn {
			z-index: 1;
			width: auto;
			color: #3c270f;
			font-size: 25px;
			margin-top: 10px;
			filter: blur(2px);
			padding: 2px 40px;
			border-radius: 35px;
			background-size: 400%;
			border: 1px solid #000;
			transition: opacity 0.3s ease-in-out;
			background: linear-gradient(to top, #edb779, #edb779, #cb8e48, #edb779);
			box-shadow: inset 0 0 9.8px 0.2px #000, inset 0px 3px 0 0 rgba(255, 255, 255, 0.41);

			&.enable {
				cursor: pointer;
				filter: blur(0px);
			}
		}

		.progress {
			margin-right: 10px;
			width: 30px !important;
			height: 30px !important;
			color: #3c270f !important;
		}
	}
`;

export const SolutionModalWrapper = styled(Dialog)`
	.backdrop {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.dialog-paper {
		color: black;
		overflow: visible;
		background: transparent;
	}

	.paper-width {
		max-width: 900px;
	}

	.box-modal {
		width: 860px;
		height: 650px;
		display: flex;
		padding-top: 40px;
		position: relative;
		align-items: center;
		padding-bottom: 50px;
		flex-direction: column;
		justify-content: center;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.MATH.CONNECT4.BOARD});

		${responsive.LAPTOP`
			width: 680px;
            height: 480px;
			padding: 50px 0;
		`}

		.timer-text {
			top: 7px;
			color: white;
			font-size: 20px;
			position: absolute;
			left: calc(50% - 45px);

			${responsive.LAPTOP`
				top: 5px;
				font-size: 18px;
			`}
		}

		.top-box-image {
			width: 80px;
			height: 80px;
			border-radius: 50%;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(${IMAGES.MATH.CONNECT4.TOP_BOX});
			box-shadow: 0px 3px 5px 3px rgb(0 0 0 / 50%);

			${responsive.LAPTOP`
				width: 70px;
            	height: 70px;
			`}
		}

		.content-container {
			margin: 15px 35px 0px;
			display: flex;
			flex-direction: column;
			align-items: center;
			overflow: auto;

			&::-webkit-scrollbar {
				display: none;
			}

			.question {
				color: #fff;
				align-self: flex-start;
				font-size: 20px;

				.question-header {
					font-size: 24px;
					font-weight: 700;
				}
			}

			.solution-header {
				color: #fff;
				margin-top: 15px;
				font-weight: 700;
				font-size: 24px;
			}

			.question-text {
				color: #fff;
				font-size: 20px;
			}
		}

		.done-btn {
			z-index: 1;
			width: auto;
			color: #3c270f;
			font-size: 25px;
			margin-top: 10px;
			filter: blur(2px);
			padding: 2px 40px;
			border-radius: 35px;
			background-size: 400%;
			border: 1px solid #000;
			transition: opacity 0.3s ease-in-out;
			background: linear-gradient(to top, #edb779, #edb779, #cb8e48, #edb779);
			box-shadow: inset 0 0 9.8px 0.2px #000, inset 0px 3px 0 0 rgba(255, 255, 255, 0.41);

			&.enable {
				cursor: pointer;
				filter: blur(0px);
			}
		}

		.progress {
			margin-right: 10px;
			width: 30px !important;
			height: 30px !important;
			color: #3c270f !important;
		}
	}
`;

export const FinalModalWrapper = styled(Dialog)`
	.MuiBackdrop-root {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.MuiDialog-paper {
		color: black;
		overflow: visible;
		position: inherit;
		background: transparent;
	}

	.MuiDialog-paperWidthSm {
		max-width: 900px;
	}

	.confetti {
		width: 100%;
		height: 100%;
		position: absolute;
	}

	.box-modal {
		width: 560px;
		height: 458px;
		display: flex;
		padding-top: 30px;
		position: relative;
		align-items: center;
		padding-bottom: 80px;
		flex-direction: column;
		justify-content: center;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.MATH.CONNECT4.FINAL_BOARD});

		&.win {
			height: 672px;
			justify-content: unset;
			background-image: url(${IMAGES.MATH.CONNECT4.CONGRATULATIONS_BACKGROUND});

			${responsive.DESKTOP`
				width: 468px;
				height: 562px;
			`}
		}

		&.lose {
			width: 600px;
			height: 600px;
			padding-top: 15px;
			justify-content: unset;
			background-image: url(${IMAGES.MATH.CONNECT4.BETTER_LUCK_BACKGROUND});

			${responsive.DESKTOP`
				width: 520px;
    			height: 520px;
			`}
		}

		.win-container {
			width: 100%;
			display: flex;
			margin-top: 95px;
			align-items: center;
			flex-direction: column;

			${responsive.DESKTOP`
				margin-top: 75px;
			`}

			.title {
				color: #fff;
				font-size: 30px;
				font-weight: bold;
				margin-left: -15px;
				text-align: center;

				${responsive.DESKTOP`
					font-size: 25px;
				`}
			}

			.you-won {
				color: #fff;
				font-size: 26px;
				margin-top: 80px;
				font-weight: bold;
				text-align: center;

				${responsive.DESKTOP`
					font-size: 20px;
				`}
			}

			.score-board-container {
				width: 70%;
				display: flex;
				margin-top: 20px;
				align-items: center;
				justify-content: space-between;

				${responsive.DESKTOP`
					width: 65%;
				`}

				.vs-text {
					color: #fff;
					font-size: 26px;

					${responsive.DESKTOP`
						font-size: 20px;
					`}
				}

				.score-board {
					width: 118px;
					height: 130px;
					display: flex;
					align-items: center;
					flex-direction: column;
					background-size: contain;
					background-repeat: no-repeat;
					background-image: url(${IMAGES.MATH.CONNECT4.MATCH_COMPLETE});

					${responsive.DESKTOP`
						width: 98px;
    					height: 108px;
					`}

					.player {
						color: #fff;
						margin-top: 3px;
						font-weight: bold;

						${responsive.DESKTOP`
							font-size: 14px;
						`}
					}

					.score {
						font-size: 30px;
						margin-top: 30px;

						${responsive.DESKTOP`
							font-size: 26px;
    						margin-top: 24px;
						`}

						&.green {
							color: #67e600;
						}

						&.red {
							color: #ff0010;
						}
					}
				}
			}

			.wrapped-up-container {
				width: 70%;
				height: 130px;
				display: flex;
				margin-top: 20px;
				align-items: center;
				flex-direction: column;
				justify-content: center;
				background-size: contain;
				background-repeat: no-repeat;
				background-image: url(${IMAGES.MATH.CONNECT4.WRAPPED_UP});

				${responsive.DESKTOP`
					width: 65%;
					height: 102px;
				`}

				span {
					color: #fbc300;
					font-size: 18px;
					font-weight: bold;
					text-align: center;

					${responsive.DESKTOP`
						font-size: 15px;
					`}
				}

				.already-played-text {
					width: 90%;
					color: white;
					font-size: 12px;
				}
			}

			.dashboard-btn {
				height: 58px;
				width: 194px;
				display: flex;
				color: #3c270f;
				cursor: pointer;
				font-size: 22px;
				margin-top: 37px;
				border-radius: 50px;
				align-items: center;
				justify-content: center;

				${responsive.DESKTOP`
					height: 55px;
    				width: 175px;
					font-size: 18px;
					margin-top: 18px;
				`}
			}
		}

		.lose-container {
			width: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;

			.title {
				color: #fff;
				font-size: 30px;
				font-weight: bold;
				margin-left: -20px;
				text-align: center;

				${responsive.DESKTOP`
					font-size: 25px;
				`}
			}

			.you-got {
				color: #fff;
				font-size: 26px;
				margin-top: 80px;
				font-weight: bold;
				text-align: center;

				${responsive.DESKTOP`
					font-size: 22px;
   					margin-top: 62px;
				`}
			}

			.score-board-container {
				width: 70%;
				display: flex;
				margin-top: 58px;
				align-items: center;
				justify-content: space-between;

				${responsive.DESKTOP`
				    width: 65%;
					margin-top: 35px;
				`}

				.vs-text {
					color: #fff;
					font-size: 26px;

					${responsive.DESKTOP`
						font-size: 20px;
					`}
				}

				.score-board {
					width: 118px;
					height: 130px;
					display: flex;
					align-items: center;
					flex-direction: column;
					background-size: contain;
					background-repeat: no-repeat;
					background-image: url(${IMAGES.MATH.CONNECT4.MATCH_COMPLETE});

					${responsive.DESKTOP`
						width: 100px;
    					height: 110px;
					`}

					.player {
						color: #fff;
						margin-top: 3px;
						font-weight: bold;
					}

					.score {
						font-size: 30px;
						margin-top: 30px;

						${responsive.DESKTOP`
							font-size: 24px;
    						margin-top: 24px;
						`}

						&.green {
							color: #67e600;
						}

						&.red {
							color: #ff0010;
						}
					}
				}
			}

			.wrapped-up-container {
				width: 70%;
				height: 140px;
				display: flex;
				margin-top: 20px;
				align-items: center;
				flex-direction: column;
				justify-content: center;
				background-size: contain;
				background-repeat: no-repeat;
				background-image: url(${IMAGES.MATH.CONNECT4.WRAPPED_UP});

				${responsive.DESKTOP`
					width: 65%;
					height: 112px;
				`}

				span {
					color: #ff0010;
					font-size: 18px;
					font-weight: bold;

					${responsive.DESKTOP`
						font-size: 14px;
					`}
				}

				.click {
					color: #fff;
					cursor: pointer;
					font-size: 18px;
					font-weight: bold;

					${responsive.DESKTOP`
						font-size: 14px;
					`}
				}
			}

			.dashboard-btn {
				height: 58px;
				width: 194px;
				display: flex;
				color: #3c270f;
				cursor: pointer;
				font-size: 22px;
				margin-top: 25px;
				border-radius: 50px;
				align-items: center;
				justify-content: center;

				${responsive.DESKTOP`
				    height: 56px;
				    width: 180px;
					font-size: 18px;
    				margin-top: 50px;
				`}
			}
		}

		.congratulations-test {
			width: 90%;
			color: white;
			font-size: 50px;
			margin-top: 25px;
			text-align: center;
		}

		.congratulations-description {
			width: 80%;
			color: white;
			font-size: 16px;
			margin-top: 25px;
			text-align: center;
		}

		.ok-text {
			width: 120px;
			bottom: 20px;
			height: 30px;
			cursor: pointer;
			position: absolute;
		}
	}
`;

export const DialogWrapper = styled(Dialog)`
	.backdrop {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.dialog-paper {
		color: black;
		overflow: visible;
		background: transparent;
	}

	.paper-width {
		max-width: 900px;
	}

	.box-modal {
		width: 896px;
		height: 600px;
		position: relative;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.MATH.CONNECT4.LETS_PLAY});

		${responsive.LAPTOP`
			width: 680px;
            height: 480px;
		`}

		.title-container {
			.sub-title {
				color: #fff;
				font-size: 18px;
				margin-top: 5px;

				${responsive.LAPTOP`
					font-size: 14px;
				`}
			}

			.icon-container {
				top: 35px;
				z-index: 3;
				right: 35px;
				cursor: pointer;
				position: absolute;

				.icon {
					color: #a66f30;
					font-size: 35px;
				}
			}
		}

		.content-wrapper {
			padding: 15px;
			height: 460px;
			position: relative;

			${responsive.LAPTOP`
				height: 360px;
			`}

			.scroll-bar {
				.thumb-vertical {
					margin-left: -15px;
					background-color: #275e00;
				}
			}

			.play-img-container {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;

				.img {
					width: 580px;
					margin-top: 40px;
					object-fit: contain;
				}
			}

			.content {
				width: 79%;
				color: #fff;
				margin: 6% auto 0px;
			}

			.attach-file {
				display: flex;
				justify-content: center;

				.icon {
					color: #fff;
				}

				.file {
					margin: 0px 10px;
					font-size: 18px;
					cursor: pointer;
					text-decoration: underline;
					color: #fff;
				}

				.pdf-loader {
					width: 20px !important;
					height: 20px !important;
					color: #275e00;
					margin-top: 1px;
				}
			}
			.progress {
				top: 36%;
				left: 0;
				right: 0;
				color: #000;
				position: absolute;
			}

			.type-switcher {
				.divider {
					width: 50%;
					height: 2px;
					opacity: 0.5;
					margin: 5px auto;
					background-color: #fff;
				}

				.text {
					color: #fff;
					cursor: pointer;
					transition: 0.3s;
					margin: 0px 0px 22px 0px;
				}
			}
		}

		.play {
			color: #3c270f;
			font-size: 26px;
			cursor: pointer;
			position: absolute;
			bottom: 0;
			margin-bottom: 20px;

			${responsive.LAPTOP`
				font-size: 20px;
			`}
		}
	}
`;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
// COMPONENTS
import { CircularProgress } from "@material-ui/core";
// STYLE
import { DialogWrapper } from "../../CrossWord/CrossWord.style";
import CancelIcon from "@material-ui/icons/Cancel";
import { Api, URL_ENGLISH_DASHBOARD } from "Helpers";
import moment from "moment";
import { useSelector } from "react-redux";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

const TYPE = {
	HOW_TO_PLAY: "howToPlay",
	DID_YOU_KNOW: "didYouKnow",
};

const DEFAULT_TYPE = TYPE.DID_YOU_KNOW;
const NEXT_TYPE = TYPE.HOW_TO_PLAY;

export default function HowToPlay(props) {
	const [content, setContent] = useState({}),
		[type, setType] = useState(DEFAULT_TYPE),
		[isLoading, setLoading] = useState(false),
		[pdfLoader, setPdfLoader] = useState(false);
	const navigate = useNavigate();
	const profileInfo = useSelector((state) => state.Auth.profileInfo);
	// eslint-disable-next-line
	useEffect(() => {
		fetchData();
	}, []);

	async function fetchData() {
		setLoading(true);
		let data = new FormData();
		data.append("moduleId", props.data.id);
		data.append("subjectId", props?.subjectId);
		let response = await new Api().post("staticPage/game", { data });
		setContent(response.data);
		setLoading(false);
	}

	function proceed() {
		if (type === DEFAULT_TYPE) setType(NEXT_TYPE);
		else {
			closeModal();
		}
	}
	const handlePdf = () => {
		setPdfLoader(true);
		let pdfUrl,
			attachedText = "",
			moduleName = props?.data?.id.replace(/ /g, "_").toUpperCase();
		if (type === TYPE.DID_YOU_KNOW) {
			pdfUrl = content?.didYouKnowFile;
			attachedText = `${moduleName}_DID_YOU_KNOW`;
		} else {
			pdfUrl = content?.howToPlayFile;
			attachedText = `${moduleName}_HOW_TO_PLAY`;
		}

		fetch(pdfUrl).then((response) => {
			response.blob().then((blob) => {
				// Creating new object of PDF file
				const fileURL = window.URL.createObjectURL(blob);
				// Setting various property values
				let alink = document.createElement("a");
				alink.href = fileURL;
				alink.download = `${attachedText}.pdf`;
				alink.click();
				setPdfLoader(false);
			});
		});
	};

	function closeModal() {
		if (props.data.url) {
			let data = props?.pursuitDetails;
			if (props?.sidebar && props.sidebar) {
				data = {
					subjectId: props?.subjectId,
				};
			}
			const dateTime = moment().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
			if (props?.schoolClassId && props.schoolClassId.length) {
				const data = {
					type: "LOGIN",
					studentId: profileInfo?.id,
					schoolClassTestId: props.schoolClassId,
					dateTime: dateTime,
				};
				if (props?.data?.subTitle === "CROSSWORD") {
					data.moduleName = "CROSSWORD";
				}
				if (data.moduleName) new Api().post("student/clock-in-clock-out", { data });
			}
			navigate(URL_ENGLISH_DASHBOARD + "/" + props.data.url, {
				state: { schoolClassTestId: props?.schoolClassId },
			});
			handleClose();
		} else {
			handleClose();
		}
	}

	const handleClose = () => props.handleClose({ url: props.data.url });

	const ThumbVertical = (props) => <div {...props} className="thumb-vertical" />;

	return (
		<DialogWrapper onClose={handleClose} open={props.open} PaperProps={{ elevation: 0 }} classes={{ root: "background-root", paper: "paper", paperWidthSm: "paper-width" }}>
			<div className="box-modal text-center">
				<div className="title-container">
					<p className="sub-title">{type === TYPE.HOW_TO_PLAY ? "How to play ?" : "Did you know ?"}</p>
					<div className="icon-container" onClick={handleClose}>
						<CancelIcon className="icon" />
					</div>
				</div>
				<div className="content-wrapper">
					{isLoading && (
						<div className="progress">
							<CircularProgress size={25} />
						</div>
					)}
					<Scrollbars className={"scroll-bar"} renderThumbVertical={ThumbVertical}>
						<div className="content" dangerouslySetInnerHTML={{ __html: content[type] }} />
						{content?.didYouKnowFile && type === TYPE.DID_YOU_KNOW && (
							<div className="attach-file">
								<PictureAsPdfIcon className="icon" />
								<div className="file" onClick={handlePdf}>
									Attachment
								</div>
								{pdfLoader && (
									<div>
										<CircularProgress className="pdf-loader" />
									</div>
								)}
							</div>
						)}

						{content?.howToPlayFile && type === TYPE.HOW_TO_PLAY && (
							<div className="attach-file">
								<PictureAsPdfIcon className="icon" />
								<div className="file" onClick={handlePdf}>
									Attachment
								</div>
								{pdfLoader && (
									<div>
										<CircularProgress className="pdf-loader" />
									</div>
								)}
							</div>
						)}
					</Scrollbars>

					<div className={`type-switcher ${type === DEFAULT_TYPE ? "visibility-none" : ""}`}>
						<div className="divider" />

						{type === TYPE.HOW_TO_PLAY && (
							<p className="text" onClick={() => setType(TYPE.DID_YOU_KNOW)}>
								Back to Review
							</p>
						)}
					</div>
				</div>
				<div className="play-btn">
					<div className="play" onClick={proceed}>
						{type === DEFAULT_TYPE ? "How to Play" : "Lets Play"}
					</div>
				</div>
			</div>
		</DialogWrapper>
	);
}

import React, { useState, useEffect } from "react";
import useSound from "use-sound";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, CircularProgress } from "@material-ui/core";
// REDUX
import { quizSound, showToast } from "Redux/General/Actions";
import { updateTotalScore, schoolClassTestID } from "Redux/Auth/Actions";
// MODALS
import HowToPlay from "Components/ForeignLanguage/RapidFire/Modal/HowToPlay";
import { HowToPlayModal as QuizHowToPlay } from "Components/ForeignLanguage/Quiz/Modal/HowToPlayModal";

// STYLES
import { PageWrapper, GridContainer, ScoreboardWrapper, ProgressWrapper } from "./Dashboard.style";
// HELPERS
import { random, Api, URL_RAPID_FIRE, FOREIGN_MODULE_ID, URL_START_QUIZ, IMAGES } from "Helpers";
// SOUNDS
import DrawerSound from "Assets/Sounds/switch-off.mp3";
// IMAGES
import LazyImage from "Components/Commons/LazyImage/LazyImage";

const tooltipStyles = { tooltip: { padding: "8px 18px", borderRadius: "10px", marginLeft: "25px", marginTop: "25px", fontSize: "13px", color: "white" } };

const sectionConst = [
	{ name: "Rapid-fire", image: IMAGES.FOREIGN.RAPID_FIRE_CARD, value: 0, subTitle: "RAPID FIRE", url: URL_RAPID_FIRE, id: FOREIGN_MODULE_ID.RAPID_FIRE, isLive: true, isTestAvailable: false },
	{ name: "Quizardy", image: IMAGES.FOREIGN.QUIZ_CARD, value: 100, subTitle: "Quizardy", url: URL_START_QUIZ, id: FOREIGN_MODULE_ID.QUIZ, isLive: true, isTestAvailable: false },
];

const Dashboard = () => {
	const dispatch = useDispatch();
	const [play] = useSound(DrawerSound, { volume: 0.2 });

	const { gamesStatus } = useSelector((state) => state.GamesData);
	const subjects = useSelector((state) => state.Auth.subjectAccessList);

	const subjectId = subjects?.filter((item) => item.subject === "Foreign Language")[0].subjectId;

	const [sections, setSections] = useState([...sectionConst]);
	const [howToPlay, setHowToPlay] = useState({ open: false, subTitle: "", url: null });
	const [quizHowToPlay, setQuizHowToPlay] = useState({ open: false, subTitle: "", url: null });
	const [scoreboard, setScoreboard] = useState([]);
	const [isStudent, setStudent] = useState(false);
	const [testAvailable, setTestStatus] = useState(false);
	const [status, setStatus] = useState(0);
	const [schoolId, setSchoolId] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		dispatch(quizSound(true));

		setTimeout(() => {
			let newValues = [...sections];

			newValues.forEach((section) => {
				if (!section.isLive) section.value = random(30, 100);
			});

			setSections(newValues);
		}, 1500);
	}, []); // eslint-disable-line

	useEffect(() => {
		if (Object.keys(gamesStatus).length) getStatus();
	}, [gamesStatus]); // eslint-disable-line

	async function getStatus() {
		try {
			setLoading(true);

			const response = await new Api().get("general/studentStatus", { params: { subjectId: subjectId } });

			const percentValue = (sub, total) => ((sub * 100) / total).toFixed(0);

			const array = response.data.scoreDashBoardList.map((item, index) => ({ ...item }));

			// SET PROGRESS
			const newValues = [...sections];

			newValues.forEach((section) => {
				switch (section.id) {
					case FOREIGN_MODULE_ID.RAPID_FIRE:
						if (response?.data?.rapidFireForeignLanguage.isRapidFireForeignLanguageComplete) section.value = parseInt(100);
						else {
							let value = percentValue(
								response?.data?.rapidFireForeignLanguage.totalRightAnswerRapidFireForeignLanguage,
								response?.data?.rapidFireForeignLanguage.totalQuestionRapidFireForeignLanguage
							);
							section.value = !isNaN(value) ? value : 0;
						}

						if (gamesStatus?.isRapidFireForeignLanguageActive) section.isTestAvailable = true;

						break;
					case FOREIGN_MODULE_ID.QUIZ:
						if (response?.data?.foreignLanguageQuizBranch?.foreignLanguageIsQuizComplete) section.value = parseInt(100);
						else {
							let value = percentValue(
								response?.data?.foreignLanguageQuizBranch?.foreignLanguageTotalRightAnswer,
								response?.data?.foreignLanguageQuizBranch?.foreignLanguageTotalQuestion
							);
							section.value = !isNaN(value) ? value : 0;
						}
						if (gamesStatus?.isQuizForeignLanguageActive) section.isTestAvailable = true;
						break;
					default:
						section.value = 0;
						break;
				}
			});

			dispatch(updateTotalScore(response.data.total));
			dispatch(schoolClassTestID(response?.data?.schoolClassTestId));

			setScoreboard(array);
			setSections(newValues);
			setTestStatus(response?.data?.isLiveTestAvailable);
			setStudent(response?.data?.isStudentLogin);
			setSchoolId(response?.data?.schoolClassTestId);
			setStatus(response.data.status);

			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	}

	const toggleHowToPlay = (section) => {
		if (!testAvailable && isStudent) {
			dispatch(showToast("Assessment is not available. Try after some time or contact to your respective class teacher | school."));
			return;
		}

		if (section?.name === "Quizardy") {
			handleQuizHowToPlay(section);
			return;
		}

		let newState = { ...howToPlay };

		if (!howToPlay.open) {
			newState.url = section.url;
			newState.subTitle = section.subTitle;
			newState.id = section.id;
		}

		newState.open = !howToPlay.open;

		if (status !== 0) {
			setHowToPlay(newState);
		} else {
			dispatch(showToast("No Active Assessments found."));
		}
	};

	const handleQuizHowToPlay = (section) => {
		let newState = { ...quizHowToPlay };

		if (!quizHowToPlay.open) {
			newState.url = section.url;
			newState.subTitle = section.subTitle;
			newState.id = section.id;
		}

		newState.open = !quizHowToPlay.open;
		setQuizHowToPlay(newState);
	};

	const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

	const gameListUi = () => {
		return (
			<GridContainer className="flex f-v-center f-h-center game-card overlay-effect">
				{sections.map((section, index) =>
					section.isLive ? (
						<div className="box" key={section.name}>
							<div className={`box-content `} onClick={() => !loading && section.isTestAvailable && toggleHowToPlay(section)}>
								<div className={`img-container ${!section.isTestAvailable && "disabled"}`} style={{ backgroundImage: `url(${section.image})` }} onMouseOver={play}></div>

								<div className="progress-container full-width" onMouseOver={(e) => e.preventDefault()}>
									<Progress value={section.value} />
								</div>
							</div>
						</div>
					) : (
						<CustomTooltip title="Coming soon!!!">
							<div className="box" key={section.name}>
								<div className={`box-content `}>
									<div className="img-container" style={{ backgroundImage: `url(${section.image})` }} onMouseOver={play}></div>
								</div>
							</div>
						</CustomTooltip>
					)
				)}
			</GridContainer>
		);
	};

	return (
		<>
			<PageWrapper className="flex f-v-center f-h-center">
				<LazyImage compressedImage={IMAGES.FOREIGN.COMPRESSED_COMMON_BACKGROUND} image={IMAGES.FOREIGN.COMMON_BACKGROUND} wrapperProps={{ className: "background-image" }} />
				<div className="content text-center">
					{loading ? (
						<div className="overlay-stackrank">
							{gameListUi()}
							{!loading && (
								<div className="display-circular-progress">
									<CircularProgress />
								</div>
							)}
						</div>
					) : (
						<>{gameListUi()}</>
					)}
				</div>

				<Scoreboard peoples={scoreboard} />

				{howToPlay.open && <HowToPlay open={howToPlay.open} handleClose={toggleHowToPlay} data={howToPlay} subjectId={subjectId} schoolId={schoolId} />}
				{quizHowToPlay.open && <QuizHowToPlay open={quizHowToPlay.open} handleClose={handleQuizHowToPlay} data={quizHowToPlay} subjectId={subjectId} schoolId={schoolId} />}
			</PageWrapper>
		</>
	);
};

function Progress(props) {
	return (
		<ProgressWrapper {...props}>
			<div className="outer">
				<div className="active" />
			</div>
			<div className="text">{props.value}%</div>
		</ProgressWrapper>
	);
}

function Scoreboard(props) {
	return (
		<ScoreboardWrapper>
			<div className="people-cards">
				{props?.peoples?.map((person) => (
					<div className="person flex f-v-center f-h-center" key={person.winnerPlace}>
						<div className="content text-center">
							<div className="image" style={{ backgroundImage: `url(${person.profileImage})` }}></div>
							<div className="name elipsis">{person.firstName}</div>
							<div className="score">{person.totalScore}</div>
						</div>
					</div>
				))}
			</div>
		</ScoreboardWrapper>
	);
}

export default Dashboard;

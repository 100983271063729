import styled from "styled-components";
import { Dialog } from "@material-ui/core";
// STYLES
import { responsive } from "Styles/Constants";
// IMAGES
import { IMAGES } from "Helpers";

export const DialogWrapper = styled(Dialog)`
	.MuiBackdrop-root {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.MuiDialog-paper {
		background: transparent;
		color: #fff;
		overflow: visible;
	}

	.MuiDialog-paperWidthSm {
		max-width: 900px;
	}

	.MuiDialog-paperScrollPaper {
		max-height: unset;
	}

	.box-modal {
		width: 935px;
		height: 750px;
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.HISTORY.HOW_TO_PLAY});

		${responsive.DISPLAY`
            width: 785px;
            height: 630px;
		`}

		${responsive.DESKTOP`
            width: 660px;
            height: 530px;
		`}

		.title-container {
			.sub-title {
				font-size: 22px;
				margin-top: 28px;

				${responsive.DISPLAY`
                    font-size: 20px;
				    margin-top: 24px;
                `}

				${responsive.DESKTOP`
                    font-size: 18px;
                    margin-top: 20px;
                `}
			}
		}

		.content-wrapper {
			height: 460px;
			margin-top: 70px;
			padding: 0px 80px;
			position: relative;

			${responsive.DISPLAY`
                height: 395px;
                font-size: 14px;
                margin-top: 45px;
            `}

			${responsive.DESKTOP`
                height: 330px;
                font-size: 12px;
                margin-top: 40px;
            `}

			.content {
				width: 90%;
				margin: 2% auto 0px;
			}

			.attach-file {
				display: flex;
				justify-content: center;

				.icon {
					color: #fff;
				}

				.file {
					margin: 0px 10px;
					font-size: 18px;
					cursor: pointer;
					text-decoration: underline;
					color: #fff;
				}

				.pdf-loader {
					width: 20px !important;
					height: 20px !important;
					color: #fff;
					margin-top: 1px;
				}
			}

			.progress {
				position: absolute;
				top: 36%;
				left: 0;
				right: 0;
			}

			.type-switcher {
				.divider {
					background-color: #fff;
					height: 2px;
					width: 50%;
					margin: 5px auto;
					opacity: 0.5;
				}

				.text {
					cursor: pointer;
					transition: 0.3s;
					padding: 0px;
					margin: 0px;
					&:hover {
						color: #fff;
					}
				}
			}
		}

		.play {
			display: flex;
			font-size: 25px;
			margin-top: 90px;
			align-items: center;
			justify-content: center;

			${responsive.DISPLAY`
                font-size: 22px;
                margin-top: 80px;
            `}

			${responsive.DESKTOP`
                font-size: 18px;
                margin-top: 65px;
            `}

			.play-btn {
				cursor: pointer;
			}
		}
	}
`;

import React from "react";
import LazyLoader from "@loadable/component";
// HELPERS
import {
	URL_CONSTITUTION,
	URL_JUDICIAL_DASHBOARD,
	URL_JUDICIAL_SPIN,
	URL_PROFILE,
	URL_CHANGE_PWD,
	URL_BILL_OR_NO_BILL,
	URL_BILL_BECOME_LAW,
	URL_ESCAPE_ROOM,
	URL_JEO_PARDY,
	URL_JEO_PARDY_ANSWER,
	URL_FINAL_MODULE,
	URL_ABOUT_US,
	URL_TERMS_AND_CONDITIONS,
	URL_PRIVACY_POLICY,
	URL_RAPID_FIRE,
	URL_HISTORY_DASHBOARD,
	URL_SCIENCE_DASHBOARD,
	URL_PERIODIC_TABLE,
	URL_ENGLISH_DASHBOARD,
	URL_CROSSWORD,
	URL_MATHS_DASHBOARD,
	URL_CONNECT4,
	URL_CONNECT4_GAME,
	URL_MATHAHTZEE,
	URL_MATHAHTZEE_GAME,
	URL_ECONOMIC_DASHBOARD,
	URL_PURSUIT,
	URL_PURSUIT_GAME,
	URL_CHASER_GAME,
	URL_PURSUIT_FINAL_GAME,
	URL_WEAKEST_LINK_GAME,
	URL_PRESENT_OR_PAST_GAME,
	URL_QUIZ,
	URL_QUIZ_ANSWER,
	URL_START_QUIZ,
	URL_FOREIGN_LANGUAGE_DASHBOARD,
	URL_CIVICS_DASHBOARD,
	URL_PRESENT_OR_PAST,
	URL_CONTACT_US,
	URL_DATA_SECURITY,
} from "Helpers/Paths";
// IMAGES
import { IMAGES } from "Helpers";

// PAGES
const CivicsDashboard = LazyLoader(() => import("Components/Civics/Dashboard"));
const FinalModuleKbc = LazyLoader(() => import("Components/Civics/Kbc/Kbc"));
const Profile = LazyLoader(() => import("Components/Profile"));
const AboutUs = LazyLoader(() => import("Components/Legal/AboutUs"));
const TermsAndConditions = LazyLoader(() => import("Components/Legal/TermsAndConditions"));
const PrivacyPolicy = LazyLoader(() => import("Components/Legal/PrivacyPolicy"));
const DataSecurity = LazyLoader(() => import("Components/Legal/DataSecurity"));
const ChangePassword = LazyLoader(() => import("Components/Pages/ChangePassword"));
const ContactUs = LazyLoader(() => import("Components/ContactUs"));
const Constitution = LazyLoader(() => import("Components/Civics/Constitution"));
const BillOrNoBill = LazyLoader(() => import("Components/Civics/BillOrNoBill"));
const LawBill = LazyLoader(() => import("Components/Civics/LawBill"));
const EscapeRoom = LazyLoader(() => import("Components/Civics/EscapeRoom/Room"));
const JeoPardy = LazyLoader(() => import("Components/Civics/JeoPardy/JeoPardy"));
const JeoPardyAnswer = LazyLoader(() => import("Components/Civics/JeoPardy/Answer"));
const JudicialDashboard = LazyLoader(() => import("Components/Civics/Judicial/JudicialDashboard"));
const JudicialSpin = LazyLoader(() => import("Components/Civics/Judicial/Spin"));
const HistoryRapidFire = LazyLoader(() => import("Components/History/RapidFire"));
const HistoryBillOrNoBill = LazyLoader(() => import("Components/History/BillOrNoBill"));
const HistoryBillOrNoBillGame = LazyLoader(() => import("Components/History/BillOrNoBill/Game"));
const ForeignLanguageRapidFire = LazyLoader(() => import("Components/ForeignLanguage/RapidFire"));
const ForeignLanguageQuiz = LazyLoader(() => import("Components/ForeignLanguage/Quiz"));
const ForeignLanguageQuizAnswer = LazyLoader(() => import("Components/ForeignLanguage/Quiz/Answer"));
const ForeignLanguageQuizStart = LazyLoader(() => import("Components/ForeignLanguage/Quiz/QuizStart"));
const ScienceRapidFire = LazyLoader(() => import("Components/Science/RapidFire"));
const EnglishCrossWord = LazyLoader(() => import("Components/English/CrossWord/Crossword"));
const EnglishRapidFire = LazyLoader(() => import("Components/English/RapidFire"));
const MathsConnect4 = LazyLoader(() => import("Components/Maths/Connect4/Connect4"));
const MathsConnect4Game = LazyLoader(() => import("Components/Maths/Connect4/GamePlay"));
const MathsMathahtzee = LazyLoader(() => import("Components/Maths/Mathahtzee/Mathahtzee"));
const MathsMathahtzeeGame = LazyLoader(() => import("Components/Maths/Mathahtzee/GamePlay"));
const SciencePeriodicTable = LazyLoader(() => import("Components/Science/PeriodicTable"));
const EconomicsPursuit = LazyLoader(() => import("Components/Economics/Pursuit"));
const PursuitRoundOne = LazyLoader(() => import("Components/Economics/Pursuit/GameRounds/PursuitRoundOne"));
const PursuitRoundTwo = LazyLoader(() => import("Components/Economics/Pursuit/GameRounds/PursuitRoundTwo"));
const PursuitRoundThree = LazyLoader(() => import("Components/Economics/Pursuit/GameRounds/PursuitRoundThree"));
const EconomicsRapidFire = LazyLoader(() => import("Components/Economics/RapidFire/RapidFire"));
const ScienceWeakestLinkGame = LazyLoader(() => import("Components/Science/WeakestLink/GamePlay"));

// SCIENCE SIDEBAR
export const scienceSidebarRoutes = [
	{ path: URL_SCIENCE_DASHBOARD, name: "Home", order: 1 },
	{ path: URL_RAPID_FIRE, name: "Rapid Fire", order: 2, icon: IMAGES.COMMONS.FIRE_BALL, activeKey: "isRapidFireScienceActive" },
	{ path: URL_PERIODIC_TABLE, name: "Periodic table", order: 3, icon: IMAGES.SCIENCE.PERIODIC_TABLE.BEAKER, activeKey: "isPeriodicTableActive" },
	{ path: URL_WEAKEST_LINK_GAME, name: "Loose Link", order: 4, icon: IMAGES.SCIENCE.LOOSE_LINK.LOGO, activeKey: "isLooseLinkActive" },
	{ name: "Settings", expand: true, order: 5 },
];

// MATHS SIDEBAR
export const mathsSidebarRoutes = [
	{ path: URL_MATHS_DASHBOARD, name: "Home", order: 1 },
	{ path: URL_CONNECT4, name: "Connect 4", order: 2, icon: IMAGES.MATH.CONNECT4.PLAY_LOGO, activeKey: "isConnect4Active" },
	{ path: URL_MATHAHTZEE, name: "Mathahtzee", order: 3, icon: IMAGES.MATH.MATHAHTZEE.LETS_PLAY, activeKey: "isMathahtzeeActive" },
	{ name: "Settings", expand: true, order: 4 },
];

// CIVICS SIDEBAR
export const civicsSidebarRoutes = [
	{ path: URL_CIVICS_DASHBOARD, name: "Home", order: 1 },
	{ path: URL_BILL_OR_NO_BILL, name: "Bill No-Bill", order: 2, icon: IMAGES.CIVICS.LEGISLATIVE_ICON, activeKey: "isLegislativeActive" },
	{ path: URL_BILL_BECOME_LAW, name: "How Bill Becomes Law", order: 3, icon: IMAGES.CIVICS.LEGISLATIVE_ICON, activeKey: "isLegislativeActive" },
	{ path: URL_ESCAPE_ROOM, name: "Executive Branch", order: 4, icon: IMAGES.CIVICS.EXECUTIVE_ICON, activeKey: "isExecutiveActive" },
	{ path: URL_JUDICIAL_DASHBOARD, name: "Judicial Branch", order: 5, icon: IMAGES.CIVICS.JUDICIAL_ICON, activeKey: "isJudicialActive" },
	{ path: URL_CONSTITUTION, name: "Constitution", order: 6, icon: IMAGES.CIVICS.CONSTITUTION_LOGO, activeKey: "isConstitutionActive" },
	{ path: URL_JEO_PARDY, name: "Quiz", order: 7, icon: IMAGES.CIVICS.QUIZ_ICON, activeKey: "isQuizActive" },
	{ path: URL_FINAL_MODULE, name: "Final", order: 8, icon: IMAGES.CIVICS.FINAL_ICON, activeKey: "isFinalActive" },
	{ name: "Settings", expand: true, order: 9 },
];

// ENGLISH SIDEBAR
export const englishSidebarRoutes = [
	{ path: URL_ENGLISH_DASHBOARD, name: "Home", order: 1 },
	{ path: URL_RAPID_FIRE, name: "Rapid Fire", order: 2, icon: IMAGES.COMMONS.FIRE_BALL, activeKey: "isRapidFireEnglishActive" },
	{ path: URL_CROSSWORD, name: "Crossword", order: 3, icon: IMAGES.ENGLISH.CROSSWORD.LOGO, activeKey: "isCrossWordActive" },
	{ name: "Settings", expand: true, order: 4 },
];

// HISTORY SIDEBAR
export const historySidebarRoutes = [
	{ path: URL_HISTORY_DASHBOARD, name: "Home", order: 1 },
	{ path: URL_RAPID_FIRE, name: "Rapid Fire", order: 2, icon: IMAGES.COMMONS.FIRE_BALL, activeKey: "isRapidFireHistoryActive" },
	{ path: URL_PRESENT_OR_PAST, name: "Present or Past", order: 3, icon: IMAGES.HISTORY.PRESENT_OR_PAST.SIDE_LOGO, activeKey: "isHistoryBillNoBillActive" },
	{ name: "Settings", expand: true, order: 4 },
];

// ECONOMICS SIDEBAR
export const economicsSidebarRoutes = [
	{ path: URL_ECONOMIC_DASHBOARD, name: "Home", order: 1 },
	{ path: URL_RAPID_FIRE, name: "Rapid Fire", order: 2, icon: IMAGES.COMMONS.FIRE_BALL, activeKey: "isRapidFireEconomicsActive" },
	{ path: URL_PURSUIT, name: "Pursuit", order: 3, icon: IMAGES.ECONOMICS.PURSUIT.LOGO, activeKey: "isPursuitActive" },
	{ name: "Settings", expand: true, order: 4 },
];

// FOREIGN LANGUAGE SIDEBAR
export const foreignSidebarRoutes = [
	{ path: URL_FOREIGN_LANGUAGE_DASHBOARD, name: "Home", order: 1 },
	{ path: URL_RAPID_FIRE, name: "Rapid Fire", order: 2, icon: IMAGES.COMMONS.FIRE_BALL, activeKey: "isRapidFireForeignLanguageActive" },
	{ path: URL_START_QUIZ, name: "Quizardy", order: 3, icon: IMAGES.FOREIGN.QUIZ.GAME_ICON_TINY },
	{ name: "Settings", expand: true, order: 4 },
];

export const appRoutes = {
	civicRoutes: [
		{
			path: "civics",
			element: <CivicsDashboard />,
			name: "Home",
			order: 1,
		},
		{
			path: URL_CONSTITUTION,
			element: <Constitution />,
			name: "Constitution",
			order: 6,
		},
		{
			path: URL_BILL_OR_NO_BILL,
			element: <BillOrNoBill />,
			name: "Legislative Branch",
			key: "legislativeComponent",
			order: 3,
		},
		{
			path: URL_BILL_BECOME_LAW,
			element: <LawBill />,
		},
		{
			path: URL_ESCAPE_ROOM,
			element: <EscapeRoom />,
			name: "Executive Branch",
			order: 4,
		},
		{
			path: URL_JEO_PARDY,
			element: <JeoPardy />,
			name: "Quiz",
			order: 7,
		},
		{
			path: URL_JEO_PARDY_ANSWER,
			element: <JeoPardyAnswer />,
		},
		{
			path: URL_FINAL_MODULE,
			element: <FinalModuleKbc />,
			name: "Final",
			order: 8,
		},
		{
			path: URL_JUDICIAL_DASHBOARD,
			element: <JudicialDashboard />,
			name: "Judicial Branch",
			order: 5,
		},
		{
			path: URL_JUDICIAL_SPIN,
			element: <JudicialSpin />,
		},
	],

	settingRoutes: [
		{
			path: URL_PROFILE,
			element: <Profile />,
		},
		{
			path: URL_ABOUT_US,
			element: <AboutUs />,
		},
		{
			path: URL_TERMS_AND_CONDITIONS,
			element: <TermsAndConditions />,
		},
		{
			path: URL_PRIVACY_POLICY,
			element: <PrivacyPolicy />,
		},
		{
			path: URL_DATA_SECURITY,
			element: <DataSecurity />,
		},
		{
			path: URL_CHANGE_PWD,
			element: <ChangePassword />,
		},
		{
			path: URL_CONTACT_US,
			element: <ContactUs />,
		},
	],

	historyRoutes: [
		{
			path: URL_RAPID_FIRE,
			element: <HistoryRapidFire />,
			name: "RapidFire",
			order: 6,
		},
		{
			path: URL_PRESENT_OR_PAST,
			element: <HistoryBillOrNoBill />,
			name: "Bill No-Bill",
			order: 6,
		},
		{
			path: URL_PRESENT_OR_PAST_GAME,
			element: <HistoryBillOrNoBillGame />,
			name: "Bill No-Bill Game",
			order: 6,
		},
	],

	foreignLanguageRoutes: [
		{
			path: URL_RAPID_FIRE,
			element: <ForeignLanguageRapidFire />,
			name: "RapidFire",
			order: 6,
		},
		{
			path: URL_QUIZ,
			element: <ForeignLanguageQuiz />,
			name: "Quiz",
			order: 2,
		},
		{
			path: URL_QUIZ_ANSWER,
			element: <ForeignLanguageQuizAnswer />,
			name: "Quiz-answer",
			order: 3,
		},
		{
			path: URL_START_QUIZ,
			element: <ForeignLanguageQuizStart />,
			name: "Quiz-start",
			order: 1,
		},
	],

	scienceRoutes: [
		{
			path: URL_RAPID_FIRE,
			element: <ScienceRapidFire />,
			name: "RapidFire",
			order: 6,
		},
		{
			path: URL_PERIODIC_TABLE,
			element: <SciencePeriodicTable />,
			name: "PeriodicTable",
			order: 6,
		},
		{
			path: URL_WEAKEST_LINK_GAME,
			element: <ScienceWeakestLinkGame />,
			name: "Weakest-Link-Game",
			order: 7,
		},
	],

	economicsRoutes: [
		{
			path: URL_PURSUIT,
			element: <EconomicsPursuit />,
			name: "Pursuit",
			order: 6,
		},
		{
			path: URL_PURSUIT_GAME,
			element: <PursuitRoundOne />,
			name: "Pursuit-round-one",
			order: 6,
		},
		{
			path: URL_CHASER_GAME,
			element: <PursuitRoundTwo />,
			name: "Pursuit-round-two",
		},
		{
			path: URL_PURSUIT_FINAL_GAME,
			element: <PursuitRoundThree />,
			name: "Pursuit-round-three",
		},
		{
			path: URL_RAPID_FIRE,
			element: <EconomicsRapidFire />,
			name: "Rapid-fire",
			order: 6,
		},
	],

	englishRoutes: [
		{
			path: URL_CROSSWORD,
			element: <EnglishCrossWord />,
			name: "Pursuit",
			order: 6,
		},
		{
			path: URL_RAPID_FIRE,
			element: <EnglishRapidFire />,
			name: "Rapid-fire",
			order: 6,
		},
	],

	mathsRoutes: [
		{
			path: URL_CONNECT4,
			element: <MathsConnect4 />,
			name: "Connect 4",
			order: 1,
		},
		{
			path: URL_CONNECT4_GAME,
			element: <MathsConnect4Game />,
			name: "Connect4-game",
			order: 2,
		},
		{
			path: URL_MATHAHTZEE,
			element: <MathsMathahtzee />,
			name: "Mathahtzee",
			order: 1,
		},
		{
			path: URL_MATHAHTZEE_GAME,
			element: <MathsMathahtzeeGame />,
			name: "Mathahtzee-game",
			order: 2,
		},
	],
};

import React, { useEffect, useState } from "react";
// STYLES
import LazyImageWrapper from "./LazyImage.style";

const LazyImage = (props) => {
	const [imageLoaded, setImageLoaded] = useState(false);

	// useEffect(() => {
	// 	setImageLoaded(false);
	// }, [props.compressedImage]);

	const handleImageLoaded = () => setImageLoaded(true);

	return (
		<LazyImageWrapper imageLoaded={imageLoaded} compressedImage={props.compressedImage} {...props.wrapperProps}>
			<img alt="..." loading="lazy" src={props.image} className="image" onLoad={handleImageLoaded} {...props.imageProps} />
		</LazyImageWrapper>
	);
};

export default LazyImage;
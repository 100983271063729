import React, { useState } from "react";
import { TeacherLoginModalWrapper, SchoolRegisterModalWrapper, EmailField, PasswordField, Input, StyledTextarea } from "./GLPCommonComponentStyle";
import { Box, InputLabel, Typography, Button, InputAdornment, IconButton, CircularProgress, FormControlLabel, Switch } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { URL_SCHOOL_REGISTER, URL_SUBSCRIPTION } from "Helpers/Paths";
import { Link, useLocation } from "react-router-dom";
import { Api, URL_FRGT_PWD } from "Helpers";
import { showToast } from "Redux/General/Actions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { URL_GLOBAL_LANDING_PAGE, IMAGES } from "Helpers";
import RedirectModal from "Components/GlobalLandingPage/Modal";

function TeacherLoginPageModal(props) {
	const dispatch = useDispatch();
	const [values, setValues] = useState({
		password: "",
		showPassword: false,
	});

	const [loading, setLoading] = useState(false);
	const validationSchema = yup.object({
		email: yup.string().required("* Email is required").email("* Enter a valid email"),
		password: yup.string().required("* Please enter your password "),
	});

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema,
		onSubmit: async () => {
			try {
				const values = formik.values;
				setLoading(true);
				const response = await new Api().post("user/cms/cmsLogin", {
					data: {
						email: values.email,
						password: values.password,
					},
					returnError: true,
				});
				if (response.status === 200 || response.status === 201) {
					const data = response?.data;
					if (data?.length) {
						window.location.href = `${process.env.REACT_APP_CMS_URL}login?token=${data}`;
					}
					setLoading(false);
				} else {
					setLoading(false);
					dispatch(showToast("Invalid Credentials"));
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		},
	});

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<TeacherLoginModalWrapper>
			<form onSubmit={formik.handleSubmit}>
				<Box className="content">
					<Box className="header">
						<InputLabel className="header">Portal Login</InputLabel>
					</Box>
					<Box>
						<Box className="flex-column-parent">
							<Box className="flex-column">
								<Box component="Box" sx={{ display: "inline" }} className="text">
									<InputLabel className="text">Email address</InputLabel>
								</Box>

								<EmailField
									id="email"
									name="email"
									className="login"
									autoComplete="false"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									defaultValue={formik.values.email}
									placeholder="teacher@subjectsmaster.com"
									helpertext={formik.touched.email && formik.errors.email}
								/>
								<Box component="div" sx={{ display: "inline", height: "10px" }} className="validation-error text">
									{formik.touched.email && formik.errors.email ? formik.errors.email : ""}
								</Box>
							</Box>

							<Box className="flex-column">
								<Box component="div" sx={{ display: "inline" }} className="text">
									<InputLabel className="text">Password</InputLabel>
								</Box>

								<PasswordField
									placeholder="Enter 6 character or more"
									name="password"
									key="password"
									className="login"
									value={formik.values.password}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									type={values.showPassword ? "text" : "password"}
									endAdornment={
										<InputAdornment position="end" name="password" key="password">
											<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} className="paswword-icon">
												{values.showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									}
								/>
								<Box component="div" sx={{ display: "inline", height: "10px" }} className="validation-error text">
									{formik.touched.password && formik.errors.password ? formik.errors.password : ""}
								</Box>
							</Box>
						</Box>

						<Box className="remember-me-parent">
							{/* <Box className="flex-center">
								<Checkbox className="checkbox" />
								<Typography className="remember-me-font">Remember me</Typography>
							</Box> */}

							<Box className="forgot-password-parent">
								<Typography className="forgot-password">
									<Link to={URL_FRGT_PWD}>Forgot Password?</Link>
								</Typography>
							</Box>
						</Box>
					</Box>

					<Box className="login-button-parent">
						<Button className="button" type="submit" endIcon={loading ? <CircularProgress className="loader" /> : ""}>
							<Typography className="login-font">Login</Typography>
						</Button>
					</Box>

					<Box className="model-footer">
						<Typography className="font dose-not-have-account-font">Don't have an account yet?</Typography>
						<Typography className="font register-as-school-font">
							<Link to={URL_SCHOOL_REGISTER}>Register as a school</Link>
						</Typography>
					</Box>
				</Box>
			</form>
		</TeacherLoginModalWrapper>
	);
}

function SchoolRegisterPageModal(props) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const ssocode = location?.state?.ssocode;

	const subscriptionDetails = props?.subscriptionDetails || {};

	const [imageObj, setImageObj] = useState(null);
	const [uploadImage, setUploadImage] = useState("");
	const [pageNumber, setPageNumber] = useState(1);

	const handleImage = (e) => {
		if (e?.target?.files[0]) {
			let fileObj = e.target.files[0];
			setImageObj(fileObj);
			if (fileObj) {
				setUploadImage(URL.createObjectURL(fileObj));
				setImageObj(fileObj);
			}
		} else {
			setUploadImage(null);
		}
	};

	const [loading, setLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [values, setValues] = useState({
		password: "",
		showPassword: false,
	});

	const phoneRegExp = new RegExp(/^[0-9]+$/);

	const validationSchema = yup.object({
		schoolName: yup.string("").trim().required("* School name is required"),
		email: yup.string("").email("* Enter a valid email").required("* Email is required"),
		password: yup.string("").trim().required("* Please enter your password").min(6, "* Password must be atleast 6 length"),
		address: yup.string("").trim().required("* Address is required"),
		phoneNumber: yup.string("").required("* Phone Number is required").matches(phoneRegExp, "* Phone number is not valid"),
	});

	const formik = useFormik({
		initialValues: {
			schoolName: location?.state?.schoolName || "",
			email: "",
			password: "",
			address: "",
			phoneNumber: "",
			isLinkedWithClassLink: false,
		},
		validationSchema,
		onSubmit: async (values) => {
			try {
				const bodyFormData = new FormData();

				const subscriptionList = Object.keys(subscriptionDetails);

				const payloadData = {
					schoolName: values?.schoolName,
					address: values?.address,
					email: values?.email,
					password: values?.password,
					phoneNumber: values?.phoneNumber,
					isLinkedWithClassLink: values?.isLinkedWithClassLink,
				};

				if (ssocode) {
					payloadData["isLinkedWithClassLink"] = true;
				}

				if (subscriptionList && subscriptionList.length) {
					payloadData.subscriptionRequestDto = { ...subscriptionDetails };
				}

				bodyFormData.append(
					"addSchoolRequestDTO",
					new Blob([JSON.stringify(payloadData)], {
						type: "application/json",
					})
				);

				if (uploadImage.length > 0) {
					bodyFormData.append("schoolProfilePic", imageObj);
				}

				setLoading(true);
				if (ssocode || values?.isLinkedWithClassLink) setIsLoading(true);

				const response = await new Api().post("school", { data: bodyFormData, returnError: true });

				if (response.status === 200 || response.status === 201) {
					dispatch(showToast("Please verify your email"));
					if (ssocode || values?.isLinkedWithClassLink) {
						navigate(URL_SUBSCRIPTION, { state: { schoolId: response?.data?.schoolId, schoolSubscriptionDetails: response?.data?.schoolSubscriptionDetails } });
					} else {
						navigate(URL_GLOBAL_LANDING_PAGE);
					}
				}

				setLoading(false);
			} catch (error) {
				setIsLoading(false);
				setLoading(false);
			}
		},
	});

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleNextButton = () => {
		if (formik.dirty && !Object.keys(formik.errors).includes("schoolName") && !Object.keys(formik.errors).includes("email") && !Object.keys(formik.errors).includes("password")) {
			setPageNumber(2);
		}
	};

	return (
		<SchoolRegisterModalWrapper>
			<Box className="school-header-parent">
				{pageNumber === 2 && <ArrowBackIcon className="icon" onClick={() => setPageNumber(1)} />}
				<span className="header">Register</span>
			</Box>
			<form onSubmit={formik.handleSubmit} className="form-data">
				{pageNumber === 1 ? (
					<React.Fragment>
						<Box className="parent-container">
							<Box className="upload-image-parent">
								<img src={uploadImage ? uploadImage : values.fileName ? values.fileName : IMAGES.LANDING_PAGE.AVATAR} alt="UploadImage" className="mtb-15 img" />
							</Box>

							<Box>
								<Button className="upload-image" component="label">
									Upload image
									<input id="profilePicture" hidden type="file" accept="image/jpg,image/png,image/jpeg" onChange={(e) => handleImage(e)} />
								</Button>
							</Box>
						</Box>

						<Box className="content">
							<Box className="flex-column-parent">
								<Box className="flex-column">
									<Box component="Box" className="text">
										<InputLabel className="text">School Name</InputLabel>
									</Box>

									<Input
										className="school-register"
										id="schoolName"
										name="schoolName"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										defaultValue={formik.values.schoolName}
										placeholder="Enter school name"
										helpertext={formik.touched.schoolName && formik.errors.schoolName}
										disabled={ssocode && location?.state?.schoolName ? true : false}
									/>

									<Box className="validation-error text">{formik.touched.schoolName && formik.errors.schoolName ? formik.errors.schoolName : ""}</Box>
								</Box>
								<Box className="flex-column">
									<Box component="Box" className="text">
										<InputLabel className="text">Email address</InputLabel>
									</Box>

									<EmailField
										className="school-register"
										id="email"
										name="email"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										defaultValue={formik.values.email}
										placeholder="teacher@subjectsmaster.com"
										helpertext={formik.touched.email && formik.errors.email}
									/>

									<Box component="div" className="validation-error text">
										{formik.touched.email && formik.errors.email ? formik.errors.email : ""}
									</Box>
								</Box>
								<Box className="flex-column">
									<Box component="div" className="text">
										<InputLabel className="text">Password</InputLabel>
									</Box>

									<PasswordField
										className="school-register"
										placeholder="Enter 6 character or more"
										name="password"
										key="password"
										value={formik.values.password}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										type={values.showPassword ? "text" : "password"}
										endAdornment={
											<InputAdornment position="end" name="password" key="password">
												<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} className="paswword-icon">
													{values.showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										}
									/>

									<Box component="div" className="validation-error text">
										{formik.touched.password && formik.errors.password ? formik.errors.password : ""}
									</Box>
								</Box>
							</Box>
						</Box>
					</React.Fragment>
				) : (
					<React.Fragment>
						<Box className="content">
							<Box className="flex-column-parent">
								<Box className="flex-column">
									<Box component="Box" className="text">
										Address
									</Box>
									<StyledTextarea
										id="address"
										name="address"
										minRows={5}
										maxRows={5}
										placeholder="Enter your Address"
										value={formik.values.address}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>

									<Box component="div" className="validation-error text">
										{formik.touched.address && formik.errors.address ? formik.errors.address : ""}
									</Box>
								</Box>

								<Box className="flex-column">
									<Box component="div" className="text">
										<InputLabel className="text">Phone Number</InputLabel>
									</Box>

									<Input
										placeholder="Enter phone number"
										name="phoneNumber"
										key="phoneNumber"
										type="number"
										value={formik.values.phoneNumber}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										className="school-register"
									/>

									<Box component="div" className="validation-error text">
										{formik.touched.phoneNumber && formik.errors.phoneNumber ? formik.errors.phoneNumber : ""}
									</Box>
								</Box>
							</Box>
						</Box>
					</React.Fragment>
				)}

				<Box className="button-container">
					{pageNumber === 1 ? (
						<Box className="next-button-parent">
							<div className="button" onClick={handleNextButton}>
								<Typography className="next-font">NEXT</Typography>
							</div>
						</Box>
					) : (
						<Box className="next-button-parent">
							<Button className="button" type="submit" endIcon={loading ? <CircularProgress className="progress" /> : ""}>
								<Typography className="next-font">Register</Typography>
							</Button>
						</Box>
					)}

					<Box className="scroll-dots">
						<Box
							style={{ backgroundColor: pageNumber === 1 ? "#000" : "#D3D3D3" }}
							className="dots"
							onClick={() => {
								setPageNumber(1);
							}}
						></Box>
						<Box
							className="dots"
							style={{ backgroundColor: pageNumber === 2 ? "#000" : "#D3D3D3" }}
							onClick={() => {
								setPageNumber(2);
							}}
						></Box>
					</Box>
				</Box>
			</form>
			{isLoading && <RedirectModal open={isLoading} title={"Please Wait, While we are syncing your data with ClassLink"} />}
		</SchoolRegisterModalWrapper>
	);
}

const GLPCommonComponentModal = (props) =>
	props.renderProps === "teacher-login" ? <TeacherLoginPageModal /> : <SchoolRegisterPageModal isSchoolRegister={true} subscriptionDetails={props?.subscriptionDetails} />;

export default GLPCommonComponentModal;

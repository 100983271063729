import styled from "styled-components";
import { Dialog } from "@material-ui/core";
// STYLES
import { responsive } from "Styles/Constants";
// IMAGES
import { IMAGES } from "Helpers";

export const DialogWrapper = styled(Dialog)`
	.background-root {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.paper {
		background: transparent;
		color: #fff;
		overflow: visible;
	}

	.paper-width {
		max-width: 900px;
	}

	.paper-scroll {
		max-height: unset;
	}

	.box-modal {
		width: 935px;
		height: 750px;
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.HISTORY.PRESENT_OR_PAST.HOW_TO_PLAY});

		${responsive.DISPLAY`
            width: 785px;
            height: 630px;
		`}

		${responsive.DESKTOP`
            width: 660px;
            height: 530px;
		`}

		.title-container {
			.sub-title {
				font-size: 28px;
				margin-top: 52px;
				color: #f5c882;
				font-weight: 900;

				${responsive.DISPLAY`
                    font-size: 20px;
				    margin-top: 45px;
                `}

				${responsive.DESKTOP`
                    font-size: 18px;
                `}
			}
		}

		.content-wrapper {
			height: 420px;
			margin-top: 40px;
			padding: 0px 100px;
			position: relative;

			${responsive.DISPLAY`
                height: 350px;
                font-size: 14px;
            `}

			${responsive.DESKTOP`
                height: 280px;
                font-size: 12px;
				margin-top: 30px;
				padding: 0px 60px;
            `}

			.content {
				width: 90%;
				margin: 2% auto 0px;
			}

			.attach-file {
				display: flex;
				justify-content: center;

				.icon {
					color: #fff;
				}

				.file {
					margin: 0px 10px;
					font-size: 18px;
					cursor: pointer;
					text-decoration: underline;
					color: #fff;
				}

				.pdf-loader {
					width: 20px !important;
					height: 20px !important;
					color: #fff;
					margin-top: 1px;
				}
			}

			.progress {
				position: absolute;
				top: 36%;
				left: 0;
				right: 0;
			}
		}

		.type-switcher {
			margin-bottom: 20px;

			.divider {
				background-color: #fff;
				height: 2px;
				width: 50%;
				margin: 5px auto;
				opacity: 0.5;
			}

			.text {
				cursor: pointer;
				transition: 0.3s;
				padding: 0px;
				margin: 0px;
				&:hover {
					color: #fff;
				}
			}
		}

		.play {
			display: flex;
			align-items: center;
			justify-content: center;

			${responsive.DISPLAY`
                font-size: 22px;
            `}

			${responsive.DESKTOP`
                font-size: 18px;
            `}

			.play-btn {
				cursor: pointer;
				width: 250px;
				height: 100px;
				margin-top: -10px;
				background-image: url(${IMAGES.HISTORY.PRESENT_OR_PAST.HOW_TO_PLAY_BUTTON});
				background-size: 100% 100%;

				${responsive.DISPLAY`
                    width: 220px;
                    height: 85px;
                `}

				${responsive.DESKTOP`
                    width: 180px;
                    height: 75px;
                `}

				.play-btn-title {
					font-size: 25px;
					font-weight: 900;
					margin: 0;

					${responsive.DISPLAY`
                    font-size: 20px;
                `}

					${responsive.DESKTOP`
                    font-size: 18px;
                `}
				}
			}
		}
	}
`;

import React from "react";
import styled from "styled-components";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { COLORS } from "Styles/Constants";

export const SnackbarContentWrapper = styled(({ css, ...rest }) => <SnackbarContent {...rest} />)`
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
	align-items: baseline;

	&.themed {
		margin-top: 10%;
		background-color: ${COLORS.PINK_LIGHT};
		color: #000;

		.notification-message {
			font-size: 22px;
		}

		.notification-action {
			padding: 0;
		}
	}
`;

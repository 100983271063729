import React from "react";
import { Box } from "@material-ui/core";
import Slider from "react-slick";
//STYLES
import { DialogWrapper, TitleBox } from "./PresentModal.style";
//IMAGES
import CloseButton from "../../../Assets/Images/Commons/CloseButton.webp";
//CONSTANT
import { GAMES_DATA } from "Helpers";

const settings = {
	dots: true,
	infinite: true,
	speed: 1000,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 8000,
};

const PresentModal = (props) => {
	const { presentModal, handleClose } = props;

	return (
		<DialogWrapper classes={{ paper: "paper", paperWidthSm: "paper-width" }} open={presentModal.open} onClose={handleClose}>
			<div className="container">
				<Slider {...settings}>
					{GAMES_DATA[presentModal.type].map((data) => {
						return (
							<Box key={data.id} component="div" className="slider">
								<img className="background-image" alt="background-pic" src={data.image} />
								<TitleBox component="div" className="flex f-h-center" textColor={data?.textColor}>
									<p className="subject-text">{data?.mainText ? data.mainText : ""}</p>
									<p className="subject-description">{data.description}</p>
								</TitleBox>
							</Box>
						);
					})}
				</Slider>
			</div>
			<img className="close-button-image" alt="close-button" src={CloseButton} onClick={handleClose} />
		</DialogWrapper>
	);
};

export default PresentModal;

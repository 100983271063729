import styled from "styled-components";
import { FONTS, responsive } from "Styles/Constants";
import { IMAGES } from "Helpers";

export const SubscriptionDetailsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow: hidden;
	background-color: #fff;
	width: 100%;
	justify-content: space-between;

	.background-image {
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: absolute;
	}

	.header-container {
		z-index: 10;
		background-color: rgba(255, 255, 255, 0.9);

		.top-bar {
			display: flex;
			justify-content: space-between;
			padding: 1%;
			margin: 0px auto;
			width: 90%;
			.subject-logo {
				background-image: url(${IMAGES.DASHBOARD.SUBJECT_MASTER_LOGO_BLACK});
				width: 110px;
				height: 40px;
				background-repeat: no-repeat;
				background-size: contain;
				cursor: pointer;
			}
			.btn-container {
				display: flex;
				width: auto;
				margin-right: -6%;
				.teacher-btn {
					border-radius: 29.5px;
					width: 180px;
					border: solid 2px rgba(4, 23, 39, 0.3);
					display: flex;
					align-items: center;
					padding: 0px 14px;
					justify-content: center;
					cursor: pointer;
				}
				.login {
					border-radius: 29.5px;
					width: 180px;
					border: solid 2px rgba(4, 23, 39, 0.3);
					display: flex;
					align-items: center;
					padding: 0px 14px;
					justify-content: center;
					margin-left: 10px;
				}
			}
		}
	}

	.footer-container {
		background-color: rgba(255, 255, 255, 0.9);
		padding: 5px;
		width: 100%;
		z-index: 10;

		.inner-container {
			display: flex;
			padding: 0% 1%;
			justify-content: space-between;
			.footer-text {
				color: #000;
				font-size: 18px;
			}

			.page-container {
				display: flex;
				font-size: 16px;
				cursor: pointer;

				.partition {
					margin: 0px 5px;
				}
			}
		}
	}

	.main-container {
		display: flex;
		height: 94%;
		margin: 0px auto;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 80%;
		margin-top: 2%;

		.board-plans {
			z-index: 10;
			display: grid;
			grid-column-gap: 30px;
			grid-template-columns: auto auto auto;

			&.decrease-gird-gap {
				grid-column-gap: 0px;
			}
			.text-board {
				background: url(${IMAGES.LANDING_PAGE.PLAN}) no-repeat center;
				background-size: 100% 100%;
				width: 100%;
				font-size: 28px;
				color: rgba(255, 255, 255, 0.3);
				width: 220px;
				height: 80px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				font-family: ${FONTS.QUATERNARY};
				font-weight: 600;
				&.selected {
					color: #fff;
					background: url(${IMAGES.LANDING_PAGE.PLAN_SELECTED}) no-repeat center;
					background-size: 100% 100%;
					height: 100px;
					padding-bottom: 18px;
					transform: scale(1.2);
					${responsive.LAPTOP`
						font-size: 22px
						width: 180px;
					`}
					${responsive.TABLET`
						font-size: 20px
						width: 180px;
						padding-bottom: 27px;
        			`}
				}
				&.disable {
					pointer-events: none;
				}
				${responsive.DESKTOP`
					font-size: 25px
					width: 220px;
					height: 80px;
				`}

				${responsive.LAPTOP`
					font-size: 25px
					width: 220px;
					height: 80px;
				`}
				${responsive.TABLET`
					font-size: 25px
					width: 170px;
					height: 60px;
        		`}
			}
		}

		.email-board {
			background: url(${IMAGES.LANDING_PAGE.BOARD}) no-repeat center;
			background-size: 100% 100%;
			width: 80%;
			height: 80%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			color: #fff;

			.text-one {
				font-size: 22px;
			}
			.text-two {
				font-size: 20px;
			}
		}

		.board {
			background: url(${IMAGES.LANDING_PAGE.BOARD}) no-repeat center;
			background-size: 100% 100%;
			width: 80%;
			height: 100%;
			margin-top: -22px;
			z-index: 1;
			${responsive.DISPLAY_1600`
          		width: 90%
				height : 85%
			`}
			${responsive.DESKTOP`
          		width: 100%
			`} 
			/* ${responsive.LAPTOP_1050`
			
       			width: 90%
				height : 85%
			`} */
			/* ${responsive.LAPTOP`
          		width: 90%
				height : 70%
				margin-top: -18px;
			`} */
			${responsive.LAPTOP`
          		width: 100%
				height : 70%
				margin-top: -18px;
			`}
			${responsive.TABLET`
            	width: 85%;
				height : 60%
				margin-top: -15px;
        	`}
			${responsive.TABLET_725`
            	width: 95%;
				height : 50%
				margin-top: -15px;
        	`}
		}
	}
`;

export const SubscriptionPlansWrapper = styled.div`
	.main-container {
		.select-plan-plate {
			width: 442px;
			height: 135px;
			display: flex;
			margin: 46px 0px;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			background: url(${IMAGES.LANDING_PAGE.SELECT_PLAN_BOARD}) no-repeat center;
			background-size: 100% 100%;

			.select-plan {
				font-size: 35px;
				font-family: ${FONTS.QUATERNARY};
				font-weight: 600;
				margin-bottom: 15px;

				${responsive.LAPTOP`
      				font-size: 25px;
				`}

				${responsive.DISPLAY_1600`
				  	font-size: 25px;
				`}
			}

			${responsive.DISPLAY_1600`
          		width: 30%
				height : 80px
			`}

			${responsive.LAPTOP`
      			width: 50%;
				height : 80px;
			`}
		}
		.plans {
			display: flex;
			div:nth-child(2) {
				margin: 70px -16px 0px -19px;
			}
			div:nth-child(4) {
				margin: 70px 0 0 -22px;
			}

			@media (max-height: 790px) {
				div:nth-child(2) {
					margin: 0px !important;
				}
				div:nth-child(4) {
					margin: 0px !important;
				}
			}

			${responsive.DISPLAY_1600`
				div:nth-child(2) {
					margin: 70px 20px 0px 20px;
				}
				div:nth-child(4) {
					margin: 70px 0 0 20px;
				}	
			`}
		}
	}
`;

export const StickerWrapper = styled.div`
	width: 289px;
	height: 320px;
	background: url(${(props) => props.stickerImage}) no-repeat center;
	background-size: 100% 100%;
	&:hover {
		cursor: pointer;
		transform: scale(1.05);
		filter: drop-shadow(4px 4px 6px ${(props) => props.hoverEffectColor});
	}

	:active {
		transform: scale(1.08);
	}

	&.selected {
		filter: drop-shadow(4px 4px 6px ${(props) => props.hoverEffectColor});
	}

	${responsive.DISPLAY_1600`
        max-width: 200px;
		max-height: 220px;
	`}
	${responsive.DESKTOP`
        width: 200px;
		height: 220px;
	`}
	${responsive.LAPTOP`
        width: 180px;
		height: 200px;
	`}
	${responsive.LAPTOP_1050`
        width: 153px;
		height: 163px;
	`}
	${responsive.TABLET`
        width: 110px;
		height: 130px;
		margin : 10px
	`}
`;

export const SubscriptionSubjectsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
	.main-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.select-plan-plate {
			width: 345px;
			background: url(${IMAGES.LANDING_PAGE.SELECT_PLAN_BOARD}) no-repeat center;
			height: 110px;
			margin: 46px 0px;
			object-fit: contain;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			background-size: contain;
			.select-plan {
				font-size: 35px;
				font-family: ${FONTS.QUATERNARY};
				font-weight: 600;
				margin-bottom: 15px;
				${responsive.DISPLAY_1600`
				  font-size: 25px;
				`}
			}
			${responsive.DESKTOP`
          		width: 40%
				height : 80px
			`}
			${responsive.DISPLAY_1600`
          		width: 30%
				height : 80px
			`}
			${responsive.LAPTOP`
      			width: 50%;
				height : 80px;
			`}
		}
		.flex {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;
			.direction-button {
				background: url(${IMAGES.LANDING_PAGE.DIRECTION_BUTTON}) no-repeat center;
				height: 40px;
				width: 40px;
				background-size: cover;
				${responsive.LAPTOP`
    				height: 30px;
					width: 30px;
				`}
				&.back {
					-webkit-transform: rotate(180deg);
				}

				&:hover {
					cursor: pointer;
				}
				&.hide-visibility {
					visibility: hidden;
				}
			}
			.font {
				font-size: 24px;
				font-family: ${FONTS.QUINARY};
				font-weight: 500;
				color: white;
				&.hide-visibility {
					visibility: hidden;
				}
				${responsive.LAPTOP`
					font-size: 20px;
				`}
			}
		}
	}
	.subject-sticker-parent {
		display: flex;
		flex-direction: row;
		gap: 20px;
		justify-content: center;

		${responsive.LAPTOP`
        	width: 90%;
			margin: 0px auto;
		`}

		div:nth-child(even) {
			margin-top: 70px;
		}
		@media (max-height: 745px) {
			div:nth-child(odd) {
				margin-top: -25px !important;
			}
			div:nth-child(even) {
				margin-top: 10px !important;
			}
		}
	}
`;
export const SubscriptionSummaryWrapper = styled.div`
	height: 20px;
	.summary-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 6% 5% 1%;

		.status-board {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 300px;

			.text-one {
				color: #fff;
				font-size: 22px;
			}
		}

		.header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 90%;
			.flex-header {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
				.direction-button {
					background: url(${IMAGES.LANDING_PAGE.DIRECTION_BUTTON}) no-repeat center;
					height: 40px;
					width: 40px;
					background-size: cover;
					&.back {
						-webkit-transform: rotate(180deg);
					}
					&.hide-visibility {
						visibility: hidden;
					}
					&:hover {
						cursor: pointer;
					}
					${responsive.LAPTOP`
    					height: 30px;
						width: 30px;
					`}
				}
				.font {
					font-size: 24px;
					font-family: ${FONTS.QUINARY};
					font-weight: 500;
					color: white;
					&.hide-visibility {
						visibility: hidden;
					}
				}
				.registerAsSchoolFont {
					font-size: 18px;
					font-family: ${FONTS.QUINARY};
					font-weight: 400;
					color: white;
					width: 100px;
					text-align: end;
					${responsive.LAPTOP`
						font-size: 20px;
					`}
				}
			}

			.summary-details {
				background: url(${IMAGES.LANDING_PAGE.SUMMARY_NOTE}) no-repeat center;
				background-size: 100% 100%;
				width: 45%;
				padding: 2% 5% 3%;
				.plan-name {
					text-align: center;
					font-size: 24px;
					font-family: ${FONTS.QUATERNARY};
					font-weight: bold;
					${responsive.LAPTOP`
						font-size: 18px;
					`}
				}

				.summary-pan-container {
					display: flex;
					justify-content: space-between;

					margin-top: 4%;
					margin-bottom: 2%;

					.tick {
						font-size: 22px;
						${responsive.LAPTOP`
							font-size: 10px;
						`}
					}

					.sub-plan {
						font-size: 18px;
						font-family: ${FONTS.QUATERNARY};
						font-weight: bold;
						${responsive.LAPTOP`
							font-size: 10px;
						`}
					}

					.price {
						font-size: 18px;
						font-family: ${FONTS.QUATERNARY};
						font-weight: bold;
						${responsive.LAPTOP`
							font-size: 10px;
						`}
					}

					.amount {
						font-size: 18px;
						margin-left: 5px;
						font-family: ${FONTS.QUATERNARY};
						color: #db0202;
						font-weight: bold;
						${responsive.LAPTOP`
							font-size: 10px;
						`}
					}
				}
			}
		}

		.selected-subjects {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			height: 420px;
			overflow-y: auto;
			width: 100%;
			${responsive.DISPLAY`
    			height : 320px
			`}
		}
	}
`;

export const SubjectSticker = styled.div`
	background: url(${(props) => props.stickerImage}) no-repeat center;
	width: 131px;
	height: 216px;
	background-size: cover;
	filter: grayscale(1);
	cursor: pointer;
	&:hover {
		/* transform: scale(1.05); */
		filter: grayscale(0);
		filter: drop-shadow(0px 0px 8px ${(props) => props.backgroundGlowColor});
		/* border: 2px solid ${(props) => props.borderColor};
		border-radius: 10px; */
	}
	:active {
		transform: scale(1.03);
		/* filter: grayscale(0); */
	}
	&.selected {
		filter: grayscale(0);
		/* transform: scale(1.05); */
		filter: drop-shadow(0px 0px 8px ${(props) => props.backgroundGlowColor});
		border: 2px solid ${(props) => props.borderColor};
		border-radius: 10px;
	}
	${responsive.DISPLAY_1600`
		width: 111px;
		height: 180px;
		background-size: 100%, 100%;
	`}

	${responsive.LAPTOP_1050`
    	width: 111px;
		height: 146px;
		background-size: 100%, 100%;
	`}
	${responsive.TABLET`
    	height : 118px
		margin-top: -15px;
	`}
`;

export const SelectedSubjectWrapper = styled.div`
	background: url(${(props) => props.subjectImage}) no-repeat center;
	background-size: contain;
	flex: 0 0 25%;
	@media (max-height: 900px) {
		flex: 0 0 17%;
	}
	@media (max-height: 800px) {
		flex: 0 0 14%;
	}
`;

export const SubscriptionPaymentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;

	.payment-sticker-success {
		width: 390px;
		height: 394px;
		background: url(${IMAGES.LANDING_PAGE.PAYMENT_SUCCESS}) no-repeat center;
		object-fit: contain;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background-size: 100% 100%;
	}
	.payment-sticker-fail {
		width: 390px;
		height: 394px;
		background: url(${IMAGES.LANDING_PAGE.PAYMENT_FAIL}) no-repeat center;
		object-fit: contain;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.try-again-parent {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 15px;
		cursor: pointer;

		.redirect {
			font-size: 20px;
			font-family: ${FONTS.QUINARY};
			font-weight: 500;
			color: white;
		}

		.font {
			font-size: 24px;
			font-family: ${FONTS.QUINARY};
			font-weight: 500;
			color: white;
		}

		.try-again {
			width: 52px;
			height: 52px;
			background: url(${IMAGES.LANDING_PAGE.TRY_AGAIN}) no-repeat center;
			object-fit: contain;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}
	}
`;

// GLOBAL ROUTES
export const URL_GLOBAL_LANDING_PAGE = "/";
export const URL_GENERAL_DASHBOARD = "/dashboard";
export const URL_CIVICS_DASHBOARD = "/civics";
export const URL_REGISTER = "/register";
export const URL_LOGIN = "/login";
export const URL_TEACHER_LOGIN = "/teacher/login";
export const URL_SCHOOL_REGISTER = "/school/register";
export const URL_FRGT_PWD = "/forgot-password";
export const URL_RESET_PWD = "/forgot-password/:token";
export const URL_EMAIL_VERIFICATION = "/email-verification/:token";
export const URL_GLOABL_ABOUT_US = "/global-about-us";
export const URL_GLOABL_TERMS_CONDITIONS = "/global-terms-conditions";
export const URL_GLOABL_PRIVACY_POLICY = "/global-privacy-policy";
export const URL_CONTACT_US = "/contact-us";
export const URL_SUBSCRIPTION = "/subscription";
export const URL_SUBSCRIPTION_PAYMENT_STATUS = "/subscription/payment-status";

// COMMON ROUTES
export const URL_PROFILE = "profile";
export const URL_ABOUT_US = "about-us";
export const URL_TERMS_AND_CONDITIONS = "terms-and-conditions";
export const URL_PRIVACY_POLICY = "privacy-policy";
export const URL_CHANGE_PWD = "change-password";
export const URL_DATA_SECURITY = "data-security";

// DASHBOARD ROUTES
export const URL_HISTORY_DASHBOARD = "/history";
export const URL_FOREIGN_LANGUAGE_DASHBOARD = "/foreign-language";
export const URL_SCIENCE_DASHBOARD = "/science";
export const URL_ECONOMIC_DASHBOARD = "/economics";
export const URL_ENGLISH_DASHBOARD = "/english";
export const URL_MATHS_DASHBOARD = "/maths";

// GAME ROUTES
export const URL_RAPID_FIRE = "rapid-fire";
export const URL_RAPID_FIRE_GAME = "rapid-fire-game";
export const URL_START_QUIZ = "quiz";
export const URL_QUIZ = "quiz-game";
export const URL_QUIZ_ANSWER = "quiz-game-answer";

export const URL_CONSTITUTION = "constitution";

export const URL_BILL_OR_NO_BILL = "bill-or-no-bill";
export const URL_BILL_BECOME_LAW = "bill-become-law";

export const URL_PRESENT_OR_PAST = "present-or-past";

export const URL_ESCAPE_ROOM = "escape-room";

export const URL_JEO_PARDY = "jeo-pardy";
export const URL_JEO_PARDY_ANSWER = "jeo-pardy-answer";

export const URL_FINAL_MODULE = "final-module";

export const URL_JUDICIAL_SPIN = "judicial/spin";
export const URL_JUDICIAL_DASHBOARD = "judicial/dashboard";

export const URL_PERIODIC_TABLE = "periodic-table";

export const URL_PURSUIT = "pursuit";
export const URL_PURSUIT_GAME = "pursuit-game";
export const URL_PRESENT_OR_PAST_GAME = "present-or-past-game";
export const URL_CHASER_GAME = "chaser";
export const URL_PURSUIT_FINAL_GAME = "pursuit-final-round";

export const URL_WEAKEST_LINK_GAME = "weakest-link-game";

export const URL_CROSSWORD = "crossword";

export const URL_CONNECT4 = "connect4";
export const URL_CONNECT4_GAME = "connect4-game";

export const URL_MATHAHTZEE = "mathahtzee";
export const URL_MATHAHTZEE_GAME = "mathahtzee-game";

/* 
  routes in this comment are used no where so commented
  export const URL_LANDING_PAGE = "/civics";
  export const URL_SETTINGS = "/settings";
  export const URL_WHITE_HOUSE = "/white-house";
  export const URL_CAPITOL = "/capitol-building";
  export const URL_SUPREME_COURT = "/supreme-court";
  export const URL_CONSTITUTION_QUESTION = "/constitution/question";
  export const URL_US = "/united-states";
*/

export const API_URL = {
	GET_QUIZ_CATEGORY: "quiz/user/points",
	STUDENT_LOG_OUT_GAME: "student/able-to-log-out",
	STUDENT_GAME_CLOCK_TIME: "student/clock-in-clock-out",
	USER_QUIZ_QUESTION: "quiz/user/question",
	PUT_QUIZ_RESULT: "quiz/result",
	GET_GAME_STATUS: "general/studentStatus",
};

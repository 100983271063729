import { createGlobalStyle } from "styled-components";
import { FONTS, responsive } from "./Constants";

import CopperPlate from "Assets/Fonts/Copperplate.ttf";
import CopperPlateLight from "Assets/Fonts/Copperplate-Light.ttf";
import CopperPlateBold from "Assets/Fonts/Copperplate-Bold.ttf";

import MyRiadRegular from "Assets/Fonts/MyRiadPro-Regular.OTF";
import MyRiadLight from "Assets/Fonts/MyRiadPro-Light.otf";
import MyRiadSemiBold from "Assets/Fonts/MyRiadPro-SemiBold.OTF";
import MyRiadBold from "Assets/Fonts/MyRiadPro-Bold.OTF";

import RubikBlack from "Assets/Fonts/Rubik-Black.ttf";
import RubikBold from "Assets/Fonts/Rubik-Bold.ttf";
import RubikMedium from "Assets/Fonts/Rubik-Medium.ttf";
import Rubik from "Assets/Fonts/Rubik.ttf";
import RubikLight from "Assets/Fonts/Rubik-Light.ttf";

import Noteworthy from "Assets/Fonts/Noteworthy-Lt.ttf";
import Chalkduster from "Assets/Fonts/Chalkduster.ttf";

export const UtilityStyles = createGlobalStyle`
    * {
        box-sizing  : border-box;
        outline     : none;
    }
    html,
    body{
        margin: 0;
        padding: 0;
    }
    body{
        font-family         : ${FONTS.PRIMARY};
        line-height         : 1.2;
        min-height          : 100vh;
        position            : relative;
    }
    .full-height{
        height: 100%;
    }
    .full-width {
        width: 100%;
    }

    .flex {
        display: flex;
    }
    .f-v-center{
        align-items: center;
    }
    .f-h-center{
        justify-content: center;
    }
    
    .f-column{
        flex-direction: column;
    }

    .text-center{
        text-align: center;
    }

    .hidden {
        display : none;
    }
    .visibility-none {
        visibility : hidden;
    }
    .visible-md{
        display: none;
        ${responsive.TABLET`display:block`}
    }
    .hidden-md{
        ${responsive.TABLET`display:none`}
    }
    .plain-notification{
        background-color: #fff;
    }

    .elipsis {
        overflow        : hidden;
        text-overflow   : ellipsis;
        white-space     : nowrap;
    }
    
    .no-data-text {
        color       : #707070;
        font-size   : 15px;
        text-align  : center;
        width       : 100%;
    }

    .mb-15 {
        margin-bottom : 15px !important;
    }
    .error-msg {
        margin-top  : 5px;
        margin-left : 12px;
        color       : red;
    }

    .scroll-bar {
        .thumb-vertical {
            background-color    : rgba(66, 222, 251, .5);
            border-radius       : 5px;
            transition          : background-color .3s;

            &:hover {
                cursor : pointer;
                background-color : rgba(66, 222, 251, 1);
            }
            
        }
        .track-vertical {
            background-color:  rgba(66,222,251,.2);
        }
    }

    .gutters{
        padding : 10px 12px; 
        font-family: ${FONTS.TERTIARY_SEMI_BOLD};
        .text {
			font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		}
    }
    .hide-overflow {
        overflow : hidden;
     }

    @font-face {
        font-family : ${FONTS.PRIMARY};
        src         : url(${CopperPlate});
    }
    @font-face {
        font-family : ${FONTS.PRIMARY_LIGHT};
        src         : url(${CopperPlateLight});
    }
    @font-face {
        font-family : ${FONTS.PRIMARY_BOLD};
        src         : url(${CopperPlateBold});
    }
    @font-face {
        font-family : ${FONTS.SECONDARY};
        src         : url(${MyRiadRegular});
    }
    @font-face {
        font-family : ${FONTS.SECONDARY_LIGHT};
        src         : url(${MyRiadLight});
    }
    @font-face {
        font-family : ${FONTS.SECONDARY_SEMI_BOLD};
        src         : url(${MyRiadSemiBold});
    }
    @font-face {
        font-family : ${FONTS.SECONDARY_BOLD};
        src         : url(${MyRiadBold});
    }
    @font-face {
        font-family : ${FONTS.TERTIARY_SEMI_BOLD};
        src         : url(${RubikMedium});
    }
    @font-face {
        font-family : ${FONTS.TERTIARY_BOLD};
        src         : url(${RubikBold});
    }
    @font-face {
        font-family : ${FONTS.TERTIARY_BLACK};
        src         : url(${RubikBlack});
    }
    @font-face {
        font-family : ${FONTS.TERTIARY};
        src         : url(${Rubik});
    }
    @font-face {
        font-family : ${FONTS.TERTIARY_LIGHT};
        src         : url(${RubikLight});
    }
    @font-face {
        font-family : ${FONTS.QUATERNARY};
        src         : url(${Noteworthy});
    }
    @font-face {
        font-family : ${FONTS.QUINARY};
        src         : url(${Chalkduster});
    }
`;

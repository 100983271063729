import styled from "styled-components";

import { Form } from "formik";

// STYLES
import { responsive, FONTS } from "Styles/Constants";
import { TextField } from "@material-ui/core";
import { IMAGES } from "Helpers";

export const PageWrapper = styled.div`
	height: 100vh;
	overflow: hidden;
	background-color: #fff;
	width: 100%;
	background-image: url(${IMAGES.LANDING_PAGE.TEACHER_BACKGROUND});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	.header-container {
		background-color: rgba(255, 255, 255, 0.9);
		.top-bar {
			display: flex;
			justify-content: space-between;
			padding: 1%;
			width: 90%;
			margin: 0px auto;
			.subject-logo {
				background-image: url(${IMAGES.DASHBOARD.SUBJECT_MASTER_LOGO_BLACK});
				width: 110px;
				height: 40px;
				background-repeat: no-repeat;
				background-size: contain;
				cursor: pointer;
			}
			.btn-container {
				display: flex;
				width: auto;
				margin-right: -6%;
				.teacher-btn {
					border-radius: 29.5px;
					width: 180px;
					border: solid 2px rgba(4, 23, 39, 0.3);
					display: flex;
					align-items: center;
					padding: 0px 14px;
					justify-content: center;
					cursor: pointer;
				}
				.login {
					border-radius: 29.5px;
					width: 180px;
					border: solid 2px rgba(4, 23, 39, 0.3);
					display: flex;
					align-items: center;
					padding: 0px 14px;
					justify-content: center;
					margin-left: 10px;
					cursor: pointer;
				}
			}
		}
	}
	.box {
		min-width: 725px;
		${responsive.DISPLAY`min-width : 625px;`}
		.inner-box {
			padding: 10px 10px 50px;
		}
	}
`;

export const FormWrapper = styled(Form)`
	.label-age-range {
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-size: 16px;
		margin: 0 10px;
	}
	.image-box {
		display: flex;
		justify-content: center;
		.avatar {
			cursor: pointer;
			width: 90px;
			height: 90px;
			margin-bottom: 20px;
			${responsive.DISPLAY`
				height	: 80px;
				width	: 80px;
       		`}
		}

		.image-wrapper {
			border: 5px solid #000000;
			border-radius: 20px;
			cursor: pointer;
			margin: 5px auto 15px;
			height: 105px;
			width: 105px;
			background-size: cover;
			background-position: center;

			${responsive.DISPLAY`
            border-width : 3px;
            height	: 80px;
            width	: 80px;
          `}
		}
	}
	.input-wrapper {
		color: black;

		.input-label-wrapper {
			margin: 5px 0;
			${responsive.DISPLAY`margin: 0;`}
		}

		/* &.number {
			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		} */

		.form-select-age {
			width: 100%;
			padding-top: 5px;

			.label {
				font-family: ${FONTS.TERTIARY_SEMI_BOLD};
				font-size: 14px;
				letter-spacing: 1px;
				margin-left: 10px;
				margin-bottom: 7px;
				margin-top: 7px;
				${responsive.DISPLAY`font-size : 11px;`}
			}
			.error {
				font-family: ${FONTS.TERTIARY_SEMI_BOLD};
				bottom: -8px;
				color: #ff0006;
				font-size: 13px;
				left: 10px;
				position: absolute;
			}

			.select-label {
				margin-left: 14%;
				color: #332e44;
				font-size: 13px;
				padding-top: 2px;
				font-family: ${FONTS.TERTIARY_SEMI_BOLD};
			}

			.select-age {
				margin: 6px auto 12px;
				width: 100%;
				height: 40px !important;
				background: transparent;
				border-radius: 10px;
				border: 1px solid grey;
				font-family: ${FONTS.TERTIARY_SEMI_BOLD};
				${responsive.DISPLAY`font-size : 13px; height : 40px`}
			}
		}
	}
	.password-icon {
		fill: #332e44;
		position: absolute;
		right: 10px;
	}
	.button-wrapper {
		display: flex;
		justify-content: center;
		margin: 35px auto 22px;

		.styled {
			width: 200px;
			margin: 0 20px;

			${responsive.DISPLAY`width: 175px;`}
		}
	}
`;

export const TextFieldWrapper = styled(TextField)`
	.outline {
		border-color: #808080 !important;
	}
	.text-root {
		border-radius: 10px;
		font-family: ${FONTS.TERTIARY_SEMI_BOLD} !important;
		height: 40px;
	}

	.label {
		top: -11px;
		left: -8px;
		color: #c9c8b066;
	}

	.gutters {
		padding: 2px 0;
		.text {
			font-family: ${FONTS.TERTIARY_LIGHT};
		}
	}
`;

export const SignUpWrapper = styled.div`
	height: 100%;
	width: 100%;
	.header-container {
		background-color: rgba(255, 255, 255, 0.9);
		.top-bar {
			display: flex;
			justify-content: space-between;
			padding: 1%;
			width: 90%;
			margin: 0px auto;
			.subject-logo {
				background-image: url(${IMAGES.DASHBOARD.SUBJECT_MASTER_LOGO_BLACK});
				width: 110px;
				height: 40px;
				background-repeat: no-repeat;
				background-size: contain;
				cursor: pointer;
			}
			.btn-container {
				display: flex;
				width: auto;
				margin-right: -6%;
				.teacher-btn {
					border-radius: 29.5px;
					width: 180px;
					border: solid 2px rgba(4, 23, 39, 0.3);
					display: flex;
					align-items: center;
					padding: 0px 14px;
					justify-content: center;
					cursor: pointer;
				}
				.login {
					border-radius: 29.5px;
					width: 180px;
					border: solid 2px rgba(4, 23, 39, 0.3);
					display: flex;
					align-items: center;
					padding: 0px 14px;
					justify-content: center;
					margin-left: 10px;
					cursor: pointer;
				}
			}
		}
	}
	.box {
		margin: 0px auto;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		box-shadow: 0 0 20.9px 3.1px rgba(4, 23, 39, 0.5);
		background-image: linear-gradient(to bottom, #fff600, #fffcb3, #fffede, #f7f7eb, #fff);
		border-radius: 8px;
		padding: 14px 30px;
		width: 725px;
		${responsive.DISPLAY`width : 625px;`}

		.title {
			.text {
				color: black;
				margin-top: 0;
				font-family: ${FONTS.TERTIARY};
			}
		}
		.button-parent {
			display: flex;
			justify-content: center;
			margin-top: 15px;
			.button {
				background-color: black;
				color: white;
				width: 50%;
				padding: 1rem;
				border-radius: 10px;
				margin-bottom: 15px;
				margin-top: 25px;
				border-color: #000;
				cursor: pointer;

				.next-font {
					font-family: ${FONTS.TERTIARY_SEMI_BOLD};
					font-size: 18px;
					font-weight: 600;
					text-align: center;
					margin: 0px;
					padding: 0px;
				}
			}

			.loader {
				height: 20px !important;
				width: 20px !important;
				color: white;
			}
		}
	}
`;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";

// COMPONENTS
import { CircularProgress } from "@material-ui/core";

// STYLE
import { DialogWrapper } from "./HowToPlay.style";

import { Api, URL_SCIENCE_DASHBOARD } from "Helpers";

import { useSelector } from "react-redux";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import moment from "moment";
const TYPE = {
	HOW_TO_PLAY: "howToPlay",
	DID_YOU_KNOW: "didYouKnow",
};

const DEFAULT_TYPE = TYPE.DID_YOU_KNOW;
const NEXT_TYPE = TYPE.HOW_TO_PLAY;

const HowToPlay = (props) => {
	const navigate = useNavigate();

	const [content, setContent] = useState({});
	const [type, setType] = useState(DEFAULT_TYPE);
	const [isLoading, setLoading] = useState(false);
	const [pdfLoader, setPdfLoader] = useState(false);

	const profileInfo = useSelector((state) => state.Auth.profileInfo);

	useEffect(() => {
		fetchData();
	}, []); // eslint-disable-line

	async function fetchData() {
		try {
			setLoading(true);

			const data = new FormData();

			data.append("moduleId", props.data.id);
			data.append("subjectId", props?.subjectId);

			const response = await new Api().post("staticPage/game", { data });

			if (response.data) setContent(response.data);

			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	function proceed() {
		if (type === DEFAULT_TYPE) setType(NEXT_TYPE);
		else {
			if (props?.data?.url) {
				const dateTime = moment().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");

				if (props?.schoolId && props.schoolId.length) {
					const data = { type: "LOGIN", studentId: profileInfo?.id, schoolClassTestId: props.schoolId, dateTime: dateTime };

					if (props?.data?.subTitle === "RAPID FIRE") data.moduleName = "RAPIDFIRESCIENCE";

					if (props?.data?.subTitle === "PERIODIC TABLE") data.moduleName = "PERIODIC_TABLE";

					if (data.moduleName) new Api().post("student/clock-in-clock-out", { data });
				}

				navigate(URL_SCIENCE_DASHBOARD + "/" + props.data.url, { state: { schoolClassTestId: props?.schoolId } });
			}

			closeModal();
		}
	}

	const handlePdf = () => {
		setPdfLoader(true);

		let pdfUrl,
			attachedText = "",
			moduleName = props?.data?.id.replace(/ /g, "_").toUpperCase();

		if (type === TYPE.DID_YOU_KNOW) {
			pdfUrl = content?.didYouKnowFile;
			attachedText = `${moduleName}_DID_YOU_KNOW`;
		} else {
			pdfUrl = content?.howToPlayFile;
			attachedText = `${moduleName}_HOW_TO_PLAY`;
		}

		fetch(pdfUrl).then((response) => {
			response.blob().then((blob) => {
				const alink = document.createElement("a");
				const fileURL = window.URL.createObjectURL(blob);

				alink.href = fileURL;
				alink.download = `${attachedText}.pdf`;
				alink.click();

				setPdfLoader(false);
			});
		});
	};

	const closeModal = () => props.handleClose({ url: props.data.url });

	return (
		<DialogWrapper onClose={closeModal} open={props.open} PaperProps={{ elevation: 0 }}>
			<div className="box-modal text-center">
				<div className="title-container">
					<p className="sub-title">{type === TYPE.HOW_TO_PLAY ? "How to play ?" : "Did you know ?"}</p>
				</div>

				<div className="content-wrapper">
					{isLoading && (
						<div className="progress">
							<CircularProgress size={25} />
						</div>
					)}

					<Scrollbars className={"scroll-bar"} renderThumbVertical={ThumbVertical}>
						<div className="content" dangerouslySetInnerHTML={{ __html: content[type] }} />

						{content?.didYouKnowFile && type === TYPE.DID_YOU_KNOW && (
							<div className="attach-file">
								<PictureAsPdfIcon />
								<div className="file" onClick={handlePdf}>
									Attachment
								</div>
								{pdfLoader && (
									<div>
										<CircularProgress className="pdf-loader" />
									</div>
								)}
							</div>
						)}

						{content?.howToPlayFile && type === TYPE.HOW_TO_PLAY && (
							<div className="attach-file">
								<PictureAsPdfIcon />
								<div className="file" onClick={handlePdf}>
									Attachment
								</div>
								{pdfLoader && (
									<div>
										<CircularProgress className="pdf-loader" />
									</div>
								)}
							</div>
						)}
					</Scrollbars>

					<div className={`type-switcher ${type === DEFAULT_TYPE ? "visibility-none" : ""}`}>
						<div className="divider" />

						{type === TYPE.HOW_TO_PLAY && (
							<p className="text" onClick={() => setType(TYPE.DID_YOU_KNOW)}>
								Back to Review
							</p>
						)}

						{type === TYPE.DID_YOU_KNOW && (
							<p className="text" onClick={() => setType(TYPE.HOW_TO_PLAY)}>
								How to play
							</p>
						)}
					</div>

					<div className="play" onClick={proceed}>
						{type === DEFAULT_TYPE ? "How to Play" : "Lets Play"}
					</div>
				</div>
			</div>
		</DialogWrapper>
	);
};

const ThumbVertical = (props) => <div {...props} className="thumb-vertical" />;

export default HowToPlay;

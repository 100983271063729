import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showToast } from "Redux/General/Actions";
import { InputWithLabel } from "Components/Commons";
import { Api, generateValidationSchema, URL_GENERAL_DASHBOARD } from "Helpers";
import { PageWrapper, StyledTextarea } from "./ContactUS.style";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";

const ContactUs = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);

	const formik = useFormik({
		initialValues: {
			email: "",
			name: "",
			message: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				setIsLoading(true);
				let htmlContent = "<p>" + values?.message.trim().replace(/\n/g, "</p><p>") + "</p><br />";
				const payload = {
					email: values.email,
					message: htmlContent,
					name: values.name,
				};
				const response = await new Api().post("contact-us", { data: payload, skipAuth: true });

				if (response.status === 200) {
					dispatch(showToast("Email sent successfully!!!"));
					handleBackToDashboard();
				}
				setIsLoading(false);
			} catch (error) {
				console.log("🚀 ~ file: GlobalLandingPage.jsx ~ line 36 ~ onSubmit: ~ error", error);
			}
		},
	});

	const handleBackToDashboard = () => navigate(URL_GENERAL_DASHBOARD, { replace: true });

	return (
		<PageWrapper isLoading={false}>
			<Box className="header-container">
				<Box className="top-bar">
					<Box className="subject-logo" onClick={handleBackToDashboard}></Box>
					<Box className="btn-container">
						<Box
							className="teacher-btn"
							onClick={() => {
								navigate(-1);
							}}
						>
							<Typography>BACK</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
			<div className="main-container">
				<div className="title">
					<h1 className="text">Contact Us</h1>
				</div>

				<form onSubmit={formik.handleSubmit}>
					<div className="name-container">
						<div className="input-data">
							<InputWithLabel className="full-width" name={"name"} onChange={formik.handleChange} placeholder={"Enter your name"} label={"Name"} value={formik.values.name} />
							<span className="error">{formik.touched.name && formik.errors.name ? formik.errors.name : ""}</span>
						</div>

						<div className="input-data">
							<InputWithLabel className="full-width" name={"email"} onChange={formik.handleChange} placeholder={"Enter your email"} label={"Email"} value={formik.values.email} />
							<span className="error">{formik.touched.email && formik.errors.email ? formik.errors.email : ""}</span>
						</div>
					</div>

					<div className="input-data">
						<label className="label">{"Message"}</label>
						<StyledTextarea maxRows={10} id="message" name="message" minRows={5} placeholder="Enter your Message" value={formik.values.message} onChange={formik.handleChange} />
						<span className="error">{formik.touched.message && formik.errors.message ? formik.errors.message : ""}</span>
					</div>

					<div className="login-button-parent">
						<Button className="button" type="submit" endIcon={isLoading ? <CircularProgress className="loader" /> : ""}>
							<Typography className="login-font">Submit</Typography>
						</Button>
					</div>
				</form>
			</div>
		</PageWrapper>
	);
};

export default ContactUs;

const inputs = [
	{
		label: "Name",
		name: "name",
		validation: { required: true },
	},
	{
		label: "Email",
		name: "email",
		type: "email",
		validation: { required: true, email: true },
	},
	{
		label: "Message",
		name: "message",
		validation: { required: true },
	},
];

const validationSchema = generateValidationSchema(inputs);

import styled from "styled-components";
import { InputBase } from "@material-ui/core";
import { FONTS, responsive } from "Styles/Constants";
import { TextareaAutosize } from "@material-ui/core";
import { IMAGES } from "Helpers";

export const GLPCommonComponentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow: hidden;
	background-color: #fff;
	width: 100%;
	background-image: url(${IMAGES.LANDING_PAGE.TEACHER_BACKGROUND});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	justify-content: space-between;
	/* ${responsive.LAPTOP_1100`
		height : 120vh;
	`} */
	.header-container {
		background-color: rgba(255, 255, 255, 0.9);
		.top-bar {
			display: flex;
			justify-content: space-between;
			padding: 1%;
			width: 90%;
			margin: 0px auto;
			.subject-logo {
				background-image: url(${IMAGES.DASHBOARD.SUBJECT_MASTER_LOGO_BLACK});
				width: 110px;
				height: 40px;
				background-repeat: no-repeat;
				background-size: contain;
				cursor: pointer;
			}
			.btn-container {
				display: flex;
				width: auto;
				margin-right: -6%;
				.teacher-btn {
					border-radius: 29.5px;
					width: 180px;
					border: solid 2px rgba(4, 23, 39, 0.3);
					display: flex;
					align-items: center;
					padding: 0px 14px;
					justify-content: center;
					cursor: pointer;
				}
				.login {
					border-radius: 29.5px;
					width: 180px;
					border: solid 2px rgba(4, 23, 39, 0.3);
					display: flex;
					align-items: center;
					padding: 0px 14px;
					justify-content: center;
					margin-left: 10px;
					cursor: pointer;
				}
			}
		}
	}

	.footer-container {
		background-color: rgba(255, 255, 255, 0.9);
		padding: 5px;
		width: 100%;
		.inner-container {
			display: flex;
			padding: 0% 1%;
			justify-content: space-between;
			.footer-text {
				color: #000;
				font-size: 18px;
				${responsive.TABLET`
					font-size : 13px
				`}
			}

			.page-container {
				display: flex;
				font-size: 16px;
				cursor: pointer;
				${responsive.TABLET`
					font-size : 13px
				`}

				.partition {
					margin: 0px 5px;
				}
			}
		}
	}

	.main-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		.modal {
			width: 28%;
			height: 80%;
			background-repeat: no-repeat;
			position: relative;
			background-size: 100% 100%;
			transform-origin: 0 0;
			transition: transform 3s;
			backface-visibility: hidden;
			transform-style: preserve-3d;
			border-radius: 8px;
			${responsive.DISPLAY`
				width : 28%; 
				height: 80%;
			`}
			${responsive.DISPLAY_1550`
				width: 30%; 
				height: 85%;
			`}
			${responsive.DESKTOP`
				width : 38%; 
				height: 80%;
			`}
			${responsive.LAPTOP`
				width: 40%; 
				height: 80%;
			`}
			${responsive.TABLET`
				width : 60%;
				height: 65%;
			`}
			@media (max-height: 790px) {
				height: 95%;
			}
		}
	}
`;

export const TeacherLoginModalWrapper = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	padding: 39px 53px 80px 55px;
	object-fit: contain;
	border-radius: 10px;
	box-shadow: 0 0 20.9px 3.1px rgba(4, 23, 39, 0.5);
	background-image: linear-gradient(to bottom, #fff600, #fffcb3, #fffede, #f7f7eb, #fff);

	.header {
		width: 208px;
		height: 29px;
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-size: 30px;
		font-weight: 600;
		color: #010101;
		${responsive.DISPLAY_1550`
			font-size: 25px;
		`}
	}

	.text {
		display: flex;
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-size: 16px;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #010101;
	}

	.remember-me {
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-size: 16px;
		text-align: center;
		color: rgba(1, 1, 1, 0.5);
	}
	/* .login-button {
		background-color: #010101;
		color: white;
		width: 413px;
		height: 65px;
		border-radius: 10px;
		background-color: #000;
		.font {
			width: 52px;
			height: 14px;
			font-family: ${FONTS.TERTIARY_SEMI_BOLD};
			font-size: 14px;
			font-weight: 600;
			text-align: center;
			color: #fff;
		}
	} */

	.validation-error {
		color: #f92525;
		font-size: 12px;
		margin-left: 1%;
	}
	.content {
		display: flex;
		flex-direction: column;
		gap: 48px;
		${responsive.DISPLAY_1550`
			gap : 38px
		`}
		.flex-column-parent {
			display: flex;
			flex-direction: column;
			gap: 15px;
			.flex-column {
				display: flex;
				flex-direction: column;
				gap: 10px;
			}
		}

		.remember-me-parent {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin-top: 10px;

			.flex-center {
				display: flex;
				align-items: center;

				.checkbox {
					padding: 0px;
				}

				.remember-me-font {
					margin: 4px 0 0 5px;
					font-family: "Rubik";
					color: rgba(1, 1, 1, 0.5);
					font-size: 14px;
				}
			}

			.forgot-password-parent {
				.forgot-password {
					font-family: ${FONTS.TERTIARY_SEMI_BOLD};
					font-size: 14px;
					text-align: center;
					margin: 0px;
					padding: 0px;
					color: #0056e6;
				}
			}
		}

		.login-button-parent {
			.button {
				background-color: black;
				color: white;
				width: 100%;
				padding: 1rem;
				border-radius: 10px;
				margin-bottom: auto;
				border-color: #000;
				cursor: pointer;

				.login-font {
					font-family: ${FONTS.TERTIARY_SEMI_BOLD};
					font-size: 18px;
					font-weight: 600;
					text-align: center;
					margin: 0px;
					padding: 0px;
				}
			}
			.loader {
				height: 20px !important;
				width: 20px !important;
				color: white;
			}
		}
	}

	.model-footer {
		display: flex;
		flex-direction: column;
		.font {
			font-family: ${FONTS.TERTIARY_SEMI_BOLD};
			text-align: center;
			font-style: normal;
			margin: 0px;
			padding: 0px;
		}
		.dose-not-have-account-font {
			color: rgba(0, 0, 0, 0.5);
			font-size: 18px;
		}
		.register-as-school-font {
			color: #0056e6;
			font-size: 14px;
		}
	}
`;

export const SchoolRegisterModalWrapper = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	padding: 20px 53px 27px 55px;
	object-fit: contain;
	border-radius: 10px;
	box-shadow: 0 0 20.9px 3.1px rgba(4, 23, 39, 0.5);
	background-image: linear-gradient(to bottom, #fff600, #fffcb3, #fffede, #f7f7eb, #fff);
	display: flex;
	flex-direction: column;
	gap: 15px;

	.switch-checked {
		color: #010101;
	}
	.switch-checked + .switch-track {
		background-color: #010101;
		opacity: 0.5;
	}

	@media (max-height: 790px) {
		padding: 15px 40px;
	}

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.form-data {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 100%;
		gap: 20px;

		.button-container {
			display: flex;
			flex-direction: column;
			gap: 20px;
			margin-top: -20px;

			.scroll-dots {
				display: flex;
				justify-content: center;
				gap: 10px;

				.dots {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					cursor: pointer;
				}
			}
		}
	}
	.parent-container {
		display: flex;
		align-items: center;
		flex-direction: row;
		gap: 40px;
		${responsive.DISPLAY_1550`
			margin-top : 15px
		`}
		.upload-image-parent {
			width: 90px;
			height: 90px;
			border-radius: 50%;
		}
		.img {
			width: 90px;
			height: 90px;
			border-radius: 50%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			display: flex;
			margin: auto;
		}
		.upload-image {
			border: 1px solid black;
			border-radius: 10px;
		}
	}

	.school-header-parent {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 2%;
		.header {
			font-family: ${FONTS.TERTIARY_SEMI_BOLD};
			font-size: 30px;
			font-weight: bold;
			text-align: center;
			color: #010101;
			${responsive.DISPLAY_1550`
				font-size: 25px;
				margin: 0 0px -18px 0px
			`}
		}
		.back-to-login {
			font-family: ${FONTS.TERTIARY_SEMI_BOLD};
			font-size: 15px;
			color: #0666fd;
		}

		.icon {
			cursor: pointer;
			position: absolute;
			left: 20px;
			top: 30px;
			@media (max-height: 790px) {
				left: 10px;
				top: 26px;
			}
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		gap: 60px;
		/* margin-top: 10px; */

		.validation-error {
			color: #f92525;
			font-size: 12px;
			margin-left: 4px;
			margin-top: -5px;
		}

		.flex-column-parent {
			display: flex;
			flex-direction: column;
			gap: 20px;
			@media (max-height: 790px) {
				gap: 15px;
			}
			.flex-column {
				display: flex;
				flex-direction: column;
				gap: 10px;
			}
		}
	}

	.text {
		display: flex;
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-size: 16px;
		text-align: center;
		color: #010101;
		@media (max-height: 790px) {
			font-size: 13px;
		}
	}

	.next-button-parent {
		.progress {
			width: 20px !important;
			height: 20px !important;
			color: #fff;
		}

		.button {
			background-color: black;
			color: white;
			width: 100%;
			padding: 1rem;
			border-radius: 10px;
			margin-bottom: auto;
			border-color: #000;
			cursor: pointer;

			.next-font {
				font-family: ${FONTS.TERTIARY_SEMI_BOLD};
				font-size: 18px;
				font-weight: 600;
				text-align: center;
				margin: 0px;
				padding: 0px;
			}
		}
	}
`;

export const DarkInputBase = styled(InputBase)`
	border-radius: 10px;
	color: rgba(1, 1, 1, 0.5);
	padding: 10px 20px 5px;

	${responsive.DISPLAY`
		padding: 4px 20px 5px;
	`}

	width: inherit;
	&.school-register {
		height: 55px;
		${responsive.DESKTOP`
			height : 40px;		
		`}
		${responsive.DISPLAY_1550`height: 40px;
		`}
	}
	&.login {
		height: 65px;
		${responsive.LAPTOP_1050`
			height : 45px;		
		`}
		${responsive.DISPLAY_1550`height: 50px;
		`}
	}
	input::placeholder {
		color: rgba(1, 1, 1, 0.5);
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-weight: normal;
		font-size: 13px;
	}
`;

export const EmailField = styled(DarkInputBase)`
	border: 2px solid grey;
	width: 100%;

	.MuiInputBase-input {
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-size: 16px;
	}
`;

export const PasswordField = styled(DarkInputBase)`
	border: 2px solid grey;
	width: 100%;

	&:hover:not(.Mui-disabled):before {
		border-bottom: none;
	}

	.paswword-icon {
		color: black;
	}

	.MuiInputBase-input {
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-size: 16px;
	}

	.MuiInputBase-input:-webkit-autofill {
	}

	${responsive.DISPLAY`
		width: 100%;
		height : 109%;		
	`}
`;

export const StyledTextarea = styled(TextareaAutosize)`
	border: 2px solid grey;
	border-radius: 10px;
	color: rgba(1, 1, 1, 0.5);
	padding: 10px 20px 5px;
	background-color: transparent;
	border: 2px solid grey;
	resize: vertical;
	font-family: ${FONTS.TERTIARY_SEMI_BOLD};
	font-weight: normal;
	font-size: 16px;
	resize: none;

	&::placeholder {
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-weight: normal;
		font-size: 13px;
		color: #00000036;
	}
`;

export const Input = styled(DarkInputBase)`
	border: 2px solid grey;
	.MuiInputBase-input {
		/* padding-left: 22px; */
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-size: 16px;
	}
`;

export const InputNumber = styled(DarkInputBase)`
	border: 2px solid grey;
`;

import styled from "styled-components";

import { PageWrapperBase } from "Components/Website.style";

// CONST
import { COLORS, FONTS, responsive } from "Styles/Constants";

// IMAGES
import { Dialog } from "@material-ui/core";
import { IMAGES } from "Helpers";

export const PageWrapper = styled(PageWrapperBase)`
	color: #fff;
	position: relative;
	margin-left: -2px;

	.background-image {
		z-index: -1;
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: absolute;
	}

	.content {
		width: 80%;
		max-width: 960px;

		${responsive.LAPTOP`width: 60%;`}
		.overlay-stackrank {
			position: relative;
			.overlay-effect {
				opacity: 0.7;
			}
			.display-circular-progress {
				position: absolute;
				left: 50%;
				bottom: 50%;
			}
			.MuiCircularProgress-colorPrimary {
				color: #fff;
			}
		}

		.title {
			width: 70%;
			margin: 0 auto;

			${responsive.DISPLAY`width: 60%;`}
			${responsive.LAPTOP`width: 80%;`}
            .text {
				font-family: ${FONTS.PRIMARY_LIGHT};
				font-size: 24px;

				${responsive.DISPLAY`font-size: 21px;`}
			}
		}
	}
`;

export const GridContainer = styled.div`
	flex-wrap: wrap;
	.box {
		width: 33.33%;
		margin: 10px 0;

		.disabled {
			pointer-events: none;
			filter: grayscale(80%);
			cursor: not-allowed;
			/* background: linear-gradient(to right, gray 100%, gray); */
		}

		.box-content {
			cursor: pointer;
			margin: 0 auto;
			max-width: 300px;
			position: relative;
			transition: 0.3s;
			width: 90%;

			&:hover {
				transform: scale(1.05);
			}

			${responsive.DISPLAY` max-width : 225px;`}

			.progress-container {
				bottom: 23px;
				padding: 0 10%;
				position: absolute;

				${responsive.DISPLAY` bottom : 19px;`}
			}
		}
	}
`;

export const ProgressWrapper = styled.div`
	text-align: left;

	.outer {
		background-color: #001d46;
		border: 1px solid rgba(59, 219, 255, 0.3);
		border-radius: 50px;
		width: 100%;

		.active {
			border-radius: 50px;
			height: 7px;
			background: #ff0041;
			width: ${(props) => props.value}%;
			transition: 0.5s ease;

			${responsive.DISPLAY`height: 6px;`}
		}
	}

	.text {
		margin-top: 3px;
		${responsive.DISPLAY` font-size : 13px;`}
	}
`;

export const ScoreboardWrapper = styled.div`
	position: absolute;
	right: 17px;
	top: 150px; /* 100 header, 50 spacing */
	min-height: 100px;
	width: 200px;

	${responsive.DISPLAY` width : 170px;`}
	${responsive.LAPTOP` width : 150px;`}

    .title {
		text-align: center;
		position: relative;
		z-index: 4;
		color: #fff;

		.image {
			width: 85%;
		}
		.text-container {
			margin-top: -39px;
			${responsive.DISPLAY`margin-top: -34px;`}

			.text {
				font-size: 18px;
				${responsive.DISPLAY`font-size: 14px; `}
			}
		}
	}

	.people-cards {
		margin-top: 25px;

		.person {
			margin-top: -40px;
			width: 100%;
			height: 230px;
			background-image: url(${IMAGES.CIVICS.DASHBOARD.SCOREBOARD});
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			position: relative;

			&:nth-child(1) {
				z-index: 3;
			}
			&:nth-child(2) {
				z-index: 2;
			}
			&:nth-child(3) {
				z-index: 1;
			}

			${responsive.DISPLAY`height : 200px;`}
			${responsive.LAPTOP`height : 180px;`}

            .content {
				padding-top: 25px;

				.image {
					border: 3px solid ${COLORS.SECONDARY};
					border-radius: 10px;
					margin: 0 auto;

					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;

					height: 90px;
					width: 90px;

					${responsive.DISPLAY`height: 70px; width: 70px;`}
					${responsive.LAPTOP`height: 50px; width: 50px;`}
				}
				.name {
					font-size: 20px;
					margin-top: 5px;
					color: #fff;
					${responsive.DISPLAY`font-size: 18px;`}
				}

				.score {
					background-color: #0c0d47;
					border-radius: 5px;
					display: inline-block;
					padding: 2px 12px;
					color: #fff;
				}
			}
		}
	}
`;

export const DialogWrapper = styled(Dialog)`
	.backgorund-root {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.paper {
		background: transparent;
		color: #fff;
		overflow: visible;
	}

	.box {
		min-width: 600px;

		.title-container {
			position: relative;
			.title {
				margin-bottom: 10px;
			}

			.sub-title {
				font-size: 22px;
				margin-top: 10px;
			}

			.icon-container {
				cursor: pointer;
				position: absolute;
				right: 23px;
				top: -4px;

				.icon {
					color: ${COLORS.SECONDARY};
					font-size: 35px;
				}
			}
		}

		.content-wrapper {
			padding: 10px 10px 35px;
			position: relative;

			.content {
				padding-right: 10px;
			}

			.disabled {
				pointer-events: none;
				filter: grayscale(80%);
				cursor: not-allowed;
			}

			.frames {
				display: flex;
				justify-content: center;

				.frame {
					margin: 20px;
					width: 30%;
					height: 150px;
					background-image: url(${IMAGES.CIVICS.DASHBOARD.SCOREBOARD});
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
					cursor: pointer;

					.image {
						height: 100%;

						.bill {
							display: flex;
							margin: 0 auto;
							justify-content: center;
							align-items: center;
							width: 50%;
							height: 100%;
							background-image: url(${IMAGES.CIVICS.BILL_OR_NO_BILL.TITLE});
							background-repeat: no-repeat;
							background-size: contain;
							background-position: center;
						}
						.law {
							display: flex;
							margin: 0 auto;
							justify-content: center;
							align-items: center;
							width: 50%;
							height: 100%;
							background-image: url(${IMAGES.CIVICS.LAWBILL.BILL});
							background-repeat: no-repeat;
							background-size: contain;
							background-position: center;
						}
					}
				}
			}
		}
	}
	.button-wrapper {
		margin-top: -25px;
		text-align: center;

		.styled {
			min-width: 185px;
		}
	}
`;

import { Dialog, InputBase } from "@material-ui/core";
import styled from "styled-components";
import { COLORS } from "Styles/Constants";

const MODAL_WIDTH = 600;

export const DialogWrapper = styled(Dialog)`
	.MuiBackdrop-root {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.MuiDialog-paper {
		background: transparent;
		color: #fff;
		overflow: visible;
	}

	.box {
		min-width: ${MODAL_WIDTH}px;

		.inner-box {
			align-items: center;
			display: flex;
			min-height: 400px;
			&::before {
				z-index: 1;
			}
		}

		.content {
			padding: 30px;
			.question-wrapper {
				margin-bottom: 30px;

				.question {
					font-size: 25px;
				}

				.points {
					border-radius: 3px;
					height: 20px;
					background: #1688fd;
					font-size: 17px;
					padding: 0 10px;
					width: max-content;
					margin: 0 0 0 auto;
				}
			}

			.options-wrapper {
				width: 85%;
				margin: 0 auto;

				.option-hint {
					opacity: 0.5;
				}

				.radio-group {
					flex-direction: row;
				}

				.checkbox,
				.radio {
					width: 100%;
					margin-right: 0;

					.icon {
						color: ${COLORS.SECONDARY};
					}
					.MuiFormControlLabel-label {
						font-size: 20px;
						text-align: left;
						word-break: break-all;
					}
				}

				.radio {
					.icon {
					}
				}
			}
		}

		.progress-container {
			flex-direction: column;
			.result {
				.text {
					font-size: 18px;
					margin-bottom: 0;
				}
				.sub-text {
					margin: 0;
				}
			}
		}

		.result-container {
			flex-direction: column;
			width: 100%;

			.title {
				font-size: 32px;

				&.correct {
					color: ${COLORS.GREEN};
				}
				&.incorrect {
					color: ${COLORS.DANGER};
				}
			}

			.sub-title {
				margin: 15px 0;
				width: 100%;
				display: flex;
				justify-content: space-evenly;
				.correct {
					display: flex;
					flex-direction: column;
					justify-content: center;
					.text {
						font-size: 25px;
					}
					.number {
						color: ${COLORS.GREEN};
						font-size: 30px;
					}
				}
				.in-correct {
					display: flex;
					flex-direction: column;
					justify-content: center;
					.text {
						font-size: 25px;
					}
					.number {
						color: ${COLORS.DANGER};
						font-size: 30px;
					}
				}
			}

			.score-wrapper {
				margin-top: 35px;

				.text {
					color: rgba(255, 255, 255, 0.7);
				}
				.score {
					font-size: 35px;
				}
			}
		}
	}

	.button-wrapper {
		position: absolute;
		bottom: -22px;
		text-align: center;

		.btn {
			min-width: 175px;
		}
	}
`;

export const DarkInputBase = styled(InputBase)`
	background-color: #000017 !important;
	border-radius: 5px !important;
	color: ${COLORS.SECONDARY} !important;
	padding: 7px 20px 5px !important;

	input::placeholder {
		color: #fff !important;
	}
`;

export const Input = styled(DarkInputBase)`
	border: 3px solid rgba(66, 222, 251, 0.2) !important;
`;

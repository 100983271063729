import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useSound from "use-sound";
import { List, ListItem, Avatar, Typography, ListItemText, Collapse } from "@material-ui/core";
// STYLES
import { SidebarWrapper } from "./Sidebar.style";
// ROUTES
import { englishSidebarRoutes } from "Routes/RoutesList";
// COMPONENTS
import CrosswordHowToPlay from "Components/English/CrossWord/Modal/HowToPlay";
import RapidFireHowToPlay from "Components/English/RapidFire/Modal/HowToPlay";
// SOUNDS
import DrawerSound from "Assets/Sounds/switch-off.mp3";
// HELPERS
import { Api, commonSidebarListItems, IMAGES } from "Helpers";
import {
	URL_PROFILE,
	URL_CHANGE_PWD,
	URL_ABOUT_US,
	URL_PRIVACY_POLICY,
	URL_TERMS_AND_CONDITIONS,
	URL_ENGLISH_DASHBOARD,
	URL_CROSSWORD,
	URL_RAPID_FIRE,
	URL_CONTACT_US,
	URL_DATA_SECURITY,
} from "Helpers/Paths";
// REDUX
import { logOutUser } from "Redux/Auth/Actions";

const getMenuItemSelected = (currentLocation, route) => {
	switch (currentLocation) {
		case URL_ENGLISH_DASHBOARD:
			return route === currentLocation;

		case `${URL_ENGLISH_DASHBOARD}/${URL_RAPID_FIRE}`:
			return URL_RAPID_FIRE.includes(route);

		case `${URL_ENGLISH_DASHBOARD}/${URL_CROSSWORD}`:
			return URL_CROSSWORD.includes(route);

		case `${URL_PROFILE}`:
			return `${URL_PROFILE}` === route;

		case `${URL_ABOUT_US}`:
			return `${URL_ABOUT_US}` === route;

		case `${URL_PRIVACY_POLICY}`:
			return `${URL_PRIVACY_POLICY}` === route;

		case `${URL_TERMS_AND_CONDITIONS}`:
			return `${URL_TERMS_AND_CONDITIONS}` === route;

		case `${URL_DATA_SECURITY}`:
			return `${URL_DATA_SECURITY}` === route;

		case `${URL_CHANGE_PWD}`:
			return `${URL_CHANGE_PWD}` === route;

		case `${URL_CONTACT_US}`:
			return `${URL_CONTACT_US}` === route;

		default:
			return false;
	}
};

const EnglishSideBar = (props) => {
	const { open } = props;

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const { gamesStatus } = useSelector((state) => state.GamesData);
	const { totalScore, profileInfo, subjectAccessList: subjects, schoolClassTestID } = useSelector((state) => state.Auth);
	const sidebarListItems = profileInfo?.isFromClassLink ? commonSidebarListItems.filter((list) => list.text !== "Change Password") : commonSidebarListItems;

	const [play] = useSound(DrawerSound, { volume: 0.2 });

	const subjectId = subjects?.filter((item) => item.subject === "English")[0].subjectId;
	const englishRoutes = englishSidebarRoutes.filter((route) => route.order).sort((a, b) => a.order - b.order);
	const routes = location.pathname.includes("english") && englishRoutes;

	const [settingsOpen, setSettingsOpen] = useState(false);
	const [rapidFireHowToPlay, setRapidFireHowToPlay] = useState({ open: false, data: {} });
	const [crosswordHowToPlay, setCrosswordHowToPlay] = useState({ open: false, data: {} });

	useEffect(() => {
		if (open) props.toggleDrawer();
	}, [location.pathname]); // eslint-disable-line

	const redirectAndClose = (path) => {
		path = path === "/" + URL_ENGLISH_DASHBOARD ? URL_ENGLISH_DASHBOARD : path;

		let setStateFunction;

		if (path === "/" + URL_RAPID_FIRE) setStateFunction = setRapidFireHowToPlay;
		if (path === "/" + URL_CROSSWORD) setStateFunction = setCrosswordHowToPlay;

		if (setStateFunction) {
			setStateFunction((prev) => {
				const copyPrev = { ...prev };

				if (!copyPrev.open) {
					props.toggleDrawer();

					if (path === "/" + URL_RAPID_FIRE) copyPrev.data = { id: "Rapid Fire English", subTitle: "RAPID FIRE", url: "rapid-fire" };
					if (path === "/" + URL_CROSSWORD) copyPrev.data = { id: "Cross Word", subTitle: "CROSSWORD", url: "crossword" };
				}

				copyPrev.open = !copyPrev.open;

				return copyPrev;
			});
		} else {
			props.toggleDrawer();
			navigate(path);
		}
	};

	const handleLogOut = () => {
		const data = new FormData();

		data.append("token", localStorage.getItem("accessToken"));

		new Api().post("user/logout", { data });

		dispatch(logOutUser());
	};

	const handleCloseCrossWord = () => setCrosswordHowToPlay((prev) => ({ ...prev, open: !prev.open }));

	const handleClose = ({ url }) => redirectAndClose("/" + url);

	return (
		<>
			<SidebarWrapper
				open={open}
				anchor="left"
				english={true}
				location={location}
				onClose={props.toggleDrawer}
				BackdropProps={{ className: "backdrop-root" }}
				classes={{ root: "root", paper: "drawer-paper" }}
			>
				<List>
					<ListItem className="menu-items profile-details">
						<Avatar variant="square" className="img-profile" src={profileInfo.profilePicUrl || IMAGES.PROFILE.BOY} />

						<span className="text">
							{profileInfo.firstName} {profileInfo.lastName}
						</span>

						<Typography variant="body1" className="score">
							{totalScore}
						</Typography>
					</ListItem>

					{routes.map((route, index) => (
						<Fragment key={index}>
							{!route.expand ? (
								<ListItem
									button
									onClick={() => redirectAndClose("/" + route.path)}
									disabled={route?.activeKey ? !gamesStatus?.[route?.activeKey] : false}
									className={`menu-items ${getMenuItemSelected(location.pathname, route.path) ? "active" : ""}`}
								>
									{route.icon ? <img className="icon" src={route.icon} alt={route.name} /> : <img className="icon" src={IMAGES.COMMONS.HOME} alt={route.name} />}
									<ListItemText className="menu-text" primary={route.name} onMouseOver={play} />
								</ListItem>
							) : (
								<>
									<ListItem button className="menu-items" onClick={() => setSettingsOpen(!settingsOpen)}>
										<img className="icon" src={IMAGES.COMMONS.SETTINGS_ICON} alt={route.name} />
										<ListItemText className="menu-text" primary={route.name} onMouseOver={play} />
										{settingsOpen ? (
											<img className="arrow" alt="less-arrow" src={IMAGES.SIDEBAR.LESS_ARROW} height="30px" width="30px" />
										) : (
											<img className="arrow" alt="more-arrow" src={IMAGES.SIDEBAR.MORE_ARROW} height="30px" width="30px" />
										)}
									</ListItem>

									<Collapse in={settingsOpen} timeout="auto" unmountOnExit>
										<List component="div" disablePadding>
											{sidebarListItems.map((listItem) => (
												<ListItem
													button
													key={listItem.text}
													onClick={() => redirectAndClose(listItem.clickParams)}
													className={`menu-items settings ${getMenuItemSelected(location.pathname, listItem.clickParams) ? "active" : ""}`}
												>
													<ListItemText className="menu-text" primary={listItem.text} />
												</ListItem>
											))}

											<ListItem button className="menu-items settings" onClick={handleLogOut}>
												<ListItemText className="menu-text" primary="Logout" />
											</ListItem>
										</List>
									</Collapse>
								</>
							)}
						</Fragment>
					))}
				</List>
			</SidebarWrapper>

			{crosswordHowToPlay.open && (
				<CrosswordHowToPlay open={crosswordHowToPlay.open} handleClose={handleCloseCrossWord} data={crosswordHowToPlay.data} subjectId={subjectId} schoolId={schoolClassTestID} />
			)}

			{rapidFireHowToPlay.open && (
				<RapidFireHowToPlay open={rapidFireHowToPlay.open} handleClose={handleClose} data={rapidFireHowToPlay.data} subjectId={subjectId} schoolId={schoolClassTestID} />
			)}
		</>
	);
};

export default EnglishSideBar;

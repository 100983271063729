import styled, { keyframes } from "styled-components";
import { Dialog } from "@material-ui/core";
// STYLES
import { COLORS, responsive } from "Styles/Constants";
import { IMAGES } from "Helpers";

const INDICATOR_BORDER_WIDTH = "2px";

const borderAnimation = keyframes`
    0% { opacity : 1 }
    50% { opacity : 0.5 }
    100% { opacity : 1 }
`;

const indicatorAnimation = keyframes`
    0% { width: calc(100% + 2px); height: calc(100% + 2px); visibility : visible; }
    75% { width: 0%; height: calc(100% + 2px); visibility : visible; }
    99.9% { width: 0%; height: 0%; visibility : visible; }
    100% { width: 0%; height: 0%; visibility : hidden; }
`;

export const GamePlayWrapper = styled.div`
	width: 100%;
	color: #fff;
	display: flex;
	min-height: 100vh;
	position: relative;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	/* background-size: 100% 100%; */
	background-attachment: fixed;

	.background-image {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: -1;
		overflow: hidden;
	}

	.board-container {
		width: 1000px;
		height: 800px;
		display: flex;
		position: relative;
		align-items: center;
		flex-direction: column;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.ENGLISH.RAPID_FIRE.QUESTION_MODAL});

		${responsive.DISPLAY`
			width: 780px;
    		height: 660px;
		`}

		${responsive.DESKTOP`
			width: 670px;
			height: 570px;
		`}

		.progress {
			width: 100%;
			height: 100%;
			display: flex;
			position: absolute;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			.wait-text {
				color: #000;
				margin-top: 10px;
			}
		}

		.timer {
			color: #fff;
			font-size: 28px;
			margin-top: 80px;
			position: relative;
			margin-left: -265px;

			${responsive.DISPLAY`
				font-size: 20px;
				margin-top: 78px;
				margin-left: -215px;
			`}

			${responsive.DESKTOP`
				font-size: 18px;
				margin-top: 66px;
				margin-left: -186px;
			`}
		}

		.initial-load-container {
			color: #000;
			display: flex;
			margin-top: 360px;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			${responsive.DISPLAY`
				margin-top: 300px;
			`}

			${responsive.DESKTOP`
				margin-top: 270px;
			`}

			.text {
				margin: 0px;
				font-size: 24px;
				text-align: center;
				margin-bottom: 10px;

				${responsive.DESKTOP`
					font-size: 18px;
					margin-bottom: 5px;
				`}
			}

			.sub-text {
				margin: 0px;
				font-size: 18px;
				text-align: center;

				${responsive.DESKTOP`
					font-size: 16px;
				`}
			}
		}

		.image {
			height: 110px;
			margin-top: 95px;
			object-fit: contain;

			${responsive.DISPLAY`
				margin-top: 75px;
			`}

			${responsive.DESKTOP`
				height: 100px;
				margin-top: 65px;
			`}
		}

		.question {
			width: 65%;
			color: #41291b;
			font-size: 30px;
			margin-top: 20px;
			font-weight: bold;
			text-align: center;

			${responsive.DISPLAY`
				font-size: 24px;
				margin-top: 10px;
			`}

			${responsive.DESKTOP`
				font-size: 18px;
				margin-top: 10px;
			`}
		}

		.option-container {
			gap: 30px;
			width: 75%;
			display: flex;
			flex-wrap: wrap;
			margin-top: 35px;
			padding: 10px 70px;
			margin-bottom: 20px;
			align-items: center;
			justify-content: space-between;

			${responsive.DISPLAY`
				gap: 20px;
				width: 82%;
				margin-top: 20px;
				margin-bottom: 10px;
			`}

			${responsive.DESKTOP`
				gap: 20px;
				width: 85%;
				margin-top: 12px;
				margin-bottom: 10px;
			`}

			.option {
				color: #fff;
				display: flex;
				cursor: pointer;
				min-height: 80px;
				font-weight: bold;
				text-align: center;
				position: relative;
				align-items: center;
				transition: all 0.3s;
				width: calc(50% - 20px);
				background-size: 100% 100%;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url(${IMAGES.ENGLISH.RAPID_FIRE.OPTION});

				${responsive.DISPLAY`
					min-height: 65px;
					width: calc(50% - 10px);
				`}

				${responsive.DESKTOP`
					min-height: 50px;
					width: calc(50% - 10px);
				`}

				&.disabled {
					cursor: default;
				}

				&.green {
					background-size: 100% 100%;
					background-image: url(${IMAGES.ENGLISH.RAPID_FIRE.CORRECT_OPTION});
				}

				&.red {
					background-size: 100% 100%;
					background-image: url(${IMAGES.ENGLISH.RAPID_FIRE.INCORRECT_OPTION});
				}

				&.blinking {
					animation-duration: 2s;
					animation-name: ${borderAnimation};
					animation-iteration-count: infinite;
					animation-timing-function: ease-in-out;
				}

				.option-text {
					width: 82%;
					font-size: 24px;
					margin-left: 9%;
					margin-bottom: 6px;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					overflow: hidden;
					color: #41291b;

					${responsive.DISPLAY`
						margin-top: 4px;
						font-size: 20px;
					`}

					${responsive.DESKTOP`
						margin-top: 5px;
						font-size: 18px;
					`}
				}
			}
		}
	}

	.confetti {
		width: 100%;
		height: 100%;
	}

	.wrapped-up-container {
		width: 540px;
		height: 700px;
		display: flex;
		margin-top: 10px;
		align-items: center;
		flex-direction: column;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.ENGLISH.RAPID_FIRE.CONGRATULATIONS_BACKGROUND});

		&.lose {
			background-image: url(${IMAGES.ENGLISH.RAPID_FIRE.BETTER_LUCK_BACKGROUND});
		}

		${responsive.DISPLAY`
			width: 465px;
    		height: 600px;
		`}

		${responsive.DESKTOP`
			width: 355px;
    		height: 450px;
		`}

		.title {
			color: #000;
			font-size: 34px;
			font-weight: bold;
			margin-top: 245px;

			${responsive.DISPLAY`
				font-size: 24px;
    			margin-top: 215px;
			`}

			${responsive.DESKTOP`
				font-size: 16px;
    			margin-top: 160px;
			`}
		}

		.score-container {
			gap: 30px;
			display: flex;
			margin-top: 30px;
			flex-wrap: nowrap;
			align-items: center;

			${responsive.DISPLAY`
				margin-top: 20px;
			`}

			${responsive.DESKTOP`
				gap: 15px;
    			margin-top: 12px;
			`}

			.score {
				width: 100px;
				height: 100px;
				display: flex;
				border-radius: 30px;
				align-items: center;
				flex-direction: column;
				justify-content: center;
				border: solid 4px #896032;
				background-color: #fcdfa7;

				${responsive.DISPLAY`
					width: 85px;
					height: 85px;
					border-radius: 20px;
					border: solid 3px #896032;
				`}

				${responsive.DESKTOP`
					width: 60px;
    				height: 60px;
				`}

				.score-text {
					font-size: 36px;

					${responsive.DISPLAY`
						font-size: 30px;
					`}

					${responsive.DESKTOP`
						font-size: 22px;
					`}

					&.correct {
						color: #06c91d;
					}

					&.incorrect {
						color: #ff0e02;
					}
				}

				.status-text {
					font-size: 12px;
					font-weight: bold;
					letter-spacing: 0.64px;
					color: rgba(65, 41, 27, 0.5);

					${responsive.DISPLAY`
						font-size: 10px;
					`}

					${responsive.DESKTOP`
						font-size: 7px;
					`}
				}
			}

			.vs-text {
				font-size: 28px;
				font-weight: bold;
				color: rgba(65, 41, 27, 0.5);

				${responsive.DISPLAY`
					font-size: 18px;
				`}

				${responsive.DESKTOP`
					font-size: 12px;
				`}
			}
		}

		.attempt-container {
			width: 280px;
			height: 105px;
			display: flex;
			margin-top: 35px;
			margin-bottom: 25px;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(${IMAGES.ENGLISH.RAPID_FIRE.WRAPPED_UP});

			${responsive.DISPLAY`
				width: 240px;
    			height: 84px;
				font-size: 14px;
				margin-bottom: 10px;
			`}

			${responsive.DESKTOP`
				width: 180px;
    			height: 65px;
				font-size: 10px;
				margin-top: 30px;
				margin-bottom: 18px;
			`}

			.attempt-text {
				color: #ffe400;
				font-size: 18px;

				${responsive.DISPLAY`
					font-size: 16px;
				`}

				${responsive.DESKTOP`
					font-size: 12px;
				`}
			}

			.wrapped-up-text {
				color: #e04704;
				font-size: 15px;

				${responsive.DISPLAY`
					font-size: 13px;
				`}

				${responsive.DESKTOP`
					font-size: 10px;
				`}
			}
		}

		.navigation-container {
			gap: 20px;
			display: flex;
			align-items: center;

			.navigation-btn-image {
				width: 60px;
				height: 60px;
				cursor: pointer;

				${responsive.DISPLAY`
					width: 50px;
    				height: 50px;
					margin-top: 15px;
				`}

				${responsive.DESKTOP`
					width: 40px;
    				height: 40px;
					margin-top: 0px;
				`}
			}
		}
	}
`;

export const RapidFireDialogWrapper = styled(Dialog)`
	.MuiBackdrop-root {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.MuiDialog-paper {
		background: transparent;
		color: black;
		overflow: visible;
	}

	.MuiDialog-paperWidthSm {
		max-width: 900px;
	}

	.box-modal {
		width: 880px;
		height: 750px;
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.ENGLISH.RAPID_FIRE.HOW_TO_PLAY});

		${responsive.DISPLAY`
			width: 755px;
			height: 580px;
		`}

		${responsive.DESKTOP`
			width: 680px;
    		height: 520px;
		`}

		.title-container {
			.sub-title {
				color: #000;
				font-size: 26px;
				margin-top: 68px;
				font-weight: bold;
				margin-left: -10px;

				${responsive.DISPLAY`
					font-size: 24px;
    				margin-top: 58px;
				`}

				${responsive.DESKTOP`
					font-size: 20px;
					margin-top: 52px;
					margin-left: 0px;
				`}
			}
		}

		.content-wrapper {
			height: 400px;
			padding: 0px 100px;
			position: relative;

			${responsive.DISPLAY`
				height: 330px;
				font-size: 14px;
			`}

			${responsive.DESKTOP`
				height: 310px;
				font-size: 12px;
			`}

			.scroll-bar {
				.thumb-vertical {
					background-color: #96624c;
				}
			}

			.content {
				width: 90%;
				color: #000;
				margin: 2% auto 0px;
			}

			.attach-file {
				display: flex;
				justify-content: center;

				.icon {
					color: #fff;
				}

				.file {
					margin: 0px 10px;
					font-size: 18px;
					cursor: pointer;
					text-decoration: underline;
					color: #fff;
				}

				.pdf-loader {
					width: 20px !important;
					height: 20px !important;
					color: #fff;
					margin-top: 1px;
				}
			}

			.progress {
				position: absolute;
				top: 36%;
				left: 0;
				right: 0;
				color: #000;
			}

			.type-switcher {
				.divider {
					background-color: #000;
					height: 2px;
					width: 50%;
					margin: 5px auto;
					opacity: 0.5;
				}

				.text {
					color: #000;
					cursor: pointer;
					transition: 0.3s;
				}
			}
		}

		.play-btn {
			color: #000;
			height: 68px;
			cursor: pointer;
			font-size: 22px;
			margin-top: 45px;
			font-weight: bold;
			padding-top: 20px;
			text-align: center;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(${IMAGES.ENGLISH.RAPID_FIRE.HOW_TO_PLAY_BUTTON});

			${responsive.DISPLAY`
				height: 60px;
				font-size: 20px;
				margin-top: 50px;
				padding-top: 18px;
			`}

			${responsive.DESKTOP`
				height: 50px;
				font-size: 18px;
				margin-top: 35px;
				padding-top: 14px;
			`}
		}
	}
`;

export const Indicators = styled.div`
	width: 265px;
	height: 60px;
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	border: ${INDICATOR_BORDER_WIDTH} solid #fff36b;

	${responsive.DISPLAY`
		width: 213px;
    	height: 50px;
	`}

	${responsive.DESKTOP`
		width: 182px;
    	height: 48px;
	`}

	&.animated {
		&:before,
		&:after {
			content: "";
			transition: 0s;
			position: absolute;
			visibility: visible;
			width: calc(100% + 2px);
			height: calc(100% + 2px);
		}

		&:before {
			right: -${INDICATOR_BORDER_WIDTH};
			bottom: -${INDICATOR_BORDER_WIDTH};
			border-top: ${INDICATOR_BORDER_WIDTH} solid ${COLORS.DANGER};
			border-right: ${INDICATOR_BORDER_WIDTH} solid ${COLORS.DANGER};
		}

		&:after {
			top: -${INDICATOR_BORDER_WIDTH};
			left: -${INDICATOR_BORDER_WIDTH};
			border-left: ${INDICATOR_BORDER_WIDTH} solid ${COLORS.DANGER};
			border-bottom: ${INDICATOR_BORDER_WIDTH} solid ${COLORS.DANGER};
		}

		&.running {
			&:before,
			&:after {
				animation-duration: 7.5s;
				animation-fill-mode: forwards;
				animation-timing-function: linear;
				animation-name: ${indicatorAnimation};
			}
			&:after {
				animation-delay: 7.5s;
			}
		}
	}
`;

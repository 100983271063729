import React from "react";
// STYLE
import { DialogWrapper } from "./EmailSuccessModal.style";

function ProfileImagePicker(props) {
	return (
		<DialogWrapper onClose={props.handleClose} open={props.open} classes={{ root: "background-root", paper: "paper" }}>
			<div className="box">
				<div className="content">
					<div className="heading">User Successfully Registered!!!</div>
					<div className="pre-defined">
						<div className="title">We will sending you email for verification please verify it.</div>
					</div>
				</div>
			</div>
		</DialogWrapper>
	);
}

export default ProfileImagePicker;

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
// HELPERS
import { URL_GLOBAL_LANDING_PAGE } from "Helpers/Paths";

const ProtectedRoute = (props) => {
	const { isLoggedIn, children } = props;

	return <div>{!isLoggedIn ? <Navigate to={URL_GLOBAL_LANDING_PAGE} /> : <Fragment>{children}</Fragment>}</div>;
};

const mapReduxStateToProps = (state) => ({ isLoggedIn: state.Auth.isLoggedIn });

export default connect(mapReduxStateToProps, null)(ProtectedRoute);

import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider as MuiThemeProvider, StylesProvider, createTheme } from "@material-ui/core/styles";
// STYLES
import { UtilityStyles } from "./Styles/Utils";
import { THEME_SETTINGS } from "./themeSettings";
// HELPERS
import { Socket } from "Helpers/SocketClient";
// COMPONENTS
import Website from "./Components/Website";
// REDUX
import { store } from "./Redux/Store";

const theme = createTheme(THEME_SETTINGS);

function App() {
	useEffect(() => {
		return () => {
			if (Socket) Socket.close();
		};
	}, []);

	return (
		<Provider store={store}>
			<BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
				<StylesProvider injectFirst>
					<MuiThemeProvider theme={theme}>
						<Website />
						<UtilityStyles />
					</MuiThemeProvider>
				</StylesProvider>
			</BrowserRouter>
		</Provider>
	);
}

export default App;

import React, { useState } from "react";
import PropTypes from "prop-types";

import { CircularProgress, MenuItem } from "@material-ui/core";

import { TextField, InputWrapper, SelectWrapper, ButtonWrapper, Menu, SimpleButtonWrapper, ForeignLangQuizBtnWrapper } from "./Commons.style";
// IMAGES
import { IMAGES } from "Helpers";

/* BUTTONS */
function AnimatedButton(props) {
	let { className, children, enableStartIcon, enableEndIcon, classes, isLoading, extraBorder, ...rest } = props;

	function Arrow() {
		return <img src={IMAGES.COMMONS.WHITE_ARROW} alt="" className="image" />;
	}

	function Progress() {
		return (
			<div className="flex">
				<CircularProgress color="secondary" size={20} />
			</div>
		);
	}

	return (
		<ButtonWrapper
			className={"btn styled " + (className || "")}
			color="inherit"
			startIcon={enableStartIcon && <Arrow />}
			endIcon={isLoading ? <Progress /> : enableEndIcon && <Arrow />}
			classes={{
				startIcon: "icon-container start",
				endIcon: "icon-container end",
				...classes,
			}}
			{...rest}
		>
			{children}

			{extraBorder && <div className="extra-border"></div>}
		</ButtonWrapper>
	);
}

AnimatedButton.propTypes = {
	primaryFonts: PropTypes.bool,
};

function SimpleButton(props) {
	let { className, children, enableStartIcon, enableEndIcon, classes, isLoading, extraBorder, ...rest } = props;

	function Arrow() {
		return <img src={IMAGES.COMMONS.WHITE_ARROW} alt="" className="image" />;
	}

	function Progress() {
		return (
			<div className="flex">
				<CircularProgress color="secondary" size={20} />
			</div>
		);
	}

	return (
		<SimpleButtonWrapper
			className={"btn styled " + (className || "")}
			color="inherit"
			startIcon={enableStartIcon && <Arrow />}
			endIcon={isLoading ? <Progress /> : enableEndIcon && <Arrow />}
			classes={{
				startIcon: "icon-container start",
				endIcon: "icon-container end",
				...classes,
			}}
			{...rest}
		>
			{children}

			{extraBorder && <div className="extra-border"></div>}
		</SimpleButtonWrapper>
	);
}

SimpleButton.propTypes = {
	primaryFonts: PropTypes.bool,
};

/* INPUT */
export function Input(props) {
	return <TextField {...props} />;
}

export function InputWithLabel(props) {
	let { label, ...rest } = props;
	return (
		<InputWrapper className={`input-label-wrapper ${props.type === "number" && "number"}`} {...rest}>
			<label className="label" style={props.signupform && { color: "black" }}>
				{label}
			</label>
			<TextField {...rest} />

			<div className={props.name === "confirmPassword" ? "confirmPassword-error" : "error elipsis"}>{props.validated}</div>
		</InputWrapper>
	);
}

export function SelectWithLabel(props) {
	const [anchorEl, setAnchorEl] = useState(null);

	let { label, values, ...rest } = props;

	function closeMenu() {
		setAnchorEl(null);
	}

	function onChange(value) {
		props.onChange(value);
		closeMenu();
	}

	return (
		<SelectWrapper>
			<label className="label">{label}</label>

			<div className="select">
				<TextField onFocus={(e) => e.target.blur()} onClick={(event) => setAnchorEl(event.currentTarget)} {...rest} />
				<div className="icon-container">
					<img alt="img" className={"icon"} src={IMAGES.COMMONS.DOWN_ARROW} />
				</div>
			</div>
			<Menu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => closeMenu()}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				keepMounted
				fullWidth
			>
				{values.map((item) => (
					<MenuItem className="menu-item" key={item.value} value={item.value} onClick={() => onChange(item.value)}>
						{item.display}
					</MenuItem>
				))}
			</Menu>

			<div className="error elipsis">{props.validated}</div>
		</SelectWrapper>
	);
}

export { AnimatedButton, ButtonWrapper, SimpleButton };

export const ForeignLangQuizBtn = (props) => {
	const { children, clickHandler, btnText, height, width, className, fontSize } = props;

	return (
		<ForeignLangQuizBtnWrapper className={className} onClick={clickHandler} btnText={btnText} height={height} width={width} fontSize={fontSize}>
			{children}
		</ForeignLangQuizBtnWrapper>
	);
};

import { ACTION_TYPES } from "./Action";

const initialState = {
	gamesStatus: {},
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.UPDATE_GAMES_STATUS:
			return {
				...state,
				gamesStatus: action.payload,
			};

		default:
			return state;
	}
};

export default Reducer;

export const ACTION_TYPES = {
	LOGIN_USER: "LOGIN_USER",
	LOGOUT_USER: "LOGOUT_USER",
	UPDATE_PROFILE: "UPDATE_PROFILE",
	UPDATE_TOTAL_SCORE: "UPDATE_TOTAL_SCORE",
	UPDATE_GAME_SCORE: "UPDATE_GAME_SCORE",
	SUBJECT_ACCESS_LIST: "SUBJECT_ACCESS_LIST",
	SELECTED_SUBJECT_DATA_VALUE: "SELECTED_SUBJECT_DATA_VALUE",
	IS_TIMER_COMPLETE_PURSUIT: "IS_TIMER_COMPLETE_PURSUIT",
	SELECTED_GENDER_PURSUIT: "SELECTED_GENDER_PURSUIT",
	CROSSWORD_COMPLETED: "CROSSWORD_COMPLETED",
	SCHOOL_CLASS_ID: "SCHOOL_CLASS_ID",
	SHOW_TOP_BAR: "SHOW_TOP_BAR",
};

export const loginUser = (data) => {
	// SET YOUR LOGIN INFO HERE
	updateStorage(data);

	return { type: ACTION_TYPES.LOGIN_USER, ...data };
};

export const updateStorage = (data) => {
	localStorage.setItem("isLoggedIn", true);
	localStorage.setItem("accessToken", data.accessToken);
	localStorage.setItem("role", data.role);
};

export const logOutUser = () => {
	localStorage.clear();

	return { type: ACTION_TYPES.LOGOUT_USER };
};

export const updateProfileInfo = (data) => {
	localStorage.setItem("profileInfo", JSON.stringify(data));

	return { type: ACTION_TYPES.UPDATE_PROFILE, profileInfo: data };
};

export const updateTotalScore = (score) => {
	return { type: ACTION_TYPES.UPDATE_TOTAL_SCORE, score };
};

export const updateGameScore = (score) => {
	return { type: ACTION_TYPES.UPDATE_GAME_SCORE, score };
};

export const subjectAccessData = (data) => {
	localStorage.setItem("subjectAccessList", JSON.stringify(data.data));

	return { type: ACTION_TYPES.SUBJECT_ACCESS_LIST, subjectAccessList: data.data };
};

export const selectedSubjectValue = (data) => {
	localStorage.setItem("selectedSubjectDataValue", data ? JSON.stringify(data) : {});

	return { type: ACTION_TYPES.SELECTED_SUBJECT_DATA_VALUE, selectedSubjectDataValue: data };
};

export const timerCompleteForPursuit = (data) => {
	localStorage.setItem("isTimerCompletePursuit", JSON.stringify(data));

	return { type: ACTION_TYPES.IS_TIMER_COMPLETE_PURSUIT, isTimerCompletePursuit: data };
};

export const selectedGenderForPursuit = (data) => {
	localStorage.setItem("selectedGenderPursuit", data);

	return { type: ACTION_TYPES.SELECTED_GENDER_PURSUIT, selectedGenderPursuit: data };
};

export const schoolClassTestID = (data) => {
	localStorage.setItem("schoolClassTestID", data);

	return { type: ACTION_TYPES.SCHOOL_CLASS_ID, schoolClassTestID: data };
};

export const updateShowTopBar = (showTopBar) => {
	return { type: ACTION_TYPES.SHOW_TOP_BAR, showTopBar };
};

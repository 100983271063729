import React from "react";
import styled, { keyframes } from "styled-components";
import { COLORS } from "Styles/Constants";

const BORDER_CUT_WIDTH = 50,
	BORDER_WIDTH = 3,
	ALLOWED_OVERFLOW_AREA = 150;

const BoxBase = styled.div`
	min-width: 350px;
	min-height: 250px;
	position: relative;
`;
export const BoxWrapper = styled(BoxBase)`
	margin: 0 auto;
	max-width: 40%;

	.box-center {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

const DiagBorder = (color) => `
    background-color : ${color};
    box-shadow  : 0 1px 21px ${color};
    content     : '';
    height      : ${BORDER_WIDTH + 1}px;
    width       : 73px;
    position    : absolute;
    transform   : rotate(-45deg);
`;

export const Box = styled(({ disableInnerShadow, borderColor, ...rest }) => <BoxBase {...rest} />)`
	background-color: ${COLORS.BLUE_BACKGROUND};
	border: ${BORDER_WIDTH}px solid ${({ borderColor }) => borderColor};
	box-shadow: ${({ disableInnerShadow, borderColor }) => (!disableInnerShadow ? `0 0 20px -4px ${borderColor} inset` : "")};
	color: #fff;
	padding: 10px;
	position: relative;
	height: 100%;
	width: 100%;

	clip-path: polygon(
		0% ${BORDER_CUT_WIDTH}px,
		/* top left */ ${BORDER_CUT_WIDTH}px 0%,
		/* top left */ ${BORDER_CUT_WIDTH}px -${ALLOWED_OVERFLOW_AREA}px,
		/* top left overflow */ 100% -${ALLOWED_OVERFLOW_AREA}px,
		/* top right overflow */ 100% 0%,
		/* top right */ 100% calc(100% - ${BORDER_CUT_WIDTH}px),
		/* bottom right */ calc(100% - ${BORDER_CUT_WIDTH}px) 100%,
		/* bottom right */ calc(100% - ${BORDER_CUT_WIDTH}px) calc(100% + ${ALLOWED_OVERFLOW_AREA}px),
		/* bottom right overflow */ 0px calc(100% + ${ALLOWED_OVERFLOW_AREA}px),
		/* bottom left overflow */ 0px 100%,
		/* bottom left */ 0 100% /* bottom left */
	);

	&:before {
		${({ borderColor }) => DiagBorder(borderColor)}
		top     : 22px;
		left: -12.5px;
	}
	&:after {
		${({ borderColor }) => DiagBorder(borderColor)}
		bottom  : 22px;
		right: -13.5px;
	}
`;

const SkewBorder = (color) => `
    background-color : ${color};
    height      : 8px;
    position    : absolute;
    transform   : skewX(-45deg);
    width       : 200px;
`;

export const TopRightBorder = styled(({ borderColor, ...rest }) => <div {...rest} />)`
	${({ borderColor }) => SkewBorder(borderColor)};
	top: -7px;
	right: -4px;

	&::after {
		${({ borderColor }) => SkewBorder(borderColor)};
		content: "";
		top: 69px;
		right: -168px;
		transform: rotate(45deg);
	}
`;

export const BottomLeftBorder = styled.div`
	${({ borderColor }) => SkewBorder(borderColor)};
	bottom: -7px;
	left: -4px;

	&::after {
		${({ borderColor }) => SkewBorder(borderColor)};
		content: "";
		bottom: 69px;
		left: -168px;
		transform: rotate(45deg);
	}
`;

const ANIM_DURATION = 2,
	INDICATOR_BORDER_WIDTH = "2px";

const borderAnimation = keyframes`
    0% { height: calc(100% + 2px); width: calc(100% + 2px); visibility : visible; }
    75% { height: calc(100% + 2px); width: 0%; visibility : visible;}
    99.9% { height: 0%; width: 0%; visibility : visible;}
    100% { height: 0%; width: 0%; visibility : hidden;}
`;

export const Indicators = styled.div`
	background: #01001c;
	border: ${INDICATOR_BORDER_WIDTH} solid ${COLORS.SECONDARY};
	padding: 5px 20px;
	position: absolute;

	top: -4%;

	&.left {
		left: 17%;
	}
	&.right {
		right: 17%;
	}

	&.animated {
		&:before,
		&:after {
			content: "";
			width: calc(100% + 2px);
			height: calc(100% + 2px);
			/* width: 0%;
            height: 0%; */
			position: absolute;
			transition: 0s;
			visibility: visible;
		}

		&:before {
			border-top: ${INDICATOR_BORDER_WIDTH} solid ${COLORS.DANGER};
			border-right: ${INDICATOR_BORDER_WIDTH} solid ${COLORS.DANGER};
			bottom: -${INDICATOR_BORDER_WIDTH};
			right: -${INDICATOR_BORDER_WIDTH};
		}

		&:after {
			border-left: ${INDICATOR_BORDER_WIDTH} solid ${COLORS.DANGER};
			border-bottom: ${INDICATOR_BORDER_WIDTH} solid ${COLORS.DANGER};
			top: -${INDICATOR_BORDER_WIDTH};
			left: -${INDICATOR_BORDER_WIDTH};
		}

		&.running {
			&:before,
			&:after {
				animation-name: ${borderAnimation};
				animation-duration: 7.5s;
				animation-fill-mode: forwards;
				animation-timing-function: linear;
			}

			&:after {
				animation-delay: 7.5s;
			}
		}

		/* BASED ON TRANSITION */
		/*  
            Total time 15s
            border :
            top     : 6s
            right   : 2s
            bottom  : 5s
            left    : 2s

        */
		/* &:hover {
            width: calc(0%);
            height: calc(0%);
            visibility : hidden;

            &:before { transition: width ${ANIM_DURATION * 3}s linear, height ${ANIM_DURATION}s linear ${ANIM_DURATION * 3}s, visibility 0s ${ANIM_DURATION * 4}s }
            &:after { transition: width ${ANIM_DURATION * 3 - 1}s linear ${ANIM_DURATION * 4}s, height ${ANIM_DURATION}s linear ${ANIM_DURATION * 7 - 1}s, visibility 1s ${ANIM_DURATION * 7}s  }
        } */
	}
`;

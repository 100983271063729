import React, { useEffect, useState } from "react";
import { DialogWrapper, Input } from "./QuestionBeginEnd.style.js";
import { AnimatedButton, DesignedBox } from "Components/Commons";
import { Typography } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import Api from "Helpers/ApiHandler.js";

const QuestionBeginEnd = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [answer, setAnswer] = useState("");
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setOpen(props.open);
	}, [props.open]);

	const submitAnswer = async () => {
		if (!answer.trim()) {
			setAnswer("");
			return;
		}

		setIsLoading(true);
		let questionStep = !props.questionModal.isInitialQuestionAnswered ? "initialQuestion" : "finalQuestion";

		let data = new FormData();
		data.append("answer", answer);
		let response = await new Api().post(`general/${questionStep}`, { data });

		if (response.status === 200) {
			setOpen(false);
		}

		setIsLoading(false);
	};

	const handleTextChange = (e) => {
		setAnswer(e.target.value);
	};

	return (
		<DialogWrapper open={open}>
			<DesignedBox className="flex f-v-center">
				<div className="content">
					<div className="question-wrapper">
						<div className="question">
							<Typography> If you were left on an island with 10,000 people, how would you form a government? </Typography>
						</div>
					</div>

					<FormGroup row>
						<InputOptions handleChange={handleTextChange} answer={answer} />
					</FormGroup>
				</div>
			</DesignedBox>

			<div className="button-wrapper full-width">
				<AnimatedButton onClick={submitAnswer} disabled={isLoading ? true : false}>
					Submit
				</AnimatedButton>
			</div>
		</DialogWrapper>
	);
};

function InputOptions(props) {
	return (
		<div className="input-wrapper full-width">
			<Input className="full-width" placeholder="Write your answer" onChange={props.handleChange} value={props.answer} autoFocus />
		</div>
	);
}

export default QuestionBeginEnd;

import React from "react";
import { Routes, Route } from "react-router-dom";
// COMPONENTS
import Login from "Components/Pages/Login";
import GlobalLandingPage from "Components/GlobalLandingPage";
import GLPCommonComponent from "Components/Pages/GLPLoginRegister/GLPCommonComponent";
import Signup from "Components/Pages/Signup";
import ForgotPassword from "Components/Pages/ForgotPassword";
import ResetPassword from "Components/Pages/ResetPassword";
import EmailVerification from "Components/Pages/EmailVerification";
import ContactUs from "Components/ContactUs";
import Dashboard from "Components/Civics/Dashboard";
import HistoryDashboard from "Components/History";
import CommonDashboard from "Components/CommonDashboard";
import ForeignLanguageDashboard from "Components/ForeignLanguage";
import ScienceDashboard from "Components/Science";
import EconomicsDashboard from "Components/Economics";
import EnglishDashboard from "Components/English";
import MathsDashboard from "Components/Maths";
import SubscriptionDetails from "Components/Pages/SubscriptionDetails/SubscriptionDetails";
import SubscriptionPaymentStatus from "Components/Pages/SubscriptionDetails/SubscriptionPaymentStatus";
// ROUTES
import { appRoutes } from "./RoutesList";
import ProtectedRoute from "./ProtectedRoute";
// HELPERS
import {
	URL_LOGIN,
	URL_REGISTER,
	URL_FRGT_PWD,
	URL_RESET_PWD,
	URL_EMAIL_VERIFICATION,
	URL_GLOBAL_LANDING_PAGE,
	URL_CONTACT_US,
	URL_MATHS_DASHBOARD,
	URL_TEACHER_LOGIN,
	URL_SCHOOL_REGISTER,
	URL_SUBSCRIPTION,
	URL_SUBSCRIPTION_PAYMENT_STATUS,
	URL_GENERAL_DASHBOARD,
	URL_CIVICS_DASHBOARD,
	URL_HISTORY_DASHBOARD,
	URL_FOREIGN_LANGUAGE_DASHBOARD,
	URL_SCIENCE_DASHBOARD,
	URL_ECONOMIC_DASHBOARD,
	URL_ENGLISH_DASHBOARD,
} from "Helpers";

const MainRoutes = () => {
	return (
		<Routes>
			<Route path={URL_GLOBAL_LANDING_PAGE} element={<GlobalLandingPage />} />
			<Route path={URL_REGISTER} element={<Signup />} />
			<Route path={URL_LOGIN} element={<Login />} />
			<Route path={URL_TEACHER_LOGIN} element={<GLPCommonComponent />} />
			<Route path={URL_SCHOOL_REGISTER} element={<GLPCommonComponent />} />
			<Route path={URL_FRGT_PWD} exact={true} element={<ForgotPassword />} />
			<Route path={URL_RESET_PWD} element={<ResetPassword />} />
			<Route path={URL_EMAIL_VERIFICATION} element={<EmailVerification />} />
			<Route path={URL_CONTACT_US} element={<ContactUs />} />
			<Route path={URL_SUBSCRIPTION} element={<SubscriptionDetails />} />
			<Route path={URL_SUBSCRIPTION_PAYMENT_STATUS} element={<SubscriptionPaymentStatus />} />

			<Route
				path={URL_GENERAL_DASHBOARD}
				element={
					<ProtectedRoute>
						<CommonDashboard />
					</ProtectedRoute>
				}
			/>

			<Route
				path={URL_CIVICS_DASHBOARD}
				element={
					<ProtectedRoute>
						<Dashboard />
					</ProtectedRoute>
				}
			/>

			<Route
				path={URL_HISTORY_DASHBOARD}
				element={
					<ProtectedRoute>
						<HistoryDashboard />
					</ProtectedRoute>
				}
			/>

			<Route
				path={URL_FOREIGN_LANGUAGE_DASHBOARD}
				element={
					<ProtectedRoute>
						<ForeignLanguageDashboard />
					</ProtectedRoute>
				}
			/>

			<Route
				path={URL_SCIENCE_DASHBOARD}
				element={
					<ProtectedRoute>
						<ScienceDashboard />
					</ProtectedRoute>
				}
			/>

			<Route
				path={URL_ECONOMIC_DASHBOARD}
				element={
					<ProtectedRoute>
						<EconomicsDashboard />
					</ProtectedRoute>
				}
			/>

			<Route
				path={URL_ENGLISH_DASHBOARD}
				element={
					<ProtectedRoute>
						<EnglishDashboard />
					</ProtectedRoute>
				}
			/>

			<Route
				path={URL_MATHS_DASHBOARD}
				element={
					<ProtectedRoute>
						<MathsDashboard />
					</ProtectedRoute>
				}
			/>

			{appRoutes.settingRoutes.map((item, index) => (
				<Route key={index} path={item.path} element={item.element} />
			))}

			<Route path="civics">
				{appRoutes.civicRoutes.map((item, index) => item.path !== "dashboard" && <Route key={index} path={item.path} element={<ProtectedRoute>{item.element}</ProtectedRoute>} />)}
			</Route>

			<Route path="history">
				{appRoutes.historyRoutes.map((item, index) => item.path !== "dashboard" && <Route key={index} path={item.path} element={<ProtectedRoute>{item.element}</ProtectedRoute>} />)}
			</Route>

			<Route path="foreign-language">
				{appRoutes.foreignLanguageRoutes.map((item, index) => item.path !== "dashboard" && <Route key={index} path={item.path} element={<ProtectedRoute>{item.element}</ProtectedRoute>} />)}
			</Route>

			<Route path="science">
				{appRoutes.scienceRoutes.map((item, index) => item.path !== "dashboard" && <Route key={index} path={item.path} element={<ProtectedRoute>{item.element}</ProtectedRoute>} />)}
			</Route>

			<Route path="english">
				{appRoutes.englishRoutes.map((item, index) => item.path !== "dashboard" && <Route key={index} path={item.path} element={<ProtectedRoute>{item.element}</ProtectedRoute>} />)}
			</Route>

			<Route path="maths">
				{appRoutes.mathsRoutes.map((item, index) => item.path !== "dashboard" && <Route key={index} path={item.path} element={<ProtectedRoute>{item.element}</ProtectedRoute>} />)}
			</Route>

			<Route path="economics">
				{appRoutes.economicsRoutes.map((item, index) => item.path !== "dashboard" && <Route key={index} path={item.path} element={<ProtectedRoute>{item.element}</ProtectedRoute>} />)}
			</Route>
		</Routes>
	);
};

export default MainRoutes;

import React, { useEffect, useState } from "react";
import useSound from "use-sound";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
// REDUX
import { quizSound, showToast } from "Redux/General/Actions";
import { updateTotalScore, updateGameScore, schoolClassTestID } from "Redux/Auth/Actions";
// COMPONENTS
import CrosswordHowToPlay from "Components/English/CrossWord/Modal/HowToPlay";
import RapidFireHowToPlay from "Components/English/RapidFire/Modal/HowToPlay";
// STYLES
import { PageWrapper, GridContainer, ScoreboardWrapper, ProgressWrapper } from "./Dashboard.style";
// HELPERS
import { random, Api, URL_CROSSWORD, ENGLISH_MODULE_ID, URL_RAPID_FIRE, IMAGES } from "Helpers";
// SOUNDS
import DrawerSound from "Assets/Sounds/switch-off.mp3";
import LazyImage from "Components/Commons/LazyImage/LazyImage";

const tooltipStyles = { tooltip: { padding: "8px 18px", borderRadius: "10px", marginBottom: "22px", fontSize: "14px", backgroundColor: "white", color: "black" } };

const sectionConst = [
	{ name: "Rapid Fire", image: IMAGES.ENGLISH.RAPID_FIRE_CARD, value: 0, subTitle: "RAPID FIRE", url: URL_RAPID_FIRE, id: ENGLISH_MODULE_ID.RAPID_FIRE, isLive: true, isTestAvailable: false },
	{ name: "CrossWord", image: IMAGES.ENGLISH.CROSSWORD_CARD, value: 0, subTitle: "CROSSWORD", url: URL_CROSSWORD, id: ENGLISH_MODULE_ID.CROSSWORD, isLive: true, isTestAvailable: false },
];

const Dashboard = () => {
	const dispatch = useDispatch();
	const [play] = useSound(DrawerSound, { volume: 0.2 });

	const { gamesStatus } = useSelector((state) => state.GamesData);
	const subjects = useSelector((state) => state.Auth.subjectAccessList);

	const subjectId = subjects?.filter((item) => item.subject === "English")[0].subjectId;

	const [sections, setSections] = useState([...sectionConst]);
	const [scoreboard, setScoreboard] = useState([]);
	const [isStudent, setStudent] = useState(false);
	const [testAvailable, setTestStatus] = useState(false);
	const [status, setStatus] = useState(0);
	const [loading, setLoading] = useState(false);
	const [schoolClassId, setSchoolClassId] = useState("");
	const [crosswordHowToPlay, setCrosswordHowToPlay] = useState({ open: false, subTitle: "", url: null, id: null });
	const [rapidFireHowToPlay, setRapidFireHowToPlay] = useState({ open: false, subTitle: "", url: null, id: null });

	useEffect(() => {
		dispatch(quizSound(true));

		setTimeout(() => {
			let newValues = [...sections];

			newValues.forEach((section) => {
				if (!section.isLive) section.value = random(30, 100);
			});

			setSections(newValues);
		}, 1500);
	}, []); // eslint-disable-line

	useEffect(() => {
		if (Object.keys(gamesStatus).length) getStatus();
	}, [gamesStatus]); // eslint-disable-line

	const getStatus = async () => {
		try {
			setLoading(true);

			let response = await new Api().get("general/studentStatus", { params: { subjectId: subjectId } });

			const percentValue = (sub, total) => ((sub * 100) / total).toFixed(0);

			// SET PROGRESS
			const newValues = [...sections];

			newValues.forEach((section) => {
				switch (section.id) {
					case ENGLISH_MODULE_ID.RAPID_FIRE:
						if (response?.data?.rapidFireEnglish?.isRapidFireEnglishComplete) section.value = parseInt(100);
						else {
							const value = percentValue(response?.data?.rapidFireEnglish?.totalRightAnswerRapidFireEnglish, response?.data?.rapidFireEnglish?.totalQuestionRapidFireEnglish);
							section.value = !isNaN(value) ? value : 0;
						}

						if (gamesStatus?.isRapidFireEnglishActive) section.isTestAvailable = true;

						break;

					case ENGLISH_MODULE_ID.CROSSWORD:
						if (response?.data?.crossWord?.isCrossWordPuzzleComplete) section.value = parseInt(100);
						else {
							const value = percentValue(response?.data?.crossWord?.totalRightAnswerCrossWordPuzzle, response?.data?.crossWord?.totalQuestionCrossWordPuzzle);
							section.value = !isNaN(value) ? value : 0;
						}

						if (gamesStatus?.isCrossWordActive) section.isTestAvailable = true;

						break;

					default:
						section.value = 0;
						break;
				}
			});

			dispatch(updateTotalScore(response.data?.total));
			dispatch(schoolClassTestID(response?.data?.schoolClassTestId));
			dispatch(updateGameScore(response.data.crossWord?.currentCrossWordScore));

			setSections(newValues);
			setScoreboard(response.data.scoreDashBoardList);
			setTestStatus(response?.data?.isLiveTestAvailable);
			setStudent(response?.data?.isStudentLogin);
			setStatus(response.data.status);
			setSchoolClassId(response?.data?.schoolClassTestId);

			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};

	const toggleHowToPlay = (section) => {
		if (!testAvailable && isStudent) {
			dispatch(showToast("Assessment is not available. Try after some time or contact to your respective class teacher | school."));
			return;
		}

		if (status !== 0) {
			let setStateFunction;

			if (section.url === URL_CROSSWORD) setStateFunction = setCrosswordHowToPlay;
			if (section.url === URL_RAPID_FIRE) setStateFunction = setRapidFireHowToPlay;

			if (setStateFunction)
				setStateFunction((prev) => {
					const copyPrev = { ...prev };

					if (!copyPrev.open) {
						copyPrev.url = section.url;
						copyPrev.subTitle = section.subTitle;
						copyPrev.id = section.id;
					}

					copyPrev.open = !copyPrev.open;

					return copyPrev;
				});
		} else dispatch(showToast("No Active Assessments found."));
	};

	const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

	const gameListUi = () => {
		return (
			<GridContainer className="flex f-v-center f-h-center overlay-effect">
				{sections.map((section) =>
					section.isLive ? (
						<div className="box" key={section.name}>
							<div className={`box-content `} onClick={() => !loading && section.isTestAvailable && toggleHowToPlay(section)}>
								<div className={`img-container ${!section.isTestAvailable && "disabled"}`} style={{ backgroundImage: `url(${section.image})` }} onMouseOver={play}></div>

								<div className="progress-container full-width" onMouseOver={(e) => e.preventDefault()}>
									<Progress value={section.value} />
								</div>
							</div>
						</div>
					) : (
						<CustomTooltip title="Coming soon!!!" placement="top">
							<div className="box" key={section.name}>
								<div className={`box-content `}>
									<div className="img-container" style={{ backgroundImage: `url(${section.image})` }} onMouseOver={play}></div>
								</div>
							</div>
						</CustomTooltip>
					)
				)}
			</GridContainer>
		);
	};

	return (
		<PageWrapper>
			<LazyImage compressedImage={IMAGES.ENGLISH.COMPRESSED_COMMON_BACKGROUND} image={IMAGES.ENGLISH.COMMON_BACKGROUND} wrapperProps={{ className: "background-image" }} />
			<div className="title-history"></div>
			<div className="content">
				{loading ? (
					<div className="overlay-stackrank">
						{gameListUi()}

						<div className="display-circular-progress">
							<CircularProgress />
						</div>
					</div>
				) : (
					gameListUi()
				)}
			</div>

			<Scoreboard peoples={scoreboard} />

			{crosswordHowToPlay.open && (
				<CrosswordHowToPlay open={crosswordHowToPlay.open} handleClose={toggleHowToPlay} data={crosswordHowToPlay} subjectId={subjectId} schoolClassId={schoolClassId} />
			)}

			{rapidFireHowToPlay.open && <RapidFireHowToPlay open={rapidFireHowToPlay.open} handleClose={toggleHowToPlay} data={rapidFireHowToPlay} subjectId={subjectId} schoolId={schoolClassId} />}
		</PageWrapper>
	);
};

function Progress(props) {
	return (
		<ProgressWrapper {...props}>
			<div className="outer">
				<div className="active" />
			</div>
			<div className="text">{props.value}%</div>
		</ProgressWrapper>
	);
}

function Scoreboard(props) {
	return (
		<ScoreboardWrapper>
			<div className="scoreboard-text">Scoreboard</div>
			<div className="people-cards">
				{props?.peoples?.map((person) => (
					<div className="person flex f-v-center f-h-center" key={person.winnerPlace}>
						<div className="content text-center">
							<div className="image" style={{ backgroundImage: `url(${person.profileImage})` }}></div>
							<div className="name elipsis">{person.firstName}</div>
							<div className="score">{person.totalScore}</div>
						</div>
					</div>
				))}
			</div>
		</ScoreboardWrapper>
	);
}

export default Dashboard;

import styled from "styled-components";
import { Dialog } from "@material-ui/core";
import { COLORS, FONTS } from "Styles/Constants";

export const DialogWrapper = styled(Dialog)`
	.background-root {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.paper {
		background: transparent;
		color: #fff;
		overflow: visible;
	}

	.box {
		width: 560px;
		margin: 0px auto;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background: linear-gradient(to bottom, #fff600, #fffcb3, #fffede, #f7f7eb, #fff);
		border-radius: 10px;
		box-shadow: 0 0 20.9px 3.1px rgba(4, 23, 39, 0.5);
		padding: 20px 30px;
		color: #000;

		.heading {
			font-size: 30px;
			margin: 10px 0 20px;
			text-align: center;
		}

		.title {
			font-size: 22px;
			text-align: center;
		}

		.avatar {
			width: 85px;
			height: 85px;

			border: 2px dashed rgba(66, 222, 251, 0.5);
			border-radius: 20px;
			cursor: pointer;
			margin: 10px;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;

			&.selected {
				border-style: solid;
				border-color: ${COLORS.SECONDARY};
			}
		}

		.pre-defined {
			.pre-defined-images {
				flex-wrap: wrap;
				justify-content: space-evenly;
				margin: 10px auto;
				width: 80%;
			}
		}

		.separator {
			margin: 20px 0;
			position: relative;

			.text {
				background: ${COLORS.BLUE_BACKGROUND};
				display: inline;
				font-size: 20px;
				padding: 0 10px;
				position: relative;
			}

			.line {
				width: 60%;
				height: 2px;
				background: rgba(66, 222, 251, 0.4);

				position: absolute;
				top: 50%;
				left: 20%;
				right: 0;
			}
		}

		.user-section {
			margin-bottom: 15px;
		}

		.btn-container {
			.button {
				background-color: black;
				color: white;
				width: 100%;
				padding: 0.7rem 2rem;
				border-radius: 10px;
				margin-top: 30px;
				border-color: #000;
				cursor: pointer;

				.login-font {
					font-family: ${FONTS.TERTIARY_SEMI_BOLD};
					font-size: 18px;
					font-weight: 600;
					text-align: center;
					margin: 0px;
					padding: 0px;
					text-transform: capitalize;
				}
			}
		}
	}
`;

import React, { useState } from "react";
import { Box, Button, FormControl, FormHelperText, TextField, CircularProgress } from "@material-ui/core";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
//STYLES
import { DialogWrapper } from "./ContactUsModal.style";
//IMAGES
import CloseButton from "../../../Assets/Images/Commons/CloseButton.webp";
//CONSTANT
import { Api, generateValidationSchema } from "Helpers";
//REDUX
import { showToast } from "Redux/General/Actions";

const initialValues = {
	name: "",
	email: "",
	message: "",
};

const inputs = [
	{
		label: "Name",
		name: "name",
		validation: { required: true },
		placeholder: "Name",
	},
	{
		label: "Email",
		name: "email",
		type: "email",
		validation: { required: true, email: true },
		placeholder: "Email",
	},
	{
		label: "Message",
		name: "message",
		type: "textarea",
		validation: { required: true },
		placeholder: "Enter Message",
	},
];

const validationSchema = generateValidationSchema(inputs);

const ContactUsModal = (props) => {
	const { open, handleClose } = props;

	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(false);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				setIsLoading(true);
				let htmlContent = "<p>" + values?.message.trim().replace(/\n/g, "</p><p>") + "</p><br />";
				const payload = {
					email: values.email,
					message: htmlContent,
					name: values.name,
				};
				const response = await new Api().post("contact-us", { data: payload, skipAuth: true });

				if (response.status === 200 || response.status === 201) {
					dispatch(showToast("Email sent successfully!!!"));
					handleClose();
				}
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
				dispatch(showToast(error.data.message));
			}
		},
	});

	return (
		<DialogWrapper classes={{ paper: "paper", paperWidthSm: "paper-width" }} open={open} onClose={handleClose}>
			<Box component="div" className="flex f-column container">
				<h4 className="header-title">CONTACT US</h4>
				<form onSubmit={formik.handleSubmit}>
					<Box component="div" className="flex f-column form-container">
						{inputs.map((input, index) => {
							return (
								<FormControl key={index}>
									<label className="label">{input.label}</label>
									<TextField
										id={input.name}
										classes={{ root: "root-input" }}
										variant="outlined"
										name={input.name}
										multiline={input.type === "textarea" ? true : false}
										minRows={input.type === "textarea" ? 4 : 1}
										maxRows={input.type === "textarea" ? 4 : 1}
										onChange={formik.handleChange}
										placeholder={input.placeholder}
										value={formik.values[input.name]}
										aria-describedby={`${input.name}-error`}
									/>
									<FormHelperText classes={{ root: "input-error-message" }} id={`${input.name}-error`}>
										{formik.touched[input.name] && formik.errors[input.name] ? formik.errors[input.name] : ""}
									</FormHelperText>
								</FormControl>
							);
						})}
						<Button classes={{ root: "submit-button" }} type="submit" variant="contained" size="large" fullWidth endIcon={isLoading ? <CircularProgress className="loader" /> : ""}>
							Submit
						</Button>
					</Box>
				</form>
			</Box>
			<img className="close-button-image" alt="close-button" src={CloseButton} onClick={handleClose} />
		</DialogWrapper>
	);
};

export default ContactUsModal;

import React from "react";
// COMPONENTS
import CivicsTopBar from "./CivicsTopbar";
import HistoryTopBar from "./HistoryTopbar";
import ForeignlanguageTopBar from "./ForeignLanguageTopBar";
import ScienceTopBar from "./ScienceTopbar";
import EconomicsTopBar from "./EconomicsTopbar";
import EnglishTopBar from "./EnglishTopbar";
import MathsTopBar from "./MathsTopbar";
// HELPERS
import withRouterWrapper from "Helpers/WithRouterWrapper";
import {
	URL_CIVICS_DASHBOARD,
	URL_GLOBAL_LANDING_PAGE,
	URL_CONTACT_US,
	URL_HISTORY_DASHBOARD,
	URL_FOREIGN_LANGUAGE_DASHBOARD,
	URL_SCIENCE_DASHBOARD,
	URL_ECONOMIC_DASHBOARD,
	URL_ENGLISH_DASHBOARD,
	URL_MATHS_DASHBOARD,
} from "Helpers/Paths";

const Topbar = (props) =>
	![URL_GLOBAL_LANDING_PAGE, URL_CONTACT_US].includes(window.location.pathname) && (
		<>
			{props.router.location.pathname.includes(URL_CIVICS_DASHBOARD) && <CivicsTopBar />}
			{props.router.location.pathname.includes(URL_HISTORY_DASHBOARD) && <HistoryTopBar />}
			{props.router.location.pathname.includes(URL_FOREIGN_LANGUAGE_DASHBOARD) && <ForeignlanguageTopBar />}
			{props.router.location.pathname.includes(URL_SCIENCE_DASHBOARD) && <ScienceTopBar />}
			{props.router.location.pathname.includes(URL_ECONOMIC_DASHBOARD) && <EconomicsTopBar />}
			{props.router.location.pathname.includes(URL_ENGLISH_DASHBOARD) && <EnglishTopBar />}
			{props.router.location.pathname.includes(URL_MATHS_DASHBOARD) && <MathsTopBar />}
		</>
	);

export default withRouterWrapper(Topbar);

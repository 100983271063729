import React, { useState, createContext, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
// COMPONENTS
import LazyImage from "Components/Commons/LazyImage/LazyImage";
// STYLES
import {
	SubscriptionDetailsWrapper,
	SubscriptionPlansWrapper,
	SubscriptionSubjectsWrapper,
	SubscriptionSummaryWrapper,
	StickerWrapper,
	SelectedSubjectWrapper,
	SubjectSticker,
} from "./SubscriptionDetails.style";
// HELPERS
import { Api, URL_ABOUT_US, URL_PRIVACY_POLICY, URL_TERMS_AND_CONDITIONS, IMAGES, URL_DATA_SECURITY } from "Helpers";
import { URL_SCHOOL_REGISTER, URL_GLOBAL_LANDING_PAGE } from "Helpers";
// REDUX
import { showToast } from "Redux/General/Actions";
// IMAGES
import { useLocation, useNavigate } from "react-router-dom";

const UserSubscriptionValueContext = createContext();

const planDetails = [
	{
		planName: "Basic",
		planTitle: "Basic Plan",
		subType: [
			{
				type: "A",
				subTypeName: "Plan - 1",
				stickerImage: IMAGES.LANDING_PAGE.BASIC_PLAN_A,
				hoverEffectColor: "#c23030",
				allowedSubjects: 1,
				price: "$ 119",
			},
			{
				type: "B",
				subTypeName: "Plan - 2",
				stickerImage: IMAGES.LANDING_PAGE.BASIC_PLAN_B,
				hoverEffectColor: "#5bb86b",
				allowedSubjects: 2,
				price: "$ 149",
			},
			{
				type: "C",
				subTypeName: "Plan - 3",
				stickerImage: IMAGES.LANDING_PAGE.BASIC_PLAN_C,
				hoverEffectColor: "#256af7",
				allowedSubjects: 3,
				price: "$ 170",
			},
			{
				type: "D",
				subTypeName: "Plan - 4",
				stickerImage: IMAGES.LANDING_PAGE.BASIC_PLAN_D,
				hoverEffectColor: "#daca05",
				allowedSubjects: 7,
				price: "$ 199",
			},
		],
	},
	{
		planName: "Premium",
		planTitle: "Premium Plan",
		subType: [
			{
				type: "A",
				subTypeName: "Plan - 1",
				stickerImage: IMAGES.LANDING_PAGE.PREMIUM_PLAN_A,
				hoverEffectColor: "#c23030",
				allowedSubjects: 1,
				price: "$ 499",
			},
			{
				type: "B",
				subTypeName: "Plan - 2",
				stickerImage: IMAGES.LANDING_PAGE.PREMIUM_PLAN_B,
				hoverEffectColor: "#5bb86b",
				allowedSubjects: 2,
				price: "$ 599",
			},
			{
				type: "C",
				subTypeName: "Plan - 3",
				stickerImage: IMAGES.LANDING_PAGE.PREMIUM_PLAN_C,
				hoverEffectColor: "#256af7",
				allowedSubjects: 3,
				price: "$ 699",
			},
			{
				type: "D",
				subTypeName: "Plan - 4",
				stickerImage: IMAGES.LANDING_PAGE.PREMIUM_PLAN_D,
				hoverEffectColor: "#daca05",
				allowedSubjects: 7,
				price: "$ 799",
			},
		],
	},
	{
		planName: "Professional",
		planTitle: "Professional Plan",
		subType: [
			{
				type: "A",
				subTypeName: "Plan - 1",
				stickerImage: IMAGES.LANDING_PAGE.PROFESSIONAL_PLAN_A,
				hoverEffectColor: "#c23030",
				allowedSubjects: 7,
				price: "$ 3599",
			},
			{
				type: "B",
				subTypeName: "Plan - 2",
				stickerImage: IMAGES.LANDING_PAGE.PROFESSIONAL_PLAN_B,
				hoverEffectColor: "#5bb86b",
				allowedSubjects: 7,
				price: "$ 3999",
			},
			{
				type: "C",
				subTypeName: "Plan - 3",
				stickerImage: IMAGES.LANDING_PAGE.PROFESSIONAL_PLAN_C,
				hoverEffectColor: "#256af7",
				allowedSubjects: 7,
				price: "$ 4599",
			},
			{
				type: "D",
				subTypeName: "Plan - 4",
				stickerImage: IMAGES.LANDING_PAGE.PROFESSIONAL_PLAN_D,
				hoverEffectColor: "#daca05",
				allowedSubjects: 7,
				price: "$ 4999",
			},
		],
	},
];

const subjectsArray = [
	{
		subjectId: 1,
		subject: "Civics",
		subjectStickerImage: IMAGES.LANDING_PAGE.CIVIC_STICKER,
		subjectImage: IMAGES.LANDING_PAGE.CIVIC_NOTE,
		backgroundGlowColor: "#004dff",
		borderColor: "#abb9ff ",
	},
	{
		subjectId: 2,
		subject: "History",
		subjectStickerImage: IMAGES.LANDING_PAGE.HISTORY_STICKER,
		subjectImage: IMAGES.LANDING_PAGE.HISTORY_NOTE,
		backgroundGlowColor: "#06e4c9",
		borderColor: "#bbfff6 ",
	},
	{
		subjectId: 3,
		subject: "English",
		subjectStickerImage: IMAGES.LANDING_PAGE.ENGLISH_STICKER,
		subjectImage: IMAGES.LANDING_PAGE.ENGLISH_NOTE,
		backgroundGlowColor: "#fff405",
		borderColor: "#fff6a5 ",
	},
	{
		subjectId: 4,
		subject: "Science",
		subjectStickerImage: IMAGES.LANDING_PAGE.SCIENCE_STICKER,
		subjectImage: IMAGES.LANDING_PAGE.SCIENCE_NOTE,
		backgroundGlowColor: "#a6ebca",
		borderColor: "#b7eaff ",
	},
	{
		subjectId: 5,
		subject: "Math",
		subjectStickerImage: IMAGES.LANDING_PAGE.MATH_STICKER,
		subjectImage: IMAGES.LANDING_PAGE.MATH_NOTE,
		backgroundGlowColor: "#c80400",
		borderColor: "#ffb0b0 ",
	},
	{
		subjectId: 6,
		subject: "Economics",
		subjectStickerImage: IMAGES.LANDING_PAGE.ECONOMICS_STICKER,
		subjectImage: IMAGES.LANDING_PAGE.ECONOMICS_NOTE,
		backgroundGlowColor: "#689cfb",
		borderColor: "#b6cfff ",
	},
	{
		subjectId: 7,
		subject: "Foreign Language",
		subjectStickerImage: IMAGES.LANDING_PAGE.FOREIGN_STICKER,
		subjectImage: IMAGES.LANDING_PAGE.FOREIGN_NOTE,
		backgroundGlowColor: "#00630e",
		borderColor: "#b6ffce ",
	},
];

function SubscriptionDetails() {
	const navigate = useNavigate();
	const location = useLocation();
	const getDetails = new URLSearchParams(window?.location?.search);
	const emailToken = getDetails.get("token");
	const checkEmailVerification = getDetails.get("email");
	const schoolId = getDetails.get("id") || location?.state?.schoolId;
	const checkAlreadyRegisterSchool = getDetails.get("isAlreadyRegistered");
	const schoolSubscriptionDetails = location?.state?.schoolSubscriptionDetails;

	const [currentStep, setCurrentStep] = useState(1);

	const [subscriptionPlanDetails, setSubscriptionPlanDetails] = useState({
		planTitle: "Basic Plan",
		planName: "Basic",
		subType: "",
		allowedSubjects: "",
		price: "",
		selectedSubjects: [],
		subTypeName: "",
	});

	const [emailVerification, setEmailVerification] = useState(JSON.parse(checkEmailVerification));
	const [isAlreadyRegisteredSchool, setIsAlreadyRegisteredSchool] = useState(false);

	useEffect(() => {
		if (emailVerification) {
			registerAccount();
		}
		if (checkAlreadyRegisterSchool && JSON.parse(checkAlreadyRegisterSchool)) {
			getSubscriptionDataOfSchool();
		}
		if (schoolSubscriptionDetails) {
			subscriptionDataOfSchool(schoolSubscriptionDetails);
		}
	}, []);

	const registerAccount = async () => {
		let data = new FormData();
		data.append("emailVerificationToken", emailToken);
		const response = await new Api().post("user/verificationToken ", { data });

		if (response.status === 200 || response.status === 201) {
			setEmailVerification(false);

			if (response.data) {
				getSubscriptionDataOfSchool();
			}
		}
	};

	const getSubscriptionDataOfSchool = async () => {
		const subscriptionResponse = await new Api().get("school/subscription-details", { params: { schoolId: schoolId } });

		if (subscriptionResponse.status === 200 || subscriptionResponse.status === 201) {
			const data = subscriptionResponse.data;
			subscriptionDataOfSchool(data);
		}
	};

	const subscriptionDataOfSchool = (data) => {
		const dataList = Object.keys(data);

		const findDataList = planDetails.find((item) => item.planName === data?.planType);

		let subDataList = {};

		const subtypeValue = data?.subType && data.subType.replace(" ", " - ");

		if (Object.keys(findDataList) && Object.keys(findDataList).length) {
			subDataList = findDataList?.subType.find((item) => item?.subTypeName === subtypeValue);
		}

		const subjectNames = [...data?.subjectName];

		const SubjectNamesNew = subjectsArray.filter((data) => subjectNames?.includes(data?.subject))?.map((item) => item?.subjectId);

		if (dataList && dataList.length) {
			setSubscriptionPlanDetails({
				planTitle: data?.planType ? data.planType + " " + "Plan" : "Basic Plan",
				planName: data?.planType ? data.planType : "Basic",
				subType: subDataList?.type || "",
				allowedSubjects: data?.noOfSubjects,
				price: "$" + " " + data?.cost,
				selectedSubjects: SubjectNamesNew,
				subTypeName: subtypeValue,
			});
			setCurrentStep(3);
			setIsAlreadyRegisteredSchool(true);
		}
	};

	const handleSelectedPlan = (planDetails) => {
		setSubscriptionPlanDetails({ planTitle: planDetails.planTitle, planName: planDetails.planName });
	};
	const responseSteps = [
		{
			id: 1,
			stepComponent: (props) => (
				<UserSubscriptionValueContext.Provider value={{ subscriptionPlanDetails, setSubscriptionPlanDetails, handleSelectedPlan, currentStep, setCurrentStep }}>
					<SubscriptionPlans {...props} />
				</UserSubscriptionValueContext.Provider>
			),
		},
		{
			id: 2,
			stepComponent: (props) => (
				<UserSubscriptionValueContext.Provider value={{ setCurrentStep, subscriptionPlanDetails, setSubscriptionPlanDetails }}>
					<SubscriptionSubjects {...props} />
				</UserSubscriptionValueContext.Provider>
			),
		},
		{
			id: 3,

			stepComponent: (props) => (
				<UserSubscriptionValueContext.Provider value={{ setCurrentStep, subscriptionPlanDetails, schoolId, isAlreadyRegisteredSchool }}>
					<SubscriptionSummary {...props} />
				</UserSubscriptionValueContext.Provider>
			),
		},
	];

	const handlerHomeButton = () => {
		navigate(URL_GLOBAL_LANDING_PAGE);
	};

	return (
		<SubscriptionDetailsWrapper>
			<LazyImage compressedImage={IMAGES.LANDING_PAGE.TEACHER_BACKGROUND_COMPRESSED} image={IMAGES.LANDING_PAGE.TEACHER_BACKGROUND} wrapperProps={{ className: "background-image" }} />

			<div className="header-container">
				<div className="top-bar">
					<div
						className="subject-logo"
						onClick={() => {
							navigate(URL_GLOBAL_LANDING_PAGE);
						}}
					/>
					<div className="btn-container">
						<div className="teacher-btn" onClick={handlerHomeButton}>
							Home
						</div>
					</div>
				</div>
			</div>
			<div className="main-container">
				{emailVerification ? (
					<div className="email-board">
						<div className="text-one">Just a Second...</div>
						<br />
						<div className="text-two">We are verifying your email...</div>
					</div>
				) : (
					<>
						<div className={`board-plans ${currentStep !== 1 && "decrease-gird-gap"}`}>
							{currentStep === 1 ? (
								planDetails?.map((planDetailsObj) => (
									<div
										className={`text-board ${planDetailsObj?.planName === subscriptionPlanDetails?.planName ? "selected" : ""} `}
										onClick={() => handleSelectedPlan(planDetailsObj)}
									>
										{planDetailsObj?.planName}
									</div>
								))
							) : (
								<div className={`text-board selected disable`}>{currentStep === 2 ? `${subscriptionPlanDetails?.planName}` : "Summary"}</div>
							)}
						</div>
						{responseSteps.length &&
							responseSteps.map((responseStep, index) => {
								if (responseStep.id === currentStep) {
									return <div className="board">{responseStep && responseStep?.stepComponent && responseStep.stepComponent({ index })}</div>;
								}
							})}
					</>
				)}
			</div>
			<div className="footer-container">
				<div className="inner-container">
					<div className="footer-text">© 2022 Subjects Master. All rights reserved.</div>
					<div className="page-container">
						<div className="about-us" onClick={() => navigate("/" + URL_ABOUT_US)}>
							ABOUT US
						</div>
						<div className="partition">|</div>
						<div className="terms" onClick={() => navigate("/" + URL_TERMS_AND_CONDITIONS)}>
							TERMS & CONDITIONS
						</div>
						<div className="partition">|</div>
						<div className="privacy" onClick={() => navigate("/" + URL_PRIVACY_POLICY)}>
							PRIVACY POLICY
						</div>
						<div className="partition">|</div>
						<div className="privacy" onClick={() => navigate("/" + URL_DATA_SECURITY)}>
							DATA SECURITY
						</div>
					</div>
				</div>
			</div>
		</SubscriptionDetailsWrapper>
	);
}

const SubscriptionPlans = () => {
	const { subscriptionPlanDetails, setSubscriptionPlanDetails, setCurrentStep } = useContext(UserSubscriptionValueContext);

	const handleSelectedPlan = (planDetails, data) => {
		setSubscriptionPlanDetails({
			planTitle: planDetails.planTitle,
			planName: planDetails.planName,
			subType: data?.type || "",
			allowedSubjects: data?.allowedSubjects,
			price: data?.price,
			subTypeName: data.subTypeName,
		});
		setCurrentStep(2);
	};

	return (
		<SubscriptionPlansWrapper subscriptionPlanDetails={subscriptionPlanDetails}>
			<div className="main-container">
				<div className="select-plan-plate">
					<div className="select-plan">Select a plan</div>
				</div>
				<div className="plans">
					{planDetails
						?.filter((planDetailsObj) => planDetailsObj.planName === subscriptionPlanDetails?.planName)?.[0]
						?.subType?.map((subTypeDetailsObj) => {
							return (
								<StickerWrapper
									stickerImage={subTypeDetailsObj?.stickerImage}
									hoverEffectColor={subTypeDetailsObj?.hoverEffectColor}
									onClick={() => handleSelectedPlan(subscriptionPlanDetails, subTypeDetailsObj)}
									className={`${subTypeDetailsObj?.type === subscriptionPlanDetails?.subType ? "selected" : ""}`}
								/>
							);
						})}
				</div>
			</div>
		</SubscriptionPlansWrapper>
	);
};

const SubscriptionSubjects = () => {
	const dispatch = useDispatch();
	const { setCurrentStep, subscriptionPlanDetails, setSubscriptionPlanDetails } = useContext(UserSubscriptionValueContext);
	const [selectedSubjects, setSelectedSubjects] = useState(subscriptionPlanDetails?.selectedSubjects || []);

	const handleBackButton = () => {
		setCurrentStep(1);
		setSubscriptionPlanDetails({
			planTitle: subscriptionPlanDetails.planTitle,
			planName: subscriptionPlanDetails.planName,
			subType: "",
			allowedSubjects: "",
			price: "",
			subTypeName: "",
			selectedSubjects: [],
		});
	};

	const handleNextButton = () => {
		setCurrentStep(3);
	};

	const handleSubjectButton = (selectedId) => {
		const newSelectedSubjects = [...selectedSubjects];
		const findIndex = newSelectedSubjects.indexOf(selectedId);

		selectedSubjects.length < subscriptionPlanDetails.allowedSubjects
			? findIndex === -1
				? newSelectedSubjects.push(selectedId)
				: newSelectedSubjects.splice(findIndex, 1)
			: findIndex > -1
			? newSelectedSubjects.splice(findIndex, 1)
			: dispatch(showToast("Please unselect anyone subject "));

		setSelectedSubjects(newSelectedSubjects);

		setSubscriptionPlanDetails((prev) => ({ ...prev, selectedSubjects: newSelectedSubjects }));
	};

	useEffect(() => {
		if (subscriptionPlanDetails?.allowedSubjects === 7) {
			setSelectedSubjects([1, 2, 3, 4, 5, 6, 7]);
			setSubscriptionPlanDetails((prev) => ({ ...prev, selectedSubjects: [1, 2, 3, 4, 5, 6, 7] }));
		}
	}, []);

	return (
		<SubscriptionSubjectsWrapper>
			<div className="main-container">
				<div className="flex">
					<div className="direction-button back" onClick={handleBackButton}></div>
					<div className="font">Back</div>
				</div>

				<div className="select-plan-plate">
					<div className="select-plan">
						Select {subscriptionPlanDetails.allowedSubjects === 7 ? "all" : "any"} {subscriptionPlanDetails.allowedSubjects !== 7 && subscriptionPlanDetails.allowedSubjects}
						{subscriptionPlanDetails.allowedSubjects !== 1 ? " subjects" : " subject"}
					</div>
				</div>

				<div className="flex">
					<div className={`font ${subscriptionPlanDetails?.allowedSubjects === selectedSubjects.length ? "" : "hide-visibility"}`}>Next</div>
					<div className={`direction-button ${subscriptionPlanDetails?.allowedSubjects === selectedSubjects.length ? "" : "hide-visibility"}`} onClick={handleNextButton}></div>
				</div>
			</div>
			<div className="subject-sticker-parent">
				{subjectsArray?.map((subjectObj) => {
					return (
						<SubjectSticker
							stickerImage={subjectObj?.subjectStickerImage}
							backgroundGlowColor={subjectObj?.backgroundGlowColor}
							borderColor={subjectObj?.borderColor}
							onClick={() => handleSubjectButton(subjectObj.subjectId)}
							className={selectedSubjects.includes(subjectObj.subjectId) ? "selected" : ""}
						/>
					);
				})}
			</div>
		</SubscriptionSubjectsWrapper>
	);
};

const SubscriptionSummary = (props) => {
	const { setCurrentStep, subscriptionPlanDetails, schoolId, isAlreadyRegisteredSchool } = useContext(UserSubscriptionValueContext);
	const SubjectNames = subjectsArray.filter((data) => subscriptionPlanDetails?.selectedSubjects?.includes(data?.subjectId))?.map((item) => item?.subject);

	const [paymentLoading, setPaymentLoading] = useState(false);

	const navigate = useNavigate();

	const handleBackButton = () => {
		setCurrentStep(2);
	};

	const handleNextButton = async () => {
		setPaymentLoading(true);

		const data = {
			schoolId: schoolId,
			planType: subscriptionPlanDetails?.planName,
			subType: subscriptionPlanDetails?.subTypeName?.replace("- ", ""),
			subjectNames: SubjectNames,
			isAlreadyRegisteredSchool: isAlreadyRegisteredSchool,
		};

		const response = await new Api().post("stripe/check-out/form", { data: data });

		if (response?.status === 200) {
			localStorage.setItem("subscription", JSON.stringify(data));
			if (response?.data) {
				window.location.href = response?.data;
			}
		}

		// setPaymentLoading(false);
	};

	const handleRegisterButton = async () => {
		const data = {
			schoolId: schoolId || "",
			planType: subscriptionPlanDetails?.planName,
			subType: subscriptionPlanDetails?.subTypeName?.replace("- ", ""),
			subjectNames: SubjectNames,
		};

		navigate(URL_SCHOOL_REGISTER, { state: { subscriptionRequestDto: data } });
	};

	return (
		<SubscriptionSummaryWrapper>
			<div className="summary-container">
				{paymentLoading ? (
					<>
						<div className="status-board">
							<div className="text-one">You will be redirected to the payment gateway in a few moments...</div>
						</div>
					</>
				) : (
					<>
						<div className="header">
							<div className="flex-header">
								<div className="direction-button back" onClick={handleBackButton}></div>
								<div className="font">Back</div>
							</div>
							<div className="summary-details">
								<div className="plan-name">{subscriptionPlanDetails?.planTitle}</div>
								<div className="summary-pan-container">
									<div className="flex">
										<span className="tick">&#10003;</span>
										<div className="sub-plan">{`Sub Plan : ${subscriptionPlanDetails?.subTypeName}`}</div>
									</div>
									<div className="flex">
										<span className="tick">&#10003;</span>
										<div className="price">
											Price :<span className="amount">{subscriptionPlanDetails?.price}</span>
										</div>
									</div>
								</div>
							</div>
							<div className="flex-header">
								<div className={` ${!schoolId ? "registerAsSchoolFont" : "font"}`}>{`${!schoolId ? "Register as a School" : "Next"}`}</div>
								<div className={`direction-button`} onClick={!schoolId ? handleRegisterButton : handleNextButton}></div>
							</div>
						</div>
						<div className="selected-subjects">
							{subjectsArray
								?.filter((sub) => subscriptionPlanDetails?.selectedSubjects?.includes(sub?.subjectId))
								?.map((data) => {
									return <SelectedSubjectWrapper subjectImage={data?.subjectImage} />;
								})}
						</div>
					</>
				)}
			</div>
		</SubscriptionSummaryWrapper>
	);
};

export default SubscriptionDetails;

import React from "react";
import PropTypes from "prop-types";

import { Box, BoxWrapper, TopRightBorder, BottomLeftBorder, Indicators } from "./DesignedBox.style";
import { COLORS } from "Styles/Constants";
function DesignedBox(props) {
	let { className, indicators, outsideTopElements, outsideBottomElements, disableInnerShadow, borderColor, ...rest } = props;
	return (
		<BoxWrapper className={(className || "") + " box text-center"} {...rest}>
			{outsideTopElements}

			<Box className={`inner-box ${props.center && "box-center"}`} borderColor={borderColor} disableInnerShadow={disableInnerShadow}>
				{props.children}
			</Box>
			<TopRightBorder borderColor={borderColor} />
			<BottomLeftBorder borderColor={borderColor} />

			{indicators && <IndicatorGroup indicators={indicators} />}
			{outsideBottomElements}
		</BoxWrapper>
	);
}

DesignedBox.defaultProps = {
	borderColor: COLORS.SECONDARY,
};

DesignedBox.propTypes = {
	indicators: PropTypes.elementType,
	outsideTopElements: PropTypes.elementType,
	outsideBottomElements: PropTypes.elementType,
};

function IndicatorGroup(props) {
	let { indicators } = props;

	// function IndicatorUnit(props) {
	//     return (
	//         <Indicators className={"indicator " + props.className}>
	//             {props.children}
	//         </Indicators>
	//     )
	// }

	/* 
        NOTE : Code is duplicated due to re-render issue
    */
	return (
		<>
			{indicators?.left && (
				<Indicators id={indicators.left.id} className={"indicator left " + indicators.left.className} running={indicators.left.running}>
					{indicators.left.component}
				</Indicators>
			)}
			{indicators?.center && (
				<div className="flex f-h-center">
					<Indicators id={indicators.center.id} className={"indicator center " + indicators.center.className} running={indicators.center.running}>
						{indicators.center.component}
					</Indicators>
				</div>
			)}
			{indicators?.right && (
				<Indicators id={indicators.right.id} className={"indicator right " + indicators.right.className} running={indicators.right.running}>
					{indicators.right.component}
				</Indicators>
			)}
			{/* {
            indicators?.left &&
            <IndicatorUnit className={"left "}>
                {indicators.left.component}
            </IndicatorUnit>
        }
        {
            indicators?.center &&
            <IndicatorUnit className={"center "}>
                {indicators.center.component}
            </IndicatorUnit>
        }
        {
            indicators?.right &&
            <IndicatorUnit className={"right animated "}>
                {indicators.right.component}
            </IndicatorUnit>
        } */}
		</>
	);
}
export { BoxWrapper };
export default DesignedBox;

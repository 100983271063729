import axios from "axios";
// REDUX
import { store } from "Redux/Store";
import { logOutUser } from "Redux/Auth/Actions";
import { showToast } from "Redux/General/Actions";
// HELPERS
import CODES from "./StatusCodes";
import { isIEBrowser } from "Helpers/Utils";

const BASEURL = process.env.REACT_APP_API_URL;
const METHOD = { GET: "get", POST: "post", PUT: "put", DELETE: "delete" };

class Api {
	constructor() {
		this.isLoggedIn = false;
		this.baseURL = BASEURL;
		this.getAuthenticationInfo();
	}

	getAuthenticationInfo() {
		if (localStorage.getItem("isLoggedIn")) {
			this.isLoggedIn = true;
			this.accessToken = localStorage.getItem("accessToken");
		}
	}

	get(url, data) {
		return new Promise((resolve, reject) => {
			this.api(METHOD.GET, url, data)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	post(url, data) {
		return new Promise((resolve, reject) => {
			this.api(METHOD.POST, url, data)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	}

	put(url, data) {
		return new Promise((resolve, reject) => {
			this.api(METHOD.PUT, url, data)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	}

	delete(url, data) {
		return new Promise((resolve, reject) => {
			this.api(METHOD.DELETE, url, data)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
				});
		});
	}

	api(method, url, data) {
		return new Promise((resolve, reject) => {
			let axiosConfig = {};
			axiosConfig.method = method;

			axiosConfig.url = this.baseURL + url;

			axiosConfig.headers = this.setHeaders(data);
			if (data) {
				if (data.params) axiosConfig.params = data.params;

				if (data.data) axiosConfig.data = data.data;
			}

			if (isIEBrowser()) {
				if (!axiosConfig.params) axiosConfig.params = {};

				axiosConfig.params.time = new Date().getTime();
			}

			axios(axiosConfig)
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					if (error && error.response) {
						if (error.response.status === CODES.UNAUTHORIZED) {
							if (this.isLoggedIn === true) {
								store.dispatch(logOutUser());
							} else resolve(error.response);
						} else if (error.response.status === CODES.SERVER_ERROR) {
							if (data && !data.skipToast) store.dispatch(showToast("Internal Server Error"));
							if (data && data.skipErrorHandling) reject(error.response);
						} else {
							if (data && data.skipErrorHandling) resolve(error.response);
							else {
								store.dispatch(showToast(error.response?.data?.message || "Internal Server Error"));
							}
						}

						if (data?.returnError) reject(error.response);
					}

					console.log("ERROR", error, error.response);
				});
		});
	}

	setHeaders(data) {
		let headers = {};

		headers["accept-language"] = "en";
		headers["Content-Type"] = "application/json";

		if (data) {
			if (data.isMultipart) headers["Content-Type"] = "multipart/form-data";

			if (data.headers) {
				for (var key in data.headers) {
					if (data.headers.hasOwnProperty(key)) headers[key] = data.headers[key];
				}
			}
		}

		if ((this.isLoggedIn && this.isLoggedIn !== false) || !(data && data.skipAuth)) headers["AccessToken"] = this.accessToken;

		return headers;
	}
}

export default Api;

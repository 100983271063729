import styled from "styled-components";
import { Dialog } from "@material-ui/core";
import { responsive } from "Styles/Constants";
import { IMAGES } from "Helpers";

export const DialogWrapper = styled(Dialog)`
	.backdrop {
		background-color: rgba(0, 0, 0, 0.8);
	}
	.dialog-paper {
		background: transparent;
		color: black;
		overflow: visible;
	}

	.paper-width {
		max-width: 900px;
	}
	.box-model {
		height: 700px;
		width: 900px;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-image: url(${IMAGES.SCIENCE.LOOSE_LINK.HOW_TO_PLAY});
		position: relative;
		color: #fff;
		padding: 8% 0%;
		text-align: center;

		${responsive.LAPTOP`
			width: 700px;
            height: 500px;
		`}

		.title-container {
			.sub-title {
				font-size: 30px;

				${responsive.LAPTOP`
					font-size: 24px;
					margin: 10px auto;
				`}
			}
			.icon-container {
				position: absolute;
				right: 8%;
				top: 10%;
				cursor: pointer;
			}
		}
		.content-wrapper {
			position: relative;
			padding: 0% 8%;
			height: 400px;

			${responsive.LAPTOP`
				height: 280px;
			`}

			.scroll-bar {
				.thumb-vertical {
					background-color: #005ca8;
					margin-left: -15px;
				}
			}
			.content {
				width: 76%;
				margin: 6% auto 0px;

				${responsive.LAPTOP`
					margin: 0 auto;
				`}
			}
			.attach-file {
				display: flex;
				justify-content: center;

				.file {
					margin: 0px 10px;
					font-size: 18px;
					cursor: pointer;
					text-decoration: underline;
				}

				.pdf-loader {
					width: 20px !important;
					height: 20px !important;
					color: #fff;
					margin-top: 1px;
				}
			}

			.progress {
				position: absolute;
				top: 36%;
				left: 0;
				right: 0;
				color: #000;
			}

			.type-switcher {
				.divider {
					background-color: #fff;
					height: 2px;
					width: 50%;
					margin: 5px auto;
					opacity: 0.5;
				}

				.text {
					cursor: pointer;
					transition: 0.3s;
					margin-bottom: 20px;
					/* margin: 0px 0px 22px 29px; */
					/* &:hover {
						color: black;
					} */
				}
			}
		}

		.play-btn-container {
			width: 100%;
			margin: auto;
			text-align: center;
			bottom: 3%;
			position: absolute;
			.play {
				padding: 8px 30px;
			}
		}
	}
`;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { IconButton, CircularProgress, Menu, MenuItem } from "@material-ui/core";
import { uniqBy } from "lodash";
// STYLES
import { DashboardWrapper } from "./CommonDashboard.style";
// HELPERS
import { Api, IMAGES } from "Helpers";
import {
	URL_CIVICS_DASHBOARD,
	URL_HISTORY_DASHBOARD,
	URL_FOREIGN_LANGUAGE_DASHBOARD,
	URL_SCIENCE_DASHBOARD,
	URL_ECONOMIC_DASHBOARD,
	URL_ENGLISH_DASHBOARD,
	URL_MATHS_DASHBOARD,
	URL_PROFILE,
	URL_CHANGE_PWD,
	URL_ABOUT_US,
	URL_TERMS_AND_CONDITIONS,
	URL_PRIVACY_POLICY,
	URL_CONTACT_US,
	URL_DATA_SECURITY,
} from "Helpers/Paths";
// REDUX
import { showToast } from "Redux/General/Actions";
import { logOutUser, subjectAccessData, selectedSubjectValue } from "Redux/Auth/Actions";
// COMPONENTS
import { AnimatedButton } from "Components/Commons";
import LazyImage from "Components/Commons/LazyImage/LazyImage";
// ASSETS
import UserLoginSound from "Assets/Sounds/Dashboard/UserLoginMusic.mp3";

const subjectData = [
	{
		id: 1,
		image: IMAGES.DASHBOARD.CIVICS_CARD,
		name: "civics",
		navigateTo: URL_CIVICS_DASHBOARD,
		backgroundImg: IMAGES.DASHBOARD.CIVICS_BACKGROUND,
		tinyImage: IMAGES.DASHBOARD.CIVICS_BACKGROUND_COMPRESSED,
	},
	{
		id: 2,
		image: IMAGES.DASHBOARD.HISTORY_CARD,
		name: "history",
		navigateTo: URL_HISTORY_DASHBOARD,
		backgroundImg: IMAGES.DASHBOARD.HISTORY_BACKGROUND,
		sideLogo: IMAGES.DASHBOARD.HISTORY_SIDE_LOGO,
		tinyImage: IMAGES.DASHBOARD.HISTORY_BACKGROUND_COMPRESSED,
	},
	{
		id: 3,
		image: IMAGES.DASHBOARD.ENGLISH_CARD,
		name: "english",
		navigateTo: URL_ENGLISH_DASHBOARD,
		backgroundImg: IMAGES.DASHBOARD.ECONOMICS_BACKGROUND,
		sideLogo: IMAGES.DASHBOARD.ENGLISH_SIDE_LOGO,
		tinyImage: IMAGES.DASHBOARD.ECONOMICS_BACKGROUND_COMPRESSED,
	},
	{
		id: 4,
		image: IMAGES.DASHBOARD.SCIENCE_CARD,
		name: "science",
		navigateTo: URL_SCIENCE_DASHBOARD,
		backgroundImg: IMAGES.DASHBOARD.SCIENCE_BACKGROUND,
		sideLogo: IMAGES.DASHBOARD.SCIENCE_SIDE_LOGO,
		tinyImage: IMAGES.DASHBOARD.SCIENCE_BACKGROUND_COMPRESSED,
	},
	{
		id: 5,
		image: IMAGES.DASHBOARD.MATH_CARD,
		name: "math",
		navigateTo: URL_MATHS_DASHBOARD,
		backgroundImg: IMAGES.DASHBOARD.MATH_BACKGROUND,
		sideLogo: IMAGES.DASHBOARD.MATH_SIDE_LOGO,
		tinyImage: IMAGES.DASHBOARD.MATH_BACKGROUND_COMPRESSED,
	},
	{
		id: 6,
		image: IMAGES.DASHBOARD.ECONOMICS_CARD,
		name: "economics",
		backgroundImg: IMAGES.DASHBOARD.ECONOMICS_BACKGROUND,
		sideLogo: IMAGES.DASHBOARD.ECONOMICS_SIDE_LOGO,
		tinyImage: IMAGES.DASHBOARD.ECONOMICS_BACKGROUND_COMPRESSED,
		navigateTo: URL_ECONOMIC_DASHBOARD,
	},
	{
		id: 7,
		image: IMAGES.DASHBOARD.FOREIGN_CARD,
		name: "foreign language",
		backgroundImg: IMAGES.DASHBOARD.FOREIGN_BACKGROUND,
		navigateTo: URL_FOREIGN_LANGUAGE_DASHBOARD,
		sideLogo: IMAGES.DASHBOARD.FOREIGN_SIDE_LOGO,
		tinyImage: IMAGES.DASHBOARD.FOREIGN_BACKGROUND_COMPRESSED,
	},
];

const menuItem = ["My Profile", "Change Password", "About us", "Privacy policy", "Terms & Conditions", "Data Security", "Contact Us", "Logout"];

function CommonDashboard() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const profileInfo = useSelector((state) => state.Auth.profileInfo);
	let subjectStatus = useSelector((state) => state.Auth.subjectAccessList);
	let subjectSelected = useSelector((state) => state.Auth.selectedSubjectDataValue);

	const [subLoading, setSubLoading] = useState(false);
	const [subjectAccess, setsubjectAccess] = useState([]);
	const [openProfileMenu, setOpenProfileMenu] = useState(false);
	const [subjectsToShow, setSubjectsToShow] = useState(subjectData);
	const [selectedSubjectData, setSelectedSubjectData] = useState(subjectSelected ? subjectSelected : {});
	const menuList = profileInfo?.isFromClassLink ? menuItem.filter((menu) => menu !== "Change Password") : menuItem;

	useEffect(() => {
		getSubjectAccess();
	}, []);

	//SUBJECT ACCESS FOR STUDENT
	const getSubjectAccess = async () => {
		setSubLoading(true);

		const newSubjectsToShow = [...subjectsToShow];

		let response = await new Api().get("general/getSubjectAccess");

		if (response.status === 200) subjectStatus = response?.data?.subjectAccessData;

		let subjectAccess = [];

		subjectStatus &&
			subjectStatus.forEach((data) => {
				if (!subjectAccess.includes(data.subject)) subjectAccess.push(data.subject.toLowerCase());
			});

		let subject = { data: subjectStatus && subjectStatus.length ? subjectStatus : [] };

		dispatch(subjectAccessData(subject));

		let showSubject = newSubjectsToShow.filter(function (value) {
			return subjectAccess.includes(value.name);
		});

		showSubject = uniqBy(showSubject, "id");

		setSubjectsToShow(showSubject);
		setsubjectAccess(subjectAccess);
		setSubLoading(false);
	};

	function logout() {
		let data = new FormData();

		data.append("token", localStorage.getItem("accessToken"));

		new Api().post("user/logout", { data });

		dispatch(logOutUser());
	}

	const settings = {
		dots: false,
		infinite: false,
		slidesToShow: 7,
		slidesToScroll: 0,
		initialSlide: 0,
		nextArrow: <SlideNextArrow />,
		prevArrow: <SlidePreviousArrow />,
		responsive: [
			{
				breakpoint: 1650,
				settings: { slidesToShow: 6, slidesToScroll: 1, infinite: subjectsToShow.length > 6 ? true : false, dots: false, nextArrow: <SlideNextArrow />, prevArrow: <SlidePreviousArrow /> },
			},
			{
				breakpoint: 1550,
				settings: { slidesToShow: 5, slidesToScroll: 1, infinite: subjectsToShow.length > 5 ? true : false, dots: false, nextArrow: <SlideNextArrow />, prevArrow: <SlidePreviousArrow /> },
			},
			{
				breakpoint: 1250,
				settings: { slidesToShow: 4, slidesToScroll: 2, infinite: subjectsToShow.length > 4 ? true : false, dots: false, nextArrow: <SlideNextArrow />, prevArrow: <SlidePreviousArrow /> },
			},
			{
				breakpoint: 1000,
				settings: { slidesToShow: 3, slidesToScroll: 1, infinite: subjectsToShow.length > 3 ? true : false, dots: false, nextArrow: <SlideNextArrow />, prevArrow: <SlidePreviousArrow /> },
			},
		],
	};

	const handlesubjectAccess = (data) => {
		setSelectedSubjectData(data);
		dispatch(selectedSubjectValue(data));
	};

	const handleSubject = () => {
		if (selectedSubjectData) navigate(selectedSubjectData.navigateTo);
	};

	const handleOpenMenu = (event) => setOpenProfileMenu(event.currentTarget);

	const handleCloseMenu = (data) => {
		setOpenProfileMenu(false);

		let dataUrl = "";

		switch (data) {
			case "My Profile":
				dataUrl = "/" + URL_PROFILE;
				break;

			case "Change Password":
				dataUrl = "/" + URL_CHANGE_PWD;
				break;

			case "About us":
				dataUrl = "/" + URL_ABOUT_US;
				break;

			case "Privacy policy":
				dataUrl = "/" + URL_PRIVACY_POLICY;
				break;

			case "Terms & Conditions":
				dataUrl = "/" + URL_TERMS_AND_CONDITIONS;
				break;

			case "Data Security":
				dataUrl = "/" + URL_DATA_SECURITY;
				break;

			case "Contact Us":
				dataUrl = URL_CONTACT_US;
				break;

			case "Logout":
				logout();
				break;

			default:
				break;
		}

		if (data !== "Logout" && menuList.includes(data)) navigate(dataUrl);
	};

	return (
		<DashboardWrapper bgImg={selectedSubjectData} profileInfo={profileInfo}>
			<LazyImage
				compressedImage={selectedSubjectData.tinyImage ? selectedSubjectData.tinyImage : IMAGES.LANDING_PAGE.TEACHER_BACKGROUND}
				image={selectedSubjectData.backgroundImg ? selectedSubjectData.backgroundImg : IMAGES.LANDING_PAGE.TEACHER_BACKGROUND}
				wrapperProps={{ className: "background-image" }}
			/>
			<div className="audio">
				<audio loop={true} src={UserLoginSound} autoPlay controls />
			</div>

			<div className="header-container">
				<div className="inner-header">
					<div className="logo-img"></div>
					<div className="profile-container">
						<div className="text">{profileInfo?.firstName + " " + profileInfo?.lastName}</div>
						<div className="profile-picture"></div>
						<div className="arrow" onClick={handleOpenMenu}></div>
						<Menu
							id="simple-menu"
							anchorEl={openProfileMenu}
							anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
							transformOrigin={{ vertical: -15, horizontal: 140 }}
							open={openProfileMenu}
							onClose={handleCloseMenu}
							getContentAnchorEl={null}
						>
							{menuList.map((item) => (
								<MenuItem key={item} onClick={() => handleCloseMenu(item)}>
									<div textAlign="center">{item}</div>
								</MenuItem>
							))}
						</Menu>
					</div>
				</div>
			</div>

			{subLoading ? (
				<div className="progress">
					<CircularProgress />
				</div>
			) : (
				<>
					{Object.keys(selectedSubjectData).length ? (
						<div className="subject-text-container">
							<div className="text-logo-container">
								<div className="text-cont flex f-column f-v-center">
									{selectedSubjectData?.name === "civics" && (
										<div className="header-text">Education is the key to unlocking your Future. Understanding your Government is the key to unlocking the World.</div>
									)}

									{selectedSubjectData?.name === "science" && (
										<>
											<div className="header-text">"No amount of experimentation can ever prove me right; a single experiment can prove me wrong."</div>
											<div className="header-text name">By: Albert Einstein</div>
										</>
									)}

									{selectedSubjectData?.name === "math" && (
										<div className="header-text">"Life is a Math Education. In order to gain the most, you have to know how to convert negatives into POSITIVES."</div>
									)}

									{selectedSubjectData?.name === "english" && (
										<>
											<div className="header-text">"What is the longest word in the English language? SMILES: there is a mile between the first and last letters!"</div>
										</>
									)}

									{selectedSubjectData?.name === "history" && (
										<div className="header-text">"A people without the knowledge of their past history, origin and culture is like a tree without roots."</div>
									)}

									{selectedSubjectData?.name === "economics" && (
										<div className="header-text">
											"The ultimate resource in economic development is people. It is people, not capital or raw materials that develop an economy."
										</div>
									)}

									{selectedSubjectData?.name === "foreign language" && (
										<>
											<div className="header-text">"One language sets you in a corridor for life. Two languages open every door along the way."</div>
											<div className="header-text name">By: Frank Smith</div>
										</>
									)}

									<AnimatedButton
										className="play"
										onClick={() =>
											subjectAccess.length && subjectAccess.includes(selectedSubjectData?.name)
												? handleSubject()
												: dispatch(showToast(`Sorry!, you don't have access to ${selectedSubjectData?.name}.`))
										}
									>
										Play now
									</AnimatedButton>
								</div>

								<div style={{ backgroundImage: `url(${selectedSubjectData?.sideLogo})` }} className="side-logo"></div>
							</div>
						</div>
					) : (
						<div className="welcome-container flex f-v-center f-h-center">
							<img className="welcome-image" alt="welcomeLogo" src={IMAGES.DASHBOARD.WELCOME_LOGO} />
						</div>
					)}
					<div className="subject-container">
						<Slider {...settings}>
							{subjectsToShow.map((data) => (
								<div key={data.id}>
									<div
										onClick={() => handlesubjectAccess(data)}
										key={data.id}
										style={{ backgroundImage: `url(${data.image})` }}
										className={`subject-img blinking ${data.id === selectedSubjectData?.id && "selected-subject"}`}
									></div>
								</div>
							))}
						</Slider>
					</div>
				</>
			)}
		</DashboardWrapper>
	);
}

const SlideNextArrow = ({ currentSlide, slideCount, ...props }) => <IconButton {...props} className="next-arrow"></IconButton>;

const SlidePreviousArrow = ({ currentSlide, slideCount, ...props }) => <IconButton {...props} className="prev-arrow"></IconButton>;

export default CommonDashboard;

import React, { useState, Fragment, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { List, ListItem, Avatar, Typography, ListItemText, Collapse } from "@material-ui/core";
import useSound from "use-sound";
// STYLES
import { SidebarWrapper } from "./Sidebar.style";
// ROUTES
import { mathsSidebarRoutes } from "Routes/RoutesList";
// COMPONENTS
import HowToPlayConnect4 from "Components/Maths/Connect4/Modal/HowToPlay";
import HowToPlayMathahtzee from "Components/Maths/Mathahtzee/Modal/HowToPlay";
// HELPERS
import { Api, MATHS_MODULE_ID, commonSidebarListItems, IMAGES } from "Helpers";
import {
	URL_PROFILE,
	URL_CHANGE_PWD,
	URL_ABOUT_US,
	URL_PRIVACY_POLICY,
	URL_TERMS_AND_CONDITIONS,
	URL_MATHS_DASHBOARD,
	URL_CONNECT4,
	URL_CONNECT4_GAME,
	URL_MATHAHTZEE,
	URL_MATHAHTZEE_GAME,
	URL_CONTACT_US,
	URL_DATA_SECURITY,
} from "Helpers/Paths";
// SOUNDS
import DrawerSound from "Assets/Sounds/switch-off.mp3";
// REDUX
import { logOutUser } from "Redux/Auth/Actions";

const getMenuItemSelected = (currentLocation, route) => {
	switch (currentLocation) {
		case URL_MATHS_DASHBOARD:
			return route === currentLocation;

		case `${URL_MATHS_DASHBOARD}/${URL_CONNECT4}`:
			return URL_CONNECT4.includes(route);

		case `${URL_MATHS_DASHBOARD}/${URL_CONNECT4_GAME}`:
			return URL_CONNECT4_GAME.includes(route);

		case `${URL_MATHS_DASHBOARD}/${URL_MATHAHTZEE}`:
			return URL_MATHAHTZEE.includes(route);

		case `${URL_MATHS_DASHBOARD}/${URL_MATHAHTZEE_GAME}`:
			return URL_MATHAHTZEE_GAME.includes(route);

		case `${URL_PROFILE}`:
			return `${URL_PROFILE}` === route;

		case `${URL_ABOUT_US}`:
			return `${URL_ABOUT_US}` === route;

		case `${URL_PRIVACY_POLICY}`:
			return `${URL_PRIVACY_POLICY}` === route;

		case `${URL_TERMS_AND_CONDITIONS}`:
			return `${URL_TERMS_AND_CONDITIONS}` === route;

		case `${URL_DATA_SECURITY}`:
			return `${URL_DATA_SECURITY}` === route;

		case `${URL_CHANGE_PWD}`:
			return `${URL_CHANGE_PWD}` === route;

		case `${URL_CONTACT_US}`:
			return `${URL_CONTACT_US}` === route;

		default:
			return false;
	}
};

const MathsSideBar = (props) => {
	const { open } = props;

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const { gamesStatus } = useSelector((state) => state.GamesData);
	const { totalScore, profileInfo, subjectAccessList: subjects, schoolClassTestID } = useSelector((state) => state.Auth);
	const sidebarListItems = profileInfo?.isFromClassLink ? commonSidebarListItems.filter((list) => list.text !== "Change Password") : commonSidebarListItems;

	const [play] = useSound(DrawerSound, { volume: 0.2 });

	const subjectId = subjects?.filter((item) => item.subject === "Math")[0].subjectId;
	const mathsRoutes = mathsSidebarRoutes.filter((route) => route.order).sort((a, b) => a.order - b.order);
	const routes = location.pathname.includes("maths") && mathsRoutes;

	const [settingsOpen, setSettingsOpen] = useState(false);
	const [connect4HowToPlay, setConnect4HowToPlay] = useState({ open: false, data: {} });
	const [mathahtzeeHowToPlay, setMathahtzee4HowToPlay] = useState({ open: false, data: {} });

	useEffect(() => {
		if (open) props.toggleDrawer();
	}, [location.pathname]); // eslint-disable-line

	const redirectAndClose = (path) => {
		path = path === "/" + URL_MATHS_DASHBOARD ? URL_MATHS_DASHBOARD : path;

		let setStateFunction;

		if (path === "/" + URL_CONNECT4) setStateFunction = setConnect4HowToPlay;
		if (path === "/" + URL_MATHAHTZEE) setStateFunction = setMathahtzee4HowToPlay;

		if (setStateFunction) {
			setStateFunction((prev) => {
				const copyPrev = { ...prev };

				if (!copyPrev.open) {
					props.toggleDrawer();

					if (path === "/" + URL_CONNECT4) copyPrev.data = { id: MATHS_MODULE_ID.CONNECT4, url: URL_CONNECT4 };
					if (path === "/" + URL_MATHAHTZEE) copyPrev.data = { id: MATHS_MODULE_ID.MATHAHTZEE, url: URL_MATHAHTZEE };
				}

				copyPrev.open = !copyPrev.open;

				return copyPrev;
			});
		} else {
			props.toggleDrawer();
			navigate(path);
		}
	};

	const logout = () => {
		const data = new FormData();

		data.append("token", localStorage.getItem("accessToken"));

		new Api().post("user/logout", { data });

		dispatch(logOutUser());
	};

	const handleClose = ({ url }) => redirectAndClose("/" + url);

	const handleLogOut = () => {
		props.toggleDrawer();
		logout();
	};

	return (
		<>
			<SidebarWrapper
				open={open}
				maths={true}
				anchor="left"
				location={location}
				onClose={props.toggleDrawer}
				BackdropProps={{ className: "backdrop-root" }}
				classes={{ root: "root", paper: "drawer-paper" }}
			>
				<List>
					<ListItem className="menu-items profile-details">
						<Avatar variant="square" className="img-profile" src={profileInfo.profilePicUrl || IMAGES.PROFILE.BOY} />

						<span className="text">
							{profileInfo.firstName} {profileInfo.lastName}
						</span>

						<Typography variant="body1" className="score">
							{totalScore}
						</Typography>
					</ListItem>

					{routes.map((route, index) => (
						<Fragment key={index}>
							{!route.expand ? (
								<ListItem
									button
									onClick={() => redirectAndClose("/" + route.path)}
									disabled={route?.activeKey ? !gamesStatus?.[route?.activeKey] : false}
									className={`menu-items ${getMenuItemSelected(location.pathname, route.path) ? "active" : ""}`}
								>
									{route.icon ? <img className="icon" src={route.icon} alt={route.name} /> : <img className="icon" src={IMAGES.COMMONS.HOME} alt={route.name} />}
									<ListItemText className="menu-text" primary={route.name} onMouseOver={play} />
								</ListItem>
							) : (
								<>
									<ListItem button className="menu-items" onClick={() => setSettingsOpen(!settingsOpen)}>
										<img className="icon" src={IMAGES.COMMONS.SETTINGS_ICON} alt={route.name} />
										<ListItemText style={{ marginRight: "30px" }} className="menu-text" primary={route.name} onMouseOver={play} />
										{settingsOpen ? (
											<img className="arrow" alt="less-arrow" src={IMAGES.SIDEBAR.LESS_ARROW} height="30px" width="30px" />
										) : (
											<img className="arrow" alt="more-arrow" src={IMAGES.SIDEBAR.MORE_ARROW} height="30px" width="30px" />
										)}
									</ListItem>

									<Collapse in={settingsOpen} timeout="auto" unmountOnExit>
										<List component="div" disablePadding>
											{sidebarListItems.map((listItem) => (
												<ListItem
													button
													key={listItem.text}
													onClick={() => redirectAndClose(listItem.clickParams)}
													className={`menu-items settings ${getMenuItemSelected(location.pathname, listItem.clickParams) ? "active" : ""}`}
												>
													<ListItemText className="menu-text" primary={listItem.text} />
												</ListItem>
											))}

											<ListItem button className="menu-items settings" onClick={handleLogOut}>
												<ListItemText className="menu-text" primary="Logout" />
											</ListItem>
										</List>
									</Collapse>
								</>
							)}
						</Fragment>
					))}
				</List>
			</SidebarWrapper>

			{connect4HowToPlay.open && <HowToPlayConnect4 open={connect4HowToPlay.open} data={connect4HowToPlay.data} handleClose={handleClose} subjectId={subjectId} schoolId={schoolClassTestID} />}

			{mathahtzeeHowToPlay.open && (
				<HowToPlayMathahtzee open={mathahtzeeHowToPlay.open} data={mathahtzeeHowToPlay.data} handleClose={handleClose} subjectId={subjectId} schoolId={schoolClassTestID} />
			)}
		</>
	);
};

export default MathsSideBar;

import React from "react";
import { GLPCommonComponentWrapper } from "./GLPCommonComponentStyle";
import GLPCommonComponentModal from "./GLPCommonComponentModal";
import { useLocation, useNavigate } from "react-router-dom";
import { URL_TEACHER_LOGIN, URL_GLOBAL_LANDING_PAGE, URL_LOGIN, URL_ABOUT_US, URL_TERMS_AND_CONDITIONS, URL_PRIVACY_POLICY, URL_DATA_SECURITY } from "Helpers";
import { Box } from "@material-ui/core";

function TeacherPage() {
	const location = useLocation();
	const navigate = useNavigate();

	const SubscriptionDetails = location?.state?.subscriptionRequestDto;

	return (
		<GLPCommonComponentWrapper>
			<Box className="header-container">
				<Box className="top-bar">
					<Box
						className="subject-logo"
						onClick={() => {
							navigate(URL_GLOBAL_LANDING_PAGE);
						}}
					></Box>
					<Box className="btn-container">
						<Box
							className="teacher-btn"
							onClick={() => {
								navigate(URL_GLOBAL_LANDING_PAGE);
							}}
						>
							HOME
						</Box>
						<Box
							className="login"
							onClick={() => {
								navigate(URL_LOGIN);
							}}
						>
							LOGIN
						</Box>
					</Box>
				</Box>
			</Box>

			<Box className="main-container">
				<Box className="modal">
					<GLPCommonComponentModal subscriptionDetails={SubscriptionDetails} renderProps={location?.pathname === URL_TEACHER_LOGIN ? "teacher-login" : "school-register"} />
				</Box>
			</Box>

			<Box className="footer-container">
				<Box className="inner-container">
					<Box className="footer-text">© 2022 Subjects Master. All rights reserved.</Box>
					<Box className="page-container">
						<Box className="about-us" onClick={() => navigate("/" + URL_ABOUT_US)}>
							ABOUT US
						</Box>
						<Box className="partition">|</Box>
						<Box className="terms" onClick={() => navigate("/" + URL_TERMS_AND_CONDITIONS)}>
							TERMS & CONDITIONS
						</Box>
						<Box className="partition">|</Box>
						<Box className="privacy" onClick={() => navigate("/" + URL_PRIVACY_POLICY)}>
							PRIVACY POLICY
						</Box>
						<Box className="partition">|</Box>
						<Box className="privacy" onClick={() => navigate("/" + URL_DATA_SECURITY)}>
							DATA SECURITY
						</Box>
					</Box>
				</Box>
			</Box>
		</GLPCommonComponentWrapper>
	);
}

export default TeacherPage;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { Button, CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
// ICONS
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
// STYLES
import { DialogWrapper } from "../Mathahtzee.style";
// REDUX
import { quizSound } from "Redux/General/Actions";
// HELPERS
import { Api, URL_MATHS_DASHBOARD, MATHS_MODULE_ID } from "Helpers";

const TYPE = { DID_YOU_KNOW: "didYouKnow", HOW_TO_PLAY: "howToPlay" };

const DEFAULT_TYPE = TYPE.DID_YOU_KNOW;
const NEXT_TYPE = TYPE.HOW_TO_PLAY;

const HowToPlay = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [content, setContent] = useState({});
	const [type, setType] = useState(DEFAULT_TYPE);
	const [isLoading, setLoading] = useState(false);
	const [pdfLoader, setPdfLoader] = useState(false);

	useEffect(() => {
		fetchData();
	}, []); // eslint-disable-line

	async function fetchData() {
		try {
			setLoading(true);

			const data = new FormData();

			data.append("moduleId", props.data.id);
			data.append("subjectId", props?.subjectId);

			const response = await new Api().post("staticPage/game", { data });

			if (response.data) setContent(response.data);

			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	function proceed() {
		if (type === DEFAULT_TYPE) setType(NEXT_TYPE);
		else {
			if (props.data.id === MATHS_MODULE_ID.MATHAHTZEE) dispatch(quizSound(true));
			if (props.data.url) navigate(URL_MATHS_DASHBOARD + "/" + props.data.url, { state: { subjectId: props?.subjectId } });

			closeModal();
		}
	}

	const handlePdf = () => {
		setPdfLoader(true);

		let pdfUrl,
			attachedText = "",
			moduleName = props?.data?.id.replace(/ /g, "_").toUpperCase();

		if (type === TYPE.DID_YOU_KNOW) {
			pdfUrl = content?.didYouKnowFile;
			attachedText = `${moduleName}_DID_YOU_KNOW`;
		} else {
			pdfUrl = content?.howToPlayFile;
			attachedText = `${moduleName}_HOW_TO_PLAY`;
		}

		fetch(pdfUrl).then((response) => {
			response.blob().then((blob) => {
				const alink = document.createElement("a");
				const fileURL = window.URL.createObjectURL(blob);

				alink.href = fileURL;
				alink.download = `${attachedText}.pdf`;
				alink.click();

				setPdfLoader(false);
			});
		});
	};

	const closeModal = () => props.handleClose({ url: props.data.url });

	return (
		<DialogWrapper open={props.open} onClose={closeModal} PaperProps={{ elevation: 0 }}>
			<div className="box-modal text-center">
				<div className="title-container">
					<p className="sub-title">{type === DEFAULT_TYPE ? "Did you know?" : "How to play?"}</p>

					<div className="icon" onClick={closeModal} />
				</div>

				<div className="content-wrapper">
					{isLoading && (
						<div className="progress">
							<CircularProgress size={25} />
						</div>
					)}

					<Scrollbars className="scroll-bar" renderThumbVertical={ThumbVertical}>
						<div className="content" dangerouslySetInnerHTML={{ __html: content[type] }} />

						{content?.didYouKnowFile && type === TYPE.DID_YOU_KNOW && (
							<div className="attach-file">
								<PictureAsPdfIcon className="icon" />
								<div className="file" onClick={handlePdf}>
									Attachment
								</div>
								{pdfLoader && (
									<div>
										<CircularProgress className="pdf-loader" />
									</div>
								)}
							</div>
						)}

						{content?.howToPlayFile && type === TYPE.HOW_TO_PLAY && (
							<div className="attach-file">
								<PictureAsPdfIcon className="icon" />
								<div className="file" onClick={handlePdf}>
									Attachment
								</div>
								{pdfLoader && (
									<div>
										<CircularProgress className="pdf-loader" />
									</div>
								)}
							</div>
						)}
					</Scrollbars>

					<div className={`type-switcher ${type === DEFAULT_TYPE ? "visibility-none" : ""}`}>
						<div className="divider" />

						{type === TYPE.HOW_TO_PLAY && (
							<p className="text" onClick={() => setType(TYPE.DID_YOU_KNOW)}>
								Back to Review
							</p>
						)}
					</div>
				</div>

				<div className="btn-container">
					<Button className="done-btn enable" onClick={proceed}>
						{type === DEFAULT_TYPE ? "How To Play" : "Lets Play"}
					</Button>
				</div>
			</div>
		</DialogWrapper>
	);
};

const ThumbVertical = (props) => <div {...props} className="thumb-vertical" />;

export default HowToPlay;

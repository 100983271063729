import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// COMPS
import { InputWithLabel } from "Components/Commons";

// FORM VALIDATION
import { Formik } from "formik";

// STYLES
import { PageWrapper, FormWrapper } from "./ResetPassword.style";

// CONST
import { Api, URL_LOGIN, generateValidationSchema } from "Helpers";
import { Button, Typography } from "@material-ui/core";

const initialValues = { password: "", confirmPassword: "" };

function ResetPassword() {
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();

	let { token } = useParams();

	function resetPassword(values) {
		setIsLoading(true);
		let data = new FormData();
		data.append("token", token);
		data.append("newPassword", values.password);

		new Api().post("user/resetPassword", { data }).then((response) => {
			setIsLoading(false);
			navigate(URL_LOGIN);
		});
	}

	return (
		<PageWrapper className="flex f-v-center">
			{/* <DesignedBox className="flex f-v-center f-h-center"> */}
			<div className="box">
				<div className="title">
					<h1 className="text">Reset Password</h1>
				</div>

				<Formik initialValues={initialValues} onSubmit={resetPassword} validationSchema={validationSchema}>
					{({ values, touched, errors, handleChange }) => (
						<FormWrapper>
							<div className="input-wrapper gap">
								{inputs.map((input) => (
									<InputWithLabel
										key={input.name}
										className="full-width"
										name={input.name}
										placeholder={input.label}
										label={input.label}
										type={input.type}
										value={values[input.name]}
										onChange={handleChange}
										validated={touched[input.name] && errors[input.name]}
										signupform={true}
									/>
								))}
							</div>

							<div className="send-button-parent">
								<Button className="button" type="submit" isLoading={isLoading}>
									<Typography className="send-font">Reset Password</Typography>
								</Button>
							</div>
						</FormWrapper>
					)}
				</Formik>
			</div>
			{/* </DesignedBox> */}
		</PageWrapper>
	);
}

export default ResetPassword;

const inputs = [
	{
		label: "Password",
		name: "password",
		type: "password",
		validation: { required: true, min: 6 },
	},
	{
		label: "Confirm Password",
		name: "confirmPassword",
		type: "password",
		validation: { required: true, equals: "password" },
	},
];

const validationSchema = generateValidationSchema(inputs);

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconButton, useTheme } from "@material-ui/core";
import useSound from "use-sound";
// STYLES
import { Header } from "./TopBar.style";
// ICONS
import HomeIcon from "@material-ui/icons/Home";
// COMPONENTS
import EconomicsSidebar from "Components/Commons/Sidebar/EconomicsSidebar";
// SOUNDS
import DrawerSound from "Assets/Sounds/switch-off.mp3";
// HELPERS
import withRouterWrapper from "Helpers/WithRouterWrapper";
import { URL_GENERAL_DASHBOARD, URL_ECONOMIC_DASHBOARD, IMAGES } from "Helpers";

const EconomicsTopBar = (props) => {
	const theme = useTheme();
	const navigate = useNavigate();

	const { isLoggedIn, profileInfo, totalScore, gameScore } = useSelector((state) => state.Auth);

	const pathname = props?.router?.location?.pathname;

	const [play] = useSound(DrawerSound, { volume: 0.2 });

	const [drawerOpen, setDrawerOpen] = useState(false);

	const toggleDrawer = () => {
		play();
		setDrawerOpen((prev) => !prev);
	};

	const homePage = () => {
		if (pathname === URL_ECONOMIC_DASHBOARD) props.router.navigate(URL_GENERAL_DASHBOARD, { replace: true });
		else props.router.navigate(URL_ECONOMIC_DASHBOARD, { replace: true });
	};

	return (
		<>
			<Header position="fixed" drawerOpen={drawerOpen} theme={theme} economic={1} rapidFire={pathname === "/economics/rapid-fire"}>
				<div className="icon-container flex f-v-center">
					<IconButton onClick={pathname !== URL_GENERAL_DASHBOARD && toggleDrawer} className={"menu " + (drawerOpen && "active")} style={{ marginTop: 0 }}>
						<div className="tick"></div>
					</IconButton>

					<div className="logo-wrapper">
						<div onClick={() => navigate(URL_GENERAL_DASHBOARD)}>
							<img src={pathname.includes("settings") ? IMAGES.DASHBOARD.SUBJECT_MASTER_LOGO_BLACK : IMAGES.ECONOMICS.WHITE_LOGO} className={"logo-history"} alt="" />
						</div>
					</div>
				</div>

				{isLoggedIn && !pathname.includes("settings") && (
					<div className="profile-section flex f-v-center">
						<div className="image" style={{ backgroundImage: `url(${profileInfo.profilePicUrl || IMAGES.PROFILE.BOY})` }} />

						<div className="info">
							<div className="name elipsis">{pathname === URL_ECONOMIC_DASHBOARD ? profileInfo.firstName || +"NA" + " " + profileInfo.lastName || "" : "Game Score"}</div>

							<div className="bottom flex f-v-center">
								<div className="points flex f-v-center">
									{pathname.includes("/settings") || pathname === "/economics" || pathname === "/economics/pursuit"
										? totalScore == (0 || null)
											? "0"
											: totalScore
										: gameScore == (0 || null)
										? "0"
										: gameScore}
								</div>

								<div className="logout flex f-v-center" onClick={homePage}>
									<HomeIcon />
								</div>
							</div>
						</div>
					</div>
				)}
			</Header>

			{isLoggedIn && <EconomicsSidebar open={drawerOpen} toggleDrawer={toggleDrawer} />}
		</>
	);
};

export default withRouterWrapper(EconomicsTopBar);

export const ACTION_TYPES = {
	TOAST_MESSAGE_ENABLE: "TOAST_MESSAGE_ENABLE",
	TOAST_MESSAGE_DISABLE: "TOAST_MESSAGE_DISABLE",
	QUIZ_SOUND: "QUIZ_SOUND",
};

export const showToast = (message) => {
	return {
		type: ACTION_TYPES.TOAST_MESSAGE_ENABLE,
		message: message || "",
	};
};

export const hideToast = () => {
	return {
		type: ACTION_TYPES.TOAST_MESSAGE_DISABLE,
	};
};

export const quizSound = (value) => {
	return {
		type: ACTION_TYPES.QUIZ_SOUND,
		QuizSound: value,
	};
};

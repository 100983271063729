import React, { useState, useEffect } from "react";
import { DialogWrapper } from "./HowToPlay.style";
import { AnimatedButton } from "Components/Commons";
import { Api, URL_SCIENCE_DASHBOARD, SCIENCE_MODULE_ID } from "Helpers";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";
import { CircularProgress } from "@material-ui/core";
import { Scrollbars } from "react-custom-scrollbars";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

const TYPE = {
	HOW_TO_PLAY: "howToPlay",
	DID_YOU_KNOW: "didYouKnow",
};

const DEFAULT_TYPE = TYPE.DID_YOU_KNOW;
const NEXT_TYPE = TYPE.HOW_TO_PLAY;

export const HowToPlay = (props) => {
	const [content, setContent] = useState({}),
		[type, setType] = useState(DEFAULT_TYPE),
		[isLoading, setLoading] = useState(false),
		[pdfLoader, setPdfLoader] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		fetchData();
	}, []);

	async function fetchData() {
		setLoading(true);
		let data = new FormData();
		data.append("moduleId", props.data.id);
		data.append("subjectId", props?.subjectId);
		let response = await new Api().post("staticPage/game", { data });
		setContent(response.data);
		setLoading(false);
	}

	const proceed = () => {
		if (type === DEFAULT_TYPE) setType(NEXT_TYPE);
		else {
			if (props.data.url) navigate(URL_SCIENCE_DASHBOARD + "/" + props.data.url, { state: { subjectId: props?.subjectId } });
			closeModal();
		}
	};

	const closeModal = () => props.handleClose({ url: props.data.url });

	const ThumbVertical = (props) => <div {...props} className="thumb-vertical" />;

	const handlePdf = () => {
		setPdfLoader(true);
		let pdfUrl,
			attachedText = "",
			moduleName = props?.data?.id.replace(/ /g, "_").toUpperCase();
		if (type === TYPE.DID_YOU_KNOW) {
			pdfUrl = content?.didYouKnowFile;
			attachedText = `${moduleName}_DID_YOU_KNOW`;
		} else {
			pdfUrl = content?.howToPlayFile;
			attachedText = `${moduleName}_HOW_TO_PLAY`;
		}

		fetch(pdfUrl).then((response) => {
			response.blob().then((blob) => {
				// Creating new object of PDF file
				const fileURL = window.URL.createObjectURL(blob);
				// Setting various property values
				let alink = document.createElement("a");
				alink.href = fileURL;
				alink.download = `${attachedText}.pdf`;
				alink.click();
				setPdfLoader(false);
			});
		});
	};

	return (
		<DialogWrapper
			onClose={closeModal}
			open={props.open}
			classes={{ paper: "dialog-paper", paperWidthSm: "paper-width" }}
			BackdropProps={{
				classes: {
					root: "backdrop",
				},
			}}
			PaperProps={{ elevation: 0 }}
		>
			<div className="box-model">
				<div className="title-container">
					<p className="sub-title">{type === TYPE.HOW_TO_PLAY ? "How to play ?" : "Did you know ?"}</p>
					<div className="icon-container" onClick={closeModal}>
						<CancelIcon className="icon" />
					</div>
				</div>

				<div className="content-wrapper">
					{isLoading && (
						<div className="progress">
							<CircularProgress size={25} />
						</div>
					)}

					<Scrollbars className={"scroll-bar"} renderThumbVertical={ThumbVertical}>
						<div className="content" dangerouslySetInnerHTML={{ __html: content[type] }} />

						{content?.didYouKnowFile && type === TYPE.DID_YOU_KNOW && (
							<div className="attach-file">
								<PictureAsPdfIcon />
								<div className="file" onClick={handlePdf}>
									Attachment
								</div>
								{pdfLoader && (
									<div>
										<CircularProgress className="pdf-loader" />
									</div>
								)}
							</div>
						)}

						{content?.howToPlayFile && type === TYPE.HOW_TO_PLAY && (
							<div className="attach-file">
								<PictureAsPdfIcon />
								<div className="file" onClick={handlePdf}>
									Attachment
								</div>
								{pdfLoader && (
									<div>
										<CircularProgress className="pdf-loader" />
									</div>
								)}
							</div>
						)}
					</Scrollbars>
					{
						<div className={`type-switcher ${type === DEFAULT_TYPE ? "visibility-none" : ""}`}>
							<div className="divider" />
							{type === TYPE.HOW_TO_PLAY && (
								<p
									className="text"
									onClick={() => {
										setType(TYPE.DID_YOU_KNOW);
									}}
								>
									Back to Review
								</p>
							)}
							{type === TYPE.DID_YOU_KNOW && (
								<p
									className="text"
									onClick={() => {
										setType(TYPE.HOW_TO_PLAY);
									}}
								>
									How to play
								</p>
							)}
						</div>
					}
				</div>
				<div className="play-btn-container">
					<AnimatedButton className="play" onClick={proceed}>
						{" "}
						{type === DEFAULT_TYPE ? "How to Play" : "Lets Play"}
					</AnimatedButton>
				</div>
			</div>
		</DialogWrapper>
	);
};

export default HowToPlay;

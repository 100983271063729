import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
// ICONS
import CloseIcon from "@material-ui/icons/Close";
// STYLES
import { SnackbarContentWrapper } from "./Toast.style";

const Toast = (props) => {
	return (
		<Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			classes={{ root: "snackbar-root" }}
			autoHideDuration={props.duration || 8000}
			open={props.open}
			onClose={props.handleClose}
			style={{ zIndex: "999999" }}
		>
			<SnackbarContentWrapper
				className={"themed"}
				aria-describedby="client-snackbar"
				classes={{ message: "notification-message", action: "notification-action" }}
				message={
					<React.Fragment>
						<b>{props.message}</b>
					</React.Fragment>
				}
				action={
					<React.Fragment>
						<IconButton size="small" aria-label="close" color="inherit" onClick={props.handleClose}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</React.Fragment>
				}
			/>
		</Snackbar>
	);
};

export default Toast;

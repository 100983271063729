import React from "react";
import styled from "styled-components";
import { AppBar } from "@material-ui/core";
// CONSTANTS
import { COLORS, FONTS, responsive } from "Styles/Constants";
// IMAGES
import { IMAGES } from "Helpers";

const tickStyle = `
	background 	    : #e4eff4;
	box-shadow	    : 0 0 2px 1px ${COLORS.SECONDARY};
	border-radius	: 5px;
	height 		    : 2px;
	width 		    : 85%;
	transition 	    : .3s;
`;

const tickStyleForiegnLanguage = `
    background 	    : #e4eff4;
    box-shadow	    : 0 0 2px 1px #256A00;
    border-radius   : 5px;
    height 		    : 2px;
    width 		    : 85%;
    transition 	    : .3s;
`;

export const Header = styled(({ drawerOpen, maths, rapidFire, foreignLanguage, presentOrPast, ...rest }) => <AppBar {...rest} />)`
	padding: 0 20px;
	flex-direction: row;
	justify-content: space-between;
	transition: ${(props) =>
		props.theme.transitions.create(["margin", "width"], {
			easing: props.theme.transitions.easing.sharp,
			duration: props.theme.transitions.duration.leavingScreen,
		})};

	.icon-container {
		height: 100%;
		${(props) =>
			props?.foreignLanguage
				? `
                    @media screen and (min-width: 2560px){
                        margin-top:4px;
                        margin-left:2%;
                    }
                    `
				: ``}

		.menu-button {
			height: 40px;
			width: 40px;
			background-image: url(${IMAGES.HISTORY.PRESENT_OR_PAST.PRESENT_PAST_MENU});
			background-size: 100% 100%;
			cursor: pointer;
		}

		.quiz-menu {
			margin: 0;
			padding: 0;

			.img-tick {
				background-image: url(${IMAGES.FOREIGN.QUIZ.MENU_ICON});
				height: 60px;
				width: 60px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
			}
		}

		.menu {
			border: ${(props) => (props?.foreignLanguage ? `2px solid red` : `2px solid #e4eff4;`)};
			box-shadow: ${(props) => (props?.foreignLanguage ? `0 0 5px red, 0 0 5px red inset` : `0 0 5px ${COLORS.SECONDARY}, 0 0 5px ${COLORS.SECONDARY} inset`)};
			height: 40px;
			margin-top: ${(props) => (props?.foreignLanguage ? `0px` : `10px`)};
			padding: 7px;
			width: 40px;
			background-color: ${(props) => (props?.foreignLanguage ? "#010901" : "#140030")};

			.MuiIconButton-label {
				position: relative;
				transition: 0.3s;

				.tick {
					width: 85%;
					${(props) => (props?.foreignLanguage ? `${tickStyleForiegnLanguage}` : `${tickStyle}`)};

					&::before {
						top: 6px;
						left: 2px;
						content: "";
						position: absolute;
						${(props) => (props?.foreignLanguage ? `${tickStyleForiegnLanguage}` : `${tickStyle}`)};
					}

					&::after {
						${(props) => (props?.foreignLanguage ? `${tickStyleForiegnLanguage}` : `${tickStyle}`)};
						content: "";
						position: absolute;
						bottom: 6px;
						left: 2px;
					}
				}
			}

			&.active {
				.MuiIconButton-label {
					transform: rotate(360deg);

					.tick {
						background: transparent;
						box-shadow: 0 0 0;

						&::before {
							transform: rotate(45deg) translate(-4px, -4px);
						}

						&::after {
							transform: rotate(-45deg) translate(-5px, 4px);
						}
					}
				}
			}
		}

		.logo-wrapper {
			cursor: pointer;
			position: relative;
			margin-left: 20px;

			.logo {
				margin-top: ${(props) => props?.civics && props.civics && "10px"};
				height: ${(props) => (props?.civics && props.civics ? "40px" : "49px")};
			}

			.logo-man {
				right: 17px;
				height: 50px;
				position: absolute;
			}
		}
		.logo-history {
			height: 38px;
			margin-top: ${(props) => (props?.economic ? "15px" : "5px")};

			${(props) =>
				props?.foreignLanguage
					? `
                        @media screen and (min-width: 2560px){
                            height:53px
                            width:265px;
                            margin-top:20px;
                        }
                    `
					: ``}
		}
	}

	.button-container {
		.styled {
			width: 175px;
			margin: 0 20px;

			.icon-container {
				height: auto;
			}

			${responsive.DISPLAY` margin : 0 15px; width : 165px;`}
		}
	}

	.button {
		display: flex;
		align-items: center;

		.student-login-button {
			height: 34px;
			border-radius: 30px;
			font-size: 16px;
			padding: 5px 20px;
			cursor: pointer !important;
			background: black !important;
			color: #fff;
			margin-right: 30px !important;
		}
	}

	.profile-section {
		height: ${(props) => (props?.history ? `108px` : props?.foreignLanguage ? `124px` : `108px`)};
		padding: 10px 10px 20px 30px;
		width: ${(props) => (props?.foreignLanguage ? `260px` : `250px`)};
		margin-right: -20px; /* REMOVING PADDING */
		margin-top: ${(props) => props.foreignLanguage && "-22px"};
		margin-top: ${(props) => props.presentOrPast && "-10px"};
		background-image: ${(props) =>
			props?.history
				? props.presentOrPast
					? `url(${IMAGES.HISTORY.PRESENT_OR_PAST.SCOREBOARD})`
					: `url(${IMAGES.HISTORY.TOP_BAR_TILE})`
				: props?.foreignLanguage
				? `url(${IMAGES.FOREIGN.TOP_BAR_TILE})`
				: `url(${IMAGES.CIVICS.TOP_BAR_TILE})`};
		background-image: ${(props) => props?.science && `url(${IMAGES.SCIENCE.TOP_BAR_TILE})`};
		${(props) => (props?.economic ? (props.rapidFire ? `background-image: url(${IMAGES.ECONOMICS.TOP_BAR_TILE});` : `background-image: url(${IMAGES.ECONOMICS.RAPID_FIRE_TOP_BAR_TILE});`) : "")}
		background-image: ${(props) => props?.english && `url(${IMAGES.ENGLISH.TOP_BAR_TILE})`};
		background-image: ${(props) => props?.maths && `url(${IMAGES.MATH.TOP_BAR_TILE})`};
		background-image: ${(props) => props?.foreignLangQuiz && `url(${IMAGES.FOREIGN.QUIZ.SCORE})`};
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 10px;
		padding-left: ${(props) => (props?.foreignLanguage ? `54px` : ``)};
		${(props) => props?.english && `padding: 10px 10px 20px 30px;`}
		${(props) => props?.economic && props.rapidFire && `padding: 0px 10px 20px 50px;`}	

        ${(props) =>
			props?.foreignLanguage
				? `
                    @media screen and (min-width: 2560px){
                        height:168px;
                        width:313px;
                        background-size:100% 100%;
                        padding-left:4%;
                    }
                    `
				: ``}

        ${responsive.DISPLAY` height: ${(props) => (props.foreignLanguage ? `124px` : `95px`)}; width: ${(props) => (props.foreignLanguage ? `241px` : `220px`)}`}
	}

	.image {
		border-radius: 10px;
		border: ${(props) => (props?.foreignLanguage ? `3px solid #f1c04c` : `3px solid ${COLORS.SECONDARY}`)};
		border: ${(props) => (props?.presentOrPast ? `3px solid #ffc33b` : `3px solid ${COLORS.SECONDARY}`)};
		background-color: #0a173a;
		height: 50px;
		width: 50px;
		margin-left: ${(props) => (props?.foreignLanguage ? `-9px` : ``)};

		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

		${(props) =>
			props?.foreignLanguage
				? `
                    @media screen and (min-width: 2560px){
                        height:59px;
                        width:59px;
                       
                    }
                    `
				: ``}
		${(props) =>
			props?.foreignLanguage
				? `
                    @media screen and (max-width: 1750px){
                        height:39px;
                        width:45px;
                       
                    }
                    `
				: `
                @media screen and (max-width: 1750px){
                        height:45px;
                        width:45px;
                       
                    }
                `}
	}

	.info {
		padding: ${(props) => (props.foreignLanguage ? `2px 10px` : `5px 10px`)};
		width: ${(props) => (props.foreignLanguage ? `calc(100% - 40px)` : `calc(100% - 50px)`)};
		/* width: ${(props) => props?.foreignLanguage && `calc(100% - 40px)`}; */

		.name {
			font-size: 21px;
			${responsive.DISPLAY` font-size: 18px;`}
			color: ${(props) => (props.foreignLanguage ? `black` : `#fff`)};
			color: ${(props) => props?.foreignLangQuiz && `${COLORS.WHITE}`};
		}

		.bottom {
			.points {
				border-radius: 3px;
				height: 20px;
				background: ${(props) => (props.foreignLanguage ? `#da2323` : props.maths ? `#a36b2d` : `#1688fd`)};
				background: ${(props) => props?.foreignLangQuiz && `${COLORS.ORANGE}`};
				font-size: 17px;
				padding: 0 10px;
				font-family: ${(props) => (props.presentOrPast ? FONTS.PRIMARY : FONTS.PRIMARY_LIGHT)};
				${(props) => (props?.maths ? `box-shadow: 0px 3px 0 0 #670101;` : ``)}
				${(props) =>
					props?.presentOrPast
						? `
					box-shadow: 0px 3px 0 0 #49107e;
  					background-color: #680ac1;
				`
						: ``}

				${(props) =>
					props?.foreignLanguage
						? `
                    @media screen and (min-width: 2560px){
                        height:27px;
                        width:38px;
                        font-size:21px;
                    }
                    `
						: ``}

                ${responsive.DISPLAY` font-size: 15px;`}
			}

			.logout {
				cursor: pointer;
				margin-left: 15px;

				svg {
					font-size: 21px;
					${(props) =>
						props?.foreignLanguage
							? `
                            @media screen and (min-width: 2560px){
                            font-size:27px;
                        }
                        `
							: ``}
				}
			}
		}
	}
`;

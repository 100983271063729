import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// COMPONENTS
import Toast from "Components/Commons/Toast/Toast";
// HELPERS
import { Api } from "Helpers";
import { connectSocket, Socket } from "Helpers/SocketClient";
// REDUX
import { hideToast } from "Redux/General/Actions";
import { updateGamesStatus } from "Redux/GamesData/Action";
import { updateProfileInfo, updateTotalScore } from "Redux/Auth/Actions";

const EventManager = () => {
	const dispatch = useDispatch();
	const {
		Auth: { isLoggedIn },
		General: { toast },
	} = useSelector((state) => state);

	const handleCloseToast = () => dispatch(hideToast());

	return (
		<React.Fragment>
			<Toast open={toast.enable} message={toast.message} css={toast.css} handleClose={handleCloseToast} />

			{isLoggedIn && <AfterLoginEvents dispatch={dispatch} />}
		</React.Fragment>
	);
};

const AfterLoginEvents = ({ dispatch }) => {
	const init = useCallback(async () => {
		try {
			const [profileInfo, gameStatus] = await Promise.all([new Api().get("user/profile"), new Api().get("general/active/gameStatus/student")]);

			connectSocket();

			if (!Socket) return;

			Socket.open();

			Socket.on("connect", () => {
				console.log("Socket Connected");
			});

			Socket.on("disconnect", (reason) => {
				console.log("Socket Disconnected", reason);
			});

			Socket.on("connect_failed", (value) => {
				console.log("Socket Connection Failed", value);
			});

			Socket.on("error", (reason) => {
				console.log("Socket Error", reason);
			});

			Socket.on("message", (value) => {
				console.log("Socket Message", value);
			});

			Socket.on("reconnect", (value) => {
				console.log("Socket Reconnected", value);
			});

			Socket.on("reconnecting", (value) => {
				console.log("Socket Reconnecting", value);
			});

			Socket.on("reconnect_failed", (value) => {
				console.log("Socket Reconnection Failed", value);
			});

			dispatch(
				updateGamesStatus({
					...(gameStatus.data.civics || {}),
					...(gameStatus.data.economics || {}),
					...(gameStatus.data.english || {}),
					...(gameStatus.data.foreignLanguage || {}),
					...(gameStatus.data.history || {}),
					...(gameStatus.data.maths || {}),
					...(gameStatus.data.science || {}),
				})
			);
			dispatch(updateProfileInfo(profileInfo.data));
			dispatch(updateTotalScore(profileInfo.data.total));
		} catch (error) {
			console.log(error);
		}
	}, [dispatch]);

	useEffect(() => {
		init();
	}, [init]);

	return <></>;
};

export default EventManager;

import styled from "styled-components";

const LazyImageWrapper = styled.div`
	position: relative;
	background-size: 100% 100%;
	background-repeat: no-repeat;

	${(props) => (props.imageLoaded ? "" : `background-image: url(${props.compressedImage})`)};

	&::before {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		transition: backdrop-filter 0.5s;
		backdrop-filter: ${(props) => (props.imageLoaded ? "none" : "blur(5px)")};
	}

	.image {
		width: 100%;
		height: 100%;
		opacity: ${(props) => (props.imageLoaded ? "1" : "0")};
	}
`;

export default LazyImageWrapper;

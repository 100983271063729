import styled from "styled-components";

import { PageWrapper as PWLogin, FormWrapper as FWLogin } from "Components/Pages/Login/Login.style";
import { FONTS, responsive } from "Styles/Constants";
import { IMAGES } from "Helpers";

export const PageWrapper = styled(PWLogin)`
	width: 100%;
	.box {
		margin: 0px auto;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background-color: rgb(50, 50, 50);
		border-radius: 56px 56px 96px 96px;
		padding: 10px 15px;
		/* width: 280px; */
		.title {
			font-size: 12px;
			text-transform: initial;
			color: #fff;
		}
		.button-wrapper {
			display: flex;
			justify-content: center;
		}
	}
`;

export const FormWrapper = styled(FWLogin)``;

export const ForgotPasswordWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow: hidden;
	background-color: #fff;
	width: 100%;
	background-image: url(${IMAGES.LANDING_PAGE.TEACHER_BACKGROUND});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	justify-content: space-between;
	.header-container {
		background-color: rgba(255, 255, 255, 0.9);
		.top-bar {
			display: flex;
			justify-content: space-between;
			padding: 1%;
			width: 90%;
			margin: 0px auto;
			.subject-logo {
				background-image: url(${IMAGES.DASHBOARD.SUBJECT_MASTER_LOGO_BLACK});
				width: 110px;
				height: 40px;
				background-repeat: no-repeat;
				background-size: contain;
				cursor: pointer;
			}
			.btn-container {
				display: flex;
				width: auto;
				margin-right: -6%;
				.teacher-btn {
					border-radius: 29.5px;
					width: 180px;
					border: solid 2px rgba(4, 23, 39, 0.3);
					display: flex;
					align-items: center;
					padding: 0px 14px;
					justify-content: center;
					cursor: pointer;
				}
				.login {
					border-radius: 29.5px;
					width: 180px;
					border: solid 2px rgba(4, 23, 39, 0.3);
					display: flex;
					align-items: center;
					padding: 0px 14px;
					justify-content: center;
					margin-left: 10px;
					cursor: pointer;
				}
			}
		}
	}

	.footer-container {
		background-color: rgba(255, 255, 255, 0.9);
		padding: 5px;
		width: 100%;
		.inner-container {
			display: flex;
			padding: 0% 1%;
			justify-content: space-between;
			.footer-text {
				color: #000;
				font-size: 18px;
				${responsive.TABLET`
					font-size : 13px
				`}
			}

			.page-container {
				display: flex;
				font-size: 16px;
				cursor: pointer;
				${responsive.TABLET`
					font-size : 13px
				`}

				.partition {
					margin: 0px 5px;
				}
			}
		}
	}

	.main-container {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0px auto;
		width: 100%;
		position: relative;
		.modal {
			display: flex;
			justify-content: center;
			align-items: center;
			background-repeat: no-repeat;
			position: relative;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			transform-origin: 0 0;
			transition: transform 3s;
			backface-visibility: hidden;
			transform-style: preserve-3d;
			border-radius: 8px;
			margin: 0px auto;
			width: 500px;
			position: relative;
			@media (max-height: 790px) {
				margin-top: -20px;
				height: 90%;
			}

			.box {
				width: 100%;
				margin: 0px auto;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				border-radius: 10px;
				box-shadow: 0 0 20.9px 3.1px rgba(4, 23, 39, 0.5);
				background-image: linear-gradient(to bottom, #fff600, #fffcb3, #fffede, #f7f7eb, #fff);
				padding: 10px 15px;

				.title {
					.text {
						color: black;
						margin-top: 25px;
						font-family: ${FONTS.TERTIARY};
					}
				}
				.button-wrapper {
					display: flex;
					justify-content: center;
				}
			}

			.send-button-parent {
				.button {
					background-color: black;
					color: white;
					width: 100%;
					padding: 1rem;
					border-radius: 10px;
					margin-bottom: auto;
					margin-top: 25px;
					border-color: #000;
					cursor: pointer;

					.send-font {
						font-family: ${FONTS.TERTIARY_SEMI_BOLD};
						font-size: 18px;
						font-weight: 600;
						text-align: center;
						margin: 0px;
						padding: 0px;
					}
				}
				.loader {
					height: 20px !important;
					width: 20px !important;
					color: white;
				}
			}
		}
	}
`;

import React from "react";
import { Box, Typography } from "@material-ui/core";
//STYLE
import { DialogWrapper } from "./RedirectModal.style";

function RedirectModal(props) {
	return (
		<DialogWrapper
			open={props.open}
			classes={{ paper: "paper" }}
			BackdropProps={{
				classes: {
					root: "backdrop",
				},
			}}
		>
			<Box component="div" className="box">
				<Typography class="title-text">{props.title}</Typography>
			</Box>
		</DialogWrapper>
	);
}

export default RedirectModal;

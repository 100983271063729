import React, { useEffect, useState } from "react";
import {
	// useHistory,
	useNavigate,
	useParams,
} from "react-router-dom";

// COMPS
import { CircularProgress } from "@material-ui/core";

// STYLES
import { PageWrapper } from "./EmailVerification.style";

// CONST
import { Api, URL_LOGIN } from "Helpers";

function ForgotPassword(props) {
	let [isLoading, setIsLoading] = useState(true),
		[isSuccess, setSuccess] = useState(false);

	// ROUTER
	// let history = useHistory();
	const navigate = useNavigate();
	let { token } = useParams();

	useEffect(registerAccount, []);

	function registerAccount() {
		let data = new FormData();
		data.append("emailVerificationToken", token);

		new Api().post("user/verificationToken ", { data }).then((response) => {
			setIsLoading(false);
			setSuccess(true);

			setTimeout(() => {
				navigate(URL_LOGIN);
			}, 1000);
		});
	}

	return (
		<PageWrapper className="flex f-v-center">
			{/* <DesignedBox className="flex f-v-center f-h-center"> */}
			<div className="box">
				<div className="content">
					<p className="message">
						{isSuccess ? (
							<>You account has been activated.</>
						) : (
							<>
								Just a Second...
								<br />
								We are preparing your shiny new account
							</>
						)}
					</p>

					{isLoading && (
						<div className="progress">
							<CircularProgress color="secondary" size={25} />
						</div>
					)}
				</div>
			</div>
			{/* </DesignedBox> */}
		</PageWrapper>
	);
}

export default ForgotPassword;

import styled from "styled-components";

import { Form } from "formik";

import { PageWrapperBase } from "Components/Website.style";
import { COLORS, FONTS, responsive } from "Styles/Constants";
import { InputBase } from "@material-ui/core";
import { IMAGES } from "Helpers";

export const PageWrapper = styled(PageWrapperBase)`
	background-image: url(${IMAGES.LANDING_PAGE.TEACHER_BACKGROUND});
	font-family: ${FONTS.SECONDARY};
	letter-spacing: 1.2px;

	.box {
		min-width: 475px;
		${responsive.DISPLAY`min-width : 425px;`}
	}

	.title {
		font-family: ${FONTS.SECONDARY_SEMI_BOLD};
		font-size: 15px;
		margin-top: 30px;
		text-transform: uppercase;

		${responsive.DISPLAY`font-size: 12px;`}
	}
`;

export const LoginParentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow: hidden;
	background-color: #fff;
	width: 100%;
	background-image: url(${IMAGES.LANDING_PAGE.TEACHER_BACKGROUND});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	justify-content: space-between;
	/* ${responsive.DESKTOP`
		height : 120vh;
	`} */
	.header-container {
		background-color: rgba(255, 255, 255, 0.9);
		.top-bar {
			display: flex;
			justify-content: space-between;
			padding: 1%;
			width: 90%;
			margin: 0px auto;
			.subject-logo {
				background-image: url(${IMAGES.DASHBOARD.SUBJECT_MASTER_LOGO_BLACK});
				width: 110px;
				height: 40px;
				background-repeat: no-repeat;
				background-size: contain;
				cursor: pointer;
			}
			.btn-container {
				display: flex;
				width: auto;
				margin-right: -6%;
				.teacher-btn {
					border-radius: 29.5px;
					width: 180px;
					border: solid 2px rgba(4, 23, 39, 0.3);
					display: flex;
					align-items: center;
					padding: 0px 14px;
					justify-content: center;
					cursor: pointer;
				}
				.login {
					border-radius: 29.5px;
					width: 180px;
					border: solid 2px rgba(4, 23, 39, 0.3);
					display: flex;
					align-items: center;
					padding: 0px 14px;
					justify-content: center;
					margin-left: 10px;
					cursor: pointer;
				}
			}
		}
	}

	.footer-container {
		background-color: rgba(255, 255, 255, 0.9);
		padding: 5px;
		width: 100%;
		.inner-container {
			display: flex;
			padding: 0% 1%;
			justify-content: space-between;
			.footer-text {
				color: #000;
				font-size: 18px;
				${responsive.TABLET`
					font-size : 13px
				`}
			}

			.page-container {
				display: flex;
				font-size: 16px;
				cursor: pointer;
				${responsive.TABLET`
					font-size : 13px
				`}

				.partition {
					margin: 0px 5px;
				}
			}
		}
	}

	.main-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		/* position: relative; */
		.modal {
			width: 28%;
			height: 80%;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			transform-origin: 0 0;
			transition: transform 3s;
			backface-visibility: hidden;
			transform-style: preserve-3d;
			border-radius: 8px;
			${responsive.DISPLAY`
				width : 28%; 
				height: 80%;
			`}
			${responsive.DISPLAY_1550`
				width: 30%;
				height: 70%;
			`}
			${responsive.DESKTOP`
				width : 38%; 
			`}
			${responsive.LAPTOP`
				width: 40%; 
			`}
			${responsive.TABLET`
				width : 60%;
			`}
			@media (max-height: 790px) {
				margin-top: -20px;
				height: 90%;
			}
		}
	}
`;

export const LoginWrapper = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	padding: 39px 53px 80px 55px;
	object-fit: contain;
	border-radius: 10px;
	box-shadow: 0 0 20.9px 3.1px rgba(4, 23, 39, 0.5);
	background-image: linear-gradient(to bottom, #fff600, #fffcb3, #fffede, #f7f7eb, #fff);

	${responsive.DESKTOP`
		padding: 30px 40px;  
	`}

	.header {
		width: 208px;
		height: 29px;
		margin: 0 71px -19px -33px;
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-size: 30px;
		font-weight: 600;
		text-align: center;
		color: #010101;
		${responsive.DISPLAY_1550`
			font-size: 25px;
			margin: 0 0px -18px -35px
		`}
	}

	.text {
		display: flex;
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-size: 16px;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #010101;
	}

	.remember-me {
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-size: 16px;
		text-align: center;
		color: rgba(1, 1, 1, 0.5);
	}
	/* .login-button {
		background-color: #010101;
		color: white;
		width: 413px;
		height: 65px;
		border-radius: 10px;
		background-color: #000;
		.font {
			width: 52px;
			height: 14px;
			font-family: ${FONTS.TERTIARY_SEMI_BOLD};
			font-size: 14px;
			font-weight: 600;
			text-align: center;
			color: #fff;
		}
	} */

	.validation-error {
		color: #f92525;
		font-size: 12px;
		margin-left: 1%;
	}
	.content {
		display: flex;
		flex-direction: column;
		gap: 40px;
		${responsive.DISPLAY_1550`
			gap : 35px
		`}
		${responsive.DESKTOP`
			gap : 30px; 
		`}
		.flex-column-parent {
			display: flex;
			flex-direction: column;
			gap: 15px;
			.flex-column {
				display: flex;
				flex-direction: column;
				gap: 10px;
			}
		}

		.remember-me-parent {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin-top: 10px;

			.flex-center {
				display: flex;
				align-items: center;

				.checkbox {
					padding: 0px;
				}

				.remember-me-font {
					margin: 4px 0 0 5px;
					font-family: "Rubik";
					color: rgba(1, 1, 1, 0.5);
					font-size: 14px;
				}
			}

			.forgot-password-parent {
				.forgot-password {
					font-family: ${FONTS.TERTIARY_SEMI_BOLD};
					font-size: 14px;
					text-align: center;
					margin: 0px;
					padding: 0px;
					color: #0056e6;
				}
			}
		}

		.login-button-parent {
			.button {
				background-color: black;
				color: white;
				width: 100%;
				padding: 1rem;
				border-radius: 10px;
				margin-bottom: auto;
				border-color: #000;
				cursor: pointer;

				.login-font {
					font-family: ${FONTS.TERTIARY_SEMI_BOLD};
					font-size: 18px;
					font-weight: 600;
					text-align: center;
					margin: 0px;
					padding: 0px;
				}
			}
			.loader {
				height: 20px !important;
				width: 20px !important;
				color: white;
			}
		}
	}

	.model-footer {
		display: flex;
		flex-direction: column;
		.font {
			font-family: ${FONTS.TERTIARY_SEMI_BOLD};
			text-align: center;
			font-style: normal;
			margin: 0px;
			padding: 0px;
		}
		.dose-not-have-account-font {
			color: rgba(0, 0, 0, 0.5);
			font-size: 18px;
		}
		.register-as-school-font {
			color: #0056e6;
			font-size: 14px;
		}
	}
`;

export const FormWrapper = styled(Form)`
	width: 80%;
	margin: 20px auto;

	.input-wrapper {
		.input-label-wrapper {
			margin: 17px 0;

			${responsive.DISPLAY`
				margin : 13px 0;
			`}
		}

		.password-icon {
			fill: #332e44;
		}
	}

	.links-wrapper {
		justify-content: space-between;
		font-size: 16px;
		letter-spacing: 0.64px;

		.link {
			cursor: pointer;
			transition: 0.3s;

			.text {
				font-size: 14px;
			}

			&.remember {
				color: rgba(255, 255, 255, 0.3);
				&:hover {
					color: rgba(255, 255, 255, 0.9);
				}
			}
			&.forgot {
				opacity: 0.9;
				&:hover {
					opacity: 1;
				}
			}
		}
	}

	.button-wrapper {
		margin-top: 45px;

		.styled {
			width: 200px;

			${responsive.DISPLAY`width: 175px;`}
		}
	}

	.register {
		margin: 40px 0 20px;

		.text {
			font-size: 16px;
			margin: 0;

			${responsive.DISPLAY`font-size: 15px;`}

			.link {
				color: ${COLORS.PRIMARY};
				cursor: pointer;
				margin-left: 5px;
				transition: 0.3s;

				&:hover {
					color: ${COLORS.PRIMARY_DARK};
				}
			}
		}
	}
`;

export const LoginBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.box-wrapper {
		width: 424px;
		height: 500px;
		background-image: linear-gradient(to bottom, #fff600, #fffcb3, #fffede, #f7f7eb, #fff);
		box-shadow: 0 0 20.9px 3.1px rgba(4, 23, 39, 0.5);
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 24px;
		justify-content: center;

		.next-button-parent {
			.button {
				background-color: black;
				color: white;
				width: 100%;
				padding: 1rem;
				border-radius: 10px;
				margin-bottom: auto;
				border-color: #000;
				cursor: pointer;

				.next-font {
					font-family: ${FONTS.TERTIARY_SEMI_BOLD};
					font-size: 18px;
					font-weight: 600;
					text-align: center;
					margin: 0px;
					padding: 0px;
				}
			}
		}
	}

	.sign-in {
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-size: 30px;
		font-weight: 600;
	}

	.form-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 24px;
	}

	.validation-error {
		color: #f92525;
		font-size: 12px;
		font-family: Copperplate;
		margin-left: -25%;
		margin-top: -5%;
	}
	.parent-remember-me {
		display: flex;
		gap: 55px;
	}

	.remember-me {
		color: black;
		font-weight: bold;
		text-align: center;
		font-size: 12px;
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		cursor: pointer;
	}

	.forgot-password {
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-size: 14px;
		text-align: center;
		margin: 0px;
		padding: 0px;
		color: #0056e6;
		cursor: pointer;
	}

	.link {
		cursor: pointer;
		transition: 0.3s;

		&.remember {
			color: rgba(255, 255, 255, 0.3);
			&:hover {
				color: rgba(255, 255, 255, 0.9);
			}
		}
	}
`;

// export const EmailField = styled(TextField)`
// 	.MuiFilledInput-root {
// 		background-color: white;
// 		width: 334px;
// 		height: 51px;
// 	}

// 	.MuiFilledInput-root {
// 		border-radius: 36px;
// 		color: #000;
// 		/* font-family: Arial, Helvetica, sans-serif; */
// 		font-weight: bold;
// 	}

// 	.MuiFilledInput-underline:before {
// 		border-bottom: none;
// 	}

// 	.MuiFilledInput-underline:after {
// 		border-bottom: none;
// 	}

// 	.MuiInputBase-input {
// 		margin-bottom: 11px;
// 		padding-left: 22px;
// 	}

// 	.MuiFormHelperText-root {
// 		color: #f92525;
// 	}

// 	.MuiFilledInput-input:-webkit-autofill {
// 		border-radius: 36px;
// 	}
// `;

// export const PasswordField = styled(Input)`
// 	background-color: white;
// 	width: 334px;
// 	height: 51px;
// 	border-radius: 36px;
// 	color: #000;
// 	/* font-family: Arial, Helvetica, sans-serif; */
// 	/* font-weight: bold; */
// 	border-bottom: none;

// 	&:hover:not(.Mui-disabled):before {
// 		border-bottom: none;
// 	}

// 	.paswword-icon {
// 		color: black;
// 	}

// 	.MuiInputBase-input {
// 		padding-left: 22px;
// 	}

// 	& ::placeholder {
// 		/* font-family: Copperplate; */
// 		font-weight: bold;
// 		color: #000;
// 	}

// 	&:before {
// 		border-bottom: none;
// 	}

// 	&:after {
// 		border-bottom: none;
// 	}
// 	.MuiInputBase-input:-webkit-autofill {
// 		border-radius: 36px;
// 		height: 37px;
// 	}
// `;

// export const SignInButton = styled(Button)`

// `;

export const DarkInputBase = styled(InputBase)`
	border-radius: 10px;
	color: rgba(1, 1, 1, 0.5);
	padding: 10px 20px 5px;

	${responsive.DISPLAY`
		padding: 4px 20px 5px;
	`}

	width: inherit;
	&.school-register {
		height: 55px;
		${responsive.DESKTOP`
			height : 40px;		
		`}
		${responsive.DISPLAY_1550`height: 40px;
		`}
	}
	&.login {
		height: 65px;
		${responsive.LAPTOP_1050`
			height : 45px;		
		`}
		${responsive.DISPLAY_1550`height: 50px;
		`}
	}
	input::placeholder {
		color: rgba(1, 1, 1, 0.5);
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-weight: normal;
		font-size: 13px;
	}
`;

export const EmailField = styled(DarkInputBase)`
	border: 2px solid grey;
	width: 100%;

	.MuiInputBase-input {
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-size: 16px;
	}
`;

export const PasswordField = styled(DarkInputBase)`
	border: 2px solid grey;
	width: 100%;

	&:hover:not(.Mui-disabled):before {
		border-bottom: none;
	}

	.paswword-icon {
		color: black;
	}

	.MuiInputBase-input {
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		font-size: 16px;
	}

	.MuiInputBase-input:-webkit-autofill {
	}

	${responsive.DISPLAY`
		width: 100%;
		height : 109%;		
	`}
`;

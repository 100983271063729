import styled from "styled-components";

import { PageWrapper as PWFrgtPwd } from "Components/Pages/ForgotPassword/ForgotPassword.style";
import { FormWrapper as FWLogin } from "Components/Pages/Login/Login.style";
import { FONTS } from "Styles/Constants";

export const PageWrapper = styled(PWFrgtPwd)`
	.box {
		width: 500px;
		margin: 0px auto;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		box-shadow: 0 0 20.9px 3.1px rgba(4, 23, 39, 0.5);
		background-image: linear-gradient(to bottom, #fff600, #fffcb3, #fffede, #f7f7eb, #fff);
		border-radius: 8px;
		padding: 10px 30px;
		color: #fff;
		text-align: center;

		.title {
			.text {
				color: black;
				font-family: ${FONTS.TERTIARY};
			}
		}
		.gap {
			display: flex;
			gap: 20px;
			flex-direction: column;
		}
		.send-button-parent {
			.button {
				background-color: black;
				color: white;
				width: 100%;
				padding: 1rem;
				border-radius: 10px;
				margin-bottom: 25px;
				margin-top: 25px;
				border-color: #000;
				cursor: pointer;

				.send-font {
					font-family: ${FONTS.TERTIARY_SEMI_BOLD};
					font-size: 18px;
					font-weight: 600;
					text-align: center;
					margin: 0px;
					padding: 0px;
				}
			}
		}
	}
`;

export const FormWrapper = FWLogin;

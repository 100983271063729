import styled from "styled-components";

import { PageWrapper as PWLogin } from "Components/Pages/Login/Login.style";

export const PageWrapper = styled(PWLogin)`
	.inner-box {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.box {
		width: 600px;
		margin: 0px auto;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background-color: rgb(50, 50, 50);
		border-radius: 56px 56px 96px 96px;
		padding: 10px 30px;
		color: #fff;
		text-align: center;
	}
`;

import React, { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";

// STYLE
import { DialogWrapper } from "./ProfileImagePicker.style";

// Images
import { IMAGES } from "Helpers";
import Avatar1 from "Assets/Images/Profile/Avatar/avatar1.png";
import Avatar2 from "Assets/Images/Profile/Avatar/avatar2.png";
import Avatar3 from "Assets/Images/Profile/Avatar/avatar3.png";
import Avatar4 from "Assets/Images/Profile/Avatar/avatar4.png";
import Avatar5 from "Assets/Images/Profile/Avatar/avatar5.png";
import Avatar6 from "Assets/Images/Profile/Avatar/avatar6.png";

import { Typography, Button } from "@material-ui/core";

function ProfileImagePicker(props) {
	const [image, setImage] = useState(""),
		[imageSrc, setImageSrc] = useState(),
		[customUpload, setStatusOfCustomUpload] = useState(false),
		[selectedImage, setSelectedImage] = useState(null);

	function onImageChange(event) {
		if (event.target.files && event.target.files[0]) {
			setImage(event.target.files[0]);
			setStatusOfCustomUpload(true);
			setImageSrc(URL.createObjectURL(event.target.files[0]));
		}
	}

	function selectImage(image, index) {
		setSelectedImage(index);

		fetch(image)
			.then((response) => response.blob())
			.then((blob) => {
				const file = new File([blob], "dot.png", blob);
				setImage(file);
				setImageSrc(image);
			})
			.catch((error) => {
				console.log("🚀 ~ file: ProfileImagePicker.jsx:39 ~ error:", error);
			});
	}

	function submitImages() {
		props.onSuccess({ image, imageSrc, customUpload });
	}

	const ThumbVertical = (props) => <div {...props} className="thumb-vertical" />;

	return (
		<DialogWrapper onClose={props.handleClose} open={props.open}>
			<div className="box">
				{/* <DesignedBox> */}

				<div className="content">
					<div className="heading">Pick an Avatar</div>
					<div className="pre-defined">
						<div className="title">From our presets</div>
						<Scrollbars className={"scroll-bar"} style={{ height: 230 }} renderThumbVertical={ThumbVertical} autoHide>
							<div className="pre-defined-images flex">
								{AVATAR_IMAGES.map((image, index) => (
									<div
										className={"avatar " + (selectedImage === index ? "selected" : "")}
										key={index}
										style={{ backgroundImage: `url(${image})` }}
										onClick={() => selectImage(image, index)}
									/>
								))}
							</div>
						</Scrollbars>
					</div>

					<div className="separator">
						<div className="line"></div>
						<div className="text">Or</div>
					</div>
					<div className="user-section">
						<div className="title">Upload your Awesome Avatar</div>
						<div className="image-wrapper flex f-h-center">
							<label htmlFor="profile-image-picker">
								<div className="avatar" style={{ backgroundImage: `url(${imageSrc || IMAGES.PROFILE.BOY})` }} />
							</label>

							<input accept="image/png, image/jpeg" id="profile-image-picker" type="file" className="hidden" onChange={onImageChange} />
						</div>
					</div>
				</div>

				<div className="btn-container">
					<Button className="button" onClick={submitImages}>
						<Typography className="login-font">Looks Good !</Typography>
					</Button>
				</div>
			</div>
		</DialogWrapper>
	);
}

const AVATAR_IMAGES = [Avatar1, Avatar2, Avatar3, Avatar4, Avatar5, Avatar6];

export default ProfileImagePicker;

import { applyMiddleware, combineReducers, compose, createStore } from "redux";
// REDUCERS
import reducers from "./Reducers";

const middlewares = [];
const env = process.env.REACT_APP_ENV;
const composeEnhancers = env !== "prod" && typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const store = createStore(combineReducers({ ...reducers }), composeEnhancers(applyMiddleware(...middlewares)));

export { store };

import React, { useEffect, useState } from "react";
import { SubscriptionDetailsWrapper, SubscriptionPaymentWrapper } from "./SubscriptionDetails.style";
import { useNavigate } from "react-router-dom";
import { IMAGES, URL_GLOBAL_LANDING_PAGE } from "Helpers";
import { Api } from "Helpers";
import LazyImage from "Components/Commons/LazyImage/LazyImage";

function SubscriptionPaymentStatus() {
	const navigate = useNavigate();
	const getPaymentStatus = new URLSearchParams(window?.location?.search);
	const paymentStatus = JSON.parse(getPaymentStatus.get("isSuccess"));

	const [loading, setLoading] = useState(false);

	const handleLoginCMS = () => {
		window.location.href = `${process.env.REACT_APP_CMS_URL}login`;
	};

	const getSubscription = async () => {
		setLoading(true);
		const subscriptionData = localStorage.getItem("subscription");

		if (subscriptionData && JSON.parse(subscriptionData)) {
			const data = JSON.parse(subscriptionData);

			const response = await new Api().post("stripe/check-out/form", { data: data });

			if (response?.status === 200) {
				localStorage.setItem("subscription", JSON.stringify(data));
				if (response?.data) {
					window.location.href = response?.data;
				}
			}
		} else {
			localStorage.removeItem("subscription");
			navigate(URL_GLOBAL_LANDING_PAGE);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (paymentStatus) {
			localStorage.removeItem("subscription");
			// setTimeout(() => {
			// 	handleLoginCMS();
			// }, 3000);
		}
	}, []); // eslint-disable-line

	return (
		<SubscriptionDetailsWrapper>
			<LazyImage compressedImage={IMAGES.LANDING_PAGE.TEACHER_BACKGROUND_COMPRESSED} image={IMAGES.LANDING_PAGE.TEACHER_BACKGROUND} wrapperProps={{ className: "background-image" }} />
			<div className="header-container">
				<div className="top-bar">
					<div className="subject-logo" onClick={() => navigate(URL_GLOBAL_LANDING_PAGE)}></div>
				</div>
			</div>
			<div className="main-container">
				<div className={`board-plans decrease-gird-gap`}>
					<div className={`text-board selected disable`}>Payment Status</div>
				</div>
				<div className="board">
					<SubscriptionPaymentWrapper>
						{paymentStatus ? (
							<>
								<div className="payment-sticker-success"></div>
								<div className="try-again-parent" onClick={handleLoginCMS}>
									<div className="font">Redirect to portal login</div>
								</div>
							</>
						) : (
							<>
								<div className="payment-sticker-fail"></div>
								{loading ? (
									<div className="try-again-parent">
										<div className="redirect">You will be redirected to the payment gateway in a few moments...</div>
									</div>
								) : (
									<div className="try-again-parent" onClick={getSubscription}>
										<div className="font">Try Again</div>
										<div className="try-again"></div>
									</div>
								)}
							</>
						)}
					</SubscriptionPaymentWrapper>
				</div>
			</div>
			<div className="footer-container">
				<div className="inner-container">
					<div className="footer-text">© 2022 Subjects Master. All rights reserved.</div>
					<div className="page-container">
						<div className="about-us">ABOUT US</div>
						<div className="partition">|</div>
						<div className="terms">TERMS & CONDITIONS</div>
						<div className="partition">|</div>
						<div className="privacy">PRIVACY POLICY</div>
					</div>
				</div>
			</div>
		</SubscriptionDetailsWrapper>
	);
}

export default SubscriptionPaymentStatus;

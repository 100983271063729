import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip, CircularProgress } from "@material-ui/core";
import useSound from "use-sound";
// COMPONENTS
import { DesignedBox } from "Components/Commons";
import HowToPlay from "Components/Commons/HowToPlay";
import QuestionBeginEnd from "Components/QuestionBeginEnd/QuestionBeginEnd.jsx";
// STYLES
import { PageWrapper, ProgressWrapper, GridContainer, ScoreboardWrapper, DialogWrapper } from "./Dashboard.style";
// HELPERS
import { random, URL_CONSTITUTION, URL_FINAL_MODULE, Api, URL_JUDICIAL_DASHBOARD, MODULE_ID, URL_BILL_OR_NO_BILL, URL_ESCAPE_ROOM, URL_BILL_BECOME_LAW, URL_JEO_PARDY, isEmpty } from "Helpers";
// ICONS
import CancelIcon from "@material-ui/icons/Cancel";
// IMAGES
import { IMAGES } from "Helpers";

// SOUNDS
import DrawerSound from "Assets/Sounds/switch-off.mp3";
// REDUX
import { quizSound, showToast } from "Redux/General/Actions";
import { updateTotalScore, schoolClassTestID } from "Redux/Auth/Actions";
import LazyImage from "Components/Commons/LazyImage/LazyImage";

const sectionConst = [
	{
		name: "legislative-branch",
		image: IMAGES.CIVICS.DASHBOARD.LEGISLATIVE,
		value: 0,
		subTitle: "Select Game You Want To Play !",
		id: MODULE_ID.LEGISLATIVE,
		isTestAvailable: false,
		modules: [
			{ stage: "bill", subTitle: "Bill or No Bill", name: "Bill Or No Bill", url: URL_BILL_OR_NO_BILL, id: MODULE_ID.BILL_OR_NO_BILL, isTestAvailable: false },
			{ stage: "law", subTitle: "Bill or No Bill", name: "Bill Become Law", url: URL_BILL_BECOME_LAW, id: MODULE_ID.BILL_BECOMES_LAW, isTestAvailable: false },
		],
		isLive: true,
	},
	{
		name: "executive-branch",
		subTitle: "ESCAPE THE OVAL OFFICE",
		image: IMAGES.CIVICS.DASHBOARD.EXECUTIVE,
		value: 0,
		id: MODULE_ID.EXECUTIVE,
		url: URL_ESCAPE_ROOM,
		isLive: true,
		isTestAvailable: false,
	},
	{
		name: "judicial-branch",
		image: IMAGES.CIVICS.DASHBOARD.JUDICIAL,
		value: 0,
		subTitle: "Judicial Spin",
		url: URL_JUDICIAL_DASHBOARD,
		id: MODULE_ID.JUDICIAL,
		isLive: true,
		isTestAvailable: false,
	},
	{ name: "Constitution", image: IMAGES.CIVICS.DASHBOARD.CONSTITUTION, value: 0, subTitle: "Constitution", url: URL_CONSTITUTION, id: MODULE_ID.CONSTITUTION, isLive: true, isTestAvailable: false },
	{ name: "Quiz", image: IMAGES.CIVICS.DASHBOARD.QUIZ, value: 0, url: URL_JEO_PARDY, subTitle: "Quizardy", id: MODULE_ID.QUIZ, isLive: true, isTestAvailable: false },
	{ name: "Finals", image: IMAGES.CIVICS.DASHBOARD.FINAL, value: 0, subTitle: "CivBlitz", url: URL_FINAL_MODULE, id: MODULE_ID.FINALS, isLive: true, isTestAvailable: false },
];

const Dashboard = () => {
	const dispatch = useDispatch();
	const [play] = useSound(DrawerSound, { volume: 0.2 });

	const subjects = useSelector((state) => state.Auth.subjectAccessList);
	const { gamesStatus } = useSelector((state) => state.GamesData);

	const subjectId = subjects?.filter((item) => item.subject === "Civics")[0].subjectId;

	const [sections, setSections] = useState([...sectionConst]);
	const [howToPlay, setHowToPlay] = useState({ open: false, subTitle: "", url: null });
	const [scoreboard, setScoreboard] = useState([]);
	const [isStudent, setStudent] = useState(false);
	const [testAvailable, setTestStatus] = useState(false);
	const [selectLegislative, setLegislativeSelection] = useState({ open: false, subTitle: "", urls: [] });
	const [questionModal, setQuestionModal] = useState({});
	const [status, setStatus] = useState(0);
	const [loading, setLoading] = useState(false);
	const [schoolId, setSchoolId] = useState("");
	const [gameCompleteStatus, setGameCompleteStatus] = useState({});

	useEffect(() => {
		dispatch(quizSound(true));

		setTimeout(() => {
			const newValues = [...sections];

			newValues.forEach((section) => {
				if (!section.isLive) section.value = random(30, 100);
			});

			setSections(newValues);
		}, 1500);
	}, []); // eslint-disable-line

	useEffect(() => {
		if (Object.keys(gamesStatus).length) getStatus();
	}, [gamesStatus]); // eslint-disable-line

	const getStatus = async () => {
		try {
			setLoading(true);

			const response = await new Api().get("general/studentStatus", { params: { subjectId: subjectId } });

			const percentValue = (sub, total) => ((sub * 100) / total).toFixed(0);

			// SET PROGRESS
			const newValues = [...sections];

			newValues.forEach((section) => {
				switch (section.id) {
					case MODULE_ID.LEGISLATIVE:
						section.value = 0;

						if (response?.data?.legislativeBranch?.isLegislativeBillNoBillComplete) section.value += 50;

						if (response?.data?.legislativeBranch?.isLegislativeBillBecomesLawComplete) section.value += 50;

						if (gamesStatus?.isLegislativeActive) {
							section.isTestAvailable = true;
							section.modules.forEach((subSection) => {
								if (subSection.id === MODULE_ID.BILL_OR_NO_BILL) {
									subSection.isTestAvailable = gamesStatus?.isBillNoBillActive;
								} else if (subSection.id === MODULE_ID.BILL_BECOMES_LAW) {
									subSection.isTestAvailable = gamesStatus?.isBillBecomesLawActive;
								}
							});
						}

						break;

					case MODULE_ID.EXECUTIVE:
						section.value = 0;

						if (response?.data?.executiveBranch?.isPadlockComplete) section.value += 33;

						if (response?.data?.executiveBranch?.isCryptexComplete) section.value += 33;

						if (response?.data?.executiveBranch?.isEscapeRoomComplete) section.value = 100;

						if (gamesStatus?.isExecutiveActive) section.isTestAvailable = true;

						break;

					case MODULE_ID.JUDICIAL:
						if (response?.data?.judicialBranch.isJudicialComplete) section.value = parseInt(100);
						else {
							const value = percentValue(response?.data?.judicialBranch.totalSpinUsed, response?.data?.judicialBranch.totalSpinEarned);

							section.value = !isNaN(value) ? value : 0;
						}
						if (gamesStatus?.isJudicialActive) section.isTestAvailable = true;

						break;

					case MODULE_ID.CONSTITUTION:
						if (response?.data?.constitution.isConstitutionComplete) section.value = parseInt(100);
						else {
							const value = percentValue(response?.data?.constitution.totalRightAnswer, response?.data?.constitution.totalQuestion);

							section.value = !isNaN(value) ? value : 0;
						}

						if (gamesStatus?.isConstitutionActive) section.isTestAvailable = true;

						break;

					case MODULE_ID.QUIZ:
						if (response?.data?.quizBranch) {
							const value = percentValue(response?.data?.quizBranch.totalRightAnswer, response?.data?.quizBranch.totalQuestion);

							section.value = !isNaN(value) ? value : 0;

							if (response?.data?.quizBranch.isQuizComplete) section.value = parseInt(100);
						}

						if (gamesStatus?.isQuizActive) section.isTestAvailable = true;

						break;

					case MODULE_ID.FINALS:
						if (response?.data?.finalGameBranch) {
							const value = percentValue(response?.data?.finalGameBranch.totalRightAnswer, response?.data?.finalGameBranch.totalQuestion);

							section.value = !isNaN(value) ? value : 0;

							if (response?.data?.finalGameBranch.isFinalComplete) section.value = 100;
						}

						if (gamesStatus?.isFinalActive) section.isTestAvailable = true;

						break;

					default:
						section.value = 0;

						break;
				}
			});

			dispatch(updateTotalScore(response.data.total));
			dispatch(schoolClassTestID(response?.data?.schoolClassTestId));

			setSections(newValues);
			setScoreboard(response.data.scoreDashBoardList);
			setTestStatus(response?.data?.isLiveTestAvailable);
			setStudent(response?.data?.isStudentLogin);
			setStatus(response.data.status);
			setSchoolId(response?.data?.schoolClassTestId);
			setQuestionModal({
				areAllQuizModulesCompleted: response.data.areAllQuizModulesCompleted,
				isFinalQuestionAnswered: response.data.isFinalQuestionAnswered,
				isInitialQuestionAnswered: response.data.isInitialQuestionAnswered,
			});
			setGameCompleteStatus({
				isConstitutionComplete: response?.data?.constitution?.isConstitutionComplete,
				isCryptexComplete: response?.data?.executiveBranch?.isCryptexComplete,
				isEscapeRoomComplete: response?.data?.executiveBranch?.isEscapeRoomComplete,
				isPadlockComplete: response?.data?.executiveBranch?.isPadlockComplete,
				isFinalComplete: response?.data?.finalGameBranch?.isFinalComplete,
				isJudicialComplete: response?.data?.judicialBranch?.isJudicialComplete,
				isLegislativeBillBecomesLawComplete: response?.data?.legislativeBranch?.isLegislativeBillBecomesLawComplete,
				isLegislativeBillNoBillComplete: response?.data?.legislativeBranch?.isLegislativeBillNoBillComplete,
				isQuizComplete: response?.data?.quizBranch?.isQuizComplete,
			});

			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};

	const toggleLegislativeSelection = (section) => {
		let newState = { ...selectLegislative };

		if (!selectLegislative.open) {
			newState.urls = section.modules;
			newState.subTitle = section.subTitle;
			newState.id = section.id;
		}
		newState.gameCompleteStatus = gameCompleteStatus;
		newState.open = !selectLegislative.open;

		setLegislativeSelection(newState);
	};

	const toggleHowToPlay = (section) => {
		if (!testAvailable && isStudent) {
			dispatch(showToast("Assessment is not available. Try after some time or contact to your respective class teacher | school."));
			return;
		}

		if (section && section?.name === "legislative-branch") {
			toggleLegislativeSelection(section);
			return;
		}

		let newState = { ...howToPlay };

		if (!howToPlay.open) {
			newState.url = section.url;
			newState.subTitle = section.subTitle;
			newState.id = section.id;
			newState.gameCompleteStatus = section.gameCompleteStatus;
			newState.name = section.name;
		}

		newState.open = !howToPlay.open;
		newState.gameCompleteStatus = gameCompleteStatus;

		if (status !== 0) setHowToPlay(newState);
		else dispatch(showToast("Assessment is not available."));
	};

	const selectModule = (currentUrl) => {
		let newState = { ...howToPlay };

		if (!howToPlay.open) {
			newState.url = currentUrl.url;
			newState.subTitle = currentUrl.subTitle;
			newState.id = currentUrl.id;
			newState.name = currentUrl.name;
		}

		newState.open = !howToPlay.open;

		setHowToPlay(newState);
	};

	const getQuestionModalStatus = () => {
		if (isEmpty(questionModal)) return false;

		if (!questionModal.isInitialQuestionAnswered) return true;

		if (!questionModal.isFinalQuestionAnswered && questionModal.areAllQuizModulesCompleted) return true;

		return false;
	};

	const gameListUi = () => {
		return (
			<GridContainer className="flex f-v-center f-h-center overlay-effect">
				{sections.map((section) => (
					<div className="box" key={section.name}>
						<div className={`box-content `} onClick={() => !loading && section.isTestAvailable && toggleHowToPlay(section)}>
							<img className={`image full-width ${!section.isTestAvailable && "disabled"}`} src={section.image} alt="" onMouseOver={play} />

							<div className="progress-container full-width" onMouseOver={(e) => e.preventDefault()}>
								<Progress value={section.value} />
							</div>
						</div>
					</div>
				))}
			</GridContainer>
		);
	};

	return (
		<PageWrapper className="flex f-v-center f-h-center">
			<LazyImage compressedImage={IMAGES.CIVICS.BACKGROUND_COMPRESSED} image={IMAGES.CIVICS.BACKGROUND} wrapperProps={{ className: "background-image" }} />

			<QuestionBeginEnd open={getQuestionModalStatus()} questionModal={questionModal} />

			<div className="content text-center">
				<div className="title">
					<h1 className="text "> A LEADER IS SOMEONE WHO DEMONSTRATES WHAT’S POSSIBLE </h1>
				</div>

				{loading ? (
					<div className="overlay-stackrank">
						{gameListUi()}

						<div className="display-circular-progress">
							<CircularProgress />
						</div>
					</div>
				) : (
					<>{gameListUi()}</>
				)}
			</div>

			<Scoreboard peoples={scoreboard} />

			{howToPlay.open && <HowToPlay open={howToPlay.open} handleClose={toggleHowToPlay} data={howToPlay} subjectId={subjectId} schoolId={schoolId} />}

			{selectLegislative.open && <MainInfo open={selectLegislative.open} data={selectLegislative} handleClose={toggleLegislativeSelection} selectModule={selectModule} />}
		</PageWrapper>
	);
};

const Progress = (props) => (
	<ProgressWrapper {...props}>
		<div className="outer">
			<div className="active" />
		</div>
		<div className="text">{props.value}%</div>
	</ProgressWrapper>
);

const Scoreboard = (props) => (
	<ScoreboardWrapper>
		{props?.peoples?.length > 0 && (
			<div className="title">
				<img className="image" src={IMAGES.CIVICS.RIBBON} alt="" />
				<div className="text-container">
					<span className="text">Scoreboard</span>
				</div>
			</div>
		)}
		<div className="people-cards">
			{props?.peoples?.map((person) => (
				<div className="person flex f-v-center f-h-center" key={person.winnerPlace}>
					<div className="content text-center">
						<div className="image" style={{ backgroundImage: `url(${person.profileImage})` }}></div>
						<div className="name elipsis">{person.firstName}</div>
						<div className="score">{person.totalScore}</div>
					</div>
				</div>
			))}
		</div>
	</ScoreboardWrapper>
);

const MainInfo = (props) => (
	<DialogWrapper open={props.open} onClose={props.handleClose} classes={{ root: "backgorund-root", paper: "paper" }}>
		<DesignedBox>
			<div className="title-container">
				<h2 className="title">{props.data.subTitle}</h2>

				<div className="icon-container" onClick={props.handleClose}>
					<CancelIcon className="icon" />
				</div>
			</div>

			<div className="content-wrapper">
				<div className="frames">
					{props.data.urls.map((item) => (
						<Tooltip key={item.id} title={item.name}>
							<div className={`frame ${!item.isTestAvailable && "disabled"}`} onClick={() => item.isTestAvailable && props.selectModule(item)}>
								<div className="image">
									<div className={`${item.stage}`} />
								</div>
							</div>
						</Tooltip>
					))}
				</div>
			</div>
		</DesignedBox>
	</DialogWrapper>
);

export default Dashboard;

import styled from "styled-components";
import { Dialog } from "@material-ui/core";
import { FONTS } from "Styles/Constants";

export const DialogWrapper = styled(Dialog)`
	.backdrop {
		background-color: rgba(0, 0, 0, 0.8);
	}
	.paper {
		background: transparent;
		color: #fff;
		overflow: visible;
	}

	.box {
		background: linear-gradient(to bottom, #fff600, #fffb79, #fffede, #f7f7eb, #fff);
		border-radius: 10px;
		box-shadow: 0 0 20.9px 3.1px rgba(4, 23, 39, 0.5);
		padding: 20px 30px;
		color: #000;
	}

	.title-text {
		font-family: ${FONTS.PRIMARY};
		font-size: 18px;
		font-weight: 500;
	}
`;

import { css } from "styled-components";

// SIDEBAR DRAWER WIDTH
export const DRAWER_WIDTH = 240;

// COLOR PALLET
export const COLORS = {
	// BLUE
	PRIMARY: "#ff0006",
	PRIMARY_DARK: "#cc0003",
	PRIMARY_LIGHT: "#ff3336",

	SKY_BLUE: '#00a8ff',
	SKY_BLUE_DARK: '#0380c2',
	SKY_BLUE_LIGHT: '#ace2ff',

	BLUE_DARK: '#171b94',
	BLUE_LIGHT: '#2d68ca',
	BLUE_TEXT_LIGHT: '#50abff',

	//PURPLE
	PURPLE_BORDER: '#dc2ad7',

	// ORANGE
	SECONDARY: "#42defb",
	SECONDARY_DARK: "#3bc7e1",
	SECONDARY_LIGHT: "#67e4fb",
	ORANGE: '#fb8a07',

	//CYAN
	TERTIARY: "#3A82A7",
	TERTIARY_LIGHT: "#92abbd",

	
	CYAN_MEDIUM: '#06d2e4',
	CYAN_DARK: '#11636d',
	CYAN_LIGHT: '#b7f8ff',

	//YELLOW
	YELLOW_DARK: '#e79a06',
	GOLDEN_YELLOW: '#f9c800',
	GOLDEN: '#ffb40e',
	YELLOW_TEXT: '#f8c102',

	//RED
	DANGER: "#ff0010",
	RED_DARK: '#de3528',
	RED_LIGHT: '#fdc2be',
	MAROON: '#8c2019',
	PINK_LIGHT: '#ffe6e8',

	//GREEN
	GREEN: "#7edd03",

	BLUE_BACKGROUND: "#01001c",

	GREY_TEXT_COLOR: "#707070",
	GREY: '#252525',
	TRANSPARENT_GREY: '#171717aa',
	MUI_GREY_800: '#757575',

	//WHITE
	WHITE: '#fff'
};

// BREAKPOINTS
export const BREAKPOINTS_VALUE = {
	DISPLAY: 1750,
	DISPLAY_1650: 1650,
	DISPLAY_1600: 1600,
	DISPLAY_1550: 1550,
	DESKTOP: 1450, // x_large
	LAPTOP: 1278, // large
	LAPTOP_1200: 1200,
	LAPTOP_1100: 1100,
	LAPTOP_1050: 1050,
	TABLET: 960, // medium
	TABLET_900: 900,
	TABLET_860: 860,
	TABLET_800: 800,
	TABLET_725: 725,
	TABLET_700: 700,
	PHABLET: 600, // small
	MOBILE: 450, // x_small
};

export const BREAKPOINTS = {
	A_DESKTOP: `${BREAKPOINTS_VALUE.DESKTOP}px`,
	A_LAPTOP: `${BREAKPOINTS_VALUE.LAPTOP}px`,
	A_TABLET: `${BREAKPOINTS_VALUE.TABLET}px`,
	A_PHABLET: `${BREAKPOINTS_VALUE.PHABLET}px`,
	A_MOBILE: `${BREAKPOINTS_VALUE.MOBILE}px`,
	DISPLAY: `${BREAKPOINTS_VALUE.DISPLAY - 1}px`,
	DISPLAY_1600: `${BREAKPOINTS_VALUE.DISPLAY_1600}px`,
	DISPLAY_1550: `${BREAKPOINTS_VALUE.DISPLAY_1550}px`,
	DESKTOP: `${BREAKPOINTS_VALUE.DESKTOP - 1}px`, // x_large
	LAPTOP: `${BREAKPOINTS_VALUE.LAPTOP - 1}px`, // large
	LAPTOP_1050: `${BREAKPOINTS_VALUE.LAPTOP_1050 - 1}px`, // large
	LAPTOP_1100: `${BREAKPOINTS_VALUE.LAPTOP_1100 - 1}px`, // large
	TABLET: `${BREAKPOINTS_VALUE.TABLET - 1}px`, // medium
	TABLET_725: `${BREAKPOINTS_VALUE.TABLET - 1}px`, // medium
	PHABLET: `${BREAKPOINTS_VALUE.PHABLET - 1}px`, // small
	MOBILE: `${BREAKPOINTS_VALUE.MOBILE - 1}px`, // x_small
};

//Fonts

export const FONTS = {
	PRIMARY: "Copperplate",
	PRIMARY_LIGHT: "Copperplate-Light",
	PRIMARY_BOLD: "Copperplate-Bold",

	SECONDARY: "MyRiadPro-Redular",
	SECONDARY_LIGHT: "MyRiadPro-Light",
	SECONDARY_SEMI_BOLD: "MyRiadPro-SemiBold",
	SECONDARY_BOLD: "MyRiadPro-Bold",

	TERTIARY: "Rubik",
	TERTIARY_ITALIC: "Rubik-Italic",
	TERTIARY_BLACK: "Rubik-Black",
	TERTIARY_BOLD: "Rubik-Bold",
	TERTIARY_SEMI_BOLD: "Rubik-Medium",
	TERTIARY_LIGHT: "Rubik-Light",

	QUATERNARY: "Noteworthy-Lt",

	QUINARY: "Chalkduster",
};

export const responsive = Object.keys(BREAKPOINTS).reduce((acc, label) => {
	acc[label] = (...args) => css`
		@media (max-width: ${BREAKPOINTS[label]}) {
			${css(...args)}
		}
	`;
	return acc;
}, {});

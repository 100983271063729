import styled from "styled-components";
import { responsive } from "Styles/Constants";
import { COLORS } from "Styles/Constants";
//IMAGES
import BackgroundImage from "Assets/Images/GlobalLandingPage/background.png";
import ChalkBoardImage from "Assets/Images/GlobalLandingPage/bg.png";
import LeftBackgroundImage from "Assets/Images/GlobalLandingPage/left-background.png";
import RightBackgroundImage from "Assets/Images/GlobalLandingPage/right-background.png";
import CivicRoundImage from "Assets/Images/civic-round-image.png";

export const PageContainer = styled.div`
	width: 100%;

	.first-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 600px;
		padding: 0 0 77px;
		background: linear-gradient(167deg, rgba(206, 206, 206, 1) 0%, rgba(255, 255, 255, 1) 100%);
	}

	.logo-button {
		justify-content: space-between;
		margin: auto 40px;
		z-index: 1;
		gap: 10px;
	}

	.logo {
		width: 150px;
		height: 120px;
	}

	.button-class {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 20px;

		.subscription-button {
			background-color: #d72e2e;
		}

		.button-text {
			font-size: 14px;
		}

		.login-button {
			color: #000000;
			background-color: #fff;
		}
	}

	.left-box {
		background: radial-gradient(circle at 0 0, #cecece, #fff);
		opacity: 0.8;
		height: 300px;
		width: 58%;
		padding: 40px 170px 10px 20px;
		margin-right: -160px;

		${responsive.LAPTOP`
			height: 280px;
			margin-right: -140px;
			padding: 20px 140px 10px 20px;
		`}
	}

	.right-box {
		background: linear-gradient(63deg, #fff 0%, #cacaca 100%);
		opacity: 0.8;
		height: 300px;
		width: 58%;
		padding: 40px 20px 10px 170px;
		margin-left: -160px;

		${responsive.LAPTOP`
			height: 280px;
			margin-left: -140px;
			padding: 20px 20px 10px 150px;
		`}
	}

	.image {
		width: 430px;
		z-index: 100;
		background-size: contain;
		background-repeat: no-repeat;
		cursor: pointer;
		position: relative;

		${responsive.LAPTOP`
			width: 380px;
			height: 380px;
		`}
	}

	.button {
		border-radius: 30px;
		padding: 5px 20px;
	}

	.left-background-image {
		background-image: url(${LeftBackgroundImage});
		background-size: cover;
		background-repeat: no-repeat;
		position: absolute;
		height: 654px;
		width: 313px;
		top: 10%;
		left: -120px;
	}

	.right-background-image {
		background-image: url(${RightBackgroundImage});
		background-size: contain;
		background-repeat: no-repeat;
		position: absolute;
		height: 883px;
		width: 185px;
		top: 0%;
		right: 0px;
	}

	.background-image {
		background-image: url(${BackgroundImage});
		height: 1000px;
		width: 108%;
		background-size: cover;
		background-repeat: no-repeat;
		position: absolute;
		top: 8%;
		left: -8%;
	}

	.title-description {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.first-div {
		width: 50%;

		.header-title {
			color: #d72e2e;
			font-size: 28px;
			margin: 0;
		}
	}

	.social-media-container {
		gap: 7px;
		margin-bottom: 8px;
	}

	.top-image {
		height: 438px;
		width: 502px;
		background-size: contain;
		background-repeat: no-repeat;

		${responsive.LAPTOP`
            height: 338px;
            width: 402px;
        `}

		${responsive.TABLET`
            height: 238px;
            width: 302px;
        `}
	}

	.second-container {
		background-image: url(${ChalkBoardImage});
		height: 700px;
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;

		.box-header {
			font-size: 30px;
			font-weight: bold;

			${responsive.LAPTOP_1100`
				font-size: 20px;
			`}
		}

		.sub-header {
			margin: 0;
			color: #0000ff;
		}

		.subtitle {
			.display-title {
				color: ${COLORS.PRIMARY};
				font-weight: 700;
			}
			${responsive.LAPTOP_1100`
				font-size: 14px;
			`}
		}

		.title {
			font-size: 50px;
		}
	}

	.civic-image {
		background-image: url(${CivicRoundImage});
		height: 430px;
		width: 430px;
		z-index: 100;
		background-size: contain;
		background-repeat: no-repeat;
		cursor: pointer;

		${responsive.LAPTOP`
			height: 380px;
			width: 380px;
		`}
	}

	.third-container {
		background: #cecece;
		padding: 60px 0;
		gap: 60px;

		.box-header {
			font-size: 30px;
			font-weight: bold;

			${responsive.LAPTOP_1100`
				font-size: 20px;
			`}
		}

		.sub-header {
			margin: 0;
			color: #0000ff;
		}

		.subtitle {
			${responsive.LAPTOP_1100`
				font-size: 14px;
			`}
		}
	}

	.fourth-container {
		background: -webkit-radial-gradient(center, ellipse cover, #fff 0%, #cacaca 70%, #cacaca 100%);
	}

	.border {
		height: 15px;
		width: 2px;
		background: rgba(0, 0, 0, 0.5);
		margin: 0px 10px;
	}

	.bottom {
		justify-content: space-between;
		width: 100%;
		padding: 30px;
	}

	.class-pointer {
		cursor: pointer;
	}
`;

export const SocialMediaButton = styled.div`
	background-image: url(${({ background }) => background});
	height: 25px;
	width: 25px;
	background-repeat: no-repeat;
	background-size: contain;
	cursor: pointer;
`;

export const BoxAnimation = styled.div`
	position: relative;
	border-radius: 50%;
	border: 8px solid #e5e4e2;
	cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

	.star {
		position: absolute;
		z-index: 1;
		top: ${(props) => (props?.star && props.star.top ? props.star.top : "-20%")};
		right: ${(props) => (props?.star && props.star.right ? props?.star.right : 0)};
	}

	.feather {
		position: absolute;
		z-index: 1;
		right: ${(props) => (props?.feather && props.feather.right ? props.feather.right : "20%")};
		bottom: ${(props) => (props?.feather && props.feather.bottom ? props.feather.bottom : 0)};
	}

	.ball {
		position: absolute;
		z-index: 2;
		right: ${(props) => (props?.ball && props.ball.right ? props.ball.right : 0)};
		bottom: ${(props) => (props?.ball && props.ball.bottom ? props.ball.bottom : 0)};
	}

	.ribbon {
		position: absolute;
		z-index: 1;
		right: ${(props) => (props?.ribbon && props.ribbon.right ? props.ribbon.right : 0)};
		top: ${(props) => (props?.ribbon && props.ribbon.top ? props.ribbon.top : 0)};
	}

	@keyframes pulsate {
		0% {
			transform: scale(0.95, 0.95);
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			transform: scale(1.01, 1.01);
			opacity: 0;
		}
	}

	.pulse-base {
		z-index: 100;
		position: relative;
		height: 300px;
		width: 300px;
		border-radius: 50%;
	}

	.pulse-circle {
		background-image: url(${({ roundImage }) => (roundImage ? roundImage : CivicRoundImage)});
		background-size: contain;
		background-repeat: no-repeat;
	}

	.pulse-base:before {
		content: "";
		border: ${(props) => (props?.shadowColor ? `16px solid ${props.shadowColor}` : `16px solid #4169E1`)};
		border-radius: 50%;
		height: 300px;
		width: 300px;
		position: absolute;
		animation: pulsate 2s ease-out;
		animation-iteration-count: infinite;
		opacity: 0;
		z-index: -1;
		left: -16px;
		top: -15px;
	}
`;

import styled from "styled-components";
import { Dialog } from "@material-ui/core";
// STYLES
import { responsive } from "Styles/Constants";
// IMAGES
import { IMAGES } from "Helpers";

export const DialogWrapper = styled(Dialog)`
	.MuiBackdrop-root {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.MuiDialog-paper {
		background: transparent;
		color: black;
		overflow: visible;
	}

	.MuiDialog-paperWidthSm {
		max-width: 900px;
	}

	.MuiDialog-paperScrollPaper {
		max-height: unset;
	}

	.box-modal {
		width: 900px;
		height: 645px;
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url(${IMAGES.SCIENCE.HOW_TO_PLAY});

		${responsive.DISPLAY`
            width: 800px;
            height: 575px;
		`}

		${responsive.DESKTOP`
            width: 660px;
            height: 475px;
		`}

		.title-container {
			.sub-title {
				color: #fff;
				font-size: 24px;
				margin-top: 30px;
				margin-left: 20px;

				${responsive.DISPLAY`
                    font-size: 20px;
                    margin-top: 24px;
                    margin-left: 8px;
                `}

				${responsive.DESKTOP`
                    font-size: 18px;
                    margin-top: 22px;
                    margin-left: 10px;
                `}
			}
		}

		.content-wrapper {
			padding: 15px;
			height: 445px;
			position: relative;

			${responsive.DISPLAY`
                height: 390px;
                font-size: 16px;
            `}

			${responsive.DESKTOP`
                width: 660px;
                height: 325px;
                font-size: 12px;
            `}

			.scroll-bar {
				.thumb-vertical {
					background-color: #005ca8;
					margin-left: -15px;
				}
			}
			.content {
				width: 76%;
				margin: 6% auto 0px;
			}
			.attach-file {
				display: flex;
				justify-content: center;

				.file {
					margin: 0px 10px;
					font-size: 18px;
					cursor: pointer;
					text-decoration: underline;
				}

				.pdf-loader {
					width: 20px !important;
					height: 20px !important;
					color: #005ca8;
					margin-top: 1px;
				}
			}

			.progress {
				position: absolute;
				top: 36%;
				left: 0;
				right: 0;
				color: #000;
			}

			.type-switcher {
				.divider {
					background-color: black;
					height: 2px;
					width: 50%;
					margin: 5px auto;
					opacity: 0.5;
				}

				.text {
					cursor: pointer;
					transition: 0.3s;
					margin: 0px 0px 22px 29px;
					&:hover {
						color: black;
					}
				}
			}

			.play {
				color: #fff;
				font-size: 26px;
				cursor: pointer;
				margin-top: 47px;
				margin-left: 22px;

				${responsive.DISPLAY`
                    font-size: 24px;
                `}

				${responsive.DESKTOP`
                    font-size: 18px;
                    margin-top: 35px;
                `}
			}
		}
	}
`;

export const Indicators = styled.div`
	position: absolute;

	top: 3.5%;
	right: 45%;

	&.animated {
		&:before,
		&:after {
			content: "";
			position: absolute;
			transition: 0s;
			visibility: visible;
		}

		&.running {
			&:before,
			&:after {
				animation-duration: 7.5s;
				animation-fill-mode: forwards;
				animation-timing-function: linear;
			}

			&:after {
				animation-delay: 7.5s;
			}
		}
	}
`;

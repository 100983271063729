import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Typography } from "@material-ui/core";

//STYLES
import { PageContainer, SocialMediaButton, BoxAnimation } from "./GlobalLandingPage.style";
//IMAGES
import { ReactComponent as SubjectMasterLogo } from "Assets/Images/GlobalLandingPage/subject_master_logo.svg";
import BlueFeather from "Assets/Images/Background/blue-feather.png";
import BlueBall from "Assets/Images/Background/blue-ball.png";
import Star from "Assets/Images/Background/star.png";
import TopRoundImage from "Assets/Images/GlobalLandingPage/top-round-image.png";
//HELPERS
import { SOCIAL_MEDIA_MODULE, URL_LOGIN, URL_SUBSCRIPTION, URL_TEACHER_LOGIN } from "Helpers";
//MODAL
import PresentModal from "./Modal/PresentModal";
import RedirectModal from "./Modal";
import ContactUsModal from "./Modal/ContactUsModal";
//CONSTANT
import { SUBJECTS_DATA, isEvenOrNot, SOCIAL_MEDIA_LINKS, CODES, Api, URL_GENERAL_DASHBOARD, URL_SCHOOL_REGISTER, FOOTER_MODULE } from "Helpers";
//REDUX
import { showToast } from "Redux/General/Actions";
import { loginUser, subjectAccessData, logOutUser } from "Redux/Auth/Actions";

const GlobalLandingPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const pathname = useLocation();
	const queryDetails = new URLSearchParams(pathname?.search);

	const [isLoading, setIsLoading] = useState(false);
	const [presentModal, setPresentModal] = useState({
		open: false,
		type: "",
	});
	const [contactUsModal, setContactUsModal] = useState(false);

	const toggleModal = (type = "") => {
		setPresentModal({
			...presentModal,
			type,
			open: !presentModal.open,
		});
	};

	const handleSocialMediaLink = (media) => {
		const socialMediaUrl = `${SOCIAL_MEDIA_LINKS[media]}`;
		window.open(socialMediaUrl, "_blank", "noreferrer");
	};

	const signInFromClasslink = useCallback(async () => {
		const ssocode = queryDetails?.get("code");
		if (ssocode) {
			try {
				setIsLoading(true);
				const response = await new Api().post("classLink/user/login", { params: { ssoCode: ssocode }, returnError: true });
				if (response.status === 200 || response.status === 201) {
					const data = response?.data;
					if (data?.role === "student") {
						let storageData = {
							accessToken: data?.accessToken,
							role: data?.role ? data.role : "",
						};

						dispatch(logOutUser());
						dispatch(loginUser(storageData));
						dispatch(
							subjectAccessData({
								data: data?.subjectAccessData.length ? data?.subjectAccessData : [],
							})
						);
						navigate(URL_GENERAL_DASHBOARD, { replace: true });
					} else {
						if (data?.accessToken) window.location.href = `${process.env.REACT_APP_CMS_URL}login?token=${data.accessToken}`;
					}
				} else {
					setIsLoading(false);
					dispatch(showToast(response?.data?.message));
				}
			} catch (error) {
				setIsLoading(false);
				dispatch(showToast(error.data.message));
				if (error.status === CODES.FAILED_DEPENDENCY) {
					navigate(URL_SCHOOL_REGISTER, { state: { ssocode, schoolName: error?.data?.data } });
				}
			}
		}
	}, [dispatch, navigate]);

	useEffect(() => {
		signInFromClasslink();
	}, [signInFromClasslink]);

	return (
		<PageContainer className="flex f-column">
			{/* First container */}
			<div className="first-container">
				<div className="flex logo-button">
					<div className="logo">
						<SubjectMasterLogo width={"150px"} height={"120px"} />
					</div>

					<div className="button-class">
						<Button classes={{ contained: "subscription-button", text: "button-text" }} className="button" variant="contained" onClick={() => navigate(URL_SUBSCRIPTION)}>
							Subscription Plans
						</Button>
						<Button classes={{ contained: "login-button" }} className="button" variant="contained" onClick={() => navigate(URL_TEACHER_LOGIN)}>
							Portal Login
						</Button>
						<Button classes={{ contained: "login-button" }} className="button" variant="contained" onClick={() => navigate(URL_LOGIN)}>
							Login
						</Button>
					</div>
				</div>

				<div className="left-background-image" />
				<div className="right-background-image" />

				<div className="title-description">
					<div className="flex f-column first-div">
						<div style={{ fontSize: "64px" }}>
							<b>INSPIRING</b>
						</div>
						<div style={{ fontSize: "32px" }}>
							<b>ACADEMIC EMPOWERMENT</b>
						</div>

						<p className="header-title">Join us in Mastering Multiple Subjects</p>

						<p>
							Through instructional content and gaming. Our focus is to empower educators to embrace the dynamics of technology teaching by providing a platform in which they control the
							content. Implementing these two components allows the educator to be the decision maker on how Subjects Master can be used effectively with in-class instruction.
							Integrating technology with in-class instruction will reinforce what an educator is teaching and will enhance a student’s ability to retain the information.
						</p>
					</div>

					<img className="top-image" alt="top-round-img" src={TopRoundImage} />
				</div>
			</div>

			{/* Second Container */}
			<div className="flex f-column f-v-center f-h-center second-container">
				<h1 className="title">
					<b>Mastering</b>
				</h1>

				<div className="flex f-h-center f-v-center">
					<BoxAnimation onClick={() => toggleModal("CIVICS")}>
						<div className="pulse-base pulse-circle" />
						<img className="ball" alt="blue-ball" src={BlueBall} />
						<img className="star" alt="star" src={Star} />
						<img className="feather" alt="blue-feather" src={BlueFeather} />
					</BoxAnimation>

					<div className="right-box">
						<Typography variant="h4" classes={{ h4: "box-header" }}>
							Civics
						</Typography>
						<p className="subtitle">
							Civics Master wants to encourage everyone to get involved in learning about government and most importantly have the basic understanding of how government works for the
							American people. Being informed is the most effective way for a <span className="display-title">VOTER</span> to make its voice heard, a{" "}
							<span className="display-title">MAYOR</span> to effectively enhance its community, a <span className="display-title">GOVERNOR</span> to enable growth within its state, a{" "}
							<span className="display-title">REPRESENTATIVE</span> and/or <span className="display-title">SENATOR</span> to ensure its constituent’s rights are upheld or a{" "}
							<span className="display-title">PRESIDENT</span> to protect Americans from undue burden. Civics Master is looking forward to its users developing a sustainable appetite for
							government, motivating its users to get involved in the process while remaining optimistic it will inspire action.
						</p>
						<p className="sub-header">Click on the icon to learn more</p>
					</div>
				</div>
			</div>

			{/* third container */}
			<div className="flex f-column f-h-center f-v-center third-container">
				{SUBJECTS_DATA.map((subject) => {
					return (
						<div key={subject.id} className="flex f-h-center f-v-center">
							{isEvenOrNot(subject.id) && (
								<BoxAnimation
									roundImage={subject.image}
									shadowColor={subject.shadowColor}
									star={subject.star}
									feather={subject.feather}
									ball={subject.ball}
									ribbon={subject.ribbon}
									onClick={() => toggleModal(subject.name)}
								>
									<div className="pulse-base pulse-circle" />
									{subject?.star && <img className="star" alt="star" src={subject.star.image} />}
									{subject?.feather && <img className="feather" alt="feather" src={subject.feather.image} />}
									{subject?.ball && <img className="ball" alt="ball" src={subject.ball.image} />}
									{subject?.ribbon && <img className="ribbon" alt="ribbon" src={subject.ribbon.image} />}
								</BoxAnimation>
							)}
							<div className={`${isEvenOrNot(subject.id) ? "right-box" : "left-box"}`}>
								<Typography variant="h4" classes={{ h4: "box-header" }}>
									{subject.title}
								</Typography>
								<p className="subtitle">{subject.description}</p>
								<p className="sub-header">Click on the icon to learn more</p>
							</div>
							{!isEvenOrNot(subject.id) && (
								<BoxAnimation
									roundImage={subject.image}
									shadowColor={subject.shadowColor}
									star={subject.star}
									feather={subject.feather}
									ball={subject.ball}
									ribbon={subject.ribbon}
									onClick={() => toggleModal(subject.name)}
								>
									<div className="pulse-base pulse-circle" />
									{subject?.star && <img className="star" alt="star" src={subject.star.image} />}
									{subject?.feather && <img className="feather" alt="feather" src={subject.feather.image} />}
									{subject?.ball && <img className="ball" alt="ball" src={subject.ball.image} />}
									{subject?.ribbon && <img className="ribbon" alt="ribbon" src={subject.ribbon.image} />}
								</BoxAnimation>
							)}
						</div>
					);
				})}
			</div>

			{/* Fourth container */}
			<div className="flex f-column f-h-center f-v-center fourth-container">
				<div className="flex bottom">
					<div>
						<div className="flex f-v-center social-media-container">
							{SOCIAL_MEDIA_MODULE.map((item) => {
								return <SocialMediaButton key={item.id} background={item.img} onClick={() => handleSocialMediaLink(item.title)} />;
							})}
						</div>
						<div>© Subjects Master LLC. All rights reserved.</div>
					</div>
					<div className="flex f-h-center f-v-center">
						{FOOTER_MODULE.map((item) => {
							return (
								<div key={item.id} className="flex">
									<div
										className="class-pointer"
										onClick={() => {
											if (item.title === "CONTACT US") {
												setContactUsModal(!contactUsModal);
											} else {
												navigate(item.path);
											}
										}}
									>
										{item.title}
									</div>
									{FOOTER_MODULE.length !== item.id && <div className="border" />}
								</div>
							);
						})}
					</div>
				</div>
			</div>
			{presentModal.open && <PresentModal presentModal={presentModal} handleClose={toggleModal} />}
			{isLoading && <RedirectModal open={isLoading} title={"Redirecting, Please Wait..."} />}
			{contactUsModal && <ContactUsModal open={contactUsModal} handleClose={() => setContactUsModal(!contactUsModal)} />}
		</PageContainer>
	);
};

export default GlobalLandingPage;

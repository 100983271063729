import styled from "styled-components";
import { Dialog } from "@material-ui/core";
import { responsive } from "Styles/Constants";

export const DialogWrapper = styled(Dialog)`
	position: relative;

	.paper {
		margin: 10px;
	}
	.paper-width {
		max-width: 500px;
		width: 500px;

		${responsive.LAPTOP`
			width: 500px;
			height: 600px;
		`}
	}

	.container {
		margin: 8px;
		height: 100%;
		background-color: #cecece;

		.header-title {
			text-align: center;
			font-size: 24px;
			margin: 16px auto;
		}
	}

	.form-container {
		margin: 20px;
		gap: 20px;

		.submit-button {
			background-color: rgba(0, 0, 0, 0.87);
		}

		.loader {
			width: 30px;
			height: 30px;
			color: #ffffff;
		}

		.label {
			margin-bottom: 8px;
		}

		.input-error-message {
			color: #ff0006;
			width: 100%;
			height: 20px;
		}
	}

	.close-button-image {
		width: 30px;
		height: 30px;
		position: absolute;
		right: 0;
		cursor: pointer;
	}
`;

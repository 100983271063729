import styled from "styled-components";

// STYLES
import { PageWrapper as RegisterPageWrapper, FormWrapper as Form } from "Components/Pages/Signup/Signup.style";

import { responsive, FONTS } from "Styles/Constants";
import { TextField } from "@material-ui/core";
import { IMAGES } from "Helpers";
import { TextareaAutosize } from "@material-ui/core";

export const PageWrapper = styled(RegisterPageWrapper)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.header-container {
		background-color: rgba(255, 255, 255, 0.9);
		position: absolute;
		top: 0;
		width: 100%;
		.top-bar {
			display: flex;
			justify-content: space-between;
			padding: 1%;
			width: 90%;
			margin: 0px auto;
			.subject-logo {
				background-image: url(${IMAGES.DASHBOARD.SUBJECT_MASTER_LOGO_BLACK});
				width: 110px;
				height: 40px;
				background-repeat: no-repeat;
				background-size: contain;
				cursor: pointer;
			}
			.btn-container {
				display: flex;
				width: auto;
				margin-right: 0;
				.teacher-btn {
					border-radius: 29.5px;
					width: 180px;
					border: solid 2px rgba(4, 23, 39, 0.3);
					display: flex;
					align-items: center;
					padding: 0px 14px;
					justify-content: center;
					cursor: pointer;
				}
			}
		}
	}

	.main-container {
		width: 770px;
		margin: 0px auto;
		display: flex;
		flex-direction: column;
		background: linear-gradient(to bottom, #fff600, #fffcb3, #fffede, #f7f7eb, #fff);
		border-radius: 10px;
		box-shadow: 0 0 20.9px 3.1px rgba(4, 23, 39, 0.5);
		padding: 40px 30px 20px;
		color: #000;
		${responsive.DISPLAY`width : 670px;`}

		.title {
			color: #000;
			margin-top: 0px;
			margin-bottom: 8px;

			.text {
				margin: 7px auto 2px;
				font-family: ${FONTS.TERTIARY_BOLD};
				font-size: 26px;
				color: #010101;
				margin-top: 0;
			}
		}

		.name-container {
			display: flex;
			justify-content: space-between;
			width: 100%;
			gap: 20px;
		}
		.input-data {
			display: flex;
			flex-direction: column;
			width: 100%;
			margin: 10px 0px;
			.name {
				width: 100%;
			}
			.label {
				font-family: ${FONTS.TERTIARY_SEMI_BOLD};
				font-size: 16px;
				margin: 0 6px;
				${responsive.DISPLAY`
					font-size: 13px;
				`}
			}
			.error {
				color: #ff0006;
				font-size: 13px;
				font-family: ${FONTS.TERTIARY_SEMI_BOLD};
				margin-left: 8px;
				margin-top: 2px;
			}

			.message {
				margin-top: 10px;

				border: 1px solid grey;
				height: 25px;
				width: 100%;
				border-radius: 10px;
				color: black;
				padding: 10px 20px 5px;

				${responsive.DISPLAY`
					padding: 7px 15px;
				`}

				${responsive.DISPLAY`margin-top: 7px;`}
			}
		}

		.login-button-parent {
			width: 80%;
			margin: 0px auto;
			max-width: 230px;
			.button {
				background-color: black;
				color: white;
				width: 100%;
				padding: 0.7rem;
				border-radius: 10px;
				margin-top: 30px;
				border-color: #000;
				cursor: pointer;

				.login-font {
					font-family: ${FONTS.TERTIARY_SEMI_BOLD};
					font-size: 18px;
					font-weight: 600;
					text-align: center;
					margin: 0px;
					padding: 0px;
				}
				.loader {
					height: 20px !important;
					width: 20px !important;
					color: white;
					margin-left: 10px;
				}
			}
		}
	}

	.content {
		/* position: absolute; */
		height: 100%;
		width: 100%;
		padding: 39px 53px 80px 55px;
		object-fit: contain;
		border-radius: 10px;
		box-shadow: 0 0 20.9px 3.1px rgba(4, 23, 39, 0.5);
		background-image: linear-gradient(to bottom, #fff600, #fffcb3, #fffede, #f7f7eb, #fff);

		.title {
			.text {
				color: black;
				margin-top: 0;
				font-family: ${FONTS.TERTIARY};
			}
		}
		.outer-box {
			gap: 2%;
			.box-container {
				display: flex;
				.email {
					margin-left: 4%;
				}
			}
		}

		.login-button-parent {
			.button {
				background-color: black;
				color: white;
				width: 100%;
				padding: 1rem;
				border-radius: 10px;
				margin-bottom: auto;
				border-color: #000;
				cursor: pointer;

				.login-font {
					font-family: ${FONTS.TERTIARY_SEMI_BOLD};
					font-size: 18px;
					font-weight: 600;
					text-align: center;
					margin: 0px;
					padding: 0px;
				}
			}
			.loader {
				height: 20px !important;
				width: 20px !important;
				color: white;
			}
		}
	}

	.box {
		width: 670px;
		margin: 0px auto;
		display: flex;
		flex-direction: column;
		background: linear-gradient(to bottom, #fff600, #fffcb3, #fffede, #f7f7eb, #fff);
		border-radius: 10px;
		box-shadow: 0 0 20.9px 3.1px rgba(4, 23, 39, 0.5);
		padding: 40px 30px 20px;
		color: #000;
		${responsive.DISPLAY`width : 570px;`}
		.title {
			color: #000;
			margin-top: 0px;
			margin-bottom: 8px;

			.text {
				margin: 7px auto 2px;
				font-family: ${FONTS.TERTIARY_BOLD};
				font-size: 26px;
				color: #010101;
				margin-top: 0;
			}
		}

		.login-button-parent {
			width: 80%;
			margin: 0px auto;
			max-width: 230px;
			.button {
				background-color: black;
				color: white;
				width: 100%;
				padding: 0.7rem;
				border-radius: 10px;
				margin-top: 30px;
				border-color: #000;
				cursor: pointer;

				.login-font {
					font-family: ${FONTS.TERTIARY_SEMI_BOLD};
					font-size: 18px;
					font-weight: 600;
					text-align: center;
					margin: 0px;
					padding: 0px;
				}
				.loader {
					height: 20px !important;
					width: 20px !important;
					color: white;
					margin-left: 10px;
				}
			}
		}

		.image-box {
			display: flex;
			justify-content: flex-start;
			.image-wrapper {
				border: 2px solid #000;
				border-radius: 50%;
				/* cursor: pointer; */
				margin: 15px 0;
				height: 90px;
				width: 90px;
				background-size: cover;
				background-position: center;

				${responsive.DISPLAY`
						height	: 70px;
						width	: 70px;
					`}
			}
		}

		.edit-btn-container {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 30px;
			width: 130px;
			.button-edit {
				width: 100%;
				border: 1px solid;
				border-radius: 10px;
				padding: 7px;
				.login-font {
					font-family: ${FONTS.TERTIARY};
					text-transform: capitalize;
				}
			}
		}
	}
`;

export const TextFieldWrapper = styled(TextField)`
	.text-root {
		border-radius: 10px;
		font-family: ${FONTS.TERTIARY_SEMI_BOLD} !important;
		height: 40px;
	}

	.label {
		top: -11px;
		left: -8px;
		color: #c9c8b066;
	}

	.gutters {
		padding: 2px 0;
		.text {
			font-family: ${FONTS.TERTIARY_LIGHT};
		}
	}
`;

export const StyledTextarea = styled(TextareaAutosize)`
	border-radius: 10px;
	/* color: rgba(1, 1, 1, 0.5); */
	padding: 10px 20px 5px;
	background-color: transparent;
	border: 1px solid grey;
	resize: vertical;
	font-family: ${FONTS.TERTIARY};
	font-weight: normal;
	font-size: 16px;
	margin-top: 8px;
	&::placeholder {
		font-family: ${FONTS.TERTIARY};
		font-weight: normal;
		font-size: 13px;
		color: #00000036;
		word-spacing: 1px;
		letter-spacing: 1px;
	}
`;

export const FormWrapper = styled(Form)``;

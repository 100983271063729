import io from "socket.io-client";
import Manager from "socket.io-client/lib/manager";

/** @type {Manager} */
let Socket;
const END_URL = process.env.REACT_APP_SOCKET_URL;

const connectSocket = () => {
	const credentials = {
		id: localStorage.getItem("profileInfo") ? JSON.parse(localStorage.getItem("profileInfo"))?.id : "",
	};

	Socket = io(END_URL, {
		transports: ["websocket"],
		query: credentials,
		autoConnect: false,
		reconnection: true,
		reconnectionAttempts: 5,
		allowEIO3: true,
		pingInterval: 30000,
		pingTimeout: 30000,
	});
};

export { connectSocket, Socket };

import styled from "styled-components";

export const PageWrapperBase = styled.div`
	background-size: 100% 100%;
	background-position: center;
	background-repeat: no-repeat;
	min-height: 100vh;
`;

export const ContentWrapper = styled.div`
	min-height: 100vh;
`;

import React from "react";
import styled, { keyframes } from "styled-components";
import { Button, InputBase, Menu as MuiMenu } from "@material-ui/core";
import { FONTS, COLORS, responsive } from "Styles/Constants";
import { IMAGES } from "Helpers";

const borderAnimation = keyframes`
    0% { opacity : 0 }
    50% { opacity : 1 }
    100% { opacity : 0;}
`;

/* BUTTONS */
export const ButtonWrapper = styled(({ primaryFonts, ...rest }) => <Button {...rest} />)`
	border-radius: 50px;
	border: 3px solid #fff;
	color: #fff;
	font-family: ${(props) => (props.primaryFonts ? FONTS.PRIMARY : FONTS.SECONDARY_SEMI_BOLD)};
	font-size: 18px;
	letter-spacing: 1px;
	min-width: 150px;
	min-height: 50px;
	position: relative;
	z-index: 0;

	${responsive.DISPLAY` min-width: 125px; min-height: 45px;`}

	.MuiButton-label {
		${responsive.DISPLAY` font-size: 15px;`}
	}

	&.Mui-disabled {
		color: inherit;
		opacity: 0.5;
	}
	.extra-border {
		border: 3px solid ${COLORS.SECONDARY};
		border-radius: 40px;
		background: #000017;

		position: absolute;
		z-index: -2;

		width: calc(100% + 30px);
		height: calc(100% + 27px);
	}

	&::before {
		animation: rounded 20s linear infinite;
		content: "";
		/* background  : linear-gradient(45deg, ${COLORS.SECONDARY}, #ffffff, ${COLORS.SECONDARY}, #ffffff); */
		background: linear-gradient(45deg, #42defb 3%, #fff 3% 50%, #32defb 50% 53%, #fff 53% 100%);
		border-radius: 35px;
		background-size: 400%;
		box-shadow: 0px 0px 11px 2px ${COLORS.SECONDARY};
		filter: blur(2px);
		position: absolute;
		top: -2px;
		left: -2px;
		z-index: -1;
		height: calc(100% + 4px);
		transition: opacity 0.3s ease-in-out;
		width: calc(100% + 4px);
	}

	&::after {
		border-radius: 35px;
		background: #02041e;
		box-shadow: inset 0 0 9px ${COLORS.SECONDARY};
		content: "";
		position: absolute;
		height: 100%;
		left: 0;
		top: 0;
		width: 100%;
		z-index: -1;
	}

	&.green {
		&::before {
			background: linear-gradient(45deg, #4caf50, rgb(255, 255, 255), #4caf50, rgb(255, 255, 255)) 0% 0% / 400%;
			box-shadow: 0px 0px 11px 4px #378b06;
		}
		&::after {
			box-shadow: inset 0 0 15px #378b06;
		}
	}
	&.red {
		&::before {
			background: linear-gradient(45deg, rgb(255, 0, 0), rgb(255, 255, 255), rgb(255, 0, 0), rgb(255, 255, 255)) 0% 0% / 400%;
			box-shadow: 0px 0px 11px 4px #ff000094;
		}
		&::after {
			box-shadow: inset 0 0 15px #ff000094;
		}
	}

	&.blinking {
		&::before {
			animation-name: ${borderAnimation};
			animation-duration: 1s;
			animation-direction: reverse;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}
	}

	@keyframes rounded {
		0% {
			background-position: 0 0;
		}
		50% {
			background-position: 400% 0;
		}
		100% {
			background-position: 0 0;
		}
	}

	.icon-container {
		position: absolute;

		&.start {
			left: 20px;
			.image {
				transform: rotate(180deg);
			}
		}
		&.end {
			right: 20px;
		}
	}
`;

export const SimpleButtonWrapper = styled(({ primaryFonts, ...rest }) => <Button {...rest} />)`
	border-radius: 50px;
	border: 3px solid #fff;
	color: #fff;
	font-family: ${(props) => (props.primaryFonts ? FONTS.PRIMARY : FONTS.SECONDARY_SEMI_BOLD)};
	font-size: 18px;
	letter-spacing: 1px;
	min-width: 150px;
	min-height: 50px;
	position: relative;
	z-index: 0;

	${responsive.DISPLAY` min-width: 125px; min-height: 45px;`}

	.MuiButton-label {
		${responsive.DISPLAY` font-size: 15px;`}
	}

	&.Mui-disabled {
		color: inherit;
		opacity: 0.5;
	}
	.extra-border {
		border: 3px solid ${COLORS.SECONDARY};
		border-radius: 40px;
		background: #000017;

		position: absolute;
		z-index: -2;

		width: calc(100% + 30px);
		height: calc(100% + 27px);
	}

	&::before {
		/* animation: rounded 20s linear infinite; */
		content: "";
		/* background  : linear-gradient(45deg, ${COLORS.SECONDARY}, #ffffff, ${COLORS.SECONDARY}, #ffffff); */
		/* background: linear-gradient(
      45deg,
      #42defb 3%,
      #fff 3% 50%,
      #32defb 50% 53%,
      #fff 53% 100%
    ); */
		border-radius: 35px;
		background-size: 400%;
		box-shadow: 0px 0px 11px 2px ${COLORS.SECONDARY};
		filter: blur(2px);
		position: absolute;
		top: -2px;
		left: -2px;
		z-index: -1;
		height: calc(100% + 4px);
		transition: opacity 0.3s ease-in-out;
		width: calc(100% + 4px);
	}

	&::after {
		border-radius: 35px;
		/* background: #02041e; */
		box-shadow: inset 0 0 9px ${COLORS.SECONDARY};
		content: "";
		position: absolute;
		height: 100%;
		left: 0;
		top: 0;
		width: 100%;
		z-index: -1;
	}

	&.green {
		&::before {
			/* background: linear-gradient(
          45deg,
          #4caf50,
          rgb(255, 255, 255),
          #4caf50,
          rgb(255, 255, 255)
        )
        0% 0% / 400%; */
			box-shadow: 0px 0px 11px 4px #378b06;
		}
		&::after {
			box-shadow: inset 0 0 15px #378b06;
		}
	}
	&.red {
		&::before {
			background: linear-gradient(45deg, rgb(255, 0, 0), rgb(255, 255, 255), rgb(255, 0, 0), rgb(255, 255, 255)) 0% 0% / 400%;
			box-shadow: 0px 0px 11px 4px #ff000094;
		}
		&::after {
			box-shadow: inset 0 0 15px #ff000094;
		}
	}

	&.blinking {
		&::before {
			animation-name: ${borderAnimation};
			animation-duration: 1s;
			animation-direction: reverse;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}
	}

	@keyframes rounded {
		0% {
			background-position: 0 0;
		}
		50% {
			background-position: 400% 0;
		}
		100% {
			background-position: 0 0;
		}
	}

	.icon-container {
		position: absolute;

		&.start {
			left: 20px;
			.image {
				transform: rotate(180deg);
			}
		}
		&.end {
			right: 20px;
		}
	}
`;

/* INPUT */
export const TextField = styled(InputBase)`
	input {
		/* border: 1px solid #332e44;
		border-radius: 8px;
		color: ${(props) => (props?.signupform && props.signupform ? "#000" : "rgba(255, 255, 255, 0.8)")};
		color: rgba(255, 255, 255, 0.8);
		font-family: ${FONTS.SECONDARY};
		padding: 15px 25px;
		transition: 0.3s;
		background-color: ${(props) => props?.signupform && props.signupform && "#fff"};
		${responsive.DISPLAY`font-size : 13px;`}
		&:focus {
			border-color: ${COLORS.SECONDARY};
		}
		${responsive.DISPLAY`padding : 13px 20px;`} */

		border: 1px solid grey;
		height: 25px;
		width: 100%;
		border-radius: 10px;
		color: black;
		padding: 10px 20px 5px;

		${responsive.DISPLAY`
        padding: 7px 15px;
    `}
	}
	input::placeholder {
		color: rgba(1, 1, 1, 0.5);
		font-family: ${FONTS.TERTIARY};
		font-size: 13px;
	}
	.MuiInputBase-input {
		font-family: ${FONTS.TERTIARY};
		font-size: 13px;
	}
`;

export const InputWrapper = styled.div`
	position: relative;
	text-align: left;
	.label {
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
		/* text-transform: uppercase; */
		letter-spacing: 1px;
		color: ${(props) => props?.signupform && props.signupform && "#fff"};
		margin-left: ${(props) => props?.signupform && props.signupform && "10px"} ${responsive.DISPLAY`font-size : 16px;`};
	}

	/* .number {
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	} */

	${TextField} {
		margin-top: 10px;

		${responsive.DISPLAY`margin-top: 7px;`}
	}

	.error {
		bottom: -17px;
		color: ${COLORS.PRIMARY};
		font-size: 13px;
		left: 10px;
		position: absolute;
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
	}

	.confirmPassword-error {
		color: ${COLORS.PRIMARY};
		font-size: 13px;
		left: 10px;
		position: absolute;
		font-family: ${FONTS.TERTIARY_SEMI_BOLD};
	}
`;

export const SelectWrapper = styled(InputWrapper)`
	.select {
		position: relative;
		input {
			cursor: pointer;
			&:focus {
				border-color: #332e44;
			}
		}

		.icon-container {
			position: absolute;
			right: 10px;
			top: 7px;

			display: flex;
			align-items: center;
			height: calc(100% - 7px);

			.icon {
				fill: rgba(255, 255, 255, 0.5);

				height: 22px;
				width: 22px;
			}
		}
	}
`;

export const Menu = styled(MuiMenu)`
	.MuiMenu-paper {
		background: rgba(1, 0, 28, 0.7);
		color: #fff;
		border: 2px solid aqua;
		min-width: 200px;
	}

	.menu-item {
		&:hover {
			color: ${COLORS.SECONDARY};
		}
	}
`;

export const ForeignLangQuizBtnWrapper = styled.div`
	position: relative;
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	cursor: pointer;
	border: 3px solid ${COLORS.WHITE};
	border-radius: 50px;

	@keyframes rounded {
		0% {
			background-position: 0 0;
		}
		50% {
			background-position: 400% 0;
		}
		100% {
			background-position: 0 0;
		}
	}

	::before {
		content: "";
		background: linear-gradient(45deg, rgba(238, 74, 0, 1) 3%, rgba(255, 255, 255, 1) 50%, rgba(254, 171, 79, 1) 53%, rgba(255, 255, 255, 1) 100%);
		border-radius: 50px;
		background-size: 400%;
		box-shadow: 0px 0px 5px 2px ${COLORS.PRIMARY};
		filter: blur(2px);
		position: absolute;
		top: -2px;
		left: -2px;
		height: calc(100% + 4px);
		width: calc(100% + 4px);
		animation: rounded 20s linear infinite;
		transition: opacity 0.1s ease-in-out;
	}

	::after {
		content: "${(props) => props.btnText}";
		display: flex;
		justify-content: center;
		align-items: center;
		color: ${COLORS.WHITE};
		font-size: ${(props) => (props.fontSize ? props.fontSize : "54px")};
		font-weight: bold;
		text-transform: uppercase;
		text-shadow: 3.2px 2.4px 0 rgba(0, 0, 0, 0.13);
		letter-spacing: 2.18px;
		border-radius: 50px;
		background-image: url(${IMAGES.FOREIGN.QUIZ.PLAY_BUTTON});
		background-position: center;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		right: 0;
	}
`;
